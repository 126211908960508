import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Cache from '../../../services/Cache';
import Spinner from '../../../components/Spinner';

const CacheService = new Cache();

export default class ClassificationModal extends Component {
  state = {
    showModal: false,
    loading: false,
    data: [],
    filterValue: '',
  }

  filter = (event) => {
    var val = event.target.value.toLowerCase();
    this.setState({ filterValue: val }, () => {
      this.setState((prev) => {
        if (val && val.length > 0) {
          for (var i in prev.data) {
            if (!prev.data[i].hasChild && prev.data[i].name.toLowerCase().indexOf(val) > -1) {
              prev.data[i].visibility = true;
              prev.data[i].filtered = true;
            } else {
              prev.data[i].visibility = false;
              prev.data[i].filtered = false;
            }
          }
          return { data: prev.data }
        } else {
          for (var j in prev.data) {
            prev.data[j].filtered = true;
            prev.data[j].collapse = true;
            prev.data[j].visibility = true;
            if (prev.data[j].hasChild) {
              this.collapseAllChild(prev.data, prev.data[j].id);
            }
          }
        }
        return { data: prev.data }
      });
    });
  }

  showModal = () => {
    this.setState({ showModal: true, loading: true });
    CacheService.getCacheDataFromStorage('classification')
      .then((data) => {
        //let obj = [];
        let sorted = [];
        function findChildsRecursive(id, level, arr) {
          let childs = [];
          for (var i in arr) {
            if (arr[i].parent === id) {
              arr[i].level = level + 1;
              arr[i].visibility = true;
              arr[i].hasChild = false;
              childs.push(arr[i]);
              let c = findChildsRecursive(arr[i].id, level + 1, arr);
              for (var j in c) {
                arr[i].hasChild = true;
                childs.push(c[j]);
              }
            }
          }
          return childs;
        }
        /* Leave it here for future reference
        for (var i in data) {
          if (!data[i].parent) {
            data[i].level = 0;
            data[i].visibility = true;
            data[i].collapse = true;
          }
          if (data[i].parent) {
            for (var j in obj) {
              if (obj[j].id === data[i].parent) {
                data[i].level = obj[j].level + 1;
              }
            }
          }
          obj.push(data[i]);
        }
         * */
        // root
        for (var i in data) {
          if (!data[i].parent || (data[i].parent && data[i].parent.length !== 36)) {
            data[i].level = 0;
            data[i].visibility = true;
            data[i].collapse = false;
            data[i].hashChild = true;
            sorted.push(data[i]);
            break;
          }
        }
        // The entire childs, sorted
        if (sorted.length > 0) {
          let childs = findChildsRecursive(sorted[0].id, sorted[0].level, data);
          if (childs.length > 0) {
            for (var j in childs) {
              sorted.push(childs[j]);
            }
          }
        }
        console.log(sorted);
        this.setState({ data: sorted, loading: false });
      })
      .catch((err) => {
        console.log(err)
        // handle err
        this.setState({ showModal: false, loading: false, data: [] });
      });
  }
  hideModal = () => {
    this.setState({ showModal: false });
  }

  collapseAllChild = (data, parentId) => {
    for (var i in data) {
      if (data[i].parent === parentId) {
        data[i].collapse = true;
        data[i].visibility = false;
        if (data[i].hasChild) {
          this.collapseAllChild(data, data[i].id);
        }
      }
    }
  }

  handleClick = (index) => {
    if (!this.state.data[index].hasChild) {
      let e = {
        target: {
          name: this.props.name,
          value: this.state.data[index].name,
          raw: this.state.data[index].id
        }
      }
      this.props.onChange(e);
      this.hideModal();
      return;
    }
    this.setState((prev) => {
      let s = {};
      prev.data[index].current = true;
      if (index === 0 && !prev.data[index].collapse) {
        s = prev.data;
        return s;
      }
      for (var i in prev.data) {
        if (prev.data[i].parent === prev.data[index].id) {
          prev.data[i].visibility = prev.data[index].collapse;
          if (prev.data[i].hasChild) prev.data[i].collapse = true;
          if (!prev.data[index].collapse) {
            this.collapseAllChild(prev.data, prev.data[index].id);
          }
        }
      }
      prev.data[index].collapse = !prev.data[index].collapse;
      s.data = prev.data
      return s;
    });
  }

  onChange = (e) => {
    // dummy onChange
  }

  render() {
    const {
      label,
      name,
      required,
      maxChar,
      labelWidth,
      fieldWidth,
      additionalLabel,
      disabled,
      placeholder,
    } = this.props;
    return (
      <div className="form-group row" style={{ marginTop: (fieldWidth && fieldWidth.toString() === '12') ? 0 : 10 }}>
        {label && <label className={`col-md-${labelWidth ? labelWidth.toString() : '1'} col-sm-${labelWidth ? labelWidth.toString() : '2'} control-label`}>
          {label}
          &nbsp;<span className="red">{required && '*'}</span>
        </label>
        }
        <div className={`col-md-${fieldWidth ? fieldWidth.toString() : '10'} col-sm-${fieldWidth ? fieldWidth.toString() : '10'}`}>
          <input
            onClick={this.showModal}
            name={name}
            value={this.props.value}
            required={required}
            className="form-control"
            disabled={disabled}
            placeholder={placeholder}
            onChange={this.onChange}
            autoComplete="off"
          />
          {additionalLabel ? <span style={{ float: 'left', color: 'red' }}>{additionalLabel}</span> : ''}
          {maxChar && maxChar > 0 ? <span style={{ float: 'right' }}>{this.state.value.length}/{maxChar}</span> : ''}
        </div>
        <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Klasifikasi
                </h4>
                <span className="close" onClick={this.hideModal}><Fa.FaClose /></span>
                <hr />
              </div>
              <div className="modal-body">
                <div style={{ display: this.state.loading ? 'none' : 'inherit' }}>
                  <div className="input-group input-group-sm">
                    <input value={this.state.filterValue} className="form-control" onChange={this.filter} disabled={this.state.loading} />
                    <span className="input-group-btn">
                      {this.state.filterValue.length > 0 ?
                        <span className="btn btn-default" onClick={() => { this.filter({ target: { value: '' } }) }}>
                          <Fa.FaClose />
                        </span>
                        :
                        <span className="btn btn-default">
                          <Fa.FaSearch />
                        </span>
                      }
                    </span>
                  </div>
                </div>
                <div className="box box-success" style={{ marginTop: 15 }}>
                  {this.state.loading ?
                    <Spinner />
                    :
                    <ul style={{ padding: 0, height: 250, overflow: 'auto' }}>
                      {Object.keys(this.state.data).map((item, index) => {
                        return <li
                          key={index}
                          style={{
                            padding: 10,
                            display: this.state.data[item].visibility ? 'block' : 'none',
                            paddingLeft: this.state.data[item].level === 0 ||
                              this.state.data[item].filtered ? 15 :
                                this.state.data[item].level === 1 ? 30 : (
                                  this.state.data[item].level === 2 ? 45 : (
                                    this.state.data[item].level === 3 ? 60 : (
                                      this.state.data[item].level === 4 ? 75 : (
                                        this.state.data[item].level === 5 ? 90 : (
                                          this.state.data[item].level === 6 ? 105 : (
                                            this.state.data[item].level === 7 ? 105 : (
                                              this.state.data[item].level === 8 ? 120 : (
                                                this.state.data[item].level === 9 ? 135 : 150 )))))))),
                            cursor: 'pointer',
                            background: !this.state.data[item].current ? 'none' : '#F7F7F7',
                          }}
                          onClick={() => { this.handleClick(index) }}
                        >
                          {!this.state.data[item].hasChild ? <Fa.FaCircleO /> :
                            !this.state.data[item].collapse ? <Fa.FaCaretDown /> :
                              this.state.data[item].hasChild && <Fa.FaCaretRight />
                          }
                          &nbsp;
                        {this.state.data[item].name}
                        </li>
                      })}
                    </ul>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
