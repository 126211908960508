import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';

export default class HelpdeskPage extends Component {

  render() {
    return (
      <div>
        {this.props.header &&
          <div style={{marginBottom: 20}}>
            <h4 style={{paddingTop: 10}}><Fa.FaUserMd size={50}/>&nbsp;Office Automation Surelintas</h4>
          </div>
        }
        <div style={{marginBottom: 20}}>
          <h5>Layanan Bantuan Pengguna</h5>
        </div>
        <div style={{marginBottom: 20}}>
          <h5 style={{paddingTop: 10}}><Fa.FaEnvelopeO size={40} />&nbsp;Email: oa@citra.digital</h5>
        </div>
        <div style={{marginBottom: 20}}>
          <h5 style={{paddingTop: 10}}><Fa.FaVolumeControlPhone size={40} />&nbsp;Telp: 021-50820031</h5>
        </div>
        <div style={{marginBottom: 20}}>
          <h5 style={{paddingTop: 10}}><Fa.FaWhatsapp color="green" size={40} />&nbsp;Whatsapp: +62 856-9265-7507</h5>
        </div>
      </div>
    )
  }
}