class Dummy {

	getAddressBook = () => {
		/*
			  let jabatan = [
				{
				  name : 'Kepala Divisi Demo I',
				  id : 'kepaladivisidemo1',
				},
				{
				  name : 'Kepala Divisi Demo II',
				  id : 'kepaladivisidemo2',
				},
				{
				  name : 'Kepala Divisi Demo III',
				  id : 'kepaladivisidemo3',
				},
				{
				  name : 'Sekretaris Demo I',
				  id : 'sekretarisdemo1',
				},
				{
				  name : 'Sekretaris Demo II',
				  id : 'sekretarisdemo2[',
				},
				{
				  name : 'Wakil Direktur Demo',
				  id : 'wakildirekturdemo',
				},
				{
				  name : '00. DIREKTORAT JENDERAL PERBENDAHARAAN',
				  id : '00direktoratjendralperbendaharaan',
				  hasChild : true,
				},
				{
				  name : 'Direktur Jenderal Perbendaharaan',
				  id : 'direkturjenderalperbendaharaan',
				  parent : '00direktoratjendralperbendaharaan',
				},
				{
				  name : '01. DIREKTORAT PEMBINAAN PK BLU',
				  id : '01direktoratpembinaanpkblu',
				  parent : '00direktoratjendralperbendaharaan',
				  hasChild : true,
				},
				{
				  name : 'Direktur Pembinaan PK BLU',
				  id : 'direkturpembinaanpkblu',
				  parent : '01direktoratpembinaanpkblu',
				},
			  ]
	    
			  // Dunno whether if this list generated from jabatan's list or not
			  let personal = [
				{
				  name : 'Abdul Rahman',
				  id : 'abdulrahman',
				  title : 'Kepala Divisi Anggaran',
				},
				{
				  name : 'Acep Ramzi Zamzami',
				  id : 'acepramzi',
				  title : 'Plt Kepala Divisi Pengelolaan Aset',
				},
				{
				  name : 'Achmad Bachrudin',
				  id : 'achmadbachrudin',
				  title : 'Pelaksana 2 Seksi Setelmen I',
				}
			  ]
	    
			  for (var i = 0; i < 10; i++) {
				personal.push({name : Faker.name.findName(), id : Faker.helpers.randomize(), title : Faker.address.country()});
			  }
	    
			  return {jabatan : jabatan, personal : personal};
		 * */
	}

	getClassification = () => {
		let obj = [
			{
				name: 'DEMO',
				id: '00',
				hasChild: true,
			},
			{
				name: '01 TARIF',
				id: '01',
				parent: '00',
				hasChild: true,
			},
			{
				name: 'DEMO.01 Tarif',
				parent: '01',
				id: '011'
			},
			{
				name: '02 RENUMERASI',
				id: '02',
				parent: '00',
				hasChild: true,
			},
			{
				name: 'DEMO.02 Renumerasi',
				parent: '02',
				id: '021',
			},
			{
				name: '03 PERATURAN',
				id: '03',
				parent: '00',
				hasChild: true,
			},
			{
				name: 'DEMO.03 Peraturan',
				parent: '03',
				id: '031',
			},
			{
				name: '04 PEMBINAAN DAN KONSULTASI',
				id: '04',
				parent: '00',
				hasChild: true,
			},
			{
				name: 'DEMO.04 Pembinaan dan Konsultasi',
				parent: '04',
				id: '041',
			},
			{
				name: '05 RBA - RKAT',
				id: '05',
				parent: '00',
				hasChild: true,
			},
			{
				name: 'DEMO.05 RBA - RKAT',
				parent: '05',
				id: '051',
			},
			{
				name: '06 LAPORAN KEUANGAN',
				id: '06',
				parent: '00',
				hasChild: true,
			},
			{
				name: 'DEMO.06 Laporan Keuangan',
				parent: '06',
				id: '061',
			}
		]
		return obj;
	}

	getLetterReferences = (opt) => {
		opt = opt || {};
		opt.page = opt.page || 1;
		opt.limit = opt.limit || 10;
		let obj = [
			// Page 1
			[
				{
					letterId: 'ND-001/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-002/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-003/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-004/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-005/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-006/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-007/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-008/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-009/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-0010/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
			],
			// Page 2
			[
				{
					letterId: 'ND-011/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-012/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-013/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-014/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-015/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-016/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-017/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-018/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-019/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
				{
					letterId: 'ND-020/IP.1/2017',
					title: 'Test Surat',
					to: 'Direktur Keuangan Umum dan Sistem Informasi',
					date: '09 April 2018',
				},
			]
		];
		return {
			data: obj[opt.page - 1],
			page: opt.page,
			total: obj[0].length + obj[1].length,
			limit: 10,
		}
	}

	getInbox = (opt) => {
		opt = opt || {}
		opt.type = opt.type || 'all';
		var inbox = {}
		inbox['all'] = [];
		inbox['all'][0] = {
			"page": 1,
			"limit": 10,
			"total": 75,
			"data": [
				{
					"id": "54B4B73129F10F13472582980024027F",
					"nomor": "UND-80/SJ.6/2018",
					"nomanual": "",
					"nomortiket": "SM/0010/DEMO/V/2018",
					"subject": "Undangan Rapat Panja RUU tentang PNBP",
					"createddate": "05/25/2018 01:33:19 PM ZE7",
					"tenggat": "04/02/2018",
					"fromtitle": "Biro Komunikasi dan Layanan Informasi Sekretariat Jenderal Kementerian Keuangan",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "SuratMasukManual",
					"mdjenissmm": "Surat Undangan",
					"lettertype": "incoming",
					"dispostatus": "1",
					"tanggapanstatus": "0",
					"repliedstatus": "In Progress",
					"notatindakandisp": [],
					"joinsendto": [""],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "04/03/2018",
					"tanggalselesaievent": "04/05/2018",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "Hotel Ayana Midplaza, Jakarta",
					"jammulaievent": "14:00",
					"jamselesaievent": "22:00",
					"errormsg": "-"
				}, {
					"id": "F4F83B356012DD89472582970035DF59",
					"nomor": "UND-80/SJ.6/2018",
					"nomanual": "",
					"nomortiket": "SM/0009/DEMO/V/2018",
					"subject": "Rapat Panja RUU tentang PNBP",
					"createddate": "05/24/2018 04:48:25 PM ZE7",
					"tenggat": "05/02/2018",
					"fromtitle": "Kepala Biro Layanan Informasi dan Komunikasi Sekretariat Jenderal Kementerian Keuangan",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "SuratMasukManual",
					"mdjenissmm": "Surat Undangan",
					"lettertype": "incoming",
					"dispostatus": "1",
					"tanggapanstatus": "0",
					"repliedstatus": "Complete",
					"notatindakandisp": [],
					"joinsendto": [""],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "05/03/2018",
					"tanggalselesaievent": "05/03/2018",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "Hotel Ayana Midplaza, Jakarta",
					"jammulaievent": "14:00",
					"jamselesaievent": "22:00",
					"errormsg": "-"
				}, {
					"id": "55B6DAEE6FA1B0F54725826A0031DB5F",
					"nomor": "ND - 0004/PB. IP.1/2018",
					"nomanual": "ND - 0004/IP.1/2018",
					"nomortiket": "ND/0008/PIP/IV/2018",
					"subject": "Test Surat",
					"createddate": "04/09/2018 04:04:34 PM ZE7",
					"tenggat": "",
					"fromtitle": "Direktur Keuangan Umum dan Sistem Informasi",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"lettertype": "incoming",
					"dispostatus": "1",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Kepala Divisi Informasi dan Teknologi", "Direktur Demo", "Pelaksana Manajemen Risiko", "Rainmaker", "Superman"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "NotaDinas",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "245AA44891D5607647258259002748A5",
					"nomor": "UND-123",
					"nomanual": "",
					"nomortiket": "SM/0004/DEMO/III/2018",
					"subject": "Undangan PPKBLU Demo OA",
					"createddate": "03/23/2018 02:09:05 PM ZE7",
					"tenggat": "",
					"fromtitle": "PPKBLU",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "SuratMasukManual",
					"mdjenissmm": "Surat Undangan",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": [""],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "03/27/2018",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "Gd. PKBLU",
					"jammulaievent": "9:00",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "ED5F2AC4BEEA78C24725824E004FDBAA",
					"nomor": "1234",
					"nomanual": "",
					"nomortiket": "SM/0003/DEMO/III/2018",
					"subject": "Test",
					"createddate": "03/12/2018 09:32:15 PM ZE7",
					"tenggat": "",
					"fromtitle": "Mr. X",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "SuratMasukManual",
					"mdjenissmm": "Surat Undangan",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": [""],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "03/14/2018",
					"tanggalselesaievent": "03/15/2018",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "Hotel XXX",
					"jammulaievent": "9:00",
					"jamselesaievent": "21:00",
					"errormsg": "-"
				}, {
					"id": "4B3F86C76E58299B4725821C003BEE0E",
					"nomor": "ST - 0123/PB. 500/2018",
					"nomanual": "",
					"nomortiket": "ST/0035/DEMO/I/2018",
					"subject": "Test balik surat",
					"createddate": "01/21/2018 05:54:35 PM ZE7",
					"tenggat": "",
					"fromtitle": "Staf 01 Divisi Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Surat Tugas",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "SuratTugas",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "D2A1429FBD6AFA644725821A003E57AE",
					"nomor": "ST - 0120/PB. 500/2018",
					"nomanual": "",
					"nomortiket": "ST/0030/DEMO/I/2018",
					"subject": "tes surat balasan deploy",
					"createddate": "01/19/2018 06:20:56 PM ZE7",
					"tenggat": "",
					"fromtitle": "Staf 02 Divisi Demo III",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Surat Tugas",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "SuratTugas",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "985D5AFFB3593A6E4725821A003D5AF1",
					"nomor": "ME 0003/DEMO.500/2018",
					"nomanual": "",
					"nomortiket": "IM/0002/DEMO/I/2018",
					"subject": "memo attachment banyak",
					"createddate": "01/19/2018 06:10:09 PM ZE7",
					"tenggat": "",
					"fromtitle": "Staf 01 Divisi Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "me",
					"mdjenissmm": "Memo",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "E68C94754E52F966472581CA0016258A",
					"nomor": "ND - 0652/PB. 5/2017",
					"nomanual": "",
					"nomortiket": "ND/3804/BLU/X/2017",
					"subject": "test surat",
					"createddate": "10/31/2017 11:01:54 AM ZE7",
					"tenggat": "",
					"fromtitle": "Sekretaris Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/1F288E974410539085258139003B714B/$FILE/foto.png",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"lettertype": "incoming",
					"dispostatus": "1",
					"tanggapanstatus": "0",
					"repliedstatus": "Draft",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "NotaDinas",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "5725D7F0D4A90A28472581CA00144B26",
					"nomor": "Und-2221/PB.5/2017a",
					"nomanual": "",
					"nomortiket": "SM/1303/BLU/X/2017",
					"subject": "tes surat Rahasia",
					"createddate": "10/31/2017 10:41:39 AM ZE7",
					"tenggat": "",
					"fromtitle": "test user demo",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "SuratMasukManual",
					"mdjenissmm": "Nota Dinas",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": [""],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}
			]
		}

		inbox['all'][1] = {
			"page": 2,
			"limit": 10,
			"total": 75,
			"data": [
				{
					"id": "D171F48EE0BAB31D472581C40020A537",
					"nomor": "ME 0376/BLU.500/2017",
					"nomanual": "",
					"nomortiket": "IM/0375/BLU/X/2017",
					"subject": "tes memo 1255",
					"createddate": "10/25/2017 12:56:34 PM ZE7",
					"tenggat": "",
					"fromtitle": "Sekretaris Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/1F288E974410539085258139003B714B/$FILE/foto.png",
					"isread": "1",
					"takahtype": "me",
					"mdjenissmm": "Memo",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "775EDA2BBF1EB565472581C3001249CA",
					"nomor": "S -  0003/PB. 500/2017",
					"nomanual": "",
					"nomortiket": "SK/0685/BLU/X/2017",
					"subject": "surat 1452",
					"createddate": "10/24/2017 10:19:45 AM ZE7",
					"tenggat": "",
					"fromtitle": "Wakil Divisi Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/E5E6672A3546CD9C85258139003B71E2/$FILE/foto.png",
					"isread": "1",
					"takahtype": "sk",
					"mdjenissmm": "",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Kementrian Komunikasi dan Informatika", "BP3TI", "Telkom Co.Ltd."],
					"joincopyto": ["Direktur Demo", "PT Codephile"],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "F50127AC4A6BAE7F472581C200572822",
					"nomor": "S -  0004/PB. 51/2017",
					"nomanual": "",
					"nomortiket": "SK/0684/BLU/X/2017",
					"subject": "surat 1558",
					"createddate": "10/23/2017 10:51:59 PM ZE7",
					"tenggat": "",
					"fromtitle": "Kepala Divisi Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/54EB95D4D27C405E85258139003B7137/$FILE/foto.png",
					"isread": "1",
					"takahtype": "sk",
					"mdjenissmm": "",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Kementrian Komunikasi dan Informatika", "BP3TI", "Telkom Co.Ltd."],
					"joincopyto": ["Direktur Demo", "PT Codephile"],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "766BD0B90E765478472581C20056F69C",
					"nomor": "S -  0005/PB. 5/2017",
					"nomanual": "",
					"nomortiket": "SK/0683/BLU/X/2017",
					"subject": "surat 1602",
					"createddate": "10/23/2017 10:49:52 PM ZE7",
					"tenggat": "",
					"fromtitle": "Kepala Divisi Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/54EB95D4D27C405E85258139003B7137/$FILE/foto.png",
					"isread": "1",
					"takahtype": "sk",
					"mdjenissmm": "",
					"lettertype": "incoming",
					"dispostatus": "1",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Kementrian Komunikasi dan Informatika", "BP3TI", "Telkom Co.Ltd."],
					"joincopyto": ["Direktur Demo", "PT Codephile"],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "E9298312AF18EBB5472581BF0028A023",
					"nomor": "012931283012",
					"nomanual": "",
					"nomortiket": "SM/1162/BLU/X/2017",
					"subject": "tes surat",
					"createddate": "10/20/2017 02:23:44 PM ZE7",
					"tenggat": "",
					"fromtitle": "sarah",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "SuratMasukManual",
					"mdjenissmm": "Surat Dinas",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": [""],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "2721113A9C5C3215472581B10009DEED",
					"nomor": "um.007/68/9phb.2017",
					"nomanual": "",
					"nomortiket": "SM/0915/BLU/X/2017",
					"subject": "Data Penilaian Aspek Pelayanan BLU Bidang Pelayanan Pendidikan Tahun 2016",
					"createddate": "10/06/2017 08:47:48 AM ZE7",
					"tenggat": "10/06/2017",
					"fromtitle": "kementerian perhubungan",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "SuratMasukManual",
					"mdjenissmm": "Surat Dinas",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": [""],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "2783B99366BDEEE8472581AE0038C0D5",
					"nomor": "S -  0007/PB. 5/2017",
					"nomanual": "S-gantisurat03-10-2017",
					"nomortiket": "SK/0515/BLU/X/2017",
					"subject": "Suat pernyataan reunian kucing dibatalkan",
					"createddate": "10/03/2017 05:19:53 PM ZE7",
					"tenggat": "",
					"fromtitle": "Wakil Direktur Demo",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/nophoto01.jpg",
					"isread": "1",
					"takahtype": "sk",
					"mdjenissmm": "",
					"lettertype": "incoming",
					"dispostatus": "1",
					"tanggapanstatus": "0",
					"repliedstatus": "Complete",
					"notatindakandisp": [],
					"joinsendto": [""],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "2A56D3C94F097B3F472581AE002F27DF",
					"nomor": "ND - 0509/PB. 500/2017",
					"nomanual": "",
					"nomortiket": "ND/2777/BLU/X/2017",
					"subject": "tes surat akhir disposisi",
					"createddate": "10/03/2017 03:35:03 PM ZE7",
					"tenggat": "",
					"fromtitle": "Sekretaris Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/1F288E974410539085258139003B714B/$FILE/foto.png",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"lettertype": "incoming",
					"dispostatus": "1",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "NotaDinas",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "359AB4BA60A2D07A4725819B000EC285",
					"nomor": "ND - 0359/PB. 500/2017",
					"nomanual": "ND - Manual",
					"nomortiket": "ND/1924/BLU/IX/2017",
					"subject": "tes ubah nomor manual",
					"createddate": "09/14/2017 09:41:13 AM ZE7",
					"tenggat": "",
					"fromtitle": "Sekretaris Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/1F288E974410539085258139003B714B/$FILE/foto.png",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"lettertype": "incoming",
					"dispostatus": "1",
					"tanggapanstatus": "0",
					"repliedstatus": "Complete",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo", "Sekretaris Demo I"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "NotaDinas",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "F6C5C5C30055562B472581A30026BEE3",
					"nomor": "S -  0004/PB. 500/2017",
					"nomanual": "",
					"nomortiket": "SK/0403/BLU/IX/2017",
					"subject": "Tes QR S",
					"createddate": "09/22/2017 02:03:12 PM ZE7",
					"tenggat": "",
					"fromtitle": "Sekretaris Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/1F288E974410539085258139003B714B/$FILE/foto.png",
					"isread": "1",
					"takahtype": "sk",
					"mdjenissmm": "",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "Complete",
					"notatindakandisp": [],
					"joinsendto": [""],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}
			]
		}

		for (var i = 2; i < 7; i++) {
			inbox['all'][i] = {
				"page": i + 1,
				"limit": 10,
				"total": 75,
				"data": inbox['all'][1].data,
			}
		}

		inbox['all'][7] = {
			"page": 8,
			"limit": 10,
			"total": 75,
			"data": [
				{
					"id": "66DA290C37F254CA472581A30026A048",
					"nomor": "Und - 0414/PB. 500/2017",
					"nomanual": "",
					"nomortiket": "Und/2222/BLU/IX/2017",
					"subject": "tes QR UND",
					"createddate": "09/22/2017 02:01:54 PM ZE7",
					"tenggat": "",
					"fromtitle": "Sekretaris Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/1F288E974410539085258139003B714B/$FILE/foto.png",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Surat Undangan",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "Complete",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "SuratUndangan",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "09/23/2017",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "15:00",
					"jamselesaievent": "13:58",
					"errormsg": "-"
				}, {
					"id": "5C0A7978C397AA85472581A3002685D6",
					"nomor": "ST - 0413/PB. 500/2017",
					"nomanual": "",
					"nomortiket": "ST/2220/BLU/IX/2017",
					"subject": "tes QR ST",
					"createddate": "09/22/2017 02:00:46 PM ZE7",
					"tenggat": "",
					"fromtitle": "Sekretaris Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/1F288E974410539085258139003B714B/$FILE/foto.png",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Surat Tugas",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "Complete",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "SuratTugas",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "FD1C8DB99B495779472581A300264981",
					"nomor": "ND - 0412/PB. 500/2017",
					"nomanual": "",
					"nomortiket": "ND/2218/BLU/IX/2017",
					"subject": "tes QR ND",
					"createddate": "09/22/2017 01:58:11 PM ZE7",
					"tenggat": "",
					"fromtitle": "Sekretaris Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/1F288E974410539085258139003B714B/$FILE/foto.png",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "Complete",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "NotaDinas",
					"isForwarded": "yes",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "271E102B214ABA3C472581A300228FB1",
					"nomor": "ND - 0411/PB. 500/2017",
					"nomanual": "",
					"nomortiket": "ND/2214/BLU/IX/2017",
					"subject": "balas dispo",
					"createddate": "09/22/2017 01:17:30 PM ZE7",
					"tenggat": "",
					"fromtitle": "Wakil Divisi Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/E5E6672A3546CD9C85258139003B71E2/$FILE/foto.png",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"lettertype": "incoming",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "NotaDinas",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}, {
					"id": "87EE4495015575DD472581A300228D85",
					"nomor": "ND - 0411/PB. 500/2017",
					"nomanual": "",
					"nomortiket": "ND/2213/BLU/IX/2017",
					"subject": "balas dispo",
					"createddate": "09/22/2017 01:17:24 PM ZE7",
					"tenggat": "",
					"fromtitle": "Wakil Divisi Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/E5E6672A3546CD9C85258139003B71E2/$FILE/foto.png",
					"isread": "1",
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"lettertype": "incoming",
					"dispostatus": "1",
					"tanggapanstatus": "0",
					"repliedstatus": "",
					"notatindakandisp": [],
					"joinsendto": ["Direktur Demo"],
					"joincopyto": [""],
					"catatandisp": "-",
					"sisastep": "-",
					"penandatangan": "-",
					"tipeGenerik": "NotaDinas",
					"isForwarded": "",
					"status": "",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"errormsg": "-"
				}
			]
		}
		return inbox['all'][opt.page - 1];
	}

	getOutbox = (opt) => {
		opt = opt || {}
		let obj = {
			page: 1,
			limit: 10,
			total: 10,
			"data": [
				{
					"id": "B1E68C04818CCABF4725821A003CA4A8",
					"nomor": "ND - 0118/PB. 5/2018",
					"nomanual": "xx-nd-deploy",
					"nomortiket": "ND/0027/DEMO/I/2018",
					"subject": "Nota Dinas balasan",
					"createddate": "01/19/2018 06:02:23 PM ZE7",
					"tenggat": "",
					"fromtitle": "Direktur Demo",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/512DD9B9E6A35D4085258139003B7127/$FILE/foto.png",
					"sendto": ["Staf 01 Divisi Demo I", "Staf 02 Divisi Demo I", ""],
					"joinsendto": ["Staf 01 Divisi Demo I", "Staf 02 Divisi Demo I"],
					"joincopyto": [""],
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "NotaDinas",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": ""
				}, {
					"id": "585AB2B2049CACA447258209000B4C96",
					"nomor": "ND - 0003/PB. 5/2018",
					"nomanual": "",
					"nomortiket": "ND/0009/DEMO/I/2018",
					"subject": "tes nodin 0853",
					"createddate": "01/02/2018 09:03:25 AM ZE7",
					"tenggat": "",
					"fromtitle": "Direktur Demo",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/512DD9B9E6A35D4085258139003B7127/$FILE/foto.png",
					"sendto": ["Kepala Divisi Demo I", ""],
					"joinsendto": ["Kepala Divisi Demo I"],
					"joincopyto": [""],
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "NotaDinas",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": ""
				}, {
					"id": "57E6452E7A70629B47258209000AA9CF",
					"nomor": "ND - 0004/PB. 5/2018",
					"nomanual": "",
					"nomortiket": "ND/0008/DEMO/I/2018",
					"subject": "nota dinas tahun baru",
					"createddate": "01/02/2018 08:56:28 AM ZE7",
					"tenggat": "",
					"fromtitle": "Direktur Demo",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/512DD9B9E6A35D4085258139003B7127/$FILE/foto.png",
					"sendto": ["Sekretaris Demo I", "Sekretaris Demo II", ""],
					"joinsendto": ["Sekretaris Demo I", "Sekretaris Demo II"],
					"joincopyto": [""],
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "NotaDinas",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": ""
				}, {
					"id": "51698D2E3D12990C472581DA0013C636",
					"nomor": "ND - 0733/PB. 5/2017",
					"nomanual": "",
					"nomortiket": "ND/4335/BLU/XI/2017",
					"subject": "test Approve",
					"createddate": "11/16/2017 10:35:59 AM ZE7",
					"tenggat": "",
					"fromtitle": "Direktur Demo",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/512DD9B9E6A35D4085258139003B7127/$FILE/foto.png",
					"sendto": ["Wakil Direktur Demo", ""],
					"joinsendto": ["Wakil Direktur Demo"],
					"joincopyto": [""],
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "NotaDinas",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": "email"
				}, {
					"id": "3006EA84FBEE94C2472581CA00144B2E",
					"nomor": "Und-2221/PB.5/2017a",
					"nomanual": "",
					"nomortiket": "SM/1303/BLU/X/2017",
					"subject": "tes surat Rahasia",
					"createddate": "11/01/2017 12:20:37 PM ZE7",
					"tenggat": "",
					"fromtitle": "test user demo",
					"pictunid": "",
					"sendto": ["Direktur Demo", ""],
					"joinsendto": [""],
					"joincopyto": [""],
					"takahtype": "SuratMasukManual",
					"mdjenissmm": "Nota Dinas",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": ""
				}, {
					"id": "DA3106D12D8A9AF2472581B10009DEF3",
					"nomor": "um.007/68/9phb.2017",
					"nomanual": "",
					"nomortiket": "SM/0915/BLU/X/2017",
					"subject": "Data Penilaian Aspek Pelayanan BLU Bidang Pelayanan Pendidikan Tahun 2016",
					"createddate": "02/12/2018 01:58:44 PM ZE7",
					"tenggat": "10/06/2017",
					"fromtitle": "kementerian perhubungan",
					"pictunid": "",
					"sendto": ["Direktur Demo", ""],
					"joinsendto": [""],
					"joincopyto": [""],
					"takahtype": "SuratMasukManual",
					"mdjenissmm": "Surat Dinas",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": ""
				}, {
					"id": "815F91693A317A2A472581AD002BF21F",
					"nomor": "ND - 0433/PB. 500/2017",
					"nomanual": "ND-GantiNomor 01/10/2017",
					"nomortiket": "ND/2669/BLU/X/2017",
					"subject": "Nodin kedua",
					"createddate": "10/02/2017 03:00:00 PM ZE7",
					"tenggat": "",
					"fromtitle": "Sekretaris Demo I",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/1F288E974410539085258139003B714B/$FILE/foto.png",
					"sendto": ["Sekretaris Demo I", ""],
					"joinsendto": ["Sekretaris Demo I"],
					"joincopyto": [""],
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "NotaDinas",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": ""
				}, {
					"id": "0FD037E58B8ED38D472581A9003131E7",
					"nomor": "ME 0281/BLU.5/2017",
					"nomanual": "",
					"nomortiket": "IM/0280/BLU/IX/2017",
					"subject": "tes memo referensi",
					"createddate": "09/28/2017 03:57:20 PM ZE7",
					"tenggat": "",
					"fromtitle": "Direktur Demo",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/512DD9B9E6A35D4085258139003B7127/$FILE/foto.png",
					"sendto": ["Kepala Divisi Demo II", ""],
					"joinsendto": ["Kepala Divisi Demo II"],
					"joincopyto": [""],
					"takahtype": "me",
					"mdjenissmm": "Memo",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": ""
				}, {
					"id": "A8C4E5C06A7F613A472581A600298123",
					"nomor": "ND - 0422/PB. 5/2017",
					"nomanual": "",
					"nomortiket": "ND/2306/BLU/IX/2017",
					"subject": "tes draft 1008",
					"createddate": "09/25/2017 02:33:20 PM ZE7",
					"tenggat": "",
					"fromtitle": "Direktur Demo",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/512DD9B9E6A35D4085258139003B7127/$FILE/foto.png",
					"sendto": ["Wakil Direktur Demo", "adasdas"],
					"joinsendto": ["Wakil Direktur Demo", "adasdas"],
					"joincopyto": [""],
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "NotaDinas",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": "email"
				}, {
					"id": "FF09D74C2DE996F5472581A300181258",
					"nomor": "ND - 0244/PB. 5/2017",
					"nomanual": "",
					"nomortiket": "ND/2204/BLU/IX/2017",
					"subject": "test pffft",
					"createddate": "02/12/2018 01:58:37 PM ZE7",
					"tenggat": "",
					"fromtitle": "Direktur Demo",
					"pictunid": "http://oa-system.djpbn.kemenkeu.go.id/eoffice/ndprofiles.nsf/0/512DD9B9E6A35D4085258139003B7127/$FILE/foto.png",
					"sendto": ["Staf 02 Divisi Demo II", ""],
					"joinsendto": ["Staf 02 Divisi Demo II"],
					"joincopyto": [""],
					"takahtype": "nd",
					"mdjenissmm": "Nota Dinas",
					"dispostatus": "0",
					"tanggapanstatus": "0",
					"notatindakandisp": [],
					"tipeGenerik": "NotaDinas",
					"lettertype": "outgoing",
					"tanggalmulaievent": "",
					"tanggalselesaievent": "",
					"harimulaievent": "",
					"hariselesaievent": "",
					"tempatevent": "",
					"jammulaievent": "",
					"jamselesaievent": "",
					"flagFinalisasi": "cetak"
				}
			]
		}
		return obj;
	}

}

export default Dummy;
