import React, { Component } from 'react';
import swal from 'sweetalert';
import Spinner from '../../../components/Spinner';

export default class UserProfileChangePIN extends Component {
  state = {
    oldPIN: '',
    PIN: '',
    PINRepeat: '',
  }

  apply = () => {
    if ((this.state.PIN.length < 1 || this.state.PINRepeat.length < 1) || this.state.PIN !== this.state.PINRepeat) {
      swal({
        title: '',
        type: 'warning',
        text: 'Pastikan konfirmasi PIN sama.',
      })
      return;
    }
    if (this.state.oldPIN === this.state.PIN) {
      swal({
        title: '',
        type: 'warning',
        text: 'PIN baru Anda masih sama dengan yang lama.',
      })
      return;
    }
    this.setState({ loading: true });
    this.props.apply(this.state.oldPIN, this.state.PIN, this.state.PINRepeat, () => {
      this.setState({ loading: false });
      this.props.back();
    });
  }

  cancel = () => {
    this.props.back();
  }

  resetPin = () => {
    this.props.reset()
  }

  render() {
    return (
      <div className="box box-success">
        <div className="box-body">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="oldPIN">PIN lama</label>
                <input
                  className="form-control"
                  style={{ width: 300 }}
                  type="password"
                  name="oldPIN"
                  aria-describedby="oldPINHelp"
                  placeholder="PIN lama"
                  value={this.state.oldPIN}
                  onChange={(e) => { this.setState({ oldPIN: e.target.value }) }}
                  autoComplete="off"
                />
                <small id="oldPINHelp" className="form-text text-muted">Harap diisi</small>
              </div>
              <div className="form-group">
                <label htmlFor="PIN">PIN baru</label>
                <input
                  className="form-control"
                  style={{ width: 300 }}
                  type="password"
                  name="PIN"
                  aria-describedby="PINHelp"
                  placeholder="PIN baru"
                  value={this.state.PIN}
                  onChange={(e) => { this.setState({ PIN: e.target.value }) }}
                  autoComplete="off"
                />
                <small id="PINHelp" className="form-text text-muted">Harap diisi</small>
              </div>
              <div className="form-group">
                <label htmlFor="PINRepeat">Konfirmasi PIN baru</label>
                <input
                  className="form-control"
                  style={{ width: 300 }}
                  type="password"
                  name="PINRepeat"
                  aria-describedby="PINRepeatHelp"
                  placeholder="Konfirmasi PIN baru"
                  value={this.state.PINRepeat}
                  onChange={(e) => { this.setState({ PINRepeat: e.target.value }) }}
                  autoComplete="off"
                />
                <small id="PINRepeatHelp" className="form-text text-muted">Harap diisi</small>
              </div>
              {this.state.loading && <Spinner inline={true} />}
              {!this.state.loading &&
                <div>
                  <button className="btn btn-primary" onClick={this.apply}>Ubah</button>&nbsp;
                  {!this.props.force && <button className="btn btn-default" onClick={this.cancel}>Batal</button>}
                  {this.props.force && <button className="btn btn-warning" onClick={this.resetPin}>Lupa PIN?</button>}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
