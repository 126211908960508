import React, { Component } from 'react';
import swal from 'sweetalert'
import Spinner from '../../../components/Spinner';

export default class UserProfileChangePassword extends Component {
  state = {
    password: '',
    newPassword: '',
    newPasswordRepeat: '',
  }

  apply = () => {
    if ((this.state.password.length < 1 || this.state.newPassword.length < 1 || this.state.newPasswordRepeat.length < 1) || this.state.newPassword !== this.state.newPasswordRepeat) {
      swal({
        title: '',
        type: 'warning',
        text: 'Pastikan sandi lama diisi dan konfirmasi sandi sama.'
      });
      return;
    }
    this.setState({ loading: true });
    this.props.apply({ password: this.state.password, newPassword: this.state.newPassword, newPasswordRepeat: this.state.newPasswordRepeat }, () => {
      this.setState({ loading: false });
    });
  }

  cancel = () => {
    this.props.back();
  }

  render() {
    return (
      <div className="box box-success">
        <div className="box-body">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="password">Sandi</label>
                <input
                  className="form-control"
                  style={{ width: 300 }}
                  type="password"
                  name="password"
                  aria-describedby="passwordHelp"
                  placeholder="Sandi"
                  value={this.state.password}
                  onChange={(e) => { this.setState({ password: e.target.value }) }}
                  disabled={this.loading}
                  autoComplete="off"
                />
                <small id="passwordHelp" className="form-text text-muted">Harap diisi</small>
              </div>
              <div className="form-group">
                <label htmlFor="password">Sandi baru</label>
                <input
                  className="form-control"
                  style={{ width: 300 }}
                  type="password"
                  name="newPassword"
                  aria-describedby="passwordHelp"
                  placeholder="Sandi Baru"
                  value={this.state.newPassword}
                  onChange={(e) => { this.setState({ newPassword: e.target.value }) }}
                  disabled={this.loading}
                  autoComplete="off"
                />
                <small id="passwordHelp" className="form-text text-muted">Harap diisi</small>
              </div>
              <div className="form-group">
                <label htmlFor="passwordRepeat">Konfirmasi sandi baru</label>
                <input
                  className="form-control"
                  style={{ width: 300 }}
                  type="password"
                  name="newPasswordRepeat"
                  aria-describedby="passwordRepeatHelp"
                  placeholder="Konfirmasi sandi baru"
                  value={this.state.newPasswordRepeat}
                  onChange={(e) => { this.setState({ newPasswordRepeat: e.target.value }) }}
                  disabled={this.loading}
                  autoComplete="off"
                />
                <small id="passwordRepeatHelp" className="form-text text-muted">Harap diisi</small>
              </div>
              {this.state.loading && <Spinner inline={true} />}
              {!this.state.loading &&
                <div>
                  <button className="btn btn-primary" onClick={this.apply}>Ubah</button>&nbsp;
                <button className="btn btn-default" onClick={this.cancel}>Batal</button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
