import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Letter from '../../../../../services/Letter';
import Constants from '../../../../../consts';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import stripTags from '../../../../../utils'
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import '../styles.css';

const LetterService = new Letter();

export default class Disposition extends Component {
  state = {
    actionMap: Constants.actionMap,
    currentUser: {},
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    searchString: '',
    loading: false,
  }

  componentDidMount = () => {
    var currentUser = JSON.parse(localStorage.getItem('current_user'));
    this.setState({ currentUser: currentUser });
    window.store.dispatch(window.actions.showPageTitle(true));
    this.setState({ loading: true }, () => {
      LetterService.getDispositions({
        page: 1,
        limit: 10,
        search: this.state.searchString,
        listType: "OUTBOX"
      })
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  page = (page) => {
    this.setState({ loading: true, searchString: '' });
    let opt = {
      page: page,
      limit: this.state.limit,
      search: this.state.searchString,
      listType: "OUTBOX"
    }
    LetterService.getDispositions(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = {
      page: this.state.page + 1,
      limit: this.state.limit,
      search: this.state.searchString,
      listType: "OUTBOX"
    }
    LetterService.getDispositions(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = {
      page: this.state.page - 1,
      limit: this.state.limit,
      search: this.state.searchString,
      listType: "OUTBOX"
    }
    LetterService.getDispositions(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  reload = () => {
    this.setState({ loading: true });
    let opt = {
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.searchString,
      listType: "OUTBOX"
    }
    LetterService.getDispositions(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  read = (id) => {
    console.log(id);
    window.browserHistory.push('/read/' + id);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Kotak Keluar Disposisi</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <div className="input-group input-group-sm">
                    <span className="input-group-btn">
                      <span className="btn btn-success">
                        <Fa.FaRefresh />
                      </span>
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      onChange={this.handleSearchString}
                      value={this.state.searchString}
                      onKeyPress={(e) => { if (e.key === 'Enter') this.search() }}
                    />
                    {this.state.searchString && this.state.searchString.length > 0 &&
                      <span className="input-group-btn">
                        <span className="btn btn-default" onClick={this.dismissSearch}>
                          <Fa.FaClose />
                        </span>
                      </span>
                    }
                    <span className="input-group-btn">
                      <span className="btn btn-default" onClick={this.search}>
                        <Fa.FaSearch />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div style={{ float: 'right' }}>
                    <Pagination
                      disabled={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prev} next={this.next}
                      toPage={this.page} />
                  </div>
                </div>
                <hr />
              </div>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>No. Surat</th>
                      <th>Untuk</th>
                      <th>Perihal</th>
                      <th>Tanggal Disposisi</th>
                      <th>Tindakan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && Object.keys(this.state.data).map((item, index) => {
                      return <tr key={index} onClick={() => { this.read(this.state.data[item].letterId) }} style={{ cursor: 'pointer' }}>
                        <td>{this.state.data[item].letterRefNumber}</td>
                        <td>
                          {this.state.data[item].recipients && Object.keys(this.state.data[item].recipients).map((r, i) => {
                            return <span key={i}>{this.state.data[item].recipients[r].split('|')[1]}
                              {(this.state.data[item].recipients.length - 1 !== parseInt(r, 10)) && <span>, </span>}
                            </span>
                          })}
                        </td>
                        <td>{stripTags(this.state.data[item].letterSubject)}</td>
                        <td>
                          {date.format(this.state.data[item].time, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>
                        <td>
                          {this.state.data[item].actions && this.state.data[item].actions.map((action, actionIndex) => {
                            return <span className="alert alert-comment" key={actionIndex} style={{ padding: '5px 10px 5px 10px', marginRight: 10 }}>{this.state.actionMap[action]}</span>
                          })}
                        </td>
                        <td>
                          {this.state.data[item].isCurrentUserAPelaksana && <Fa.FaUser style={{ marginLeft: 5 }} />}
                          {this.state.data[item].labels && this.state.data[item].labels.indexOf('DISPOSITIONED') > -1 && <Fa.FaShareAlt style={{ marginLeft: 5 }} />}
                          {this.state.data[item].labels && this.state.data[item].labels.indexOf('PROCESS') > -1 && <Fa.FaHourglass style={{ marginLeft: 5 }} />}
                          {this.state.data[item].labels && this.state.data[item].labels.indexOf('FINAL') > -1 && <Fa.FaEdit style={{ marginLeft: 5 }} />}
                          {this.state.data[item].labels && this.state.data[item].labels.indexOf('REFERENCED') > -1 && this.state.data[item].labels.indexOf('PROCESS') < 0 && <Fa.FaCheck style={{ marginLeft: 5 }} />}
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <span style={{ fontSize: 32 }}>
                    <Fa.FaInbox />
                  </span>
                  <br />
                  <h4>Tidak ada disposisi</h4>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
