import React, { Component } from 'react';
import DispositionAction from '../../../services/DispositionAction';
import Spinner from '../../../components/Spinner';
import swal from 'sweetalert';
import Config from "../../../config";

const DispositionActionService = new DispositionAction();

export class DispositionActionEditComponent extends Component {
    state = {
      name: '',
      isDefault: false,
      loading: false,
    }

    componentDidMount = () => {
      let currentUser = JSON.parse(localStorage.getItem("current_user"))
      if (currentUser.role !== 'ADMIN') {
        window.browserHistory.push('/');
        return;
      }
      this.setState({loading: true}, () => {
        if (this.props.uuid === 'new') {
          this.setState({ loading: false })
          return;
        }
        DispositionActionService.get(this.props.uuid)
        .then((result) => {
          this.setState(result);
          this.setState({loading: false});
        })
        .catch((err) => {
          this.setState({
            loading: false,
            name: ''
          })
        })
      })
      
    }

    back = () => {
      window.browserHistory.push('/settings/disposition-actions');
    }

    create = () => {
      if (!this.state.name || (this.state.name ** this.state.name.length < 1 )) {
        swal({
          title: '',
          type: 'warning',
          text: 'Mohon lengkapi isian yang diwajibkan,',
        });
        return;
      }
      swal({
        title: '',
        type: 'warning',
        showCancelButton: true,
        canceButtonText: 'Batal',
        text: 'Anda yakin ingin menambah aksi disposisi ' + this.state.name + ' ?',
      }, (ok) => {
        if (ok) {
          this.setState({ loading: true }, () => {
            let payload = {
              name: this.state.name
            }
            DispositionActionService.create(payload)
              .then((result) => {
                this.setState({ loading: false });
                swal({
                  title: '',
                  text: 'Data aksi disposisi berhasil ditambahkan'
                });
                this.back();
              })
              .catch((err) => {
                if (err & err.response && err.response.status && err.response.status === 400 ) {
                  swal({
                    title: '',
                    type: 'warning',
                    text: 'Gagal menambahkan aksi disposisi'
                  })
                }
                this.setState({ loading: false })
              })
          })
        }
      })
    }

    update = () => {
      this.setState({ loading: true }, () => {
        let payload = {
          name: this.state.name
        }
        DispositionActionService.update(this.props.uuid, payload)
          .then((result) => {
            this.setState({ loading: false})
            swal({
              title: '',
              text: 'Data aksi disposisi berhasil diperbarui.'
            })
            this.back()
          })
          .catch((err) => {
            this.setState({ loading: false })
          })
      })
    }

    delete = () => {
      swal({
        title: '',
        type: 'warning',
        showCancelButton: true,
        canceButtonText: 'Batal',
        text: 'Anda yakin ingin menghapus aksi disposisi ini ?'
      }, (ok) => {
        if (ok) {
          setTimeout(() => {
            this.setState({ loading: true }, () => {
              DispositionActionService.delete(this.props.uuid)
                .then((result) => {
                  this.setState({loading: false})
                  swal({
                    title: '',
                    type: 'warning',
                    text: 'Aksi disposisi berhasil dihapus'
                  })
                  this.back()
                })
                .catch((err) => {
                  this.setState({ loading: false})
                })
            })
          }, 500)
        }
      })
    }

    render(){
        return (
            <div className="row">
              <div className="col-md-8">
                <div>
                  <h4>{this.props.uuid === 'new' ? 'Tambah aksi disposisi baru' : "Sunting Aksi Disposisi"}</h4>
                  <div className="box box-success">
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Nama aksi disposisi&nbsp;<span style={{color: 'red'}}>*</span></label>
                            <div className="col-md-9">
                              <input 
                                className="form-control"
                                name="name"
                                placeholder="Nama aksi disposisi"
                                value={this.state.name}
                                onChange={(e) => { this.setState({ name: e.target.value }) }}
                                aria-describedby="nameHelp"
                              />
                              <small id="namehelp" className="form-text text-muted">Harap diisi</small>
                            </div>
                          </div>
                          {this.state.loading && <Spinner style={{float: 'right'}} />}
                          {!this.state.loading &&
                            <div style={{ float: 'right' }}>
                              <button className="btn btn-default" onClick={this.back}>Kembali</button>&nbsp;&nbsp;
                              {this.state.id && ((this.state.isDefault && Config.mutableDefaultDispositionAction) || 
                                 (!this.state.isDefault && !Config.mutableDefaultDispositionAction) ||
                                 (!this.state.isDefault && Config.mutableDefaultDispositionAction)) && 
                                  <span><button className="btn btn-danger" onClick={this.delete}>Hapus</button>&nbsp;&nbsp;</span>}
                              {this.props.uuid === 'new' ?
                                <span><button className="btn btn-primary" onClick={this.create}>Simpan</button>&nbsp;</span>
                                :
                                !this.state.isDefault && <span><button className="btn btn-primary" onClick={this.update}>Simpan</button>&nbsp;</span>
                              }
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
    }
}

const DispositionActionEdit = ({ match }) => (
  <DispositionActionEditComponent uuid={match.params.uuid} />
)

export default DispositionActionEdit;