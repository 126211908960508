import React, { Component } from 'react';
import DragList from 'react-drag-list';
import * as Fa from 'react-icons/lib/fa';
import Cache from '../../../services/Cache';
import Spinner from '../../../components/Spinner';
import Tab from '../../../components/Tab';
import Config from "../../../config";
import './styles.css';

const CacheService = new Cache();

export default class AddressBookModal extends Component {
  state = {
    showModal: false,
    loading: false,
    data: {},
    currentTab: 'Jabatan',
    tabNav: {
      Jabatan: {
        name: 'Jabatan',
        default: true,
      },
      Personal: {
        name: 'Personal'
      },
      Group: {
        name: 'Grup'
      },
    },
    selected: [],
    filterValue: '',
    screenNumber: 0,
  }

  componentDidMount = () => {
    if (Config.gerbang && this.props.oaVisible) {
      this.setState(prevState => ({
        tabNav: {                   
            ...prevState.tabNav,    
            OA: {
              name: "OA"
            }       
        }
      }))
    }
  }

  filter = (event, isTabSwitch) => {
    /*
    */
    var val = event.target.value.toLowerCase();
    this.setState({ filterValue: val }, () => {
      this.setState((prev) => {
        if (val && val.length > 0) {
          if(this.state.currentTab === 'OA' && prev.data[prev.currentTab]) {
            const result = prev.data[prev.currentTab].filter((item) => {
              const index = prev.data[prev.currentTab].indexOf(item);
              if (prev.data[prev.currentTab][index].name.toLowerCase().indexOf(val) > -1) {
                prev.data[prev.currentTab][index].visibility = true;
                prev.data[prev.currentTab][index].filtered = true;
              } else {
                prev.data[prev.currentTab][index].visibility = false;
                prev.data[prev.currentTab][index].filtered = false;
              }
              return prev.data[prev.currentTab]
            })
            
            return { data: {...prev.data, OA: result}}
          } else {
            for (var i in prev.data[prev.currentTab]) {
              if (this.state.currentTab === 'Group') {
                if (!prev.data[prev.currentTab][i].hasChild && prev.data[prev.currentTab][i].name && prev.data[prev.currentTab][i].name.toLowerCase().indexOf(val) > -1) {
                  prev.data[prev.currentTab][i].visibility = true;
                  prev.data[prev.currentTab][i].filtered = true;
                } else {
                  prev.data[prev.currentTab][i].visibility = false;
                  prev.data[prev.currentTab][i].filtered = false;
                }
              } else {
                if (prev.data[prev.currentTab][i].name && prev.data[prev.currentTab][i].name.toLowerCase().indexOf(val) > -1) {
                  prev.data[prev.currentTab][i].visibility = true;
                  prev.data[prev.currentTab][i].filtered = true;
                } else {
                  prev.data[prev.currentTab][i].visibility = false;
                  prev.data[prev.currentTab][i].filtered = false;
                }
              }
            }
            return { data: prev.data }
          }
        } else {
          let tab = Object.keys(prev.data);
          for (var k in tab) {
            for (var j in prev.data[tab[k]]) {
              prev.data[tab[k]][j].filtered = false;
              if (!isTabSwitch) {
                prev.data[tab[k]][j].collapse = true;
                prev.data[tab[k]][j].visibility = true;
              }
            }
          }
        }
        return { data: prev.data }
      });
    });
  }

  getOnlineStatusColor = seconds => {
    return Date.now() / 1000 - seconds < 60 * 60 * 24 ? 'green' : seconds < 0 ? 'red' : 'yellow' 
  }

  showModal = () => {
    this.setState({ showModal: true, loading: true, selected: [], filterValue: '', });
    var currentUser = JSON.parse(localStorage.getItem('current_user'));
    CacheService.getCacheDataFromStorage('addressbook')
      .then((data) => {
        function findChildsRecursive(id, level, arr) {
          let childs = [];
          for (var i in arr) {
            if (arr[i].parent === id) {
              arr[i].level = level + 1;
              //arr[i].visibility = false;
              if (arr[i].id === id) {
                continue;
              }
              childs.push(arr[i]);
              let child = findChildsRecursive(arr[i].id, level + 1, arr);
              if (child && child.length > 0) {
                arr[i].hasChild = true;
              }
              for (var j in child) {
                childs.push(child[j]);
              }
            }
          }
          return childs;
        }

        let jabatan = [];
        // Search for root
        let root;
        // The first must be org item
        for (let i in data.jabatan) {
          if (!data.jabatan[i].parent) {
            data.jabatan[i].level = 0;
            data.jabatan[i].visibility = true;
            data.jabatan[i].collapse = true;
            root = data.jabatan[i];
            break;
          }
        }
        
        // The original root is the child of org
        for (let i in data.jabatan) {
          if (data.jabatan[i].parent === root.id) {
            data.jabatan[i].level = 0;
            data.jabatan[i].visibility = true;
            data.jabatan[i].collapse = true;
            data.jabatan[i].hasChild = true;
            root = data.jabatan[i];
            jabatan.push(data.jabatan[i]);
            break;
          }
        }
        // The entire childs, sorted
        let childs = findChildsRecursive(root.id, root.level, data.jabatan);
        if (childs.length > 0) {
          for (var j in childs) {
            jabatan.push(childs[j]);
          }
        }

        let personal = [];
        for (var pItem in data.personal) {
          if (data.personal[pItem].username === '00000') {
            continue;
          }
          if (data.personal[pItem].name && data.personal[pItem].name === '') {
            continue;
          }
          if (!data.personal[pItem].name) {
            continue;
          }
          // Exclude anyone that has no title
          if (data.personal[pItem].organization && data.personal[pItem].organization.id === '') {
            continue;
          }
          data.personal[pItem].level = 0;
          data.personal[pItem].visibility = true;
          personal.push(data.personal[pItem]);
        };
        if (currentUser.organization && currentUser.organization.id && currentUser.organization.parent) {
          let priorityList = [];
          // Sub, one level bellow, non PUA
          for (let k in jabatan) {
            if (this.props.plt) {
              if (jabatan[k].parent === this.props.plt && jabatan[k].role !== 'PUA') {
                let obj = JSON.parse(JSON.stringify(jabatan[k]));
                obj.level = 0;
                obj.hasChild = false;
                obj.visibility = true;
                obj.parent = null;
                priorityList.push(obj);
              }
            } else if (this.props.delegated) {
              if (jabatan[k].parent === this.props.delegated && jabatan[k].role !== 'PUA') {
                let obj = JSON.parse(JSON.stringify(jabatan[k]));
                obj.level = 0;
                obj.hasChild = false;
                obj.visibility = true;
                obj.parent = null;
                priorityList.push(obj);
              }
            } else {
              if (jabatan[k].parent === currentUser.organization.id && jabatan[k].role !== 'PUA') {
                let obj = JSON.parse(JSON.stringify(jabatan[k]));
                obj.level = 0;
                obj.hasChild = false;
                obj.visibility = true;
                obj.parent = null;
                priorityList.push(obj);
              }
            }
          }

          // Sub, one level bellow, PUA
          for (let k in jabatan) {
            if (this.props.plt) {
              if (jabatan[k].parent === this.props.plt && jabatan[k].role === 'PUA') {
                let obj = JSON.parse(JSON.stringify(jabatan[k]));
                obj.level = 0;
                obj.hasChild = false;
                obj.visibility = true;
                obj.parent = null;
                priorityList.push(obj);
              }
            } else if (this.props.delegated) {
              if (jabatan[k].parent === this.props.delegated && jabatan[k].role === 'PUA') {
                let obj = JSON.parse(JSON.stringify(jabatan[k]));
                obj.level = 0;
                obj.hasChild = false;
                obj.visibility = true;
                obj.parent = null;
                priorityList.push(obj);
              }
            } else {
              if (jabatan[k].parent === currentUser.organization.id && jabatan[k].role === 'PUA') {
                let obj = JSON.parse(JSON.stringify(jabatan[k]));
                obj.level = 0;
                obj.hasChild = false;
                obj.visibility = true;
                obj.parent = null;
                priorityList.push(obj);
              }
            }
          }

          /*
          // The same level
          let currentUserParent = currentUser.organization.parent;
          for (let k in jabatan) {
            if (jabatan[k].parent === currentUserParent) {
              let obj = JSON.parse(JSON.stringify(jabatan[k]));
              obj.level = 0;
              priorityList.push(obj);
            }
          }
          */


          for (let k in priorityList) {
            jabatan.unshift(priorityList[k]);
          }
        }

        // User group
        let group = [];
        for (let i in data.group) {
          if (data.group[i].members && data.group[i].members.length > 0) {
            let obj = {
              id: data.group[i].id,
              name: data.group[i].name,
              visibility: true,
              collapse: true,
              hasChild: true,
              level: 0,
            }
            group.push(obj);
            for (let j in data.group[i].members) {
              group.push({
                id: data.group[i].members[j].split('|')[0],
                name: data.group[i].members[j].split('|')[1],
                parent: data.group[i].id,
                level: 1,
                visibility: false,
              });
            }
          }
        }

        // OA
        let oa = [];
        if (data.oa && data.oa.length > 0 && this.props.oaVisible) {
          let orgsOA = [];
          for (let item in data.oa) { 
            if (!data.oa[item].bios_id || !data.oa[item].name) continue;
            data.oa[item].id = data.oa[item].bios_id;
            delete data.oa[item].bios_id;
            data.oa[item].parent = data.oa[item].bios_parent;
            delete data.oa[item].bios_parent;
            orgsOA.push(data.oa[item])
          }
          
          for (let item in orgsOA) {
            let parent;
            if (!orgsOA[item].parent) {
              orgsOA[item].level = 0;
              orgsOA[item].hasChild = true;
              orgsOA[item].collapse = true;
              orgsOA[item].visibility = true;
              parent = orgsOA[item];
              oa.push(orgsOA[item])
            }
            
            if (parent) {
              let childs = findChildsRecursive(parent.id, parent.level, orgsOA);
              if (childs.length > 0) {  
                for (let child in childs) {
                  oa.push(childs[child])
                }
              } else {
                parent.collapse = false;
                parent.hasChild = false;
              }
            }
          }
        }
        
        this.setState({ data: { Jabatan: jabatan, Personal: personal, Group: group, OA: oa }, loading: false }, () => {
          if (!(this.props.raw && this.props.raw.length > 0)) {
            return;
          }
          let selected = []
          let alreadyAdded = []
          for (var i in this.props.raw) {
            for (let j in this.state.data.Jabatan) {
              if (this.state.data.Jabatan[j].id === this.props.raw[i].recipientUuid) {
                let item = this.state.data.Jabatan[j];
                item.selected = true;
                if (alreadyAdded.indexOf(this.state.data.Jabatan[j].id) < 0) {
                  alreadyAdded.push(this.state.data.Jabatan[j].id)
                  selected.push(item);
                }
              } else if (this.state.data.Jabatan[j].id === this.props.raw[i]) {
                let item = this.state.data.Jabatan[j];
                item.selected = true;
                if (alreadyAdded.indexOf(this.state.data.Jabatan[j].id) < 0) {
                  alreadyAdded.push(this.state.data.Jabatan[j].id)
                  selected.push(item);
                }
              }
            }
            for (let k in this.state.data.Personal) {
              if (this.state.data.Personal[k].id === this.props.raw[i].recipientUuid) {
                let item = this.state.data.Personal[k];
                item.selected = true;
                if (alreadyAdded.indexOf(this.state.data.Personal[k].id) < 0) {
                  alreadyAdded.push(this.state.data.Personal[k].id)
                  selected.push(item);
                }
              } else if (this.state.data.Personal[k].id === this.props.raw[i]) {
                let item = this.state.data.Personal[k];
                item.selected = true;
                if (alreadyAdded.indexOf(this.state.data.Personal[k].id) < 0) {
                  alreadyAdded.push(this.state.data.Personal[k].id)
                  selected.push(item);
                }
              }
            }
            for (let k in this.state.data.Group) {
              if (this.state.data.Group[k].id === this.props.raw[i].recipientUuid) {
                let item = this.state.data.Group[k];
                item.selected = true;
                if (alreadyAdded.indexOf(this.state.data.Group[k].id) < 0) {
                  alreadyAdded.push(this.state.data.Group[k].id)
                  selected.push(item);
                }
              } else if (this.state.data.Group[k].id === this.props.raw[i]) {
                let item = this.state.data.Group[k];
                item.selected = true;
                if (alreadyAdded.indexOf(this.state.data.Group[k].id) < 0) {
                  alreadyAdded.push(this.state.data.Group[k].id)
                  selected.push(item);
                }
              }
            }

            for (let k in this.state.data.OA) {
              if (this.state.data.OA[k].id === this.props.raw[i].recipientUuid) {
                let item = this.state.data.OA[k];
                item.selected = true;
                if (alreadyAdded.indexOf(this.state.data.OA[k].id) < 0) {
                  alreadyAdded.push(this.state.data.OA[k].id)
                  selected.push(item);
                }

                item.recipientType = "OA";
              } else if (this.state.data.OA[k].id === this.props.raw[i]) {
                let item = this.state.data.OA[k];
                item.selected = true;
                if (alreadyAdded.indexOf(this.state.data.OA[k].id) < 0) {
                  alreadyAdded.push(this.state.data.OA[k].id)
                  selected.push(item);
                }
                
                item.recipientType = "OA";
              }
            }
          }
          let data = this.state.data;
          this.setState({ data: data, selected: selected })
        });
      })
      .catch((err) => {
        // handle err
        this.setState({ showModal: false, loading: false, selected: [], filterValue: '', });
      });
  }

  hideModal = () => {
    this.setState({ showModal: false });
  }

  collapseAllChild = (data, parentId) => {
    for (var i in data) {
      if (data[i].parent === parentId) {
        data[i].collapse = true;
        data[i].visibility = false;
        if (data[i].hasChild) {
          this.collapseAllChild(data, data[i].id);
        }
      }
    }
  }

  toggleCollapse = (index) => {
    this.setState((prev) => {
      let s = {};
      
      if (prev.data[prev.currentTab][index].hasChild) {
        prev.data[prev.currentTab][index].current = true;
        if (prev.currentTab !== 'Group' && index === 0 && !prev.data[prev.currentTab][index].collapse && prev.currentTab !== 'OA') {
          s[prev.currentTab] = prev.data[prev.currentTab];
          return s;
        }
        for (var i in prev.data[prev.currentTab]) {
          if (prev.data[prev.currentTab][i].parent === prev.data[prev.currentTab][index].id) {
            prev.data[prev.currentTab][i].visibility = prev.data[prev.currentTab][index].collapse;
            if (prev.data[prev.currentTab][i].hasChild) prev.data[prev.currentTab][i].collapse = true;
            if (!prev.data[prev.currentTab][index].collapse) {
              this.collapseAllChild(prev.data[prev.currentTab], prev.data[prev.currentTab][index].id);
            }
          }
        }
        prev.data[prev.currentTab][index].collapse = !prev.data[prev.currentTab][index].collapse;
        s.data = prev.data
        return s;
      }
    })
  }

  handleClick = (index) => {
    this.setState((prev) => {
      let s = {};
      let selected = JSON.parse(JSON.stringify(prev.selected));
      let found = -1

      for (var j in selected) {
        if (selected[j].id === prev.data[prev.currentTab][index].id) {
          found = j;
          break;
        }
        if (prev.data[prev.currentTab][index].organization && selected[j].id === prev.data[prev.currentTab][index].organization.id) {
          found = j;
          break;
        }
        if (prev.data[prev.currentTab][index].holder && selected[j].id === prev.data[prev.currentTab][index].holder) {
          found = j;
          break;
        }
      }
      
      if (found > -1) {
        selected.splice(found, 1);
        prev.data[prev.currentTab][index].selected = false;
      } else {
        prev.data[prev.currentTab][index].selected = true;
        let obj = prev.data[prev.currentTab][index];
        
        if (prev.currentTab === "OA") {
          obj.recipientType = "OA";
        }

        if (obj.organization) {
          obj.organization.holderName = obj.name;
          selected.push(obj.organization);
        } else if (!obj.organization && obj.holder) {
          selected.push(obj);
        } else {
          let isFound = false
          for (let i in this.state.data.Personal) {
            if (obj.holder === this.state.data.Personal[i].id) {
              selected.push(this.state.data.Personal[i]);
              isFound = true
              break;
            }
          }
          if (!isFound) {
            selected.push(obj)
          }
        }
        if (selected[selected.legnth - 1]) {
          selected[selected.length - 1].type = prev.currentTab;
        }
      }
      s.selected = selected;
      return s;
    }, () => {
      if(this.okElement != null) this.okElement.focus();
      if (this.props.single) {
        this.ok();
      }
    });
  }

  remove = (item, index) => {
    this.setState((prev) => {
      for (var i in prev.data['Jabatan']) {
        if (prev.data['Jabatan'][i].id === item.id) {
          prev.data['Jabatan'][i].selected = false;
        }
      }
      for (var j in prev.data['Personal']) {
        if (prev.data['Personal'][j].id === item.id) {
          prev.data['Personal'][j].selected = false;
        }
      }
      for (var k in prev.data['OA']) {
        if (prev.data['OA'][k].id === item.id) {
          prev.data['OA'][k].selected = false;
        }
      }
      prev.selected.splice(index, 1);
      return { data: prev.data, selected: prev.selected }
    });
  }

  switchTab = (tab) => {
    this.filter({ target: { value: '' } }, true);
    this.setState({ currentTab: tab });
  }

  ok = () => {
    let e = {
      target: {
        name: this.props.name,
        value: ''
      }
    }
    e.target.raw = []
    let order = 0
    for (var i in this.state.selected) {
      order++
      if (!this.state.selected[i] ||
      (this.state.selected[i] && !this.state.selected[i].id)) {
        return;
      }

      if (i > 0) {
        e.target.value += '\n';
      }
      e.target.value += this.state.selected[i].name;
      if (this.props.name.toLowerCase().indexOf('recipient') > -1) {
        e.target.raw.push({
          recipientUuid: this.state.selected[i].id,
          recipientName: this.state.selected[i].name,
          recipientType: this.state.selected[i].recipientType ? this.state.selected[i].recipientType : 'RECIPIENT',
          recipientOrder: order,
        });
      } else if (this.props.name.toLowerCase().indexOf('member') > -1) {
        e.target.raw.push(this.state.selected[i].id);
      } else if (this.props.name.toLowerCase().indexOf('approver') > -1) {
        e.target.raw.push(this.state.selected[i].id);
      } else if (this.props.name.toLowerCase().indexOf('via') > -1) {
        e.target.raw.push(this.state.selected[i].id);
      } else if (this.props.name.toLowerCase().indexOf('signer') > -1) {
        e.target.raw.push(this.state.selected[i].id);
      } else if (this.props.name.toLowerCase().indexOf('reviewer') > -1) {
        e.target.raw = this.state.selected[i].id;
      } else {
        e.target.raw = this.state.selected[i].id;
      }
    }
    this.props.onChange(e);
    this.hideModal();
  }

  clear = () => {
    let e = {
      target: {
        name: this.props.name,
        value: ''
      }
    }
    e.target.raw = []
    this.props.onChange(e);
    this.hideModal();
  }

  onChange = () => {
  }

  render() {
    const {
      label,
      name,
      required,
      maxChar,
      additionalLabel,
      vertical,
      row,
      labelWidth,
      fieldWidth,
      single,
      button,
    } = this.props;

    const isSplitScreen = window.innerWidth < 800

    const displayScreenOne = (isSplitScreen && this.state.screenNumber === 0) || isSplitScreen === false
    const displayScreenTwo = (isSplitScreen && this.state.screenNumber === 1) || isSplitScreen === false


    return (
      <div
        style={{ display: button ? 'inline-block' : 'block' }}
        onKeyDown={(e) => { if (e.key === 'Enter') { this.ok() } else if (e.key === 'Escape') { this.hideModal() } }}
      >
        {!button ?
          <div className="form-group row disposition" style={{ marginTop: 10 }}>
            <label
              className={`col-md-${labelWidth ? labelWidth : 1} col-sm-2 control-label`}
              style={{textAlign:displayScreenOne ? 'left' : 'right', paddingLeft: 15 }}
            > 
              {label}
              &nbsp;<span className="red">{required && '*'}</span>
            </label>
            <div
              className={`col-md-${fieldWidth ? fieldWidth : '10'} col-sm-${fieldWidth ? fieldWidth : '10'}`}
              style={{ padding: vertical ? 0 : 15 }}
            >
              <textarea
                onClick={this.showModal}
                name={name} value={this.props.value}
                onChange={this.onChange}
                required={required}
                className="form-control"
                rows={row ? row : 4}
                placeholder={this.props.placeholder}
                style={{ whiteSpace: 'pre-line' }}
                disabled={this.props.disabled}
                autoComplete="nope"
              >
              </textarea>
              {additionalLabel ? <span style={{ float: 'left', color: 'red' }}>{additionalLabel}</span> : ''}
              {maxChar && maxChar > 0 ? <span style={{ float: 'right' }}>{this.state.value.length}/{maxChar}</span> : ''}
            </div>
          </div>
          :
          <button type="button" onClick={this.showModal} className={this.props.primary ? "btn btn-primary" : "btn btn-default"}>
            {this.props.label}
          </button>
        }
        <div>
          <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog-address-book">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    Buku Alamat
                </h4>
                  <span className="close" onClick={this.hideModal}><Fa.FaClose /></span>
                  <hr />
                </div>
                <div className="modal-body">
                  <div className="row">
                    {displayScreenOne &&

                      <div className={"col-md-" + (single ? 12 : 6).toString() + " col-sm-" + (single ? 12 : 6).toString()}>
                        <div style={{ display: this.state.loading ? 'none' : 'inherit' }}>
                          <Tab nav={this.state.tabNav} handler={this.switchTab}/>
                          {this.state.currentTab !== 'Group' &&
                            <div className="input-group input-group-sm" style={{ marginTop: 10 }}>
                              <input value={this.state.filterValue} className="form-control" onChange={this.filter} disabled={this.state.loading} placeholder="Cari" />
                              <span className="input-group-btn">
                                {this.state.filterValue.length > 0 ?
                                  <span className="btn btn-default" onClick={() => { this.filter({ target: { value: '' } }) }}>
                                    <Fa.FaClose />
                                  </span>
                                  :
                                  <span className="btn btn-default">
                                    <Fa.FaSearch />
                                  </span>
                                }
                              </span>
                            </div>
                          }
                        </div>
                        <div className="box box-success" style={{ marginTop: 15 }}>
                          {this.state.loading ?
                            <Spinner />
                            :
                            <ul style={{ padding: 0, height: 400, overflow: 'auto' }}>
                              {(this.state.currentTab === 'OA' && this.state.data[this.state.currentTab].length < 1) ? 
                                <li>
                                  <span style={{
                                        width: '100%',
                                        display: 'inline-block',
                                        paddingTop: 50,
                                        textAlign: 'center',
                                        fontSize: 14,
                                        fontStyle: 'bold'
                                      }}>
                                        Gagal memuat data organisasi dari PPKBLU, <br/>mohon kontak Administrator.
                                  </span>
                                </li>
                              :
                              this.state.data[this.state.currentTab] && Object.keys(this.state.data[this.state.currentTab]).map((item, index) => {
                                return <li
                                  key={index}
                                  style={{
                                    height: this.state.currentTab === 'Group' ? 40 : 60,
                                    borderBottom: '1px solid lightgrey',
                                    display: this.state.data[this.state.currentTab][item].visibility ? 'block' : 'none',
                                    paddingLeft: this.state.data[this.state.currentTab][item].level === 0
                                      || this.state.data[this.state.currentTab][item].filtered
                                      ? 15 :
                                      this.state.data[this.state.currentTab][item].level === 1 ? 30 :
                                        this.state.data[this.state.currentTab][item].level === 2 ? 45 :
                                          this.state.data[this.state.currentTab][item].level === 3 ? 60 : 75,
                                    cursor: 'pointer',
                                    background: !this.state.data[this.state.currentTab][item].current ? 'none' : '#F7F7F7',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'block',
                                      height: '60px',
                                      width: '100%',
                                      textOverflow: 'ellipses',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    {!this.state.data[this.state.currentTab][item].collapse && this.state.data[this.state.currentTab][item].hasChild ?
                                      <Fa.FaCaretDown
                                        size={20}
                                        onClick={() => { this.toggleCollapse(index) }}
                                        style={{
                                          color: 'green',
                                          verticalAlign: 'top',
                                          marginTop: this.state.data[this.state.currentTab][item].hasChild ?'2px':'10px'
                                        }}
                                      />
                                      :
                                      this.state.data[this.state.currentTab][item].collapse && this.state.data[this.state.currentTab][item].hasChild &&
                                      <Fa.FaCaretRight
                                        size={20}
                                        onClick={() => { this.toggleCollapse(index) }}
                                        style={{
                                          verticalAlign: 'top',
                                          marginTop: this.state.data[this.state.currentTab][item].hasChild ?'2px':'10px'
                                        }}
                                      />
                                    }

                                {this.state.data[this.state.currentTab][item].hasChild ? <br/>:' ' }
                                <div
                                      style={{
                                        width: '100%',
                                        display: 'inline-block',
                                        marginTop: this.state.data[this.state.currentTab][item].hasChild ?'0px':'10px',
                                        paddingBottom: 10,
                                        paddingRight: 10,
                                        paddingLeft: 10,

                                      }}
                                      onClick={() => {
                                        if ((this.state.currentTab === 'Group' && this.state.data[this.state.currentTab][item].hasChild) ||
                                          (this.state.currentTab !== 'Group')) {
                                          this.handleClick(index)
                                        }
                                      }}
                                    >
                                      {this.state.currentTab === 'Jabatan' && <span style={{ fontWeight: this.state.data[this.state.currentTab][item].hasChild ? 'bold' : 'normal' }}>{this.state.data[this.state.currentTab][item].name}</span>}
                                      {this.state.currentTab === 'Jabatan' && <span style={{ fontWeight: this.state.data[this.state.currentTab][item].hasChild ? 'bold' : 'normal', fontSize: 10, display: 'block' }}>
                                        {(this.state.data[this.state.currentTab][item].holderName && this.state.data[this.state.currentTab][item].holderName.length > 0 && this.state.data[this.state.currentTab][item].holderName) || this.state.data[this.state.currentTab][item].pltName}
                                      </span>}
                                      {this.state.currentTab === 'Personal' && <span style={{ fontWeight: this.state.data[this.state.currentTab][item].hasChild ? 'bold' : 'normal' }}>{this.state.data[this.state.currentTab][item].name}</span>}
                                      {this.state.currentTab === 'Personal' && this.state.data[this.state.currentTab][item].name && this.state.data[this.state.currentTab][item].name.length > 0 && <span style={{ fontWeight: this.state.data[this.state.currentTab][item].hasChild ? 'bold' : 'normal', fontSize: 10, display: 'block' }}>{this.state.data[this.state.currentTab][item].organization.name}</span>}
                                      {this.state.currentTab === 'Group' && <span style={{ fontWeight: this.state.data[this.state.currentTab][item].hasChild ? 'bold' : 'normal' }}>{this.state.data[this.state.currentTab][item].name}</span>}
                                      {this.state.currentTab === 'OA' && <span style={{whiteSpace: 'normal' ,fontWeight: this.state.data[this.state.currentTab][item].hasChild ? 'bold' : 'normal' }}>{this.state.data[this.state.currentTab][item].name}</span>}
                                    </div>
                                    {this.state.currentTab === 'OA' &&
                                      <div style={{ positoin: 'relative', top: 0, marginTop: '-30px', marginRight: '5px' }}>
                                        <Fa.FaCircle 
                                          color={this.getOnlineStatusColor(this.state.data['OA'][item].last_sign_in.seconds)} 
                                          style={{ float: 'Right' }} /> 
                                      </div>
                                    }
                                    {((this.state.currentTab === 'Group' && this.state.data[this.state.currentTab][item].hasChild) || (this.state.currentTab !== 'Group')) &&
                                      <div style={{ positoin: 'relative', top: 0, marginTop:  !this.state.data[this.state.currentTab][item].hasChild ?'-32px':'-38px', marginRight: '5px' }}>
                                        {!this.state.data[this.state.currentTab][item].selected ?
                                          <Fa.FaCircleO style={{ float: 'Right' }} /> :
                                          this.state.data[this.state.currentTab][item].selected && <Fa.FaCheck color="green" style={{ float: 'Right' }} />
                                        }
                                      </div>
                                    }
                                  </div>
                                </li>
                              })}
                            </ul>
                          }
                        </div>
                      </div>
                    }
                    {!single && displayScreenTwo &&
                      <div className="col-md-6 col-sm-6">
                        Pejabat terpilih
                        <div className="box box-success" style={{ marginTop: 10 }}>
                          <DragList
                            dataSource={this.state.selected}
                            rowKey="name"
                            style={{ height: 460, overflowY: 'scroll' }}
                            row={(record, index) => (
                              <div key={index} style={{ color: record.color }}>
                                <div className="drag-list-item">
                                  <div className="address-book-modal-selected-wrapper">
                                    {record.name}<br />
                                    <span style={{ fontSize: 10 }}>
                                      {record.organization && record.organization.name}
                                      {record.holderName || record.pltName}
                                    </span>
                                  </div>
                                  <Fa.FaClose
                                    style={{ cursor: 'pointer', float: 'right', fontSize: 14, marginTop: 2 }}
                                    color="red"
                                    onClick={() => { this.remove(record, index) }}
                                  />
                                </div>
                              </div>
                            )}
                            handles={false}
                            className="simple-drag"
                            rowClassName="simple-drag-row"
                            onUpdate={this._handleUpdate}
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="modal-footer">
                  {displayScreenOne && isSplitScreen && <div style={{ float: 'right' }}>
                    <button className="btn btn-primary" style={{ marginRight: 10 }} onClick={() => {
                      this.setState({
                        screenNumber: 1
                      })
                    }}
                      disabled={this.state.selected.length < 1}
                    >Daftar pejabat terpilih ({this.state.selected.length}) <Fa.FaArrowRight /> </button>
                    <span className="btn btn-default" onClick={this.hideModal}>Batal</span>
                  </div>}

                  {displayScreenTwo && isSplitScreen && <div style={{ float: 'left', position: 'absolute', left: '15px' }}>
                    <span className="btn btn-primary" style={{ marginRight: 5 }} onClick={() => {
                      this.setState({ screenNumber: 0 })
                    }}><Fa.FaArrowLeft /> Pilih lagi</span>
                  </div>}


                  {displayScreenTwo && !single && <div style={{ float: 'right' }}>
                    <button
                      type="button"
                      ref={ okElement => this.okElement = okElement}
                      className="btn btn-primary"
                      style={{ marginRight: 10 }}
                      onClick={this.ok}
                      onKeyDown={(e) => { if (e.key === 'Escape') this.hideModal() }}
                    >
                      OK
                    </button>
                    <button
                     type="button"
                     className="btn btn-default"
                     onClick={this.hideModal}
                    >
                      Batal
                    </button>
                  </div>}
                  {single && <div style={{ margin: '0 auto' }}>
                    <span className="btn btn-danger" onClick={this.clear}>
                      Kosongkan
                  </span>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}