import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardUser from './DashboardUser';
import DashboardLeader from './DashboardLeader';
import DashboardHelpdesk from './DashboardHelpdesk'
import Tab from '../../../components/Tab';
import Letter from '../../../services/Letter';

const mapper = state => {
  return {
    currentUser: state.rootReducers.currentUser
  }
}

const LetterService = new Letter()

class Dashboard extends Component {
  state = {
    currentTab: 'LEADER',
    tabNav: {
      LEADER: {
        name: 'Dashboard Pimpinan',
        default: true
      },
      USER: {
        name: 'Dashboard User'
      }
    }
  }

  componentDidMount = () => {
    LetterService.getStats()
  }

  switchTab = (tab) => {
    this.setState({ currentTab: tab })
  }

  render() {
    let currentUser = JSON.parse(localStorage.getItem('current_user'))
    return (
      <div>
        <div>
          {currentUser && currentUser.organization.leaderDashboardEnabled === true ?
            <div>
              <Tab nav={this.state.tabNav} handler={this.switchTab} />
              {
                this.state.currentTab === 'LEADER' ?
                  <DashboardLeader />
                  : this.state.currentTab === 'USER' ?
                    <DashboardUser />
                    : <div></div>
              }
            </div>
            :
            <DashboardUser />
          }
        </div>
      </div>
    )
  }
}

export default connect(mapper)(Dashboard);