import React, { Component } from 'react';
import downloadjs from 'downloadjs';
import LetterFormTextArea from './LetterFormTextArea';
import Editor from '../../../../../components/Editor';
import stripTags from '../../../../../utils';
import FileUploader from './FileUploader/';
import LetterReferenceModal from './LetterReferenceModal';
import config from '../../../../../config';
import Blob from '../../../../../services/Blob';

const BlobService = new Blob();

export default class component extends Component {

  downloadAttachment = (url, filename) => {
    BlobService.downloadToDataURI(url)
      .then((res) => {
        downloadjs(res.uri, filename, res.type);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { inputEventHandler } = this.props;
    return (
      <form className="form-horizontal" style={{ paddingTop: 10 }}>
        <Editor
          name="contents"
          label="Isi surat"
          required={true}
          content={this.props.parentState.contents}
          onChange={inputEventHandler}
          config={{
            height: 600,
          }}
        />
        <LetterFormTextArea
          name="notes"
          value={this.props.parentState.notes}
          label="Catatan"
          vertical={true}
          onChange={inputEventHandler}
          row={2}
          fieldWidth={12}
          required={true}
          maxChar={500}
        />
        <FileUploader files={this.props.parentState.files} onChange={this.props.attachmentHandler} />

        <LetterReferenceModal
          name="references"
          value={this.props.parentState.references}
          label={'Referensi Surat'}
          modalLabel={'Pilih Referensi Surat'}
          box={'INBOX'}
          selectedField={'number'}
          onChange={inputEventHandler}
          require={true}
        />
        <ul>
          {Object.keys(this.props.parentState.references).map((item, index) => {
            if (this.props.parentState.references[item].blobId) {
              return <li key={index}>
                <span style={{ cursor: 'pointer', textDecoration: 'none', color: '#72afd2' }}
                  onClick={() => { this.downloadAttachment(config.apiHost + "/api/v1/blob/" + this.props.parentState.references[item].blobId + "?download=true", this.props.parentState.references[item].number); }}
                >{this.props.parentState.references[item].number}</span>
              </li>
            } else {
              return <li key={index}>
                <a
                  target="_blank"
                  href={'/read/' + this.props.parentState.references[item].id}>{this.props.parentState.references[item].number}
                </a>
              </li>
            }

          })}
        </ul>
        {!config.letterBundleDisabled &&
          this.props.parentState.recipients && this.props.parentState.recipients.raw && this.props.parentState.recipients.raw.length > 0 &&
          <LetterReferenceModal
            name="bundle"
            value={this.props.parentState.bundle}
            label={'Paket Surat'}
            modalLabel={'Pilih surat yang akan dipaketkan'}
            box={'WAIT_FOR_BUNDLE'}
            signers={this.props.parentState.recipients}
            selectedField={'subject'}
            onChange={inputEventHandler}
            require={true}
          />
        }
        <ul>
          {Object.keys(this.props.parentState.bundle).map((item, index) => {
            return <li key={index}>
              {stripTags(this.props.parentState.bundle[item].subject)}
            </li>
          })}
        </ul>
      </form>
    );
  }
}
