import React, { Component } from 'react';
import Spinner from '../../../components/Spinner';
import '../styles.css';
import Auth from '../../../services/Auth';
import Settings from '../../../services/Settings';
import swal from 'sweetalert';
import async from 'async';
import Switch from 'react-switch';
import LetterRefCounter from './LetterRefCounterModal';
import Org from '../../../services/Org';

const SettingsService = new Settings();
const AuthService = new Auth();
const OrgService = new Org();

export default class SettingsComponent extends Component {
  state = {
    loading: false,
    satkerName: '',
    satkerCode: '',
    letterYear: (new Date()).getFullYear(),
    letterReferenceNumberCount: 0,
    letterHeader: '',
    organizationLogo: '',
    useNIPOnLetter: 'TRUE',
    hideDefaultClassification: 'false',
    rootOrg: {},
  }

  componentDidMount = () => {
    if (AuthService.getRoles().role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.load();
  }

  inputEventHandler = (event) => {
    let val = (event.target.getContent && event.target.getContent()) || event.target.value;
    let obj = {};
    obj[event.target.name] = val;
    this.setState(obj);
  }

  load = () => {
    this.setState({ loading: true }, () => {
      SettingsService.getSettings()
        .then((settings) => {
          OrgService.getAll()
          .then((result) => {
            let orgId = result.data.find((x) => { return x.role === "ORG" })            
            this.setState({ rootOrg: result.data.find((x) => { return x.parent === orgId.id })})
            let settingsKeys = Object.keys(settings);
            this.setState(settings, () => {
              this.setState({ loading: false }, () => {
                this.setState({ settingsKeys });
                // Simulate inputEventHandler for letterHeader
                let e = { target: { name: 'letterHeader', value: this.state.letterHeader } }
                this.inputEventHandler(e);
              });
            });
          })
          .catch(() => {
            this.setState({ loading: false });
          })
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  }

  save = () => {
    this.setState({ loading: true }, () => {
      async.each(this.state.settingsKeys, (key, cb) => {
        let obj = {}
        obj[key] = this.state[key];
        SettingsService.update(obj)
          .then(cb)
          .catch(cb);
      }, (err) => {
        this.setState({ loading: false });
        if (!err) {
          swal({
            title: '',
            text: 'Setelan berhasil tersimpan.'
          })
        }
        this.load();
      });
    });
  }

  useNIPSwitched = (checked) => {
    this.setState({ useNIPOnLetter: checked ? 'TRUE' : 'FALSE' });
  }

  showLastYearArchivedLetterSwitched = (checked) => {
    this.setState({ showLastYearArchivedLetter: checked ? 'TRUE' : 'FALSE' });
  }
  useLetterHeaderSwitched = (checked) => {
    this.setState({ useLetterHeader: checked ? 'TRUE' : 'FALSE' });
  } 
  hideDefaultClassificationSwitched = (checked) => {
    this.setState({ hideDefaultClassification: checked ? 'true' : 'false' });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Setelan Satuan Kerja</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ margin: 15 }}>
                <div className="col-md-12">
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Nama Satuan Kerja</label>
                    <div className="col-md-9" >
                      <input
                        className="form-control"
                        name="satkerName"
                        placeholder="Nama satuan kerja"
                        value={this.state.satkerName}
                        onChange={(e) => { this.setState({ satkerName: e.target.value }) }}
                      />
                      <small id="satkerNameHelp" className="form-text text-muted">Harap diisi</small>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Kode Satuan Kerja/Unit</label>
                    <div className="col-md-9" >
                      <input
                        className="form-control"
                        name="satkerCode"
                        placeholder="Nama satuan kerja"
                        value={this.state.satkerCode}
                        onChange={(e) => { this.setState({ satkerCode: e.target.value }) }}
                      />
                      <small id="satkerCodeHelp" className="form-text text-muted">Harap diisi</small>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Tahun pada nomor referensi surat</label>
                    <div className="col-md-9" >
                      <input
                        className="form-control"
                        disabled={this.state.loading}
                        name="letterYear"
                        type="number"
                        placeholder="Tahun surat"
                        value={this.state.letterYear}
                        onChange={(e) => { this.setState({ letterYear: e.target.value }) }}
                      />
                      <small id="letterYearHelp" className="form-text text-muted">Harap diisi</small>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Acuan nomor surat</label>
                    <div className="col-md-9" >
                      <input
                        className="form-control"
                        disabled={this.state.loading}
                        name="letterReferenceNumberCount"
                        type="number"
                        placeholder="Acuan nomor surat"
                        value={this.state.letterReferenceNumberCount}
                        onChange={(e) => { this.setState({ letterReferenceNumberCount: e.target.value }) }}
                      />
                      <small id="letterReferenceNumberHelp" className="form-text text-muted">Harap diisi</small>
                      {this.state.rootOrg.kodeJabatan === '' &&
                        <LetterRefCounter id={this.state.rootOrg.id} text="Setelan Nomor Surat Induk Organisasi"/>
                      }
                    </div>
                  </div>
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Gunakan NIP di dalam surat</label>
                    <div className="col-md-9" >
                      <Switch
                        onChange={this.useNIPSwitched}
                        checked={this.state.useNIPOnLetter === 'TRUE'}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Sembunyikan Klasifikasi Surat Bawaan</label>
                    <div className="col-md-9" >
                      <Switch
                        onChange={this.hideDefaultClassificationSwitched}
                        checked={this.state.hideDefaultClassification === 'true'}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Tampilkan surat dari arsip tahun sebelumnya</label>
                    <div className="col-md-9" >
                      <Switch
                        onChange={this.showLastYearArchivedLetterSwitched}
                        checked={this.state.showLastYearArchivedLetter === 'TRUE'}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Gunakan kop surat</label>
                    <div className="col-md-9" >
                      <Switch
                        onChange={this.useLetterHeaderSwitched}
                        checked={this.state.useLetterHeader === "TRUE"}
                      />
                    </div>
                  </div>
                  {this.state.loading ?
                    <Spinner /> :
                    <div className="form-group row">
                      <div className="col-md-2" >
                      </div>
                      <div className="col-md-9" >
                        <button className="btn btn-primary" onClick={this.save}>Simpan setelan Satker</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
