import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Spinner from '../../../../../components/Spinner';
import DelegationForm from './DelegationForm';

export default class Delegation extends Component {
  state = {
    page : 1,
    limit : 10,
    total : 0,
    pagination : [],
    data : [],
    loading : false,
    form : false,
  }

  componentDidMount = () => {
    this.load();
  }

  remove = (index) => {
    console.log(index);
    this.setState((prevState) => {
      prevState.data.splice(index,1);
      return {data : prevState.data}
    });
  }

  onApply = (data) => {
    this.setState({loading : true}, () => {
      setTimeout(() => {
        this.setState((prevState) => {
          prevState.data.push(data);
          return {loading: false, form: false, data : prevState.data}
        });
      }, 500);
    });
  }

  load = () => {
    this.setState({loading : true}, () => {
      setTimeout(() => {
        this.setState({
          loading : false,
          data : [
            {
              delegator : 'Direktur Demo',
              delegationRecipient : 'Kepala Divisi Demo II',
              startDate : new Date(),
              endDate : new Date(),
              reason : 'Hai',
            },
            {
              delegator : 'Direktur Demo',
              delegationRecipient : 'Direktur Jenderal Perbendaharaan',
              startDate : new Date(),
              endDate : new Date(),
              reason : 'Yoo',
            },
          ]
        });
      }, 500);
    });
  }

  render() {
    return (
      <div>
        <div>
          {this.state.form ? 
          <div>
            <h4>
              <Fa.FaChevronLeft 
                style={{cursor:'pointer'}}
                onClick={() => {this.setState({form : false}, () => {this.load()})}}
              /> 
              &nbsp;&nbsp;Form Delegasi
            </h4>
          </div>
          :
          <div>
            <button
              className="btn btn-primary"
              onClick={() => {this.setState({form : true})}}
              style={{float:'right', width:150, height:30, padding:0, position: 'absolute', right:0, marginRight:20, cursor:'pointer', zIndex:99}}
            >
              <Fa.FaPlus/> Pendelegasian
            </button>
            <h4>Daftar Pendelegasian</h4>
          </div>
          }
        </div>
	    <div className="row">
          {!this.state.loading && !this.state.form &&
	      <div className="col-md-12">
            {Object.keys(this.state.data).map((item, index) => {
              return <div className="card" style={{margin:5,padding:15}} key={index}>
                <button 
                  className="btn btn-danger" 
                  style={{float:'right', width:30, height:30, padding:0, position: 'absolute', right:0, marginRight:15, cursor:'pointer', zIndex:99}}
                  onClick={() => {
                   this.remove(index); 
                  }}
                >
                  <Fa.FaPowerOff/>
                </button>
                <div className="row">
                  <div className="col-md-4">
                    Pendelegasi
                  </div>
                  <div className="col-md-8">
                    : {this.state.data[item].delegator}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    Penerima Delegasi
                  </div>
                  <div className="col-md-8">
                    : {this.state.data[item].delegationRecipient}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    Periode
                  </div>
                  <div className="col-md-8">
                    : {this.state.data[item].startDate.toLocaleString()} - {this.state.data[item].endDate.toLocaleString()}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    Alasan
                  </div>
                  <div className="col-md-8">
                    : {this.state.data[item].reason}
                  </div>
                </div>
              </div>
            })}
	      </div>
          }
          <div className="col-md-12">
            {this.state.loading && <Spinner/>}
            {this.state.data.length < 1 && !this.state.loading &&
            <div style={{textAlign:'center', marginTop:50}}>
              <h3>Tidak ada delegasi</h3>
            </div>
            }
          </div>
          {this.state.form && !this.state.loading &&
          <div className="col-md-12">
            <DelegationForm
              onApply={this.onApply}
            />
          </div>
          }
	    </div>
      </div>
    );
  }
}
