import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import selector from './RemoteListSelector.js';
import RemoteUser from './RemoteUser';
import Local from './Local';

class Remotes extends Component {
  state = {
    mainFeed: null,
  };

  setMainFeed = feed => {
    var height = 0;
    let space = document.getElementById('main-feed-space');
    if (space) {
      let main = document.getElementById('main');
      if (main) {
        height = main.clientHeight / 2;
      }
      space.classList.add('main-feed-space-enabled');
      space.setAttribute('style', 'height:' + height + 'px;');
    }

    let element = document.getElementById('local-stream');
    if (element) {
      element.classList.remove('full-stream-box');
      element.classList.remove('max1');
      element.classList.remove('max2');
      element.classList.remove('max3');
      element.classList.remove('max4');
      element.classList.add('max' + (this.props.feeds.length + 1));
      element.setAttribute('style', 'height: inherit;');
    }
    for (let i in this.props.feeds) {
      let id = this.props.feeds[i].user.id;
      let element = document.getElementById(id);
      if (element) {
        element.classList.remove('full-stream-box');
        element.classList.remove('max1');
        element.classList.remove('max2');
        element.classList.remove('max3');
        element.classList.remove('max4');
        element.classList.add('max' + (this.props.feeds.length + 1));
        element.setAttribute('style', 'height: inherit;');
      }
    }

    let target = document.getElementById(feed.user.id);
    target.classList.add('full-stream-box');
    target.classList.remove('max1');
    target.classList.remove('max2');
    target.classList.remove('max3');
    target.classList.remove('max4');
    target.setAttribute('style', 'height:' + height + 'px;');
    console.log(feed);
  };

  render() {
    const {feeds} = this.props;
    const size = feeds.length + 1;
    return (
      <div className="feeds-container">
        <div id="main-feed-space"></div>
        <Local size={size} setMainFeed={this.setMainFeed} />
        {feeds.map((feed, key) => (
          <RemoteUser
            size={size}
            key={key}
            feed={feed}
            setMainFeed={this.setMainFeed}
          />
        ))}
        {size <= 1 ? (
          <div>
            <span className="status-bar">
              Sedang mencoba terhubung ke ruang rapat...
            </span>
          </div>
        ) : (
          <span />
        )}
      </div>
    );
  }
}

Remotes.propTypes = {
  feeds: PropTypes.array,
};

export default connect(selector)(Remotes);
