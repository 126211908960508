import React, { Component } from 'react';
import swal from 'sweetalert';
import Helpdesk from '../../../services/Helpdesk';
import CredentialResetModal from './CredentialResetModal';
import Tab from '../../../components/Tab';
import Error from '../../../services/Error';

const ErrorService = new Error();
const HelpdeskService = new Helpdesk();
export default class DashboardHelpdesk extends Component {
  state = {
    showModal: false,
    mode: '',
    resetType: '',
    currentTab: 'PIN',
    tabNav: {
      PIN: {
        name: 'PIN',
        default: true,
      },
      PASSWORD: {
        name: 'Kata Sandi',
      },
      ENV: {
        name: 'Konfigurasi ENV',
      },
      LOGO: {
        name: 'Gambar dan Logo',
      }
    }
  };

  switchTab = (currentTab) => {
    this.setState({ currentTab });
  };

  handleConfirm = payload => {
    let key = this.props.match.params.uuid;
    switch (this.state.resetType) {
      case 'resetPin':
        return HelpdeskService.resetAllPin(payload, key);
      case 'resetPassword':
        return HelpdeskService.resetAllPassword(payload, key);
      case 'resetOneUserPin':
        payload.key = key;
        return HelpdeskService.resetPin(payload);
      case 'resetOneUserPassword':
        payload.key = key;
        return HelpdeskService.resetPassword(payload);
    }
  };

  componentDidMount() {
    HelpdeskService.confirmDashboardId(this.props.match.params.uuid)
      .catch(err => {
        if (err.response.status === 403) {
          swal({
            title: '',
            type: 'warning',
            text: 'Maaf, URL salah atau sudah kadaluwarsa',
          }, () => {
            window.location = '/login';
          });
        } else {
          ErrorService.handle(err);
        }
      });
  }
  render() {
    return (
      <div style={{ marginLeft: '2%' }}>
        {
          this.state.showModal &&
          <CredentialResetModal
            onConfirm={this.handleConfirm}
            mode={this.state.mode}
            onCanceled={() => { this.setState({ showModal: false }); }}
            for={this.state.resetType === 'resetPin' || this.state.resetType === 'resetOneUserPin' ? 'PIN' : 'Kata Sandi'}
          />
        }
        <div className="row">
          <h1>Helpdesk Tools</h1>
        </div>
        <Tab nav={this.state.tabNav} handler={this.switchTab}></Tab>
        <div className="row bg-light" style={{ padding: '4%', marginLeft: '0%' }}>
          {this.state.currentTab == 'PIN' &&
            <div className="row" style={{ width: '100%' }}>
              <div className="helpdesk-button-container">
                <div className="small-box bg-green pointer helpdesk-button" onClick={() => { this.setState({ showModal: true, resetType: 'resetPin', mode: 'all' }); }}>
                  <div className="inner" style={{ textAlign: 'center' }}>
                    <h5>Reset Semua PIN</h5>
                  </div>
                </div>
              </div>
              <div className="helpdesk-button-container">
                <div className="small-box bg-green pointer helpdesk-button" onClick={() => { this.setState({ showModal: true, resetType: 'resetOneUserPin', mode: 'single' }); }}>
                  <div className="inner" style={{ textAlign: 'center' }}>
                    <h5>Reset PIN 1 Pengguna</h5>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.state.currentTab == 'PASSWORD' &&
            <div className="row" style={{ width: '100%' }}>
              <div className="helpdesk-button-container">
                <div className="small-box bg-green pointer helpdesk-button" onClick={() => { this.setState({ showModal: true, resetType: 'resetPassword', mode: 'all' }); }}>
                  <div className="inner" style={{ textAlign: 'center' }}>
                    <h5>Reset Semua Kata Sandi</h5>
                  </div>
                </div>
              </div>
              <div className="helpdesk-button-container">
                <div className="small-box bg-green pointer helpdesk-button" onClick={() => { this.setState({ showModal: true, resetType: 'resetOneUserPassword', mode: 'single' }); }}>
                  <div className="inner" style={{ textAlign: 'center' }}>
                    <h5>Reset Kata Sandi 1 Pengguna</h5>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}