import React, { Component } from 'react';
import { Mcu, VideoRoom } from 'react-janus'
import VideoList from './VideoList'
import './rapat.css'

export default class RapatRoom extends Component {


  constructor(props) {
    super(props)
    var currentUser
    try {
      currentUser = JSON.parse(localStorage.getItem('current_user'));
    } catch (e) {
      // empty
    }
    const id = parseInt(props.id.split('-').pop(), 16)
    this.state = {
      room: {
        room: id,
        request: 'create',
        fir_freq: 10,
        secret: 'test',
        description: 'Video room',
        bitrate: 128000
      },
      user: {
        id: currentUser.id,
        name: currentUser.name,
      },
      publishers: 6,
      janus: {
        server: process.env.WEBRTC_HOST ||'https://fr.webrtc.oa.citra.digital/janus'
      }
    }
  }

  render = () => {
    return (
      <div>
        <Mcu>
          <VideoRoom
            janus={this.state.janus}
            publishers={this.state.publishers}
            user={this.state.user}
            room={this.state.room}
            autoPublish={true}>

            <div className="rapat-container">
              <VideoList/>
            </div>
          </VideoRoom>
        </Mcu>
        
      </div>
    )
  }
}