import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import { PDFDownloadLink, BlobProvider, Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import User from '../../../services/User';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import Config from "../../../config";
import Auth from '../../../services/Auth';


import './styles.css';

const UserService = new User();
const AuthService = new Auth();

const signatureMarginLeft = 342;

const styles = StyleSheet.create({
  page: { size: 'A4' },
  topSection: { fontFace: 'Arial', textAlign: 'left', marginRight: '2cm', marginLeft: signatureMarginLeft, marginTop: 60, fontSize: '8pt', lineHeight: 1.5 },
  singleSpace: { fontFace: 'Arial', lineHeight: 1 },
  title: { fontFace: 'Arial', textAlign: 'center', marginTop: 60, fontSize: '11pt' },
  list: { fontFace: 'Arial', textAlign: 'left', marginLeft: 60, marginTop: 30, fontSize: '11pt', lineHeight: 1.5 },
  signature1: { textAlign: 'left', marginLeft: signatureMarginLeft, marginTop: 30, fontSize: '11pt', marginRight: 60 },
  signature2: { textAlign: 'left', marginLeft: signatureMarginLeft, marginTop: 10, fontSize: '11pt', marginRight: 60 },
  signature3: { textAlign: 'left', marginLeft: 365, marginTop: 10, fontSize: '11pt', marginRight: 60 },
  signatureOnBehalf: { textAlign: 'left', marginLeft: signatureMarginLeft, marginTop: 30, fontSize: '11pt', marginRight: 60, flexDirection: 'row' },
  signatureImage: { height: '100px', marginLeft: 190 },
  signatureImageOnBehalf: { height: '100px', marginLeft: 230 },
});

const Max = 25;
const Threshold = 4;

function titleCase(str) {
  return str.toLowerCase().split(' ').map(function (word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

function resolveLetterName(letterType) {
  return titleCase(letterType.replace("TNDE_", "").replace("MANUAL_", "").replace(/_/g, " "));
}

function generatePDF(vals, useNIPOnLetter) {
  const letterName = resolveLetterName(letterType);
  return (
    <Document>
      {vals.pages.map((page, index) => {
        const lastPage = index === vals.pages.length - 1;
        return (
          <Page key={index} style={styles.page}>
            <View style={styles.topSection}>
              <Text>LAMPIRAN</Text>
              <Text>{letterName} {vals.signer}</Text>
              <Text>Nomor   &#x2004; : &#x2004; {vals.number}</Text>
              <Text>Tanggal &#x2006; : &#x2004; {vals.date}</Text>
            </View>
            {index === 0 && letterType === "TNDE_UNDANGAN" && (
              <View style={styles.title}>
                <Text>DAFTAR PEJABAT/PEGAWAI YANG DIUNDANG</Text>
              </View>
            )}
            {index === 0 && letterType !== "TNDE_UNDANGAN" && (
              <View style={styles.title}>
                <Text>DAFTAR PEJABAT/PEGAWAI PENERIMA</Text>
              </View>
            )}

            <View style={styles.list}>
              {page.map(name => <Text key={name.recipient}>{name.index + 1}. {name.recipient}</Text>)}
            </View>


            {lastPage && vals.onBehalfOfTitle !== "" && (
              <View style={styles.signatureOnBehalf}>
                <Text>a.n. &nbsp;</Text>
                <View>
                  <Text>{vals.onBehalfOfTitle}</Text>
                  <Text>{vals.signer}</Text>
                </View>
              </View>
            )}

            {lastPage && vals.onBehalfOfTitle === "" &&
              vals.signerOrgSignerName !== "" && (
                <View style={styles.signature1}>
                  <Text>{vals.signerOrgSignerName}</Text>
                </View>
              )}

            {lastPage && vals.signerOrgSignerName === "" && vals.onBehalfOfTitle === "" && (
              <View style={styles.signature1}>
                <Text>{vals.signer}</Text>
              </View>
            )}

            {
              lastPage &&
              vals.scannedSignature !== '' &&
              vals.signatureType === 'SCANNED' &&
              (vals.letterState === 'SENT' || vals.letterState === 'FINAL') &&
              <Image
                style={vals.onBehalfOfTitle === "" ? styles.signatureImage : styles.signatureImageOnBehalf}
                src={Config.apiHost + "/api/v1/blob/unrestricted/" + vals.scannedSignature}
              />
            }
            {
              lastPage &&
              (vals.signatureType !== 'SCANNED' ||
                vals.scannedSignature === '' ||
                (vals.letterState !== 'SENT' && vals.letterState !== 'FINAL')) &&
              <View style={styles.singleSpace}>
                <Text>&nbsp;</Text>
                <Text>&nbsp;</Text>
                <Text>&nbsp;</Text>
                <Text>&nbsp;</Text>

              </View>
            }
            {lastPage && (
              <View style={vals.onBehalfOfTitle === "" ? styles.signature2 : styles.signature3}>
                <Text>{vals.signerName}</Text>
                {useNIPOnLetter === 'TRUE' && <Text>NIP. {vals.signerNIP}</Text>}
              </View>
            )}

          </Page>
        );

      })}
    </Document>

  );
}


export default class InvitationListPDF extends Component {
  state = {
    signer: '',
    signerName: '',
    recipients: [],
    number: '',
    date: '',
    done: false,
    signerNIP: '',
    scannedSignature: '',
    pages: [],
    numRecipients: 0,

    signerOrgSignerName: '',
    onBehalfOfTitle: '',
    step: 0,
  };

  createPages(recipients) {
    var r = [];
    var items = [];

    for (var i = 0,
      counter = 0,
      remaining = recipients.length;
      i < recipients.length;
      i++ ,
      counter++ ,
      remaining--) {
      items.push({
        index: i,
        recipient: recipients[i]
      });

      var increasePage = counter >= Max - 1;

      if (remaining < Threshold && counter > (Max - Threshold)) {
        increasePage = true;
      }
      if (increasePage) {
        counter = 0;
        r.push(items);
        items = [];
      }
    }
    if (items.length > 0) {
      r.push(items);
    }
    return r;
  }


  populateFromEditor() {

    var vals = this.props.value;

    if (this.state.signerName === '' && vals.signers && vals.signers.raw) {
      var signerId = vals.signers.raw[0];
      if (signerId) {
        UserService.get(signerId).then((v) => {
          var signerOrg = '';

          if (v.organization && v.organization.name) {
            signerOrg = v.organization.name;
          }

          this.setState({
            step: this.state.step + 1,
            signerName: v.name,
            signer: signerOrg
          });
        });
      }
    }
    var item;
    var recipients = [];
    if (vals.recipientSorted && vals.recipientSorted.raw && vals.recipientSorted.raw.length > 0) {
      for (item of vals.recipientSorted.raw) {
        if (item.recipientType.indexOf('VIA') > -1 ||
          item.recipientType.indexOf('ASSISTANT') > -1 ||
          item.recipientType.indexOf('DELEGATION') > -1 ||
          item.recipientType.indexOf('FORWARD') > -1 ||
          item.isGroup
        ) {
          continue;
        }
        recipients.push(item.recipientName.replace("|", ","));
      }
    } else {
      if (vals.recipients && vals.recipients.raw && vals.recipients.raw.length > 0) {
        for (item of vals.recipients.raw) {
          if (item.recipientType.indexOf('VIA') > -1 ||
            item.recipientType.indexOf('ASSISTANT') > -1 ||
            item.recipientType.indexOf('DELEGATION') > -1 ||
            item.recipientType.indexOf('FORWARD') > -1 ||
            item.isGroup
          ) {
            continue;
          }
          recipients.push(item.recipientName.replace("|", ","));
        }
      }
      if (vals.externRecipients && vals.externRecipients.raw && vals.externRecipients.raw.length > 0) {
        for (item of vals.externRecipients.raw) {
          if (item.recipientType.indexOf('VIA') > -1 ||
            item.recipientType.indexOf('ASSISTANT') > -1 ||
            item.recipientType.indexOf('DELEGATION') > -1 ||
            item.recipientType.indexOf('FORWARD') > -1
          ) {
            continue;
          }
          recipients.push(item);
        }
      }
    }

    if (vals.ccRecipientSorted && vals.ccRecipientSorted.raw && vals.ccRecipientSorted.raw.length > 0) {
      for (item of vals.ccRecipientSorted.raw) {
        if (item.recipientType.indexOf('ASSISTANT') > -1 || item.isGroup) continue;
        recipients.push(item.recipientName.replace("|", ","));
      }
    } else {
      if (vals.ccRecipients && vals.ccRecipients.raw && vals.ccRecipients.raw.length > 0) {
        for (item of vals.ccRecipients.raw) {
          if (item.recipientType.indexOf('VIA') > -1 ||
            item.recipientType.indexOf('ASSISTANT') > -1 ||
            item.recipientType.indexOf('DELEGATION') > -1 ||
            item.recipientType.indexOf('FORWARD') > -1 ||
            item.isGroup
          ) {
            continue;
          }
          recipients.push(item.recipientName.replace("|", ","));
        }
      }
      if (vals.ccExternRecipients && vals.ccExternRecipients.raw && vals.ccExternRecipients.raw.length > 0) {
        for (item of vals.ccExternRecipients.raw) {
          if (item.recipientType.indexOf('VIA') > -1 ||
            item.recipientType.indexOf('ASSISTANT') > -1 ||
            item.recipientType.indexOf('DELEGATION') > -1 ||
            item.recipientType.indexOf('FORWARD') > -1
          ) {
            continue;
          }
          recipients.push(item);
        }
      }
    }
    const pages = this.createPages(recipients);

    this.setState({
      subject: vals.subject,
      pages,
      numRecipients: recipients.length,
      step: this.state.step + 1,
    });
  }


  populateFromReader() {
    var vals = this.props.value.currentLetter;
    var signerName, signerOrg, scannedSignature = '';

    if (this.state.signerName === '') {
      if (vals.letterState === 'SENT' || AuthService.getRoles().role === 'FINALIZER') {
        scannedSignature = vals.scannedSignature;
      }
      signerName = vals.signerName;
      signerOrg = vals.signerOrg;
    }
    var recipients = [];
    if (vals.recipients && vals.recipients.length > 0) {
      vals.recipients.sort((a, b) => { return a.recipientOrder - b.recipientOrder; });
      for (var i = vals.recipients.length - 1; i >= 0; i--) {
        for (var j = vals.recipients.length - 1; j >= 0; j--) {
          if (vals.recipients[i].recipientUuid === vals.recipients[j].parent) {
            vals.recipients.isGroup = true;
          }
        }
      }
      for (var item of vals.recipients) {
        if (item.isGroup || item.recipientType.indexOf('VIA') > -1 ||
          item.recipientType.indexOf('ASSISTANT') > -1 ||
          item.recipientType.indexOf('DELEGATION') > -1 ||
          item.recipientType.indexOf('FORWARD') > -1
        ) {
          continue;
        }
        recipients.push(item.recipientName.replace("|", ","));
      }
    }

    var dateStr;
    var dateEpoch = Date.parse(vals.letterDate);
    if (!isNaN(dateEpoch) && dateEpoch > 0) {
      dateStr = date.format(dateEpoch, 'DD MMMM YYYY', { locale: dateLocaleId });
    }

    var signerNIP;
    if (this.props.value.settings.useNIPOnLetter && this.props.value.settings.useNIPOnLetter === 'TRUE') {
      signerNIP = vals.signerNIP;
    }
    const pages = this.createPages(recipients);

    this.setState({
      recipients,
      subject: vals.subject,
      letterState: vals.letterState,
      signatureType: vals.signatureType,
      number: vals.number,
      date: dateStr,
      signerNIP,
      pages,
      numRecipients: recipients.length,
      signerName,
      signer: signerOrg,
      scannedSignature,
      onBehalfOf: vals.onBehalfOf || "",
      onBehalfOfTitle: vals.onBehalfOfTitle || "",
      signerOrgSignerName: vals.signerOrgSignerName || "",
      step: 2
    });
  }

  componentDidMount() {
    if (this.props.value && this.props.value.currentLetter) {
      letterType = this.props.value.currentLetter.letterType;
      letterState = this.props.value.currentLetter.letterState;
      this.populateFromReader();
    } else {
      letterType = this.props.value.letterType;
      this.populateFromEditor();
    }
  }

  render() {
    if (this.state.numRecipients <= 5 || this.state.step < 2) {

      return (<div />);
    }

    if (last != null) {
      clearTimeout(last);
    }

    if (this.state.step === 2) {
      last = setTimeout(() => {
        this.setState({ step: 3 });
      }, 500);
    }

    if (letterType === "TNDE_UNDANGAN") {
      return (
        (letterState === "SENT") ? <div></div> :
          <div className="letter-read-attachment-info col-md-3">
            {this.state.step === 3 && (
              <BlobProvider document={generatePDF(this.state)}>
                {({ blob, url, loading, error }) => {
                  return loading ? 'Menyiapkan daftar undangan...' :
                    <div>
                      <a href={url} download="oa-daftar-undangan.pdf">
                        Unduh daftar undangan
                                        <button
                          className="btn btn-default"
                          style={{ float: 'right' }}
                        >
                          <Fa.FaDownload />
                        </button>
                      </a>
                    </div>;
                }}
              </BlobProvider>
            )}
          </div>
      );
    } else {
      return (
        <div className="letter-read-attachment-info col-md-3">
          {this.state.step === 3 && (
            <PDFDownloadLink document={generatePDF(this.state, this.props.value.settings.useNIPOnLetter)} fileName="oa-daftar-penerima.pdf">
              {({ blob, url, loading, error }) => (loading ? 'Menyiapkan daftar penerima...' :
                <div>
                  Unduh daftar penerima
                                    <button
                    className="btn btn-default"
                    style={{ float: 'right' }}
                  >
                    <Fa.FaDownload />
                  </button>
                </div>
              )}
            </PDFDownloadLink>
          )}
        </div>
      );
    }
  }
}


var last = null;
var letterType;
var letterState;