import PropTypes from 'prop-types';
import React, { Component } from 'react'
import * as Fa from 'react-icons/lib/fa';

class Cam extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cam: true
    }
  }

  componentWillReceiveProps(nextProps) {
    const { videoRoomLocal } = nextProps
    const { cam } = this.state
    if(nextProps.stream !== this.props.stream) {
      if(cam) {
        videoRoomLocal.unmuteVideo()
      } else {
        videoRoomLocal.muteVideo()
      }
    }
  }

  toggle() {
    let { cam } = this.state
    const { videoRoomLocal } = this.props
    cam = !cam
    if(!cam) {
      videoRoomLocal.muteVideo()
    } else {
      videoRoomLocal.unmuteVideo()
    }
    this.setState({
      cam
    })
    this.props.onToggle(cam)
  }

  render() {
    console.log(this.state.cam, this.props)
    return (
      <button className="cam" onClick={this.toggle.bind(this)}>
        { this.state.cam ? (
          <Fa.FaVideoCamera/>
        ) : (
          <Fa.FaVolumeOff/>
        ) }
      </button>
    )
  }
}

Cam.propTypes = {
  videoRoomLocal: PropTypes.object,
  stream: PropTypes.object
}

export default Cam