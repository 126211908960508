import React, { Component } from 'react';
import asyncLib from 'async';
import * as Fa from 'react-icons/lib/fa';
import './styles.css';
import dateLocaleId from 'date-fns/locale/id';
import Letter from '../../../services/Letter';
import stripTags from '../../../utils'
import date from 'date-fns';
import Spinner from '../../../components/Spinner';
import Constants from '../../../consts';

const LetterService = new Letter();

export default class LetterReadReferenceHistory extends Component {
  state = {
    loading: false,
    dispositionHistories: [],
    actionMap: Constants.actionMap,
  }
  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      let tempState = {
        currentLetter: this.props.currentLetter,
      }
      tempState.referencing = []
      if (!(tempState.currentLetter.referencing && tempState.currentLetter.referencing.length > 0)) {
        tempState = { ...tempState, ...{ loading: false } }
        this.setState(tempState);
        return
      }
      asyncLib.eachSeries(tempState.currentLetter.referencing, (id, cb) => {
        LetterService.readLetter(id)
          .then((referencingLetter) => {
            LetterService.getHistories(referencingLetter.id)
              .then((result) => {
                referencingLetter = { ...referencingLetter, ...{ letterHistories: result, letterHistoryLoading: false } }
                return LetterService.getComments(referencingLetter.id)
              })
              .then((result) => {
                referencingLetter = { ...referencingLetter, ...{ letterHistoryLoading: false, letterComments: result } }
                // Combine
                let combinedHistories = referencingLetter.combinedHistories || [];
                for (let i in referencingLetter.letterComments.data) {
                  combinedHistories.push(referencingLetter.letterComments.data[i]);
                }
                for (let i in referencingLetter.letterHistories.data) {
                  combinedHistories.push(referencingLetter.letterHistories.data[i]);
                }
                combinedHistories.sort(function (a, b) { return new Date(a.timestamp) - new Date(b.timestamp) });
                referencingLetter = { ...referencingLetter, ...{ combinedHistories: combinedHistories } }
                tempState.referencing.push(referencingLetter)
                cb();
              })
              .catch((err) => {
                cb(err)
              });
          })
      }, (err) => {
        if (err) {
          console.log(err)
        }
        tempState = { ...tempState, ...{ loading: false } }
        console.log("FINAL SET HISTORY AND REFERENCING STATE", tempState)
        this.setState(tempState);
      })
    });
  }
  render() {
    return (
      <div>
        <div>
          <div style={{ display: 'inline-block', verticalAlign: 'top', margin: 10, marginLeft: 0 }}>
            <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={this.props.back} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'inline-block' }}>
            <h4>Riwayat Balasan</h4>
            <span> Nomor surat : {this.props.currentLetter.number} <br /> {stripTags(this.props.currentLetter.subject)}</span>
          </div>
        </div>
        {this.state.loading && <Spinner />}
        {!this.state.loading && (!this.state.referencing || (this.state.referencing && this.state.referencing.length === 0)) &&
          <div className="alert alert-warning" style={{ marginTop: 15 }}><Fa.FaInfo />&nbsp;Belum ada balasan.</div>
        }
        {!this.state.loading && this.state.referencing && this.state.referencing.length > 0 &&
          <div className="row" style={{ marginTop: 15 }}>
            <div className="col-md-12">
              {this.state.referencing && this.state.referencing.map((letter, referencingIndex) => {
                return <div className="box box-body" style={{ marginTop: 15 }} key={referencingIndex}>
                  <span style={{ fontWeight: 'bold' }}>Perihal: {stripTags(letter.subject)}</span>
                  <table className="table table-bordered table-read-detail" style={{ marginTop: 10 }}>
                    <thead>
                      <tr className="active">
                        <th colSpan="2">Riwayat Konsep Surat</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          {letter.combinedHistories && letter.combinedHistories.map((item, index) => {
                            return <div style={{ marginLeft: 10 }} key={index}><div className="alert alert-default" style={{ padding: 4, marginBottom: 5 }}>
                              {date.format(item.timestamp, 'ddd, DD MMMM YYYY - HH:mm:ss', { locale: dateLocaleId })} -&nbsp;
                            {item.originatorName.split('|')[0]}
                              {item.originatorName.split('|')[1] && <span>, {item.originatorName.split('|')[1]}</span>}
                              &nbsp;:&nbsp;
                        {item.state && item.state === 'CREATE' && 'Membuat surat'}
                              {item.state && item.state === 'MODIFIED' && 'Menyunting'}
                              {item.state && item.state === 'PULLBACK' && <span>Menarik surat <Fa.FaChevronLeft color={'orange'} /></span>}
                              {item.state && item.state === 'REJECT' && <span>Mengembalikan <Fa.FaChevronLeft color={'red'} /></span>}
                              {item.state && item.state === 'DONE' && <span>Menyetujui <Fa.FaCheck color={'green'} /></span>}
                              {item.state && item.state === 'CANCELED' && <span>Membatalkan <Fa.FaClose color={'red'} /></span>}
                              {item.state && item.state.indexOf('FINALIZE') > -1 && <span>Finalisasi dan dikirim <Fa.FaCheck color={'green'} /></span>}
                              {item.notes && <span>Mengomentari  </span>}
                              {item.notes && <span className="alert alert-comment">&nbsp;{item.notes}</span>}
                            </div></div>
                          })}
                        </td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr className="active">
                        <th colSpan="2">Proses Konsep Surat</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <div style={{ margin: 10 }}>
                            <span className="alert alert-default" style={{ padding: 4 }}>
                              {letter.originatorTitle} (KONSEPTOR)
                              &nbsp;:&nbsp;
                            Membuat surat&nbsp;<Fa.FaCheck style={{ color: 'green' }} />
                            </span>
                          </div>
                          {letter && letter.processings && letter.processings.map((item, index) => {
                            return <div style={{ margin: 10 }} key={index}><span className="alert alert-default" style={{ padding: 4 }}>
                              {item.holderName.split('|')[0]}
                              {item.holderName.split('|')[1] && <span>, {item.holderName.split('|')[1]}</span>}
                              {item.role !== 'bundle' && <span>
                                &nbsp;(
                        {item.role && item.role === 'approver' && 'PEMERIKSA'}
                                {item.role && item.role === 'via' && 'MELALUI'}
                                {item.role && item.role === 'signer' && 'PENANDA TANGAN'}
                                {item.role && item.role === 'assistant' && 'ASISTEN'}
                                )&nbsp;:&nbsp;
													</span>}
                              {item.state && item.state === 'DONE' && <span>Telah menyetujui &nbsp; <Fa.FaCheck color={'green'} /></span>}
                              {item.state && item.state === 'RETURNED' && <span>Telah mengembalikan &nbsp; <Fa.FaChevronLeft color={'red'} /></span>}
                              {item.state && item.state === 'CANCELED' && <span>Telah membatalkan &nbsp; <Fa.FaClose color={'red'} /></span>}
                              {item.state && item.state === 'PROCESS' && item.role !== 'bundle' && <span>Menunggu diperiksa &nbsp; <Fa.FaEdit /></span>}
                              {item.state && item.state === 'PROCESS' && item.role === 'bundle' && <span>Menunggu dipaketkan &nbsp; <Fa.FaEdit /></span>}
                              {item.state && item.state === 'WAIT' && <span>Menunggu giliran &nbsp; <Fa.FaClockO /></span>}
                            </span></div>
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              })}
            </div>
          </div>
        }
      </div>
    );
  }
}
