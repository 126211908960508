var variant = {}
console.log(process.env.REACT_APP_OA_VARIANT)
if (process.env.REACT_APP_OA_VARIANT) {
  variant = require("./" + process.env.REACT_APP_OA_VARIANT + "/config.js")
} else {
  variant = require("./default/config.js")
}
const noRapat = process.env.REACT_APP_NO_RAPAT
const baseUrl = process.env.REACT_APP_BACKEND_URL
const wsUrl = process.env.REACT_APP_WS_URL
const defaultSignature = process.env.REACT_APP_DEFAULT_SIGNATURE
const noDefaultClassifications = process.env.REACT_APP_NO_DEFAULT_CLASSIFICATIONS
const manualLetterReviewerEnabled = (
  process.env.REACT_APP_OA_ENABLE_INCOMING_MANUAL_LETTER_REVIEWER === undefined ||
  process.env.REACT_APP_OA_ENABLE_INCOMING_MANUAL_LETTER_REVIEWER === false ||
  process.env.REACT_APP_OA_ENABLE_INCOMING_MANUAL_LETTER_REVIEWER === '' ||
  process.env.REACT_APP_OA_ENABLE_INCOMING_MANUAL_LETTER_REVIEWER === 'false'
) ? false : true
const gerbang = (
  process.env.REACT_APP_OA_GERBANG === undefined ||
  process.env.REACT_APP_OA_GERBANG === false ||
  process.env.REACT_APP_OA_GERBANG === '' ||
  process.env.REACT_APP_OA_GERBANG === 'false'
) ? false : true
const letterBundleDisabled = (
  process.env.REACT_APP_LETTER_BUNDLE_DISABLED === undefined ||
  process.env.REACT_APP_LETTER_BUNDLE_DISABLED === false ||
  process.env.REACT_APP_LETTER_BUNDLE_DISABLED === '' ||
  process.env.REACT_APP_LETTER_BUNDLE_DISABLED === 'false'
) ? false : true
const PDFDisabled = (
  process.env.REACT_APP_PDF_DISABLED === undefined ||
  process.env.REACT_APP_PDF_DISABLED === false ||
  process.env.REACT_APP_PDF_DISABLED === '' ||
  process.env.REACT_APP_PDF_DISABLED.toLowerCase() === 'false'
) ? false : true
const isSignerOrgVisible = (
  process.env.REACT_APP_OA_SHOW_SIGNER_ORG_ON_TNDE_NOTA_DINAS === undefined ||
  process.env.REACT_APP_OA_SHOW_SIGNER_ORG_ON_TNDE_NOTA_DINAS === false ||
  process.env.REACT_APP_OA_SHOW_SIGNER_ORG_ON_TNDE_NOTA_DINAS === '' ||
  process.env.REACT_APP_OA_SHOW_SIGNER_ORG_ON_TNDE_NOTA_DINAS.toLowerCase() === 'false'
) ? false : true
const impersonationEnabled = process.env.REACT_APP_IMPERSONATION_ENABLED
const dispositionThroughAssistant = process.env.REACT_APP_OA_ENABLE_DISPOSITION_THROUGH_ASSISTANT
const trackingCode = process.env.REACT_APP_OA_TRACKING_CODE
const isProduction = (process.env.REACT_APP_OA_PRODUCTION === "true")
const mutableDefaultDispositionAction = process.env.REACT_APP_OA_MUTABLE_DEFAULT_DISPOSITION_ACTION
var config = () => {
  return {
    apiHost: (
      baseUrl === undefined ||
      baseUrl === ''
    ) ? "https://" + window.location.hostname : baseUrl,
    wsHost: (
      wsUrl === undefined ||
      wsUrl === ''
    ) ? "wss://" + window.location.hostname + "/ws" : wsUrl,
    disposition: {
      initiationByStaff: false,
    },
    variant: variant.default,
    production: isProduction,
    trackingCode: trackingCode? trackingCode: 'UA-146709439-1',
    autoLogoutTimer: 1000 * 60 * 15, // 15 minutes
    letterBundleDisabled: letterBundleDisabled,
    noRapat: noRapat === undefined || noRapat === '' ? false : true,
    signatureType: (
      defaultSignature &&
      defaultSignature.length > 0
    ) ? defaultSignature : 'HANDWRITTEN',
    noDefaultClassifications: noDefaultClassifications,
    PDFDisabled: PDFDisabled,
    gerbang: gerbang,
    manualLetterReviewerEnabled: manualLetterReviewerEnabled,
    impersonationEnabled: impersonationEnabled === undefined || impersonationEnabled === '' ? false : true,
    dispositionThroughAssistant: dispositionThroughAssistant === undefined || dispositionThroughAssistant === '' ? false : true,
    mutableDefaultDispositionAction: mutableDefaultDispositionAction === undefined || mutableDefaultDispositionAction === '' ? true : false,
    isSignerOrgVisible: isSignerOrgVisible,
  }
}

if (!isProduction) {
  window.showConfig = config;
}

export default config()

