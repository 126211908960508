import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Letter from '../../../../../services/Letter';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import stripTags from '../../../../../utils'

const LetterService = new Letter();

export default class ProcessingDraft extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    loading: false,
    searchString: '',
    selectedForDeletion: {}
  }


  componentDidMount = () => {

    this.setState({ loading: true }, () => {
      LetterService.getList({ box: 'DRAFT', page: 1, limit: 10, search: this.state.searchString })
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  page = (page) => {
    this.setState({ loading: true });
    let opt = { box: 'DRAFT', page: page, limit: this.state.limit, search: this.state.searchString }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true });
    let opt = { box: 'DRAFT', page: this.state.page + 1, limit: this.state.limit, search: this.state.searchString }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true });
    let opt = { box: 'DRAFT', page: this.state.page - 1, limit: this.state.limit, search: this.state.searchString }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  reload = () => {
    this.setState({ loading: true });
    let opt = { letterType: this.props.type, page: this.state.page, limit: this.state.limit, box: "DRAFT", search: this.state.searchString }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          currentLetter: {},
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          currentLetter: {},
          data: [],
          loading: false,
        });
      });
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target && e.target.value) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  compose = (letter) => {
    console.log(letter.id);
    window.browserHistory.push('/compose/' + letter.letterType + '?uuid=' + letter.id);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Draf</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <div className="input-group input-group-sm">
                    <span className="input-group-btn">
                      <span className="btn btn-success">
                        <Fa.FaRefresh />
                      </span>
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      onChange={this.handleSearchString}
                      value={this.state.searchString}
                      onKeyPress={(e) => { if (e.key === 'Enter') this.search() }}
                    />
                    {this.state.searchString && this.state.searchString.length > 0 &&
                      <span className="input-group-btn">
                        <span className="btn btn-default" onClick={this.dismissSearch}>
                          <Fa.FaClose />
                        </span>
                      </span>
                    }
                    <span className="input-group-btn">
                      <span className="btn btn-default" onClick={this.search}>
                        <Fa.FaSearch />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div style={{ float: 'right' }}>
                    <Pagination
                      disabled={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prev} next={this.next}
                      toPage={this.page} />
                  </div>
                </div>
                <hr />
              </div>
              {this.state.loading ? <Spinner /> :
                <div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Tanggal</th>
                        <th>Perihal</th>
                        <th>No. Surat</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data && Object.keys(this.state.data).map((item, index) => {
                        return <tr key={index} style={{ cursor: 'pointer' }}>
                          <td onClick={(e) => {

                            var d = this.state.selectedForDeletion
                            if (e.target.checked) {
                              d[item] = 1
                            } else {
                              delete d[item]
                            }
                            this.setState({
                              selectedForDeletion: d
                            })
                          }} style={{ width: "30px" }}><input type="checkbox" /></td>
                          <td onClick={() => { this.compose(this.state.data[item]) }}>{date.format(this.state.data[item].timestamp, 'DD MMMM YYYY', { locale: dateLocaleId })}</td>
                          <td onClick={() => { this.compose(this.state.data[item]) }}>{stripTags(this.state.data[item].subject)}</td>
                          <td onClick={() => { this.compose(this.state.data[item]) }}>{this.state.data[item].number}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                  {Object.keys(this.state.selectedForDeletion).length > 0 && (
                    <button style={{ margin: "20px" }} className="btn btn-primary btn-danger" onClick={() => {
                      this.setState({ loading: true}, () => {

                        var deletes = []
                        for (var d of Object.keys(this.state.selectedForDeletion)) {
                          deletes.push(LetterService.deleteLetter(this.state.data[d].id))
                        }
                        Promise.all(deletes).then((result) => {
                          this.setState({
                            selectedForDeletion: {} 
                          }, () => {
                            this.reload()
                          })
                        })
                      })
                    }}>Hapus</button>
                  )}
                </div>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <span style={{ fontSize: 32 }}>
                    <Fa.FaInbox />
                  </span>
                  <br />
                  <h4>Tidak ada surat</h4>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
