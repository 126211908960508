import axios from './Axios'
import Config from "../config";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

export default class Helpdesk {
  confirmDashboardId = id => {
    return new Promise((resolve, reject)=>{
      axios.get(Config.apiHost+`/api/v1/helpdesk/${id}`)
      .then(resolve)
      .catch(reject)
    })
  }
  resetPin = (payload) => (
    new Promise((resolve, reject)=>{
      axios.post(
        Config.apiHost+`/api/v1/helpdesk/reset-pin/${payload.username}`,
        { newPin:payload.newCred, key:payload.key },
        { headers: MiscService.generateHeaders() }
      ).then(resolve)
      .catch(err =>{
        ErrorService.handle(err)
        reject(err)
      })
    })
  )
  resetPassword = (payload) => (
    new Promise((resolve, reject)=>{
      axios.post(
        Config.apiHost+`/api/v1/helpdesk/reset-password/${payload.username}`,
        { newPassword:payload.newCred, key:payload.key },
        { headers: MiscService.generateHeaders() }
      ).then(resolve)
      .catch(err =>{
        ErrorService.handle(err)
        reject(err)
      })
    })
  )
  resetAllPin = (newPin, key) => (
    new Promise((resolve, reject)=>{
      axios.post(
        Config.apiHost+`/api/v1/helpdesk/reset-pin`,
        { newPin, key },
        { headers: MiscService.generateHeaders() }
      ).then(resolve)
      .catch(err =>{
        ErrorService.handle(err)
        reject(err)
      })
    })
  )
  resetAllPassword = (newPassword, key) => (
    new Promise((resolve, reject)=>{
      axios.post(
        Config.apiHost+`/api/v1/helpdesk/reset-password`,
        { newPassword, key },
        { headers: MiscService.generateHeaders() }
      ).then(resolve)
      .catch(err =>{
        ErrorService.handle(err)
        reject(err)
      })
    })
  )
}