import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import swal from 'sweetalert';
import AddressBookModal from '../../AddressBookModal';
import Misc from '../../../../../services/Misc';

const MiscService = new Misc();

export default class Assistant extends Component {
  state = {
    currentUser: {}
  }

  componentDidMount = () => {
    this.reload();
  }
  cancel = () => {
    this.setState({ assistant: '', active: false }, () => {
    });
  }

  reload = () => {
    MiscService.getCurrentUser()
      .then((currentUser) => {
        this.setState({ loading: false }, () => {
          this.setState({
            currentUser: currentUser,
          });
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        // TODO handle err
      });
  }

  setAssistant = (active) => {
    if (active && (!this.state.assistantId || (this.state.assistantId && this.state.assistantId.length < 1))) {
      swal({
        title: '',
        type: 'warning',
        text: 'Silakan pilih asistan terlebih dahulu.'
      });
      return;
    }
    MiscService.setAssistant({ uuid: this.state.assistantId, state: active ? 'ACTIVE' : 'DEACTIVE' })
      .then((currentUser) => {
        console.log(currentUser);
        this.setState({ loading: false, assistant: null, assistantId: null }, () => {
          this.reload();
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        // TODO handle err
      });
  }

  render() {
    return (
      <div className="box box-success">
        <div className="box-body" style={{ padding: 15 }}>
          <div className="form-group row">
            <label className="control-label col-md-2">Jabatan</label>
            <div className="col-md-7">
              {this.state.currentUser && this.state.currentUser.organization && this.state.currentUser.organization.name}
            </div>
          </div>
          {this.state.currentUser && this.state.currentUser.organization && this.state.currentUser.organization.assistant && this.state.currentUser.organization.assistant.length > 0 ?
            <div className="form-group row">
              <label className="control-label col-md-2">Nama Asistan</label>
              <div className="col-md-7">
                {this.state.currentUser.organization.assistantName}
              </div>
            </div>
            :
            <AddressBookModal
              name="assistant"
              value={this.state.assistant}
              label="Asistan"
              additionalLabel="Klik pada textarea untuk membuka Buku Alamat"
              onChange={(e) => { this.setState({ assistant: e.target.value, assistantId: e.target.raw }) }}
              labelWidth={2}
              fieldWidth={5}
              row={1}
              single={true}
              require={true}
            />
          }
          <div className="form-group row">
            <label className="control-label col-md-2">Status</label>
            <div className="col-md-7">
              {this.state.currentUser && this.state.currentUser.organization && this.state.currentUser.organization.assistantState && this.state.currentUser.organization.assistantState === 'ACTIVE' ? 'Asistan sudah diaktifkan' : 'Asistan belum diaktifkan'}
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
            </div>
            <div className="col-md-7">
              {this.state.currentUser && this.state.currentUser.organization && this.state.currentUser.organization.assistantState === 'ACTIVE' ?
                <button className="btn btn-danger" onClick={() => { this.setAssistant(false) }}>
                  <Fa.FaPowerOff /> Batalkan
              </button>
                :
                <button className="btn btn-primary" onClick={() => { this.setAssistant(true) }}>
                  <Fa.FaPowerOff /> Aktifkan
              </button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
