import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import './styles.css';
import dateLocaleId from 'date-fns/locale/id';
import stripTags from '../../../utils'
import date from 'date-fns';
import Spinner from '../../../components/Spinner';


export default class LetterReadForwardHistory extends Component {
  state = {
    loading: false,
    forwardHistories: []
  }
  componentDidMount = () => {
    let obj = []
    this.props.recipients.sort((a, b) => { return new Date(a.timestamp) - new Date(b.timestamp) })
    for (let i in this.props.recipients) {
      if (!(this.props.recipients[i].recipientType.indexOf('FORWARD') > -1)) {
        continue;
      }
      let dateRec = new Date(this.props.recipients[i].timestamp);
      let dateStrRecipient = `${dateRec.getDate()}-${dateRec.getMonth()}-${dateRec.getFullYear()}-${dateRec.getHours()}-${dateRec.getMinutes()}`
      // Check first the data empty or not,if empty insert it first
      if (obj.length > 0) {
        for (let j in obj) {
          let dateData = new Date(obj[j].timestamp);
          let dateStrData = `${dateData.getDate()}-${dateData.getMonth()}-${dateData.getFullYear()}-${dateData.getHours()}-${dateData.getMinutes()}`
          if (dateStrRecipient === dateStrData) {
            obj[j].recipientName.push(this.props.recipients[i].recipientName)
          } else {
            if (obj.find((x) => {
              let data = new Date(x.timestamp);
              let dateStr = `${data.getDate()}-${data.getMonth()}-${data.getFullYear()}-${data.getHours()}-${data.getMinutes()}`
              return dateStr === dateStrRecipient
            })) {
              continue
            } else {
              obj.push({
                timestamp: this.props.recipients[i].timestamp,
                recipientName: [this.props.recipients[i].recipientName]
              })
            }
          }
        }
      } else {
        obj.push({
          timestamp: this.props.recipients[i].timestamp,
          recipientName: [this.props.recipients[i].recipientName]
        })
      }
    }
    this.setState({ forwardHistories: obj })
  }

  render() {
    return (
      <div>
        <div>
          <div style={{ display: 'inline-block', verticalAlign: 'top', margin: 10, marginLeft: 0 }}>
            <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={this.props.back} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'inline-block' }}>
            <h4>Riwayat Teruskan</h4>
            <span> Nomor surat : {this.props.currentLetter.number} <br /> {stripTags(this.props.currentLetter.subject)}</span>
          </div>
        </div>
        {this.state.loading && <Spinner/>}
        {!this.state.loading && (!this.state.forwardHistories || (this.state.forwardHistories && this.state.forwardHistories.length === 0)) &&
          <div className="alert alert-warning" style={{ marginTop: 15 }}><Fa.FaInfo />&nbsp;Tidak ada riwayat meneruskan surat.</div>
        }
        {!this.state.loading && this.state.forwardHistories && this.state.forwardHistories.length > 0 &&
          <div className="row" style={{ marginTop: 15 }}>
            <div className="col-md-12">
              <div className="box">
                {this.state.forwardHistories && this.state.forwardHistories.map((item, index) => {
                  return <div className="box-body" key={index}>
                    <table className="table table-bordered table-read-detail">
                      <thead>
                        <tr className="active">
                          <th colSpan="2">
                            {date.format(item.timestamp, 'DD MMMM YYYY, HH:mm', { locale: dateLocaleId })}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="20%">Meneruskan Surat Kepada</td>
                          <td width="80%">
                            <ul>
                            {item.recipientName.map((recipientName, index) => (
                              <li key={index}>{recipientName}</li>
                            ))}
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                })}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
