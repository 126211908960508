import React, { Component } from 'react';
import Comments from './Comments.js';

export default class InboxTanggapan extends Component {
  render() {
    return (
      <Comments/>
    );
  }
}
