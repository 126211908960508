import React, { Component } from 'react';
import Letter from '../../../../../services/Letter';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import * as Fa from 'react-icons/lib/fa';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import stripTags from '../../../../../utils'

import Tab from '../../../../../components/Tab';

const LetterService = new Letter();

export default class ProcessingNeedStatus extends Component {
  state = {
    currentUser: {},
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    filterValue: '',
    loading: false,
    currentTab: 'RETURNED',
    tabNav: {
      RETURNED: {
        name: 'Dikembalikan',
        default: true,
      },
      CHAIN_PROCESS: {
        name: 'Dalam Proses',
      },
      CHAIN_FINAL: {
        name: 'Dalam Finalisasi',
      },
      SENT: {
        name: 'Terkirim',
      },
    },
  }

  componentDidMount = () => {
    this.setState({ currentUser: window.store.getState().rootReducers.currentUser });
    this.setState({ loading: true }, () => {
      LetterService.getList({ box: this.state.currentTab, page: 1, limit: 10 })
        .then((result) => {
          console.log(result);
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  switchTab = (tab) => {
    this.setState({ currentTab: tab }, () => {
      this.page();
    });
  }

  page = (page) => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { box: this.state.currentTab, page: page, limit: this.state.limit }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { box: this.state.currentTab, page: this.state.page + 1, limit: this.state.limit }
    LetterService.getOutbox(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { box: this.state.currentTab, page: this.state.page - 1, limit: this.state.limit }
    LetterService.getOutbox(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  compose = (letter) => {
    console.log(letter.id);
    window.browserHistory.push('/compose/' + letter.letterType + '?uuid=' + letter.id);
  }

  read = (letter) => {
    if (this.state.currentTab === 'RETURNED') {
      window.browserHistory.push('/read/' + letter.id + '?action=process');
    } else {
      window.browserHistory.push('/read/' + letter.id + '?action=processStatus');
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Status</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <Tab nav={this.state.tabNav} handler={this.switchTab} />
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th style={{ width: '200px' }}>Tanggal</th>
                      {this.state.currentTab === 'SENT' &&
                        <th style={{ width: '200px' }}>Nomor</th>
                      }
                      <th style={{ minWidth: '250px', maxWidth: '400px' }}>Kepada</th>
                      <th style={{ minWidth: '400px' }}>Perihal</th>
                      {this.state.currentTab === 'CHAIN_PROCESS' &&
                        <th style={{ minWidth: '250px', maxWidth: '300px' }}>Pemeriksa saat ini</th>
                      }
                      {this.state.currentTab === 'CHAIN_PROCESS' &&
                        <th style={{ maxWidth: '50px' }}>Sisa Step</th>
                      }
                      {this.state.currentTab === 'CHAIN_PROCESS' &&
                        <th style={{ minWidth: '250px', maxWidth: '300px' }}>Penandatangan</th>
                      }

                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && Object.keys(this.state.data).map((item, index) => {
                      let signer 
                      
                      if (this.state.data[item].signers) 
                        signer = this.state.data[item].signers[0].split('|')

                      return <tr key={index} onClick={() => {
                        this.read(this.state.data[item])
                      }} style={{ cursor: 'pointer' }}>
                        <td style={{ width: '200px' }}>
                          {date.format(this.state.data[item].timestamp, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>
                        {this.state.currentTab === 'SENT' &&
                          <td style={{ width: '200px' }}> 
                            {this.state.data[item].number}
                          </td>
                        }
                        <td style={{ minWidth: '250px', maxWidth: '400px' }}>
                          {this.state.data[item].recipients && this.state.data[item].recipients.map((recItem, recIndex) => {
                            return <span>{recItem.recipientName}, </span>
                          })}
                        </td>
                        <td style={{ minWidth: '400px' }}>{stripTags(this.state.data[item].subject)}</td>
                        {this.state.currentTab === 'CHAIN_PROCESS' &&
                          <td style={{ minWidth: '250px', maxWidth: '300px' }}> {this.state.data[item].processings && this.state.data[item].processings.map((procItem, procIndex) => {
                            let holderName = '';
                            if (procItem.state === 'PROCESS') holderName = procItem.holderName;
                            return <span key={procIndex}>{holderName}</span>
                          })}
                          </td>
                        }
                        {this.state.currentTab === 'CHAIN_PROCESS' &&
                          <td style={{ maxWidth: '50px' }}> {this.state.data[item].processings && this.state.data[item].processings.map((procItem, procIndex) => {
                            if (procItem.state === 'PROCESS') {
                              return <span key={procIndex}>{this.state.data[item].processings.length - procIndex}</span>
                            } else {
                              return <span key={procIndex}></span>
                            }
                          })}
                          </td>
                        }
                        {this.state.currentTab === 'CHAIN_PROCESS' &&
                          <td style={{ minWidth: '250px', maxWidth: '300px' }}>
                            <span key={"signer-$index"}>{signer[1] + ' ' + signer[2]}</span>
                          </td>
                        }
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <span style={{ fontSize: 32 }}>
                    <Fa.FaInbox />
                  </span>
                  <br />
                  <h4>Tidak ada surat</h4>
                </div>
              }
              <Pagination
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prev} next={this.next}
                toPage={this.page}
                disabled={this.state.loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
