import React, { Component } from 'react';
import Letter from '../../../services/Letter';
import Spinner from '../../../components/Spinner';
import Pagination from '../../../components/Pagination';
import * as Fa from 'react-icons/lib/fa';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import stripTags from '../../../utils';
import config from '../../../config';

const LetterService = new Letter();

export default class DispositionBox extends Component {
  state = {
    currentUser: {},
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    filterValue: '',
    loading: false,
  }

  componentDidMount = () => {
    var currentUser = JSON.parse(localStorage.getItem('current_user'));
    this.setState({ currentUser: currentUser });
    this.page(1)
  }

  page = (page) => {
    this.setState({ loading: true }, () => {
      LetterService.getDispositions({
        page: page,
        limit: 10, search: this.state.searchString,
        recipientOrgId: this.state.currentUser.organization.id,
        recipientPltId: this.state.currentUser.pltId,
        recipientDelegatedId: this.state.currentUser.delegatedId,
        recipientUuid: this.state.currentUser.id,
        listType: this.props.match.params.type,
        throughAssistant: config.dispositionThroughAssistant
      })
        .then((result) => {
          let resultData = result.data
          let dataWithPriority = []
          let dataWithoutPriority = []
          resultData.forEach(data => {
            if (data.deadline.indexOf('0001-01') < 0)
              dataWithPriority[dataWithPriority.length] = data
            else
              dataWithoutPriority[dataWithoutPriority.length] = data
          })
          dataWithPriority.sort((a, b) => {
            let deadlineA = (new Date(a.deadline)).valueOf()
            let deadlineB = (new Date(b.deadline)).valueOf()
            return deadlineA - deadlineB
          })
          dataWithPriority.push(...dataWithoutPriority)
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: dataWithPriority || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  next = () => {
    this.page(this.state.page + 1)
  }

  prev = () => {
    this.page(this.state.page - 1)
  }

  read = (id) => {
    window.browserHistory.push('/read/' + id);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{ display: 'inline-block', verticalAlign: 'top', margin: 10, marginLeft: 0 }}>
              <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={() => window.browserHistory.push('/')} style={{ cursor: 'pointer' }} />
            </div>
            <div style={{ display: 'inline-block', marginTop: 8 }} >
              <h4>
                {this.props.match.params.type === 'IN_PROCESS' && 'Disposisi dalam proses'}
                {this.props.match.params.type === 'CLOSED' && 'Disposisi selesai'}
              </h4>
            </div>
          </div>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>No. Surat</th>
                      <th>Dari</th>
                      <th>Perihal</th>
                      <th>Tanggal Disposisi</th>
                      <th>Tenggat waktu</th>
                      <th>Prioritas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && Object.keys(this.state.data).map((item, index) => {
                      let deadlineTimeLeft = ((new Date(this.state.data[item].deadline)).valueOf() - (new Date()).valueOf()) / 1000 
                      let backgroundColor = !(this.state.data[item].deadline.indexOf('0001-01') < 0) ? "#ffffff" : deadlineTimeLeft< 172800 ? "#ffe2e2" : deadlineTimeLeft< 432000 ? "#ffe0b0" :"#ebf2f9"
                      
                      return <tr key={index} onClick={() => { this.read(this.state.data[item].letterId) }} style={{ cursor: 'pointer', backgroundColor , fontWeight: this.state.data[item].isUnread ? 'bold' : 'normal' }}>
                        <td>{this.state.data[item].letterRefNumber}</td>
                        <td>{stripTags(this.state.data[item].letterSigner)}</td>
                        <td>{stripTags(this.state.data[item].letterSubject)}</td>
                        <td>
                          {date.format(this.state.data[item].time, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>
                        {this.state.data[item].originator !== this.state.currentUser.id && <td>
                          {this.state.data[item].deadline.indexOf('0001-01') < 0 && date.format(this.state.data[item].deadline, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>}
                        {this.state.data[item].originator !== this.state.currentUser.id && <td>
                          {this.state.data[item].deadline.indexOf('0001-01') < 0 && <span>
                            {deadlineTimeLeft< 172800 ?
                              <span className="alert alert-danger">Tinggi</span> :
                              deadlineTimeLeft< 432000 ?
                                <span className="alert alert-warning">Sedang</span> :
                                deadlineTimeLeft>= 432000 &&
                                <span className="alert alert-primary">Rendah</span>}
                          </span>}
                        </td>}
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <span style={{ fontSize: 32 }}>
                    <Fa.FaInbox />
                  </span>
                  <br />
                  <h4>Tidak ada surat</h4>
                </div>
              }
              <Pagination
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prev} next={this.next}
                toPage={this.page}
                disabled={this.state.loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}