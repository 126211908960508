import React, { Component } from 'react';
import spinnerGif from './images/spinner.gif';
import './styles.css';

export default class Spinner extends Component {
  state = {
  }
  render() {
    const { inline, height } = this.props;
    return (
      <div
        className={!inline ? 'spinner-container' : ''}
        style={inline && { display: 'inline-block' }}>
        <img
          src={spinnerGif}
          className={!inline ? 'spinner' : ''}
          alt="spinner"
          height={height ? height : 75}
        />
      </div>
    );
  }
}
