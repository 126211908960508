// Libs
import React, { Component } from 'react';
import swal from 'sweetalert';
import { connect } from "react-redux";
import { loggedIn } from "../../modules/rootReducers.js";

import Spinner from '../../components/Spinner';
import Alert from '../../components/Alert';
import Auth from '../../services/Auth';
import Misc from '../../services/Misc.js';

const AuthService = new Auth();
const MiscService = new Misc();

const dispatcher = dispatch => {
  return {
    loggedIn: payload => dispatch(loggedIn(payload))
  }
}

class ResetPINComponent extends Component {

  state = {
    spinner: false,
    resetResult: '',
    resetErrorMessage: '',
    secretId: '',
    secretKey: '',
    PIN: '',
    repeatPIN: '',
  }

  componentDidMount() {
    this.setState({ spinner: true });
    AuthService.resetPIN(this.props.uuid)
      .then((result) => {
        this.setState({ spinner: false, secretId: result.id, secretKey: result.key });
      })
      .catch((err) => {
        this.setState({ spinner: false });
        swal({
          title: '',
          type: 'warning',
          text: 'Tautan penyetelan ulang PIN ini sudah tidak valid.',
        }, () => {
          let currentUser = JSON.parse(window.localStorage.getItem('current_user'));
          if(currentUser && currentUser.PINLastChanged && ((new Date()).valueOf() - (new Date(currentUser.PINLastChanged)).valueOf()) / 1000 > 7884000){
            window.location = '/user-profile#forceChangePIN?callback=' + window.location.pathname.toString();
          }else{
            window.location = '/user-profile'
          }
        });
      });
  }

  newPIN = () => {
    if (this.state.PIN !== this.state.repeatPIN) {
      swal({
        title: '',
        type: 'warning',
        text: 'Konfirmasi PIN belum sama.',
      });
      return;
    }
    this.setState({ spinner: true });
    AuthService.newPIN(this.state.secretId, this.state.secretKey, this.state.PIN)
      .then((result) => {
        MiscService.getCurrentUser()
        .then((result) => {
          this.setState({ spinner: false });
          swal({
            title: '',
            type: 'warning',
            text: 'PIN Anda telah berhasil disetel ulang.',
          }, () => {
            window.location = '/user-profile'
          });
        })
        .catch((err) => {
          this.setState({spinner: false})
        })
      })
      .catch((err) => {
        this.setState({ spinner: false });
      });
  }

  render() {
    let body = this.state.spinner ? (<Spinner />) :
      (
        <form>
          {this.state.resetResult === 400 && this.state.resetErrorMessage === 'invalid-request' && <Alert message='Tautan pengesetan ulang sandi ini sudah tidak berlaku.' />}
          {this.state.resetResult === 0 && <Alert message='Galat koneksi : gagal menghubungi peladen.' />}
          {this.state.resetResult >= 500 && <Alert message='Galat pada peladen, silakan menghubungi administrator.' />}
          {this.state.secretId && this.state.secretKey && <div>
            <p className="login-box-msg">Silakan setel PIN baru:</p>
            <div className="form-group has-feedback">
              <input
                onChange={(evt) => { this.setState({ PIN: evt.target.value }); }}
                name="PIN"
                type="password"
                maxLength="255"
                autoComplete="off"
                className="form-control"
                placeholder="PIN"
              />
              <span className="fa fa-unlock-alt fa-lg form-control-feedback" aria-hidden="true"></span>
            </div>
            <div className="form-group has-feedback">
              <input
                onChange={(evt) => { this.setState({ repeatPIN: evt.target.value }); }}
                name="Ulangi PIN"
                type="password"
                maxLength="255"
                autoComplete="off"
                className="form-control"
                placeholder="Ulangi PIN"
              />
              <span className="fa fa-unlock-alt fa-lg form-control-feedback" aria-hidden="true"></span>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <button type="button" onClick={this.newPIN} className="btn btn-primary btn-block btn-flat">Setel ulang PIN</button>
              </div>
            </div>
          </div>}
        </form>
      );
    return (
      <div className="login-box">
        <div className="login-box-body">
          {body}
        </div>
      </div>
    )
  }
}

const ResetPIN = ({ match }) => (
  <ResetPINComponent uuid={match.params.uuid} />
)
export default connect(null, dispatcher)(ResetPIN);
