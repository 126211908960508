import React, { Component } from 'react';
import Cropper from 'react-easy-crop';

export default class ImageCroper extends Component {
  state = {
    imageSrc: null,
    crop: { x: 0, y: 0 },
    cropSize: { width: 200, height: 120 },
    aspect: 16 / 10,
    zoom: 1,
    showModal: false,
    error: null,
    file: null,
    pixelCrop: {}
  }

  onCropChange = (crop) => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({pixelCrop: croppedAreaPixels})
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom })
  }

  handleModal = () => {
    this.setState({showModal: !this.state.showModal}, () => {
      if (this.state.showModal) {
        document.getElementById("signature").click()
      } else if (!this.state.showModal){
        this.setState({imageSrc: null})
      }
    })
  }

  onFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0]
    let reader = new FileReader();
    let allowedMimeType = ['image/png','image/jpeg'] 
    if (file && !(allowedMimeType.indexOf(file.type) > -1)) {
      this.setState({error: 'Jenis berkas ini tidak diizinkan.'})
      return;
    }
    reader.addEventListener('load', () => {
      this.setState({imageSrc: reader.result, file: file, error: null})
    });
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  getCropedImage = (pixelCrop, file) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    var image = new Image();
    return new Promise((resolve, reject) => {
      image.onload = function(){ 
        ctx.drawImage(
          image,
          pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height,
          0, 0, pixelCrop.width, pixelCrop.height
        );
        resolve();
      };
      image.src = URL.createObjectURL(file);
    }).then(() =>{
      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          blob.name = file.name;
          blob.lastModifiedDate = new Date();
          resolve(blob);
        }, file.type);
      });  
    });
  }

  handleSave = () => {
    if (this.state.error || !this.state.imageSrc) {
      document.getElementById("signature").click()
      return;
    }
    this.getCropedImage(this.state.pixelCrop, this.state.file)
      .then((img) => { 
        this.setState({showModal: !this.state.showModal}, () => {
          this.props.handleUpload(img)
        })
      })
      .catch((err) => console.log(err))
  }

  render() {
    return (
      <div style={{float: "left"}}>
        <button type="button" className="btn btn-default" onClick={this.handleModal}>Unggah tanda tangan</button>
        <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <input 
                  type="file" 
                  id="signature" 
                  onChange={this.onFileChange}
                  onClick={(e)=> e.currentTarget.value = null}
                  multiple={false} style={{ display: 'none' }}/>
                <p className="text-center text-danger" style={{ fontSize: 14, 
                  display: (this.state.error || !this.state.imageSrc)? 'block': 'none'}}>
                  {(this.state.error) ? this.state.error: !this.state.imageSrc? 'Anda belum memilih berkas': ''}
                </p>
                <div>
                  {this.state.imageSrc && (
                  <div style={{position: 'relative', width: '100%', height: 200, background: '#333'}}>
                    <Cropper
                      image={this.state.imageSrc}
                      crop={this.state.crop}
                      cropSize={this.state.cropSize}
                      zoom={this.state.zoom}
                      minZoom={0.1}
                      aspect={this.state.aspect}
                      onCropChange={this.onCropChange}
                      restrictPosition={false}
                      onCropComplete={this.onCropComplete}
                      onZoomChange={this.onZoomChange}
                    />
                  </div>)}
                </div>
              </div>
              <div className="modal-footer">
                <input 
                  type="range" 
                  value={this.state.zoom}  
                  onChange={(e) => this.onZoomChange(e.target.value)} 
                  className="custom-range" 
                  min="1" 
                  max="3" 
                  step="0.1"
                />
                <button className="btn btn-danger" onClick={this.handleModal}>Batal</button>
                <button className="btn btn-success" 
                  onClick={this.handleSave}>{(this.state.error || !this.state.imageSrc)? 'Pilih berkas': 'Unggah'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}