import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Comment from '../../../../../services/Comment';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import Tab from '../../../../../components/Tab';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import '../styles.css';

const CommentService = new Comment();

export default class DispositionComment extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    searchString: '',
    loading: false,
    currentTab: 'DISPOSITION',
    tabNav: {
      DISPOSITION: {
        name: 'Disposisi',
        default: true,
      },
      OPINION: {
        name: 'Pendapat',
      },
    },
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    this.setState({ loading: true }, () => {
      CommentService.getList({
        page: 1,
        limit: 10,
        search: this.state.searchString,
        isInbox: true,
        disposition: this.state.currentTab === 'DISPOSITION',
        opinion: this.state.currentTab === 'OPINION'
      })
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  page = (page) => {
    this.setState({ loading: true, searchString: '' });
    let opt = {
      page: page,
      limit: this.state.limit,
      search: this.state.searchString,
      isInbox: true,
      disposition: this.state.currentTab === 'DISPOSITION',
      opinion: this.state.currentTab === 'OPINION'
    }
    CommentService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = {
      page: this.state.page + 1,
      limit: this.state.limit,
      isInbox: true,
      search: this.state.searchString,
      disposition: this.state.currentTab === 'DISPOSITION',
      opinion: this.state.currentTab === 'OPINION'
    }
    CommentService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = {
      page: this.state.page - 1,
      limit: this.state.limit,
      search: this.state.searchString,
      isInbox: true,
      disposition: this.state.currentTab === 'DISPOSITION',
      opinion: this.state.currentTab === 'OPINION'
    }
    CommentService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  reload = (options) => {
    options = options || {}
    this.setState({ loading: true });
    let opt = {
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.searchString,
      isInbox: true,
      disposition: this.state.currentTab === 'DISPOSITION',
      opinion: this.state.currentTab === 'OPINION',
      markAllAsRead: options.markAllAsRead
    }
    CommentService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  read = (item) => {
    window.browserHistory.push('/read/' + item.letterId + (this.state.currentTab === 'OPINION' ? (JSON.parse(window.localStorage.getItem('current_user')).id === item.parentOriginator ? '?action=process' : '?action=opinion') : ''));
  }

  switchTab = (tab) => {
    this.setState({ currentTab: tab }, () => {
      this.page();
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Tanggapan</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <Tab nav={this.state.tabNav} handler={this.switchTab} />
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <span className="input-group-btn">
                    <span className="btn btn-default" onClick={() => { this.reload({ markAllAsRead: true }) }}>
                      Tandai semua sebagai sudah dibaca
                    </span>
                  </span>
                  <div style={{ float: 'right' }}>
                    <Pagination
                      disabled={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prev} next={this.next}
                      toPage={this.page} />
                  </div>
                </div>
                <hr />
              </div>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      {this.state.currentTab === 'DISPOSITION' && <th>Nomor Surat</th>}
                      <th>Dari</th>
                      <th>Komentar</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && Object.keys(this.state.data).map((item, index) => {
                      return <tr key={index} onClick={() => { this.read(this.state.data[item]) }} style={{ cursor: 'pointer', fontWeight: this.state.data[item].isUnread ? 'bold' : 'normal' }}>
                        {this.state.currentTab === 'DISPOSITION' && <td>{this.state.data[item].letterReferenceNumber}</td>}
                        <td>{this.state.data[item].originatorName}</td>
                        <td>{this.state.data[item].notes}</td>
                        <td>
                          {date.format(this.state.data[item].timestamp, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>
                        <td>
                          {this.state.data[item].asPelaksana && <Fa.FaUser style={{ marginLeft: 5 }} />}
                          {this.state.data[item].isLetterClosed && <Fa.FaClose style={{ marginLeft: 5 }} />}
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <span style={{ fontSize: 32 }}>
                    <Fa.FaInbox />
                  </span>
                  <br />
                  <h4>Tidak ada tanggapan</h4>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
