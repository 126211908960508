import React, { Component } from 'react';
import swal from 'sweetalert';
import * as Fa from 'react-icons/lib/fa';
import Spinner from '../../../components/Spinner';
import Unit from '../../../services/Unit';
import Auth from '../../../services/Auth';

const UnitService = new Unit();
const AuthService = new Auth();

export class UnitEditComponent extends Component {
  state = {
    name: '',
    loading: false,
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    if (AuthService.getRoles().role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.setState({ loading: true }, () => {
      if (this.props.uuid === 'new') {
        this.setState({ loading: false, role: 'USER' });
        return;
      }
      UnitService.get(this.props.uuid)
        .then((result) => {
          this.setState(result);
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
        });
    });
  }

  back = () => {
    window.browserHistory.push('/settings/units');
  }

  create = () => {
    if (!this.state.name || (this.state.name ** this.state.name.length < 1)) {
      swal({
        title: '',
        type: 'warning',
        text: 'Mohon lengkapi isian yang diwajibkan.',
      });
      return;
    }
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menambah unit ' + this.state.name + ' ?',
    }, (ok) => {
      if (ok) {
        this.setState({ loading: true }, () => {
          let payload = {
            name: this.state.name,
          }
          UnitService.create(payload)
            .then((result) => {
              this.setState({ loading: false });
              swal({
                title: '',
                text: 'Data unit berhasil diperbarui.',
              });
              this.back();
            })
            .catch((err) => {
              if (err && err.response && err.response.status && err.response.status === 409) {
                swal({
                  title: '',
                  type: 'warning',
                  text: 'Gagal mendaftarkan unit baru: unit dengan nama unit tersebut sudah ada.',
                });
              }
              this.setState({ loading: false });
            });
        });
      }
    })
  }

  update = () => {
    this.setState({ loading: true }, () => {
      let payload = {
        name: this.state.name,
      }
      UnitService.update(this.props.uuid, payload)
        .then((result) => {
          this.setState({ loading: false });
          swal({
            title: '',
            text: 'Data unit berhasil diperbarui.',
          });
          this.back();
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    });
  }

  delete = () => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menghapus unit ini?',
    }, (ok) => {
      if (ok) {
        setTimeout(() => {
          this.setState({ loading: true }, () => {
            UnitService.delete(this.props.uuid)
              .then((result) => {
                this.setState({ loading: false });
                swal({
                  title: '',
                  type: 'warning',
                  text: 'Unit berhasil dihapus',
                });
                this.back();
              })
              .catch((err) => {
                this.setState({ loading: false });
                if (err.response && err.response.status === 409) {
                  swal({
                    title: '',
                    type: 'error',
                    text: 'Gagal menghapus unit. Unit masih dipakai',
                  });
                  return;
                }
              });
          });
        }, 500)
      }
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <div>
            <h4>{this.props.uuid === 'new' ? 'Tambah unit baru' : 'Sunting unit'}</h4>
            <div className="box box-success">
              <div className="box-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row" style={{ textAlign: 'center' }}>
                      <div style={{ margin: '0 auto' }}>
                        {this.state.organization && this.state.organization.name && <span><Fa.FaBriefcase /> {this.state.organization && this.state.organization.name}</span>}<br />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Nama unit&nbsp;<span style={{ color: 'red' }}>*</span></label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="name"
                          placeholder="Nama unit"
                          value={this.state.name}
                          onChange={(e) => { this.setState({ name: e.target.value }) }}
                          aria-describedby="nameHelp"
                        />
                        <small id="nameHelp" className="form-text text-muted">Harap diisi</small>
                      </div>
                    </div>
                    {this.state.loading && <Spinner style={{ float: 'right' }} />}
                    {!this.state.loading &&
                      <div style={{ float: 'right' }}>
                        <button className="btn btn-default" onClick={this.back}>Kembali</button>&nbsp;&nbsp;
                        {this.state.id && <span><button className="btn btn-danger" onClick={this.delete}>Hapus</button>&nbsp;&nbsp;</span>}
                        {this.props.uuid === 'new' ?
                          <span><button className="btn btn-primary" onClick={this.create}>Simpan</button>&nbsp;</span>
                          :
                          <span><button className="btn btn-primary" onClick={this.update}>Simpan</button>&nbsp;</span>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const UnitComponent = ({ match }) => (
  <UnitEditComponent uuid={match.params.uuid} />
)

export default UnitComponent;
