import React, { Component } from 'react';
import Inbox from './Inbox.js';

export default class InboxSuratKeterangan extends Component {
  render() {
    return (
      <Inbox type='TNDE_SURAT_KETERANGAN'/>
    );
  }
}
