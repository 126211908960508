export const loggedIn = payload => ({ type: "LOGGED_IN", payload: payload });
export const loggedOut = payload => ({ type: "LOGGED_OUT", payload: payload });
export const showPageTitle = isEnable => ({ type: "SHOW_PAGE_TITLE", payload: isEnable });
export const toggleSidebar = isCollapse => ({ type: "TOGGLE_SIDEBAR", payload: isCollapse });
export const stats = stats => ({ type: "STATS", payload: stats });
export const enableTesterMode = () => ({ type: "ENABLE_TESTER_MODE"});

const initialState = {
  currentUser: {},
  stats: {
    "INBOX" : 0,
    "ALL_UNREADS" : 0,
    "TNDE_NOTA_DINAS": 0,
    "TNDE_SURAT_TUGAS": 0,
    "TNDE_UNDANGAN": 0,
    "TNDE_SURAT": 0,
    "TNDE_MEMO": 0,
    "OA2OA": 0,
    "TNDE_LEMBAR_RALAT": 0,
    "TNDE_LAPORAN": 0,
    "TNDE_TELAAHAN_STAF": 0,
    "TNDE_SURAT_KETERANGAN": 0,
    "TNDE_PENGUMUMAN": 0,
    "DISPOSITION" : 0,
    "COMMENT" : 0,
    // PROCESSING
    "PROCESSING_AS_FINALIZER" : 0,
    "PROCESSING" : 0,
    "FINAL" : 0,
    "NEED_APPROVAL" : 0,
    "NEED_OPINION" : 0,
    "DRAFT" : 0,
    // REKAP
    "INBOX_TOTAL" : 0,
    "DISPOSITION_TOTAL" : 0,
    "DELEGATED_TOTAL" : 0,
  },
  accessToken : '',
  showPageTitle : true,
  sidebarCollapse : window.innerWidth < 800,
  testerMode: false
};
const rootReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'LOGGED_IN':
      var newLoggedInState = Object.assign({}, state);
      localStorage.setItem('access_token', action.payload.accessToken);
      localStorage.setItem('current_user', JSON.stringify(action.payload.currentUser));
      let currentUser = localStorage.getItem('current_user');
      if (currentUser && currentUser.length > 0) {
        try {
            newLoggedInState.currentUser = JSON.parse(currentUser);
        } catch(e) {
            newLoggedInState.currentUser = {};
        }
      }
      newLoggedInState.accessToken = localStorage.getItem('access_token');
      return newLoggedInState;
    case 'LOGGED_OUT':
      let newLoggedOutState = Object.assign({}, state);
      localStorage.removeItem('access_token');
      localStorage.removeItem('current_user');
      newLoggedOutState.currentUser = {};
      newLoggedOutState.accessToken = '';
      return newLoggedOutState;
    case 'SHOW_PAGE_TITLE':
      let newShowPageTitleState = Object.assign({}, state);
      newShowPageTitleState.showPageTitle = action.payload;
      return newShowPageTitleState;
    case 'TOGGLE_SIDEBAR':
      let newToggleSidebarState = Object.assign({}, state);
      newToggleSidebarState.sidebarCollapse = action.payload;
      return newToggleSidebarState;
    case 'STATS':
      let newUnreadsState = Object.assign({}, state);
      newUnreadsState.stats = action.payload;
      return newUnreadsState;
    case 'ENABLE_TESTER_MODE':
      let newEnableState = Object.assign({}, state);
      newEnableState.testerMode = true
      return newEnableState;
    default:
      return state;
  }
}
export default rootReducer
