import React, { Component } from 'react';
import DragList from 'react-drag-list';
import * as Fa from 'react-icons/lib/fa';
import Tab from '../../../../../components/Tab';
import Letter from '../../../../../services/Letter';
import ManualLetterArchive from '../../../../../services/ManualLetterArchive';
import Spinner from '../../../../../components/Spinner';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import stripTags from '../../../../../utils';
import './styles.css';

const LetterService = new Letter();
const ManualLetterArchiveService = new ManualLetterArchive();

export default class LetterReferenceModal extends Component {
  state = {
    showModal: false,
    loading: false,
    data: [],
    page: 1,
    limit: 10,
    allowPrev: false,
    allowNext: false,
    total: 0,
    selected: [],
    filterValue: '',
    currentTab: 'Inbox',
    tabNav: {
      Inbox: {
        name: 'Inbox',
        default: true,
      },
      Manual: {
        name: 'Manual'
      },
    },
  }

  getList = (opt) => {
    if (this.state.currentTab === 'Inbox') {
      return LetterService.getList(opt);
    } else {
      return ManualLetterArchiveService.getList(opt);
    }
  }

  filter = (event) => {
    var val = event.target.value.toLowerCase();
    this.setState({ filterValue: val }, () => {
      this.setState((prev) => {
        if (val && val.length > 0) {
          for (var i in prev.data) {
            if (!prev.data[i].hasChild && prev.data[i].name.toLowerCase().indexOf(val) > -1) {
              prev.data[i].visibility = true;
              prev.data[i].filtered = true;
            } else {
              prev.data[i].visibility = false;
              prev.data[i].filtered = false;
            }
          }
          return { data: prev.data }
        } else {
          for (var j in prev.data) {
            prev.data[j].filtered = false;
            prev.data[j].collapse = true;
            if (prev.data[j].level === 0) {
              prev.data[j].visibility = true;
            } else {
              prev.data[j].visibility = false;
            }
            if (prev.data[j].hasChild) {
              this.collapseAllChild(prev.data, prev.data[j].id);
            }
          }
        }
        return { data: prev.data }
      });
    });
  }

  showModal = () => {
    if (this.props.value && this.props.value[0] && this.props.value[0].id) {
      let selected = [];
      for (var i in this.props.value) {
        selected.push(this.props.value[i]);
      }
      this.setState({ selected: selected });
    }
    this.setState({ showModal: true, loading: true, filterValue: '', box: this.props.box });
    let opt = { page: 1, limit: this.state.limit, box: this.props.box }
    if (this.props.box === 'WAIT_FOR_BUNDLE' && this.props.signers.raw.length > 0) {
      opt.bundleSigners = '';
      for (let i in this.props.signers.raw) {
        if (i > 0) opt.bundleSigners += ',';
        opt.bundleSigners += this.props.signers.raw[i].recipientUuid;
      }
    }
    this.getList(opt)
      .then((result) => {
        for (var j in result.data) {
          if (result.data[j].referenceNumber) result.data[j].number = result.data[j].referenceNumber;
          for (var i in this.state.selected) {
            if (this.state.selected[i].id === result.data[j].id) {
              result.data[j].selected = true;
            }
          }
        }
        this.setState(result);
        this.setState({ loading: false });
        let s = {
          allowPrev: result.page > 1 ? true : false,
          allowNext: (result.page * result.limit) < result.total ? true : false
        }
        this.setState(s);
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  next = () => {
    this.setState({ showModal: true, loading: true, filterValue: '' });
    let opt = { page: this.state.page + 1, limit: this.state.limit, box: this.props.box }
    if (this.props.box === 'WAIT_FOR_BUNDLE' && this.props.signers.raw.length > 0) {
      opt.bundleSigners = '';
      for (let i in this.props.signers.raw) {
        if (i > 0) opt.bundleSigners += ',';
        opt.bundleSigners += this.props.signers.raw[i].recipientUuid;
      }
    }
    this.getList(opt)
      .then((result) => {
        for (var j in result.data) {
          if (result.data[j].referenceNumber) result.data[j].number = result.data[j].referenceNumber;
          for (var i in this.state.selected) {
            if (this.state.selected[i].id === result.data[j].id) {
              result.data[j].selected = true;
            }
          }
        }
        this.setState(result);
        let s = {
          allowPrev: result.page > 1 ? true : false,
          allowNext: (result.page * result.limit) < result.total ? true : false,
          loading: false
        }
        this.setState(s);
      })
      .catch((err) => {
        // handle err
      });
  }

  prev = () => {
    this.setState({ showModal: true, loading: true, filterValue: '' });
    let opt = { page: this.state.page - 1, limit: this.state.limit, box: this.props.box }
    if (this.props.box === 'WAIT_FOR_BUNDLE' && this.props.signers.raw.length > 0) {
      opt.bundleSigners = '';
      for (let i in this.props.signers.raw) {
        if (i > 0) opt.bundleSigners += ',';
        opt.bundleSigners += this.props.signers.raw[i].recipientUuid;
      }
    }
    this.getList(opt)
      .then((result) => {
        for (var j in result.data) {
          if (result.data[j].referenceNumber) result.data[j].number = result.data[j].referenceNumber;
          for (var i in this.state.selected) {
            if (this.state.selected[i].id === result.data[j].id) {
              result.data[j].selected = true;
            }
          }
        }
        this.setState(result);
        let s = {
          allowPrev: result.page > 1 ? true : false,
          allowNext: (result.page * result.limit) < result.total ? true : false,
          loading: false
        }
        this.setState(s);
      })
      .catch((err) => {
        // handle err
      });
  }

  hideModal = () => {
    this.setState({ showModal: false });
  }

  handleClick = (index) => {
    this.setState((prev) => {
      let s = {};
      prev.data[index].selected = !prev.data[index].selected;
      let selected = JSON.parse(JSON.stringify(prev.selected));
      let found = -1
      for (var j in selected) {
        if (selected[j].id === prev.data[index].id) {
          found = j;
        }
      }
      if (found > -1) {
        console.log('found');
        selected.splice(found, 1);
      } else {
        console.log('not found');
        selected.push(prev.data[index]);
      }
      s.selected = selected;
      return s;
    });
  }

  remove = (item, index) => {
    this.setState((prev) => {
      for (var i in prev.data) {
        if (prev.data[i].id === item.id) {
          prev.data[i].selected = false;
        }
      }
      prev.selected.splice(index, 1);
      return { data: prev.data, selected: prev.selected }
    });
  }

  ok = () => {
    let e = {
      target: {
        name: this.props.name,
        value: this.state.selected,
      }
    }
    this.props.onChange(e);
    this.hideModal();
  }

  switchTab = (tab) => {
    this.setState({ currentTab: tab }, () => {
      if (this.props.value && this.props.value[0] && this.props.value[0].id) {
        let selected = [];
        for (var i in this.props.value) {
          selected.push(this.props.value[i]);
        }
        this.setState({ selected: selected });
      }
      this.setState({ showModal: true, loading: true, filterValue: '', box: this.props.box });
      let opt = { page: 1, limit: this.state.limit, box: this.props.box }
      if (this.props.box === 'WAIT_FOR_BUNDLE' && this.props.signers.raw.length > 0) {
        opt.bundleSigners = '';
        for (let i in this.props.signers.raw) {
          if (i > 0) opt.bundleSigners += ',';
          opt.bundleSigners += this.props.signers.raw[i].recipientUuid;
        }
      }
      this.getList(opt)
        .then((result) => {
          for (var j in result.data) {
            if (result.data[j].referenceNumber) result.data[j].number = result.data[j].referenceNumber;
            for (var i in this.state.selected) {
              if (this.state.selected[i].id === result.data[j].id) {
                result.data[j].selected = true;
              }
            }
          }
          this.setState(result);
          this.setState({ loading: false });
          let s = {
            allowPrev: result.page > 1 ? true : false,
            allowNext: (result.page * result.limit) < result.total ? true : false
          }
          this.setState(s);
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    });

  }

  render() {
    const {
      label,
    } = this.props;
    return (
      <div className="form-group row" style={{ margin: 0, marginTop: 10 }}>
        <span
          style={{ fontWeight: 'bold', cursor: 'pointer', marginBottom: 15 }}
          onClick={this.showModal}
        >
          {label} <Fa.FaPencilSquare color="#3c8dbc" />
        </span>
        <div
          className="modal modal-open"
          style={{ display: this.state.showModal ? 'block' : 'none' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {this.props.modalLabel}
                </h4>
                <span className="close" onClick={this.hideModal}><Fa.FaClose /></span>
                <hr />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    {this.props.box !== 'WAIT_FOR_BUNDLE' &&
                      <Tab nav={this.state.tabNav} handler={this.switchTab} />
                    }
                    {this.state.total > 10 &&
                      <div style={{ marginTop: 10 }}>
                        <span style={{ float: 'left' }}>
                          <button
                            type="button"
                            className="btn btn-default letter-ref-page-nav-btn"
                            onClick={this.prev}
                            style={{ display: this.state.loading || !this.state.allowPrev ? 'none' : 'inherit' }}
                          >
                            &lt;
                        </button>
                        </span>
                        <span style={{ float: 'right' }}>
                          <button
                            type="button"
                            className="btn btn-default letter-ref-page-nav-btn"
                            style={{ float: 'right', display: this.state.loading || !this.state.allowNext ? 'none' : 'inherit' }}
                            onClick={this.next}
                          >
                            &gt;
                        </button>
                        </span>
                      </div>
                    }
                    <div style={{ marginTop: 45 }}>Daftar Surat</div>
                    <div className="box box-warning" style={{ marginTop: 10, minHeight: 400 }}>
                      {this.state.loading ?
                        <Spinner />
                        :
                        <ul style={{ padding: 0, height: 400, overflow: 'auto' }}>
                          {this.state.data && Object.keys(this.state.data).map((item, index) => {
                            return <li
                              key={index}
                              style={{
                                padding: 10,
                                paddingLeft: 15,
                                cursor: 'pointer',
                                borderBottom: '1px solid lightgrey',
                              }}
                              onClick={() => { this.handleClick(index) }}
                            >
                              {this.state.data[item].selected ?
                                <Fa.FaCheck style={{ float: 'right', verticalAlign: 'top' }} /> :
                                <Fa.FaCircleO style={{ float: 'right', verticalAlign: 'top' }} />
                              }

                              {this.state.data[item].number && <span>{this.state.data[item].number}</span>}
                              {this.state.data[item].number && <br />}
                              {this.state.data[item].letterDate &&
                              this.state.data[item].letterDate.length > 0 &&
                              this.state.data[item].letterDate !== '0001-01-01T00:00:00Z' &&
                                <span>
                                  {date.format(this.state.data[item].letterDate, 'DD MMMM YYYY', { locale: dateLocaleId })}
                                </span>
                              }
                              {this.state.data[item].number && <br />}
                              {stripTags(this.state.data[item].subject)} <br />
                            </li>
                          })}
                        </ul>
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div style={{ marginTop: 45 }}><span style={{ marginRight: 15 }}>No</span>Surat terpilih</div>
                    <div className="box box-warning" style={{ marginTop: 10 }}>
                      <DragList
                        dataSource={this.state.selected}
                        rowKey="title"
                        row={(record, index) => (
                          <div key={index} style={{ color: record.color }}>
                            <div className="drag-list-item">
                              <span style={{ marginRight: 15 }}>{index + 1}</span>
                              {stripTags(record[this.props.selectedField])}
                              <Fa.FaClose
                                style={{ cursor: 'pointer', float: 'right', fontSize: 14, marginTop: 2 }}
                                color="red"
                                onClick={() => { this.remove(record, index) }}
                              />
                            </div>
                          </div>
                        )}
                        handles={false}
                        className="simple-drag"
                        rowClassName="simple-drag-row"
                        onUpdate={this._handleUpdate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div style={{ float: 'right' }}>
                  <span className="btn btn-primary" style={{ marginRight: 10 }} onClick={this.ok}>OK</span>
                  <span className="btn btn-default" onClick={this.hideModal}>Cancel</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
