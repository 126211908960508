import axios from "./Axios";
import Config from "../config";
import Misc from "./Misc";

const MiscService = new Misc();

export default class Rapat {

    create = (payload) => {
        return new Promise((resolve, reject) => {
            const url = `${Config.apiHost}/api/v1/rapat/create`
            axios.post(url, payload, 
            {
                headers: MiscService.generateHeaders()
            })
            .then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    checkIn = (rapatID) => {
        return new Promise((resolve, reject) => {
            const url = `${Config.apiHost}/api/v1/rapat/check-in/${rapatID}`
            axios.get(url, {
                headers: MiscService.generateHeaders()
            })
            .then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    checkOut = (rapatID) => {
        return new Promise((resolve, reject) => {
            const url = `${Config.apiHost}/api/v1/rapat/check-out/${rapatID}`
            axios.get(url, 
            {
                headers: MiscService.generateHeaders()
            })
            .then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    status = (rapatID) => {
        return new Promise((resolve, reject) => {
            const url = `${Config.apiHost}/api/v1/rapat/status/${rapatID}`
            axios.get(url, 
            {
                headers: MiscService.generateHeaders()
            })
            .then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

}