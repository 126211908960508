import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import User from '../../../services/User';
import Misc from '../../../services/Misc';
import swal from 'sweetalert';

const MiscService = new Misc();
const UserService = new User();

export default class Users extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    loading: false,
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    if (window.store.getState().rootReducers.currentUser.role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.setState({ loading: true }, () => {
      let opt = { page: this.state.page, limit: this.state.limit, search: this.state.searchString }
      UserService.getAll(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
          UserService.getInvalidEmailUsers()
            .then((result) => {
              if (result.data.length > 0) {
                let message = 'Masih ada alamat email yang mengandung karakter yang tidak valid seperti spasi dan baris baru pada pengguna berikut : ';
                for (let i in result.data) {
                  if (i > 0) message += ', ';
                  message += result.data[i].name
                  if (i > 4) break;
                }
                swal({
                  title: 'Periksa kembali alamat email',
                  text: message,
                })
              }
            })
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: page, limit: this.state.limit, search: this.state.searchString }
    UserService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: this.state.page + 1, limit: this.state.limit, search: this.state.searchString }
    UserService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: this.state.page - 1, limit: this.state.limit, search: this.state.searchString }
    UserService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  newUser = () => {
    window.browserHistory.push('/settings/user/new');
  }

  read = (id) => {
    console.log(id);
    window.browserHistory.push('/settings/user/' + id);
  }

  reload = () => {
    this.page()
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  showFileInputDialog = () => {
    document.getElementById("import-users").click()
  }
  handleUsersFileInput = (e) => {
    this.setState({loading:true})
    const files = Array.from(e.target.files)
    let reader = new FileReader();
    reader.addEventListener('load', (e) => {
      let text = e.target.result;
      let userStrings = text.split("\n")
      
      let users = []
      let index = 0

      let invalidCSVRows = []

      userStrings.forEach((userString) => {
        if(userString.length > 0){
          const values = MiscService.CSVtoArray(userString)
          if (values === null){
            invalidCSVRows.push(index+1)
          }else{
            users[index] = {
              username:values[0],
              password:values[1],
              pin:values[2],
              role:values[3],
              name:values[4],
              nip:values[5],
              pangkat:values[6],
              picture:values[7],
              scannedSignature:values[8],
              emailAddress:values[10],
              phoneNumber:values[9]
            }
          }
          index++
        }
      })

      if(invalidCSVRows.length > 0){
        let invalidCSVRowsString = ""
        invalidCSVRows.forEach(row => {
          invalidCSVRowsString += row+","
        });
        setTimeout(()=>swal({
          title: 'CSV Tidak Valid',
          text: 'Silahkan cek baris ' + invalidCSVRowsString,
        }), 100)
        this.setState({ loading:false }) 
        return
      }
      UserService.createMultiple(users)
      .then(res => {
        setTimeout(()=>swal({
          title: '',
          text: 'Data pengguna berhasil di-import.',
        }), 100)
      })
      .catch(err => {
        const {failedUsers} = err.response.data
        let failedUsersString = ""
        failedUsers.forEach(user => {
          failedUsersString += user.name + ", "
        });
        switch(err.response.status){
          case 400:
            swal({ title: '', text: 'Data yang terkirim belum lengkap/benar. Silakan diperiksa kembali data dengan nama : '+ failedUsersString, confirmButtonText: 'OK' });
            break
          case 409:
            swal({ title: '', text: 'Terdapat konflik pada data milik : '+ failedUsersString, confirmButtonText: 'OK' });
            break
        }
      })
      .finally(()=>{ this.setState({ loading:false }) })
    });
    reader.readAsText(files[0]);
  }
  
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div style={{ float: 'right' }}>
            <span className="btn btn-default" onClick={this.newUser}>
              <Fa.FaPlus /> Tambah pengguna baru
            </span>
          </div>
          <div style={{ float: 'right' }}>
            <input type="file" id="import-users" accept=".csv" onChange={this.handleUsersFileInput} style={{display:'none'}}></input>
            <span className="btn btn-default" onClick={this.showFileInputDialog}>
              <Fa.FaPlus /> Impor pengguna dari file CSV
            </span>
          </div>
          <h4>Manajemen Pengguna</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <div className="input-group input-group-sm">
                    <span className="input-group-btn">
                      <span className="btn btn-success">
                        <Fa.FaRefresh />
                      </span>
                    </span>
                    <input
                      className="form-control" type="text"
                      onChange={this.handleSearchString}
                      onKeyPress={(e) => { if (e.key === 'Enter') this.search() }}
                      value={this.state.searchString}
                    />
                    {this.state.searchString && this.state.searchString.length > 0 &&
                      <span className="input-group-btn">
                        <span className="btn btn-default" onClick={this.dismissSearch}>
                          <Fa.FaClose />
                        </span>
                      </span>
                    }
                    <span className="input-group-btn">
                      <span className="btn btn-default" onClick={this.search}>
                        <Fa.FaSearch />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div style={{ float: 'right' }}>
                    <Pagination
                      disabled={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prev} next={this.next}
                      toPage={this.page} />
                  </div>
                </div>
                <hr />
              </div>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Nama pengguna</th>
                      <th>Role</th>
                      <th>Nama lengkap</th>
                      <th>NIP</th>
                      <th>Pangkat</th>
                      <th>Jabatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(this.state.data).map((item, index) => {
                      return <tr key={index} onClick={() => { this.read(this.state.data[item].id) }} style={{ cursor: 'pointer' }}>
                        <td>{this.state.data[item].username}</td>
                        <td>{this.state.data[item].role}</td>
                        <td>{this.state.data[item].name}</td>
                        <td>{this.state.data[item].nip}</td>
                        <td>{this.state.data[item].pangkat}</td>
                        <td>{this.state.data[item].organization && this.state.data[item].organization.name}</td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <br />
                  <h4>Tidak ada data</h4>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
