import React, { Component } from 'react';
import Avatar from 'react-avatar-edit';
import swal from 'sweetalert';
import Blob from '../../../services/Blob';
import User from '../../../services/User';

const BlobService = new Blob();
const UserService = new User();

const src = '';

export default class UserProfileChangeAvatar extends Component {
  state = {
    loading: false,
    preview: null,
    src
  }

  onClose = () => {
    this.setState({ preview: null })
  }

  onCrop = (preview) => {
    this.setState({ preview })
  }

  apply = () => {
    console.log(this.state.preview);
    BlobService.uploadDataURI(this.state.preview)
      .then((blobUuid) => {
        //return MiscService.setAvatar(blobUuid)
    		let currentUser = JSON.parse(JSON.stringify(this.props.currentUser));
        currentUser.picture = blobUuid;
        currentUser.excludeValidate = ['PhoneNumber', 'EmailAddress'];
    		return UserService.update(currentUser.id, currentUser)
      })
      .then(() => {
        this.props.apply(this.state.preview, () => {
          this.props.back();
        });
      })
      .catch((err) => {
        console.log(err);
        if (err && err.toString().indexOf('not allowed') > -1) {
          swal({
            title: '',
            type: 'warning',
            text: 'Jenis berkas ini tidak diizinkan untuk diunggah.'
          })
        }
      });
  }

  cancel = () => {
    this.props.back();
  }

  render() {
    return (
      <div className="box box-success">
        <div className="box-body">
          <div className="row">
            <div className="col-md-12" style={{ marginBottom: 15 }}>
              <div>
                <Avatar
                  width={390}
                  height={295}
                  onCrop={this.onCrop}
                  onClose={this.onClose}
                  src={this.state.src}
                />
              </div>
              <div>
                Preview<br />
                <img src={this.state.preview} alt="" />
              </div>
            </div>
            <div className="col-md-12">
              <button className="btn btn-primary" onClick={this.apply}>Ubah</button>&nbsp;
              <button className="btn btn-default" onClick={this.cancel}>Batal</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
