import axios from "axios";
import Logger from './Logger';

let LoggerService = new Logger()
const requestLoggerInterceptor = (request) => {
  if (!request.hasOwnProperty('disableLogger') || !request.disableLogger) {
    request.metadata = {started : performance.now()}
  }
  return request
}

const responseLoggerInterceptor = (response) => {
  if (response.hasOwnProperty('disableLogger') || !response.config.disableLogger) {
    response.config.metadata.ended = performance.now()
    response.duration = Math.ceil(response.config.metadata.ended - response.config.metadata.started)
    LoggerService.info(response.config.method.toUpperCase(), response.config.url, response.duration)
  }
  return response
}
const errorLoggerInterceptor = (error) => {
  if (error.hasOwnProperty('disableLogger') || !error.config.disableLogger) {
    error.config.metadata.ended = performance.now()
    error.duration = Math.ceil(error.config.metadata.ended - error.config.metadata.started)
    if (!(error.message.includes("Network Error")||error.message.includes("ERR_CONNECTION_REFUSED")))
      LoggerService.error(error.config.method.toUpperCase(), error.config.url, error.duration)
  }
  return Promise.reject({ ...error })
}
axios.interceptors.request.use(requestLoggerInterceptor)
axios.interceptors.response.use(responseLoggerInterceptor, errorLoggerInterceptor)

export default axios