import PropTypes from 'prop-types';
import React, { Component } from 'react'
import * as Fa from 'react-icons/lib/fa';


class Mic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mic: true
    }
  }

  componentWillReceiveProps(nextProps) {
    const { videoRoomLocal } = nextProps
    const { mic } = this.state
    if (nextProps.stream !== this.props.stream) {
      if (mic) {
        videoRoomLocal.unmuteAudio()
      } else {
        videoRoomLocal.muteAudio()
      }
    }
  }

  toggle() {
    let { mic } = this.state
    const { videoRoomLocal } = this.props
    mic = !mic
    if (mic) {
      videoRoomLocal.unmuteAudio()
    } else {
      videoRoomLocal.muteAudio()
    }
    this.setState({
      mic
    })
  }

  render() {
    return (
      <button className="mic" onClick={this.toggle.bind(this)}>
        { this.state.mic ? (
          <Fa.FaMicrophone/>        ) : (
            <Fa.FaMicrophoneSlash/>
        ) }
      </button>
    )
  }
}

Mic.propTypes = {
  videoRoomLocal: PropTypes.object,
  stream: PropTypes.object
}

export default Mic