import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Janus} from 'react-janus';
import {connect} from 'react-redux';

import selector from './RemoteSelector';

//Components
//import RemoteUserVolume from '../components/RemoteUserVolume'

class RemoteUser extends Component {
  shouldComponentUpdate(nextProps) {
    const {feed, streamFeed} = nextProps;
    return streamFeed.user.id === feed.user.id;
  }

  componentDidUpdate() {
    const {feed} = this.props;
    if (feed && feed.stream) {
      const {stream} = feed;
      const video = ReactDOM.findDOMNode(this).querySelector('video');
      Janus.attachMediaStream(video, stream);
    }
  }

  toggleVolume(volume) {
    const video = ReactDOM.findDOMNode(this).querySelector('video');
    video.volume = volume ? 1 : 0;
  }

  render() {
    const {feed, size} = this.props;
    const style = 'pointer remote-user max' + size;

    return (
      <div
        id={feed.user.id}
        className={style}
        onClick={() => {
          this.props.setMainFeed(feed);
        }}>
        <span>{feed.user.name}</span>
        <div className="video-wrapper">
          <video autoPlay="true"></video>
        </div>
      </div>
    );
  }
}

RemoteUser.propTypes = {
  feed: PropTypes.object.isRequired,
  streamFeed: PropTypes.object,
};

export default connect(selector)(RemoteUser);
