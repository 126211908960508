import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';

import Chat from '../../../../services/Chat';
import Spinner from '../../../../components/Spinner';
import { toggleMode, visualModeEnum } from './ChatCommon';
import ChatMessage from './ChatMessage';

const ChatService = new Chat();


function messageSort(a, b) {
  const dA = Date.parse(a['timestamp']);
  const dB = Date.parse(b['timestamp']);
  return dA - dB;
}
export default class ChatConversation extends Component {
  state = {
    id: '',
    title: '',
    loading: true,
    visualMode: visualModeEnum.EXPANDED,
    right: {},
    typedText: '',
    canSend: false,
    messages: []
  }

  componentDidMount = () => {

    const id = this.props.value.id;
    this.setState({
      id: id,
      title: this.props.value.name,
      right: { 'right': (((this.props.value.idx + 1) * 302) + 22) + 'px' }
    });

    this.props.chatMaps[id] = this;
    this.loadMessages();
  }


  loadMessages = () => {
    return new Promise((resolve) => {
      let chatID = localStorage.getItem("CHATID-" + this.props.value.id);
      if (chatID && chatID.length > 0) {
        ChatService.getMessages(chatID).then((result) => {
          let messages = [];
          if (result.messages) {
            messages = result.messages.sort(messageSort);
          }
          this.setState({
            messages,
            loading: false
          });
          this.scrollToBottom();
          resolve(messages);
        })
          .catch((err) => {
            console.log(err);
            this.setState({
              loading: false
            });
          })
      } else {
        ChatService.getChatID(this.props.value.id).then((result) => {
          chatID = result['chat-id'];
          localStorage.setItem("CHAT-ID" + this.props.value.id, chatID);
          ChatService.getMessages(chatID).then((result) => {
            var messages = [];
            if (result.messages) {
              messages = result.messages.sort(messageSort);
            }
            this.setState({
              messages: messages,
              loading: false
            });
            this.scrollToBottom();
            resolve(messages);
          })
            .catch((err) => {
              console.log(err);
              this.setState({
                loading: false
              });
            })
        })
      }
    })
  }

  onWindowClick = () => {
    this.setState({ visualMode: toggleMode(this.state.visualMode) });
  }

  onCloseButtonClick = () => {
    if (typeof this.props.onChildExit === 'function') {
      this.props.onChildExit(this.props.value.idx);
    }
  }

  onTextTyped = (event) => {
    let text = event.target.value;
    let canSend = text.trim().length > 0;
    this.setState({
      canSend,
      typedText: event.target.value
    });
  }

  onSend = () => {
    if (this.state.canSend) {
      const payload = {
        recipient: this.state.id,
        message: this.state.typedText,
      };
      this.setState({
        typedText: '',
        canSend: false
      });
      ChatService.postMessages(payload).then((result) => {
        this.loadMessages();
      });
    }
  }

  scrollToBottom = () => {
    this._messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  render = () => {
    return (
      <div className={`chat-conversation-window ${this.state.visualMode}`} style={this.state.right}>
        <div onClick={this.onWindowClick.bind(this)} className="chat-title-box">
          {this.props.value.name}
          <div className="close-button" style={{ cursor: 'pointer' }} onClick={this.onCloseButtonClick.bind(this)}><Fa.FaClose style={{ verticalAlign: 'top' }} /></div>
        </div>

        <div className="chat-message-list-entries">
          {this.state.loading === true && (<div className="data-loading"><Spinner /></div>)}
          {this.state.messages && this.state.messages.map((message, index) => {
            return (
              <ChatMessage key={index} service={ChatService} value={message} />
            )
          })}
          <div style={{ float: "left", clear: "both" }}
            ref={(el) => { this._messagesEnd = el; }}>
          </div>
        </div>
        {this.state.visualMode === visualModeEnum.EXPANDED && (
          <div className="chat-text-box">
            <textarea onChange={this.onTextTyped.bind(this)} type="text"
              value={this.state.typedText} placeholder="Ketikkan pesan..."></textarea>
            {this.state.canSend && (
              <button onClick={this.onSend.bind(this)}>Kirim</button>
            )}
          </div>
        )}
      </div>
    )
  }
}
