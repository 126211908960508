import React, { Component } from 'react';
import Inbox from './Inbox.js';

export default class InboxMemo extends Component {
  render() {
    return (
      <Inbox type='TNDE_MEMO'/>
    );
  }
}
