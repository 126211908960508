import React, { Component } from 'react';
import Spinner from '../../../components/Spinner';
import '../styles.css';
import Auth from '../../../services/Auth';
import Settings from '../../../services/Settings';
import swal from 'sweetalert';
import async from 'async';
import AddressBookPersonal from './AddressBookPersonalModal';

const SettingsService = new Settings();
const AuthService = new Auth();

export default class ExternalLetterRecipientSetting extends Component {
  state = {
    loading: false,
    satkerName: '',
    satkerCode: '',
    letterYear: (new Date()).getFullYear(),
    letterReferenceNumberCount: 0,
    letterHeader: '',
    organizationLogo: '',
    useNIPOnLetter: 'TRUE',
    externalLetterRecipient: ''
  }

  componentDidMount = () => {
    if (AuthService.getRoles().role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.load();
  }

  inputEventHandler = (event) => {
    let val = (event.target.getContent && event.target.getContent()) || event.target.value;
    let obj = {};
    obj[event.target.name] = val;
    this.setState(obj);
  }

  load = () => {
    this.setState({ loading: true }, () => {
      SettingsService.getSettings()
        .then((settings) => {
          let settingsKeys = Object.keys(settings);
          this.setState(settings, () => {
            this.setState({ loading: false }, () => {
              this.setState({ settingsKeys: settingsKeys });
              // Simulate inputEventHandler for letterHeader
              let e = { target: { name: 'externalLetterRecipient', value: this.state.externalLetterRecipient } }
              this.inputEventHandler(e);
            });
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    });
  }

  save = () => {
    this.setState({ loading: true }, () => {
      async.each(this.state.settingsKeys, (key, cb) => {
        console.log(key);
        let obj = {}
        obj[key] = this.state[key];
        SettingsService.update(obj)
          .then(() => {
            cb();
          })
          .catch((err) => {
            cb(err)
          });
      }, (err) => {
        this.setState({ loading: false });
        if (!err) {
          swal({
            title: '',
            text: 'Setelan berhasil tersimpan.'
          })
        }
        this.load();
      });
    });
  }

  handleSelectedPersonForForm = (event) => {
    
    if (event.target.value &&
      event.target.value.length > 0 &&
      event.target.raw
    ) {
      let obj = {}
      
      obj[event.target.name] = event.target.raw[event.target.name];
      obj[event.target.name + 'Name'] = event.target.value;
      obj['externalLetterRecipient'] = event.target.orgId+","+event.target.value;
      console.log(obj);
      
      this.setState(obj);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <h4>Setelan Penerima Surat Luar</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ margin: 15 }}>
                <div className="col-md-12">
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-4 col-sm-4">Pengguna</label>
                    <div className="col-md-8" >
                      <AddressBookPersonal
                          style={{ float: 'right' }}
                          single={true}
                          titleless={false}
                          item={this.state}
                          name={'holder'}
                          label={'Pilih Pengguna'}
                          onChange={this.handleSelectedPersonForForm}
                        />
                        
                    </div>
                  </div>
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-4 col-sm-4">Penerima Surat Luar Oleh: </label>
                    <div className="col-md-8" >
                      <h5>{this.state.externalLetterRecipient.split(',')[1] && <span>&nbsp;&nbsp;{this.state.externalLetterRecipient.split(',')[1]}</span>}</h5>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label style={{ textAlign: 'right' }} className="col-md-4 col-sm-4">ID Organisasi: </label>
                    <div className="col-md-8" >
                      <p>{this.state.externalLetterRecipient.split(',')[0] && <span>&nbsp;&nbsp;{this.state.externalLetterRecipient.split(',')[0]}</span>}</p>
                    </div>
                  </div>
                 
                  {this.state.loading ?
                    <Spinner /> :
                    <div className="form-group row" style={{marginLeft: '70px'}}>
                      <div className="col-md-2" >
                      </div>
                      <div className="col-md-9" >
                        <button className="btn btn-primary" onClick={this.save}>Simpan setelan</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
