import React from "react";
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';

export const getTimeFromTimeStamp = (timestamp, skipAlert) => {
  const time = date.format(new Date(timestamp).getTime(), 'HH:mm') || '-';
  if (!skipAlert) {
    if (Date.parse(`1/1/2020 ${time}`) >= Date.parse('1/1/2020 08:00') &&
      Date.parse(`1/1/2020 ${time}`) <= Date.parse('1/1/2020 08:30')) {
      return (
        <div>
          {time} <span className="late-alert-1">TL 1</span>
        </div>
      )
    } else if (Date.parse(`1/1/2020 ${time}`) >= Date.parse('1/1/2020 08:30') &&
      Date.parse(`1/1/2020 ${time}`) <= Date.parse('1/1/2020 09:00')) {
      return (
        <div>
          {time} <span className="late-alert-2">TL 2</span>
        </div>
      )
    }
  }
  return time
}

export const getDateFromTimeStamp = (timestamp) => {
  return date.format(new Date(timestamp), 'ddd, DD MMMM YYYY', { locale: dateLocaleId })
}

export const renderReports = (reports) => {
  if (!reports || reports.length < 1) {
    return <li>-</li>
  }
  return reports.map((item, index) => {
    const itemSplitted = item.split('|')
    const id = itemSplitted[0]
    const number = itemSplitted[1]
    const subject = itemSplitted[2]

    return (
      <li key={index}>
        <a target="_blank" href={`/read/${id}`}>
          {number.length > 0 ? `${number} | ${subject}` : subject}
        </a>
      </li>
    );
  });
}

export const renderStatus = (now, today, current) => {
  return (
    <table>
      <tbody>
        <tr>
          <td className="presence">Jam Masuk</td>
          <td /><td /><td />
          <td>
            <ul>
              <li style={{ listStyleType: 'circle' }}>
                {now ?
                  today && today.loginAt !== undefined ? getTimeFromTimeStamp(today.loginAt, true) : '-' :
                  current && current.loginAt !== undefined ? getTimeFromTimeStamp(current.loginAt) : '-'
                }
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className="presence">
            {now ? 'Jam Keluar Terahir' : 'Jam Pulang'}
          </td>
          <td /><td /><td />
          <td>
            <ul>
              <li style={{ listStyleType: 'circle' }}>
                {now ?
                  today && today.logoutAt !== undefined ? getTimeFromTimeStamp(today.logoutAt, true) : '-' :
                  current && current.logoutAt !== undefined ? getTimeFromTimeStamp(current.logoutAt) : '-'
                }
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className="presence">
            {now ? 'Laporan/Memo Hari Ini' : 'Laporan/Memo'}
          </td>
          <td /><td /><td />
          <td>
            {now && today && today.laporan !== undefined && <ul>{renderReports(today.laporan)}</ul>}
            {!now && current && current.laporan !== undefined && <ul>{renderReports(current.laporan)}</ul>}
          </td>
        </tr>
      </tbody>
    </table>
  )
}