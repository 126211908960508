import axios from "./Axios";
import Config from "../config";
import Misc from "./Misc";
import Error from "./Error";
const MiscService = new Misc();
const ErrorService = new Error();

export default class Chat {
    getCurrentUser = () => {
        if (this._currentUserId) {
            return this._currentUserId
        }
        const dataRaw = localStorage.getItem('current_user')
        try {
            const dataJson = JSON.parse(dataRaw)
            this._currentUserId = dataJson.id
            return this._currentUserId
        } catch (e) {
            // ignore
        }
        return '';
    }

    postMessages = (opt) => {
        const payload = {
            recipient: opt.recipient,
            'chat-type': "PEER-TO-PEER",
            'reception-state': "SENT",
            'message-contents': opt.message,
        }
        return new Promise((resolve, reject) => {

            const url = `${Config.apiHost}/api/v1/chat/message`
            axios.post(url, payload, {
                headers: MiscService.generateHeaders()
            }).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    checkIn = () => {
        return new Promise((resolve, reject) => {
            const url = `${Config.apiHost}/api/v1/chat/check-in`
            axios.get(url, {
                headers: MiscService.generateHeaders()
            }).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    getOnlineUsers = () => (
        new Promise((resolve, reject) => {
            axios.get(`${Config.apiHost}/api/v1/chat/status/online`,{
                headers: MiscService.generateHeaders()
            })
            .then(res => {resolve(res.data)})
            .catch(err => {
                ErrorService.handle(err)
                reject(err)
            })
        })
    )

    getUnreadMessages = () => (
        new Promise((resolve, reject) => {
            axios.get(`${Config.apiHost}/api/v1/chat/messages`,{
                headers: MiscService.generateHeaders()
            })
            .then(res => {resolve(res.data)})
            .catch(err => {
                ErrorService.handle(err)
                reject(err)
            })
        })
    )
    getChatID = (peer) => {
        return new Promise((resolve, reject) => {
            const url = `${Config.apiHost}/api/v1/chat/id/` + peer
            axios.get(url, {
                headers: MiscService.generateHeaders()
            }).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    getMessages = (peer) => {
        return new Promise((resolve, reject) => {
            const url = `${Config.apiHost}/api/v1/chat/messages/` + peer
            if (typeof this._sourceGetMessages !== typeof undefined) {
                this._sourceGetMessages.cancel('New req')
            }
            this._sourceGetMessages = axios.CancelToken.source();
            axios.get(url, {
                cancelToken: this._sourceGetMessages.token,
                headers: MiscService.generateHeaders()
            }).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err);
                    return;
                }
                reject(err)
            })
        })
    }

    listPeople = (searchString) => {
        return new Promise((resolve, reject) => {
            const url = `${Config.apiHost}/api/v1/chat/people` + searchString
            if (typeof this._sourceListPeople !== typeof undefined) {
                this._sourceListPeople.cancel('New req')
            }
            this._sourceListPeople = axios.CancelToken.source();

            axios.get(url, {
                cancelToken: this._sourceListPeople.token,
                headers: MiscService.generateHeaders()
            }).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err);
                    return;
                }
                reject(err)
            })
        })
    }
}

