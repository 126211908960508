import React, { Component } from 'react';
import Settings from '../../../services/Settings';

const SettingsService = new Settings()
export default class MonitorWhatsapp extends Component {

  state = {
    phone: "",
    status: false
  }

  async getSetting() {
    await SettingsService.getWhatsAppInfo().then((result) => {
      this.setState({
        status: result.isWhatsappSessionActive,
        phone: result.phoneNumber != "" ? result.phoneNumber : result.phoneNumber
      })

    }).catch((err) => console.log("Error => ", err))
  }
  componentDidMount() {
    window.store.dispatch(window.actions.showPageTitle(true));
    if (window.store.getState().rootReducers.currentUser.role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    
    this.getSetting()
    this.intervalID = setInterval(() => this.getSetting(), 2000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { phone, status } = this.state
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Monitor Whatsapp</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 10 }}>
              <div style={{ float: 'right', margin: 15 }}>
              </div>
              <div className="col-md-8" style={{ margin: '0 auto' }}>
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr >
                      <th style={{ borderColor: '#00a65a' }}>Phone Number</th>
                      <th style={{ borderColor: '#00a65a' }}>{phone != undefined && phone.length > 1 ? phone : '-'}</th>
                    </tr>
                    <tr>
                      <th style={{ borderColor: '#00a65a' }}>Status</th>
                      <th style={{ borderColor: '#00a65a' }}>{status ? "Active" : "Deactive"}</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
