import axios from "./Axios";
import Config from "../config";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();


class LetterRefCounter {
  get = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/letter-ref-counters/" + id,
      {
        headers: MiscService.generateHeaders()
      }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  upsert = (payload, id) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter-ref-counters/" + id,
      payload,
      {headers: MiscService.generateHeaders()})
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }
}

export default LetterRefCounter;