import React, { Component } from 'react';
import Outbox from './Outbox.js';

export default class OutboxBatal extends Component {
  render() {
    return (
      <Outbox type='canceled'/>
    );
  }
}
