import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as Fa from 'react-icons/lib/fa';
import swal from 'sweetalert';
import Config from '../../../config';
import Auth from '../../..//services/Auth';
import Letter from '../../..//services/Letter';
import User from '../../..//services/User';
import downloadjs from 'downloadjs';

const AuthService = new Auth();
const LetterService = new Letter();
const UserService = new User();

const mapper = state => {
  return {
    currentUser: state.rootReducers.currentUser,
    sidebarCollapse: state.rootReducers.sidebarCollapse,
    stats: state.rootReducers.stats,
    location: state.router.location,
  };
};

const dispatcher = dispatch => {
  return {
    toggleSidebar: isCollapse =>
      dispatch(window.actions.toggleSidebar(isCollapse)),
    letterStats: stats => dispatch(window.actions.letterStats(stats)),
  };
};

const composeKemenkeu =  {
  path: '/compose',
  name: 'Buat Surat',
  childs: {
    naskahDinasKorespondensi: {
      path: '/naskahDinasKorespondensi',
      name: 'Naskah Dinas Korespondensi',
      childsOfChild: {
        notaDinas: {
          path: '/compose/TNDE_NOTA_DINAS',
          name: 'Nota Dinas',
        },
        suratDinas: {
          path: '/compose/TNDE_SURAT',
          name: 'Surat Dinas',
        },
        suratUndangan: {
          path: '/compose/TNDE_UNDANGAN',
          name: 'Surat Undangan',
        },
      },
    },
    naskahDinasArahan: {
      path: '/naskahDinasArahan',
      name: 'Naskah Dinas Arahan',
      childsOfChild: {
        suratTugas: {
          path: '/compose/TNDE_SURAT_TUGAS',
          name: 'Surat Tugas',
        },
      },
    },
    naskahDinasKhusus: {
      path: '/naskahDinasKhusus',
      name: 'Naskah Dinas Khusus',
      childsOfChild: {
        suratKeterangan: {
          path: '/compose/TNDE_SURAT_KETERANGAN',
          name: 'Surat Keterangan'
        },
        laporan: {
          path: '/compose/TNDE_LAPORAN',
          name: 'Laporan'
        },
        pengumuman: {
          path: '/compose/TNDE_PENGUMUMAN',
          name: 'Pengumuman'
        },
        lembarRalat: {
          path: '/compose/TNDE_LEMBAR_RALAT',
          name: 'Lembar Ralat',
        },
        telaahanStaf: {
          path: '/compose/TNDE_TELAAHAN_STAF',
          name: 'Telaahan Staf'
        }
      }
    },
    memo: {
      path: '/compose/TNDE_MEMO',
      name: 'Memo',
    },
    tndeTemplate: {
      path: '/settings/letter-template',
      name: 'Templat Naskah TNDE',
    },
  }
}

const inboxKemenkeu = {
  path: '/inbox',
  name: 'Kotak Masuk',
  unread: 'ALL_UNREADS',
  childs: {
    all: {
      path: '/inbox/all',
      name: 'Semua',
      unread: 'INBOX',
    },
    naskahDinasKorespondensi: {
      path: '/inbox/naskahDinasKorespondensi',
      name: 'Naskah Dinas Korespondensi',
      unread: 'NASKAH_DINAS_KORESPONDENSI',
      childsOfChild: {
        notaDinas: {
          path: '/inbox/nota-dinas',
          name: 'Nota Dinas',
          unread: 'TNDE_NOTA_DINAS',
        },
        surat: {
          path: '/inbox/surat',
          name: 'Surat Dinas',
          unread: 'TNDE_SURAT',
        },
        suratUndangan: {
          path: '/inbox/surat-undangan',
          name: 'Surat Undangan',
          unread: 'TNDE_UNDANGAN',
        },
      },
    },
    naskahDinasArahan: {
      path: '/inbox/naskahDinasArahan',
      name: 'Naskah Dinas Arahan',
      unread: 'NASKAH_DINAS_ARAHAN',
      childsOfChild: {
        suratTugas: {
          path: '/inbox/surat-tugas',
          name: 'Surat Tugas',
          unread: 'TNDE_SURAT_TUGAS',
        },
      },
    },
    naskahDinasKhusus: {
      path: '/inbox/naskahDinasKhusus',
      name: 'Naskah Dinas Khusus',
      unread: 'NASKAH_DINAS_KHUSUS',
      childsOfChild: {
        suratKeterangan: {
          path: '/inbox/surat-keterangan',
          name: 'Surat Keterangan',
          unread: 'TNDE_SURAT_KETERANGAN',
        },
        laporan: {
          path: '/inbox/laporan',
          name: 'Laporan',
          unread: 'TNDE_LAPORAN',
        },
        pengumuman: {
          path: '/inbox/pengumuman',
          name: 'Pengumuman',
          unread: 'TNDE_PENGUMUMAN'
        },
        lembarRalat: {
          path: '/inbox/lembar-ralat',
          name: 'Lembar Ralat',
          unread: 'TNDE_LEMBAR_RALAT'
        },
        telaahanStaf: {
          path: '/inbox/telaahan-staf',
          name: 'Telaahan Staf',
          unread: 'TNDE_TELAAHAN_STAF'
        }
      }
    },
    memo: {
      path: '/inbox/memo',
      name: 'Memo',
      unread: 'TNDE_MEMO',
    },
    oa2oa: {
      path: '/inbox/oa2oa',
      name: 'Surat OA2OA',
      unread: 'OA2OA',
    },
    separator: {
      path: 'separator',
    },
    disposition: {
      path: '/inbox/disposition',
      name: 'Disposisi',
      unread: 'DISPOSITION',
    },
    tanggapan: {
      path: '/inbox/tanggapan',
      name: 'Tanggapan',
      unread: 'COMMENT',
    },
  },
} 

const outBoxKemenkeu = {
        path: '/outbox',
        name: 'Kotak Keluar',
        unread: 'CANCELED',
        childs: {
          all: {
            path: '/outbox/all',
            name: 'Semua',
          },
          naskahDinasKorespondensi: {
            path: '/outbox/naskahDinasKorespondensi',
            name: 'Naskah Dinas Korespondensi',
            childsOfChild: {
              notaDinas: {
                path: '/outbox/nota-dinas',
                name: 'Nota Dinas',
              },
              surat: {
                path: '/outbox/surat',
                name: 'Surat Dinas',
              },
              suratUndangan: {
                path: '/outbox/surat-undangan',
                name: 'Surat Undangan',
              },
            },
          },
          naskahDinasArahan: {
            path: '/outbox/naskahDinasArahan',
            name: 'Naskah Dinas Arahan',
            childsOfChild: {
              suratTugas: {
                path: '/outbox/surat-tugas',
                name: 'Surat Tugas',
              },
            },
          },
          naskahDinasKhusus: {
            path: '/outbox/naskahDinasKhusus',
            name: 'Naskah Dinas Khusus',
            childsOfChild: {
              suratKeterangan: {
                path: '/outbox/surat-keterangan',
                name: 'Surat Keterangan',
              },
              laporan: {
                path: '/outbox/laporan',
                name: 'Laporan',
              },
              pengumuman: {
                path: '/outbox/pengumuman',
                name: 'Pengumuman'
              },
              lembarRalat: {
                path: '/outbox/lembar-ralat',
                name: 'Lembar Ralat',
              },
              telaahanStaf: {
                path: '/outbox/telaahan-staf',
                name: 'Telaahan Staf',
              }
            }
          },
          memo: {
            path: '/outbox/memo',
            name: 'Memo',
          },
          separator: {
            path: 'separator',
          },
          disposition: {
            path: '/outbox/disposition',
            name: 'Disposisi',
          },
          manual: {
            path: '/outbox/manual',
            name: 'Surat Manual',
          },
          canceled: {
            path: '/outbox/canceled',
            name: 'Batal',
            unread: 'CANCELED',
          },
        },
}

class Sidebar extends Component {
  state = {
    isAdminLTELoaded: false,
    isLoggedIn: AuthService.isLoggedIn(),
    currentPage: {
      path: '/dashboard',
      name: 'Dashboard',
    },
    firstLoad: true,
    navigation: {
      dashboard: {
        path: '/dashboard',
        name: 'Dashboard',
      },
      userProfile: {
        path: '/userProfile',
        name: 'Profile User',
      },
      compose:(Config.variant.name === 'ppkblu' || 
      Config.variant.name === 'lman' || 
      Config.variant.name === 'pip' || 
      Config.variant.name === 'lpdp' ) ? composeKemenkeu : {
        path: '/compose',
        name: 'Buat Surat',
        childs: {
          notaDinas: {
            path: '/compose/TNDE_NOTA_DINAS',
            name: 'Nota Dinas',
          },
          suratTugas: {
            path: '/compose/TNDE_SURAT_TUGAS',
            name: 'Surat Tugas',
          },
          suratUndangan: {
            path: '/compose/TNDE_UNDANGAN',
            name: 'Surat Undangan',
          },
          surat: {
            path: '/compose/TNDE_SURAT',
            name: 'Surat Dinas',
          },
          memo: {
            path: '/compose/TNDE_MEMO',
            name: 'Memo',
          },
          tndeTemplate: {
            path: '/settings/letter-template',
            name: 'Templat Naskah TNDE',
          },
        }
      },
      inbox: (Config.variant.name === 'ppkblu' || 
      Config.variant.name === 'lman' || 
      Config.variant.name === 'pip' || 
      Config.variant.name === 'lpdp' ) ? inboxKemenkeu : {
        path: '/inbox',
        name: 'Kotak Masuk',
        unread: 'ALL_UNREADS',
        childs: {
          all: {
            path: '/inbox/all',
            name: 'Semua',
            unread: 'INBOX',
          },
          notaDinas: {
            path: '/inbox/nota-dinas',
            name: 'Nota Dinas',
            unread: 'TNDE_NOTA_DINAS',
          },
          suratTugas: {
            path: '/inbox/surat-tugas',
            name: 'Surat Tugas',
            unread: 'TNDE_SURAT_TUGAS',
          },
          suratUndangan: {
            path: '/inbox/surat-undangan',
            name: 'Surat Undangan',
            unread: 'TNDE_UNDANGAN',
          },
          surat: {
            path: '/inbox/surat',
            name: 'Surat Dinas',
            unread: 'TNDE_SURAT',
          },
          memo: {
            path: '/inbox/memo',
            name: 'Memo',
            unread: 'TNDE_MEMO',
          },
          oa2oa: {
            path: '/inbox/oa2oa',
            name: 'Surat OA2OA',
            unread: 'OA2OA',
          },
          separator: {
            path: 'separator',
          },
          disposition: {
            path: '/inbox/disposition',
            name: 'Disposisi',
            unread: 'DISPOSITION',
          },
          tanggapan: {
            path: '/inbox/tanggapan',
            name: 'Tanggapan',
            unread: 'COMMENT',
          },
        }
      },
      outbox: (Config.variant.name === 'ppkblu' || 
        Config.variant.name === 'lman' || 
        Config.variant.name === 'pip' || 
        Config.variant.name === 'lpdp' ) ? outBoxKemenkeu : {
        path: '/outbox',
        name: 'Kotak Keluar',
        unread: 'CANCELED',
        childs: {
          all: {
            path: '/outbox/all',
            name: 'Semua',
          },
          notaDinas: {
            path: '/outbox/nota-dinas',
            name: 'Nota Dinas',
          },
          suratTugas: {
            path: '/outbox/surat-tugas',
            name: 'Surat Tugas',
          },
          suratUndangan: {
            path: '/outbox/surat-undangan',
            name: 'Surat Undangan',
          },
          surat: {
            path: '/outbox/surat',
            name: 'Surat Dinas',
          },
          memo: {
            path: '/outbox/memo',
            name: 'Memo',
          },
          separator: {
            path: 'separator',
          },
          disposition: {
            path: '/outbox/disposition',
            name: 'Disposisi',
          },
          manual: {
            path: '/outbox/manual',
            name: 'Surat Manual',
          },
          canceled: {
            path: '/outbox/canceled',
            name: 'Batal',
            unread: 'CANCELED',
          },
        },
      },
      processing: {
        path: '/processing',
        name: 'Kotak Proses',
        unread: 'PROCESSING',
        childs: {
          finalization: {
            path: '/processing/finalization',
            name: 'Perlu Finalisasi',
            unread: 'FINAL',
          },
          finalized: {
            path: '/processing/finalized',
            name: 'Sudah Difinalisasi',
          },
          needApproval: {
            path: '/processing/need-approval',
            name: 'Perlu Persetujuan',
            unread: 'NEED_APPROVAL',
          },
          needOpinion: {
            path: '/processing/need-opinion',
            name: 'Perlu Pendapat',
            unread: 'NEED_OPINION',
          },
          status: {
            path: '/processing/status',
            name: 'Status',
            unread: 'RETURNED',
          },
          /*
          requester: {
            path: '/processing/requester',
            name: 'Pemesan',
          },
					*/
          draft: {
            path: '/processing/draft',
            name: 'Draf',
            unread: 'DRAFT',
          },
        },
      },
      archive: {
        path: '/archive',
        name: 'Kotak Arsip',
        childs: {
          manual: {
            path: '/archive/manual-letter-archive',
            name: 'Surat Manual Lama',
          },
          in: {
            path: '/archive/external',
            name: 'Arsip Eksternal',
          },
          out: {
            path: '/archive/internal',
            name: 'Arsip Internal',
          },
          disposition: {
            path: '/archive/disposition',
            name: 'Arsip Disposisi',
          },
        },
      },
      presence: {
        path: '/presence',
        name: 'Monitor Presensi',
      },
      tools: {
        path: '/tools',
        name: 'Perkakas',
        childs: {
          userGuideOASystem: {
            path: '/docs/user_guide_office_automation.pdf',
            newTab: true,
            name: 'Panduan Penggunaan OA',
          },
        },
      },
      settings: {
        path: '/settings',
        name: 'Pengaturan',
        childs: {
          general: {
            path: '/settings/satker',
            name: 'Satuan Kerja',
          },
          externalRecipient: {
            path: '/settings/external_letter_recipient',
            name: 'Penerima Surat Luar',
          },
          users: {
            path: '/settings/users',
            name: 'Manajemen Pengguna',
          },
          userGroups: {
            path: '/settings/user-groups',
            name: 'Grup Pengguna',
          },
          orgs: {
            path: '/settings/organizations',
            name: 'Struktur Organisasi',
          },
          units: {
            path: '/settings/units',
            name: 'Unit',
          },
          dipositionAction: {
            path: '/settings/disposition-actions',
            name: 'Setelan Aksi Disposisi',
          },
          classification: {
            path: '/settings/classification',
            name: 'Klasifikasi Surat',
          },
          letterHeader: {
            path: '/settings/letter-header',
            name: 'Templat Kop Surat',
          },
          monitorPresence: {
            path: '/settings/monitor-presence',
            name: 'Monitor Presensi',
          },
          monitorLog: {
            path: '/settings/monitor',
            name: 'Monitor Log',
          },
          monitorWhatsapp: {
            path: '/settings/monitor-wa',
            name: 'Monitor Whatsapp',
          },
        },
      },
      help: {
        path: '/helpdesk',
        name: 'Bantuan',
      },
    },
  };

  constructor() {
    super()
    this.adminLTELoaderInterval = setInterval(()=>{
      if (window.loadAdminLTE && !this.state.isAdminLTELoaded) {
        window.loadAdminLTE();
        clearInterval(this.state.adminLTELoaderInterval)
        this.setState({isAdminLTELoaded:true})
      }
    },100)
  }

  componentWillUnmount = () => {
    clearInterval(this.adminLTELoaderInterval)
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.sidebarCollapse) {
      document.body.classList.toggle(
        'sidebar-collapse',
        nextProps.sidebarCollapse,
      );
      document.body.classList.remove('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-collapse');
      document.body.classList.toggle(
        'sidebar-open',
        !nextProps.sidebarCollapse,
      );
    }
  };
  componentDidMount = () => {
    LetterService.getStats();
  };
  showSidebar = () => {
    window.store.dispatch(window.actions.toggleSidebar(false));;
  };
  hideSidebar = () => {
    if (this.props.sidebarCollapse) {
      document.body.classList.toggle('sidebar-open', false);
    }
  };
  logout = () => {
    swal({
      title: '',
      type: 'warning',
      text: 'Anda yakin ingin keluar dari sistem?',
      showCancelButton: true,
    }, (ok) => {
      if (ok) {
        setTimeout(() => {
          if(!window.store.getState().router.location.pathname.includes('/helpdesk/panel'))
            AuthService.logout()
          else window.location = "/login"
        }, 500);
      }
    })
  }
  navigateTo = (page) => {
    // Expand
    this.setState({currentPage: page});
    if (!page.childs) {
      window.browserHistory.push(page.path);
    }
  };

  logoutImpersonate = () => {
    swal(
      {
        title: '',
        type: 'warning',
        text: 'Anda yakin ingin keluar dari penyamaran ?',
        showCancelButton: true,
      },
      ok => {
        if (ok) {
          setTimeout(() => {
            UserService.logoutImpersonate()
              .then(result => {
                this.setState({loading: false});
                window.store.dispatch(
                  window.actions.loggedIn({
                    accessToken: localStorage.getItem(
                      'impersonator_origin_token',
                    ),
                    currentUser: JSON.parse(
                      localStorage.getItem('impersonator_user'),
                    ),
                  }),
                );
                localStorage.removeItem('impersonator_origin_token');
                localStorage.removeItem('impersonator_token_id');
                localStorage.removeItem('impersonate_id');
                localStorage.removeItem('impersonator_user');
                window.browserHistory.push('/');
              })
              .catch(err => {
                this.setState({loading: false});
              });
          }, 500);
        }
      },
    );
  };

  downloadAttachment = res => {
    downloadjs(res);
  };

  render() {
    if( window.store.getState().router.location.pathname.includes('/helpdesk/panel') && this.state.navigation.compose){
      delete this.state.navigation.compose
      delete this.state.navigation.inbox
      delete this.state.navigation.outbox
      delete this.state.navigation.archive
      delete this.state.navigation.processing
      delete this.state.navigation.settings
      delete this.state.navigation.help
    }
    if (!AuthService.getToken() && !window.store.getState().router.location.pathname.includes('/helpdesk/panel')) {
      return <div />;
    }

    var windowHeight = window.innerHeight
    return (
      <div>
        {this.props.location.pathname !== '/login' &&
          this.props.location.pathname.indexOf('reset-password') < 0 && (
            <aside className="main-sidebar" 
              style={{ width: ( windowHeight > 900 || window.innerHeight < 900 && !this.props.sidebarCollapse ) && 270}}>
              <section
                className="sidebar"
                //onMouseOver={this.showSidebar} onMouseLeave={this.hideSidebar}
              >
              {!window.store.getState().router.location.pathname.includes('/helpdesk/panel') &&
                <Link to="/user-profile">
                    <div className="user-panel" style={{cursor: 'pointer'}}>
                      <div
                        className="pull-left image"
                        style={{width: 40, display: 'inline-block'}}>
                        <img
                          src={
                            this.props.currentUser.picture? 
                            `${Config.apiHost}/api/v1/blob/unrestricted/${this.props.currentUser.picture}`
                            :
                            '/images/default-avatar.png'
                          }
                          onError={e => {
                            e.target.src = '/images/default-avatar.png';
                          }}
                          alt=""
                          style={{
                            background: 'grey',
                            width: this.props.sidebarCollapse ? 30 : 40,
                            height: this.props.sidebarCollapse ? 30 : 40,
                            borderRadius: 50,
                          }}
                        />
                      </div>
                      <div
                        className="pull-left info"
                        style={{display: 'inline-block', width: 175}}>
                        <p className="ellipsis">{this.props.currentUser.name}</p>
                        <p className="ellipsis">{this.props.currentUser.title}</p>
                        <p>{this.props.currentUser.username}</p>
                      </div>
                    </div>
                  </Link>
                }
                {!(this.props.sidebarCollapse) && this.props.currentUser.isAssignedAsDelegation && 
                  <div className="delegate-mode">Mode Plh/Delegasi</div>
                }
                {!(this.props.sidebarCollapse) && this.props.currentUser.organization && this.props.currentUser.organization.delegated.length > 0 && 
                  <div className="delegate-mode" disabled>Jabatan Anda sedang didelegasikan (Plh)</div>
                }
                <div className="sidebar-menu-container">
                  <ul
                    id="sidebar-menu"
                    className="sidebar-menu"
                    data-widget="tree">
                    {this.props.currentUser &&
                      AuthService.getRoles().role === 'FRONTOFFICE' && (
                        <li>
                          <Link
                            to="/compose/MANUAL"
                            className="a-nohref"
                            style={{cursor: 'pointer'}}>
                            <Fa.FaEdit
                              style={{fontSize: 16, lineHeight: '12px'}}
                              onClick={this.showSidebar}
                            />
                            &nbsp; Buat Surat Masuk Manual
                          </Link>
                        </li>
                      )}
                    {Object.keys(this.state.navigation).map((item, index) => {
                      if (item === 'userProfile' || item === 'dashboard') {
                        return '';
                      }

                      if (
                        item === 'settings' && AuthService.getRoles().role !== 'ADMIN'
                      ) {
                        return '';
                      }
                      if (Config.variant.name !== 'ppkblu' &&
                        (item === 'presence' || item === 'monitorPresence')) {
                          return '';
                      }
                      const currentNavItem = this.state.navigation[item]
                      return (
                        <li
                          key={index}
                          id={'top-menu-' + index}
                          className={`${this.state.currentPage.path.indexOf(
                            item,
                          ) > -1 && 'active'}`}>
                          <a
                            className="a-nohref"
                            onClick={() => {
                              this.navigateTo(currentNavItem);
                              Object.keys(this.state.navigation).map((k, i) => {
                                let el = document.getElementById(
                                  'top-menu-' + i,
                                );
                                if (el) {
                                  if (i === index) {
                                    el.className =
                                      el.className === 'false'
                                        ? 'active'
                                        : 'false';
                                  } else {
                                    el.className = 'false';
                                  }
                                }
                                return '';
                              });
                            }}>
                            {/* Icons */}
                            {currentNavItem.path ===
                              '/compose' && (
                              <Fa.FaPencilSquare
                                style={{fontSize: 16, lineHeight: '12px'}}
                                onClick={this.showSidebar}
                              />
                            )}
                            {currentNavItem.path === '/inbox' && (
                              <Fa.FaInbox
                                style={{fontSize: 16, lineHeight: '12px'}}
                                onClick={this.showSidebar}
                              />
                            )}
                            {currentNavItem.path === '/outbox' && (
                              <Fa.FaShareSquareO
                                style={{fontSize: 16, lineHeight: '12px'}}
                                onClick={this.showSidebar}
                              />
                            )}
                            {currentNavItem.path ===
                              '/processing' && (
                              <Fa.FaHourglass
                                style={{fontSize: 16, lineHeight: '12px'}}
                                onClick={this.showSidebar}
                              />
                            )}
                            {currentNavItem.path ===
                              '/archive' && (
                              <Fa.FaArchive
                                style={{fontSize: 16, lineHeight: '12px'}}
                                onClick={this.showSidebar}
                              />
                            )}
                            {currentNavItem.path === '/presence' && (
                              <Fa.FaClipboard
                                style={{ fontSize: 16, lineHeight: '12px' }}
                                onClick={this.showSidebar}
                              />
                            )}
                            {currentNavItem.path === '/tools' && (
                              <Fa.FaWrench
                                style={{fontSize: 16, lineHeight: '12px'}}
                                onClick={this.showSidebar}
                              />
                            )}
                            {currentNavItem.path ===
                              '/settings' && (
                              <Fa.FaCog
                                style={{fontSize: 16, lineHeight: '12px'}}
                                onClick={this.showSidebar}
                              />
                            )}
                            {currentNavItem.path ===
                              '/helpdesk' && (
                              <Fa.FaQuestion
                                style={{fontSize: 16, lineHeight: '12px'}}
                                onClick={this.showSidebar}
                              />
                            )}

                            <span>
                              &nbsp; {currentNavItem.name}
                            </span>
                            <span className="unread-count">
                              {currentNavItem.unread &&
                                this.props.stats &&
                                this.props.stats[
                                  currentNavItem.unread
                                ] !== 0 &&
                                this.props.stats[
                                  currentNavItem.unread
                                ]}
                            </span>
                          </a>
                          {currentNavItem.childs ? (
                            <ul className="treeview-menu">
                              {Object.keys(
                                currentNavItem.childs,
                              ).map((child, childIndex) => {

                                if (
                                  child === 'oa2oa' && this.props.currentUser &&
                                  !this.props.currentUser.isExternalLetterRecipient
                                ) {
                                  return '';
                                }
          
                                if (
                                  child === 'externalRecipient' && !Config.gerbang
                                ) {
                                  return '';
                                }
                                const currNavItemChild = currentNavItem.childs[child]
                                if (
                                  currNavItemChild
                                    .path === '/processing/finalization' &&
                                  this.props.currentUser &&
                                  AuthService.getRoles().role !== 'FINALIZER'
                                ) {
                                  return '';
                                } else if (
                                  currNavItemChild.path === '/archive/internal' &&
                                  this.props.currentUser &&
                                  AuthService.getRoles().role !== 'ADMIN' &&
                                  this.props.currentUser &&
                                  AuthService.getRoles().orgRole !== 'PUKS'
                                ) {
                                  return '';
                                } else if (
                                  currNavItemChild.path === '/archive/external' &&
                                  this.props.currentUser &&
                                  AuthService.getRoles().role !== 'ADMIN' &&
                                  this.props.currentUser &&
                                  AuthService.getRoles().orgRole !== 'PUKS'
                                ) {
                                  return '';
                                } else if (
                                  currNavItemChild.path === '/archive/disposition' &&
                                  this.props.currentUser &&
                                  AuthService.getRoles().role !== 'ADMIN' &&
                                  this.props.currentUser &&
                                  AuthService.getRoles().orgRole !== 'PUKS'
                                ) {
                                  return '';
                                } else if (
                                  currNavItemChild.path === '/processing/finalized' &&
                                  this.props.currentUser &&
                                  AuthService.getRoles().role !== 'FINALIZER'
                                ) {
                                  return '';
                                } else if (
                                  currNavItemChild.path === '/outbox/manual' &&
                                  this.props.currentUser &&
                                  AuthService.getRoles().role !== 'FRONTOFFICE'
                                ) {
                                  return '';
                                } else if (
                                  currNavItemChild.path === 'separator'
                                ) {
                                  return (
                                    <hr key={childIndex} style={{margin: 0}} />
                                  );
                                } else {
                                  return (
                                    <li
                                      key={childIndex}
                                      className={`treeview ${!currNavItemChild.childsOfChild &&
                                        this.props.location.pathname === currNavItemChild.path &&
                                        'active'}`}>
                                      <a
                                        onClick={() => {
                                          currNavItemChild.newTab
                                            ? this.downloadAttachment(
                                                currNavItemChild.path,
                                              )
                                            : !currNavItemChild.childsOfChild &&
                                              window.browserHistory.push(
                                                currNavItemChild.path,
                                              );
                                        }}>
                                        {
                                          currNavItemChild.name
                                        }
                                        <span
                                          className={
                                            child === 'needApproval'
                                              ? 'important-count'
                                              : child === 'draft'
                                              ? 'item-count'
                                              : 'unread-count'
                                          }>
                                          {currNavItemChild.unread &&
                                            this.props.stats &&
                                            this.props.stats[
                                              currNavItemChild.unread
                                            ] !== 0 &&
                                            this.props.stats[
                                              currNavItemChild.unread
                                            ]}
                                        </span>
                                        <br />
                                      </a>
                                      {currNavItemChild
                                        .childsOfChild ? (
                                        <ul className="treeview-menu">
                                          {Object.keys(
                                            currNavItemChild.childsOfChild,
                                          ).map((childItem, indexItem) => {
                                            const childOfNavItemChild = currNavItemChild.childsOfChild[childItem]
                                            return (
                                              <li 
                                                key={indexItem}
                                                className={`${this.props.location.pathname ===
                                                  childOfNavItemChild.path && 'active'}`}
                                              >
                                                <a
                                                  onClick={() => {
                                                    window.browserHistory.push(
                                                      childOfNavItemChild.path,
                                                    );
                                                  }}>
                                                  {
                                                    childOfNavItemChild.name
                                                  }
                                                  <span
                                                    className={
                                                      child === 'needApproval'
                                                        ? 'important-count'
                                                        : child === 'draft'
                                                        ? 'item-count'
                                                        : 'unread-count'
                                                    }>
                                                    {childOfNavItemChild.unread &&
                                                      this.props.stats &&
                                                      this.props.stats[
                                                        childOfNavItemChild.unread
                                                      ] !== 0 &&
                                                      this.props.stats[
                                                        childOfNavItemChild.unread
                                                      ]}
                                                  </span>
                                                </a>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      ) : (
                                        ''
                                      )}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          ) : (
                            ''
                          )}
                        </li>
                      );
                    })}
                    {!localStorage.getItem('impersonator_token_id') ? (
                      <li>
                        <a
                          className="no-a-href"
                          style={{cursor: 'pointer'}}
                          onClick={this.logout}>
                          <i className="fa fa-circle-o text-aqua"></i>{' '}
                          <span>Keluar</span>
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a
                          className="no-a-href"
                          style={{cursor: 'pointer'}}
                          onClick={this.logoutImpersonate}>
                          <i className="fa fa-circle-o text-aqua"></i>{' '}
                          <span>Hentikan Penyamaran</span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </section>
            </aside>
          )}
      </div>
    );
  }
}

export default connect(
  mapper,
  dispatcher,
)(Sidebar);
