import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import DateTimePicker from '../../DateTimePicker';

export default class Delegation extends Component {
  state = {
    delegator : 'Direktur Demo', // Current title
    delegationRecipient : '',
    // startDate
    // endDate
    reason : '',
  }

  onApply = () => {
    let obj = JSON.parse(JSON.stringify(this.state));
    this.setState({delegator : obj.delegator, delegationRecipient :'', reason : '', startDate : '', endDate : ''});
    this.props.onApply(obj);

  }

  render() {
    return (
      <div className="box box-success">
        <div className="box-body" style={{padding:15}}>
          <div className="form-group row">
            <label className="control-label col-md-2">Jabatan</label>
            <div className="col-md-7">
              {this.state.delegator}
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-2">Pejabat Pengganti</label>
            <div className="col-md-7">
              <input 
                className="form-control"
                value={this.state.delegationRecipient} 
                onChange={(e) => {this.setState({delegationRecipient : e.target.value})}}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-2">Periode</label>
            <div className="col-md-3">
              <DateTimePicker
                value={this.state.startDate}
                onChange={(e) => {this.setState({startDate : e.target.value})}}
                fieldWidth={12}
              />
            </div>
            <div className="col-md-1" style={{textAlign:'center'}}>
            s/d
            </div>
            <div className="col-md-3">
              <DateTimePicker
                value={this.state.endDate}
                onChange={(e) => {this.setState({endDate : e.target.value})}}
                fieldWidth={12}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-2">Alasan</label>
            <div className="col-md-7">
              <textarea 
                className="form-control" 
                value={this.state.reason} 
                onChange={(e) => {this.setState({reason : e.target.value})}}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
            </div>
            <div className="col-md-7">
              <button className="btn btn-primary" onClick={this.onApply}>
                <Fa.FaPowerOff/> Aktifkan
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
