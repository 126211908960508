import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Spinner from '../../../components/Spinner';
import DateTimePicker from './DateTimePicker';
import Auth from '../../../services/Auth';
import Org from '../../../services/Org';
import Settings from '../../../services/Settings';
import LetterHeader from '../../../services/LetterHeader'
import Letter from '../../../services/Letter'
import Unit from '../../../services/Unit'
import AddressBookPersonal from './AddressBookPersonalModal';
import Switch from 'react-switch';
import swal from 'sweetalert';
import LetterCounterRef from './LetterRefCounterModal';

const LetterHeaderService = new LetterHeader();
const LetterService = new Letter();
const UnitService = new Unit();

const AuthService = new Auth();
const OrgService = new Org();
const SettingsService = new Settings();

export default class Orgs extends Component {
  state = {
    userData: [],
    data: [],
    loading: false,
    mode: 'structure',
    selectedPerson: {
      value: '',
      raw: '',
    },
    letterHeaders: [],
    letterHeaderID: '',


    name: 'Staf',
    direktorat: '',
    kodeUnit: '',
    kodeJabatan: '',
    city: '',
    location: '',
    signerName: '',

    satkerName: '',
    letterCounterDisabled: false,
    archiverUnit: false,
    unitId: null,
    leaderDashboardEnabled: false,

    units: [],
  }

  componentDidMount = () => {
    if (AuthService.getRoles().role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.fetchLetterHeaders()

    this.setState({ loading: true }, () => {
      SettingsService.getSettings()
        .then((settings) => {
          let settingsKeys = Object.keys(settings);
          this.setState(settings, () => {
            this.setState({ loading: false }, () => {
              this.setState({ settingsKeys: settingsKeys });
              // Simulate inputEventHandler for letterHeader
              let e = { target: { name: 'letterHeader', value: this.state.letterHeader } }
              this.inputEventHandler(e);
            });
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
      this.load();
    });
  }

  load = () => {
    this.setState({
      loading: true,
    }, () => {
      OrgService.getAll()
        .then((result) => {
          let data = result.data;
          let sorted = [];

          function findChildsRecursive(id, level, arr) {
            let childs = [];
            for (var i in arr) {
              if (arr[i].parent === id) {
                arr[i].level = level + 1;
                arr[i].visibility = true;
                arr[i].hasChild = false;
                childs.push(arr[i]);
                let c = findChildsRecursive(arr[i].id, level + 1, arr);
                for (var j in c) {
                  arr[i].hasChild = true;
                  childs.push(c[j]);
                }
              }
            }
            return childs;
          }
          // Root
          for (var i in data) {
            if (!data[i].parent) {
              data[i].level = 0;
              data[i].visibility = true;
              data[i].collapse = false;
              data[i].hasChild = true;
              sorted.push(data[i]);
              break;
            }
          }
          // The entire childs, sorted
          let childs = findChildsRecursive(sorted[0].id, sorted[0].level, data);
          if (childs.length > 0) {
            for (var j in childs) {
              sorted.push(childs[j]);
            }
          }
          this.setState({ data: sorted, loading: false });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  delete = (item) => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menghapus jabatan ' + item.name + '?',
    }, (ok) => {
      if (ok) {
        OrgService.delete(item.id)
          .then((result) => {
            this.setState({ loading: false });
            this.load();
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    });
  }

  collapseAllChild = (data, parentId) => {
    for (var i in data) {
      if (data[i].parent === parentId) {
        data[i].collapse = true;
        data[i].visibility = false;
        if (data[i].hasChild) {
          this.collapseAllChild(data, data[i].id);
        }
      }
    }
  }

  handleClick = (index) => {
    if (!this.state.data[index].hasChild) {
      return;
    }
    this.setState((prev) => {
      let s = {};
      prev.data[index].current = true;
      if (index === 0 && !prev.data[index].collapse) {
        s = prev.data;
        return s;
      }
      for (var i in prev.data) {
        if (prev.data[i].parent === prev.data[index].id) {
          prev.data[i].visibility = prev.data[index].collapse;
          if (prev.data[i].hasChild) prev.data[i].collapse = true;
          if (!prev.data[index].collapse) {
            this.collapseAllChild(prev.data, prev.data[index].id);
          }
        }
      }
      prev.data[index].collapse = !prev.data[index].collapse;
      s.data = prev.data
      return s;
    });
  }

  handleSelectedPersonForForm = (event) => {
    if (event.target.value &&
      event.target.value.length > 0 &&
      event.target.raw
    ) {
      let obj = {}
      obj[event.target.name] = event.target.raw[event.target.name];
      obj[event.target.name + 'Name'] = event.target.value;
      this.setState(obj);
    }
  }

  handleSelectedPerson = (event) => {
    if (event.target.value &&
      event.target.value.length > 0 &&
      event.target.raw
    ) {
      this.setState({ selectedPerson: { value: event.target.value, raw: event.target.raw } });
      this.update(event.target.raw.id, event.target.raw);
    }
  }

  inputEventHandler = (event) => {
    let val = (event.target.getContent && event.target.getContent()) || event.target.value;
    let obj = {};
    obj[event.target.name] = val;
    this.setState(obj);
  }

  newItem = (parent) => {
    if (parent.parent === '' && this.state.data.length > 1) {
      swal({
        title: '',
        type: 'warning',
        text: 'Hanya diperbolehkan satu pimpinan tertinggi dalam organisasi.',
      });
      return;
    }
    this.setState({
      mode: 'new',
      currentParent: parent || null,
      parent: null,
      role: 'PUK',
      selectedPerson: {},
      name: (parent && parent.id) ? this.getNewStaffName(parent.id) : 'Staf',
      hasChild: false,
      holder: '',
      holderName: '',
      direktorat: '',
      kodeUnit: '',
      kodeJabatan: '',
      city: '',
      signerName: '',
      assistant: '',
      assistantName: '',
      assistantState: 'DEACTIVE',
      delegated: '',
      delegatedName: '',
      delegationState: 'DEACTIVE',
      delegationStartDate: null,
      delegationEndDate: null,
      plt: '',
      pltName: '',
      pltState: 'DEACTIVE',
      location: '',
      currentId: null,
      jabatanIsActive: false,
    });
  }

  editItem = (item) => {
    let keys = Object.keys(item);
    if (item.delegationStartDate === '0001-01-01T00:00:00Z') {
      item.delegationStartDate = null;
    }
    if (item.delegationEndDate === '0001-01-01T00:00:00Z') {
      item.delegationEndDate = null;
    }
    item.currentParent = {}
    if (item.kodeJabatan === "") {
      item.letterCounterDisabled = true;
    }
    this.setState({
      role: 'PUK',
      selectedPerson: {},
      hasChild: false,
      holder: '',
      holderName: '',
      direktorat: '',
      kodeUnit: '',
      kodeJabatan: '',
      city: '',
      signerName: '',
      assistant: '',
      assistantName: '',
      assistantState: 'DEACTIVE',
      delegated: '',
      delegatedName: '',
      delegationState: 'DEACTIVE',
      delegationStartDate: null,
      delegationEndDate: null,
      plt: '',
      pltName: '',
      pltState: 'DEACTIVE',
      location: '',
      currentId: null,
      jabatanIsActive: (item.holder !== '00000000-0000-0000-0000-000000000000') ? true : false,
      leaderDasboardEnabled: false,
      archiverUnit: false,
    }, () => {
      LetterService.getLetterHeaderForPUA(item.id).then((result) => {
        this.setState(item, () => {
          for (let i in this.state.data) {
            if (this.state.data[i].id === item.parent) {
              this.setState({ currentParent: this.state.data[i] });
            }
          }
          var letterHeaderID = ""
          if (result && result.data && result.data.letterHeaderID) {
            letterHeaderID = result.data.letterHeaderID
          }
          this.setState({
            mode: 'edit',
            fields: keys,
            letterHeaderID: letterHeaderID
          });
        })
      })

      let opt = { page: 1, limit: 999, search: null }
      UnitService.getAll(opt)
        .then((result) => {
          let units = result.data ? result.data:[]
          this.setState({ units })
        })
    });
  }
  back = (parent) => {
    this.setState({ mode: 'structure', form: {} });
  }

  create = () => {
    if ((!this.state.name || (this.state.name && this.state.name.length < 1)) || (!this.state.holder || (this.state.holder && this.state.holder.length < 1))) {
      swal({
        title: '',
        type: 'warning',
        text: 'Mohon dilengkapi isian yang diwajibkan.',
      });
      return
    }
    const payload = {
      parent: (this.state.currentParent && this.state.currentParent.id) ? this.state.currentParent.id : null,
      name: this.state.name,
      role: this.state.role,
      direktorat: this.state.direktorat,
      kodeUnit: this.state.kodeUnit,
      kodeJabatan: this.state.kodeJabatan,
      city: this.state.city,
      location: this.state.location,
      holder: this.state.holder,
      signerName: this.state.signerName,
      letterCounterDisabled: this.state.letterCounterDisabled,
      archiverUnit: this.state.archiverUnit,
      unitId: this.state.unitId,
      leaderDashboardEnabled: this.state.leaderDashboardEnabled
    }
    if (payload.archiverUnit && !payload.kodeUnit) {
      swal({
        title: '',type: 'warning',
        text: 'Kode unit tidak boleh kosong saat unit penyimpan berkas aktif.',
      });
      return
    }
    this.setState({ loading: true }, () => {
      OrgService.create(payload)
        .then((result) => {
          this.setState({ loading: false, mode: 'structure' });
          this.load();
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.response.data.message && err.response.data.message === "Unit file saved exist") {
            swal({title: '',type: 'warning',text: 'Unit penyimpan berkas sudah ada.' });
            this.setState({ archiverUnit: false });
            return
          }
        })
    });
  }

  jabatanSwitched = (checked) => {
    this.setState({ jabatanIsActive: checked });
    if (!checked) {
      this.setState({ holder: '00000000-0000-0000-0000-000000000000', holderName: '', jabatanIsActive: checked });
    } else {
      this.setState({ pltName: '', plt: '', pltState: 'DEACTIVE' });
    }
  }
  assistantSwitched = (checked) => {
    this.setState({ assistantState: checked ? 'ACTIVE' : 'DEACTIVE' });
    if (!checked) {
      this.setState({ assistantName: '', assistant: '' });
    }
  }
  delegationSwitched = (checked) => {
    this.setState({ delegationState: checked ? 'ACTIVE' : 'DEACTIVE' });
    if (!checked) {
      this.setState({ delegatedName: '', delegated: '' });
    }
  }
  pltSwitched = (checked) => {
    this.setState({ pltState: checked ? 'ACTIVE' : 'DEACTIVE' });
    if (!checked) {
      this.setState({ pltName: '', plt: '' });
    } else {
      this.setState({ holder: '00000000-0000-0000-0000-000000000000', holderName: '', jabatanIsActive: false });
    }
  }
  letterCounterDisabledSwitched = (checked) => {
    this.setState({ letterCounterDisabled: checked ? false : true });
  }

  archiverUnitSwitched = (checked) => {
    this.setState({ archiverUnit: checked });
  }

  fetchLetterHeaders = () => {
    LetterHeaderService.getAll({ limit: 999 })
      .then((result) => {
        let letterHeaders = result.data ? result.data : []
        this.setState({
          letterHeaders,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          letterHeaders: [],
          loading: false,
        });
      });
  }

  update = (id, payload) => {
    if (!id && !payload) {
      payload = {}
      id = this.state.id;
      for (var i in this.state.fields) {
        if (this.state.fields[i] === 'visibility' || this.state.fields[i] === 'level' || this.state.fields[i] === 'hasChild') {
          continue;
        }
        payload[this.state.fields[i]] = this.state[this.state.fields[i]];
      }
    }
    if (payload.delegationState === 'ACTIVE' && (!payload.delegationStartDate || !payload.delegationEndDate || !payload.delegated || (payload.delegated && payload.delegated.length === 0))) {
      swal({
        title: '',
        type: 'warning',
        text: 'Personil delegasi dan rentang waktu delegasi wajib ditetapkan.',
      });
      return;
    }
    if (this.state.jabatanIsActive && payload.holder === '00000000-0000-0000-0000-000000000000') {
      swal({
        title: '',
        type: 'warning',
        text: 'Tetapkan pemegang jabatan terlebih dahulu.',
      });
      return;
    }
    if (this.state.assistantState === 'ACTIVE' && (!payload.assistant || (payload.assistant && payload.assistant.length === 0))) {
      swal({
        title: '',
        type: 'warning',
        text: 'Tetapkan asisten terlebih dahulu.',
      });
      return;
    }
    if (this.state.pltState === 'ACTIVE' && (!payload.plt || (payload.plt && payload.plt.length === 0))) {
      swal({
        title: '',
        type: 'warning',
        text: 'Tetapkan pemegang pelaksana tugas terlebih dahulu.',
      });
      return;
    }
    if (this.state.archiverUnit && !payload.kodeUnit) {
      swal({
        title: '',
        type: 'warning',
        text: 'Kode unit tidak boleh kosong saat unit penyimpan berkas aktif.',
      });
      return;
    }
    this.setState({ loading: true }, () => {
      var ops = []
      if (this.state.letterHeaderID) {
        ops.push(LetterService.setLetterHeaderForPUA(id, this.state.letterHeaderID))
      }
      ops.push(OrgService.update(id, payload))

      Promise.all(ops)
        .then((result) => {
          this.setState({ loading: false, mode: 'structure' });
          this.load();
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.response.data.message && err.response.data.message.indexOf('Archiver unit is already exist') > -1) {
            swal({
              title: '',
              type: 'warning',
              text: 'Organisasi ini sudah memiliki unit penyimpan berkas, yaitu ' + err.response.data.message.split(':')[1]
            });
            this.setState({ archiverUnit: false });
            return
          }
          if (err && err.response && err.response.data && err.response.data.assignments && err.response.data.assignments.length > 0) {
            let msg = 'Pengguna ini sudah memegang posisi '
            if (err.response.data.assignments[0].type === 'ASSISTANT') {
              msg += 'asisten'
            }
            if (err.response.data.assignments[0].type === 'DELEGATION') {
              msg += 'delegasi/Plh.'
            }
            if (err.response.data.assignments[0].type === 'PLT') {
              msg += 'Plt.'
            }
            msg += ' untuk jabatan ' + err.response.data.assignments[0].orgName + '.'
            swal({title: '', type: 'warning', text: msg});
            return;
          }
        })
    });
  }

  getNewStaffName = (parentId) => {
    let count = 1
    for (let i in this.state.data) {
      if (this.state.data[i].parent === parentId) {
        count++;
      }
    }
    return 'Staf ' + count;
  }

  leaderDashboardEnabledSwitch = (checked) => {
    this.setState({ leaderDashboardEnabled: checked});
  }  

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {!(this.state.data && this.state.data.length > 0) &&
            <button
              className="btn btn-default btn-sm btn-sm-extra" style={{ width: 150, float: 'right', marginBottom: 15 }}
              onClick={() => { this.newItem() }}
            >
              <Fa.FaPlus /> Buat struktur baru
          </button>
          }
          <h4>
            {(this.state.mode === 'edit' || this.state.mode === 'new') &&
              <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={this.back} style={{ cursor: 'pointer', marginRight: 5 }} />
            }
            {this.state.mode === 'edit' &&
              'Sunting Struktur Organisasi'
            }
            {this.state.mode === 'new' &&
              'Tambah Struktur Organisasi'
            }
            {this.state.mode !== 'edit' && this.state.mode !== 'new' &&
              'Struktur Organisasi'
            }
          </h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              {this.state.mode === 'structure' &&
                <div>
                  {!this.state.loading && !(this.state.data && this.state.data.length > 0) &&
                    <p style={{ margin: 15 }}>Belum ada struktur organisasi</p>
                  }
                  {this.state.loading ?
                    <Spinner /> :
                    <ul>
                      {Object.keys(this.state.data).map((item, index) => {
                        return <li
                          key={index}
                          style={{
                            padding: 10,
                            display: this.state.data[item].visibility ? 'block' : 'none',
                            paddingLeft: this.state.data[item].level === 0 ||
                              this.state.data[item].filtered ? 15 :
                              this.state.data[item].level === 1 ? 30 : (
                                this.state.data[item].level === 2 ? 45 : (
                                  this.state.data[item].level === 3 ? 60 : (
                                    this.state.data[item].level === 4 ? 75 : (
                                      this.state.data[item].level === 5 ? 90 : 105)))),
                            background: !this.state.data[item].current ? 'none' : '#F7F7F7',
                          }}
                        >
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => { this.handleClick(index) }}
                          >
                            {!this.state.data[item].hasChild ? '' :
                              !this.state.data[item].collapse ? <Fa.FaCaretDown /> :
                                this.state.data[item].hasChild && <Fa.FaCaretRight />
                            }
                            &nbsp;
                          <b>
                              {this.state.data[item].role !== 'ORG' ? this.state.data[item].name : this.state.satkerName}
                            </b>
                          </span>
                          &nbsp;&nbsp;
                        {(this.state.data[item].role === 'PUA' || !this.state.data[item].parent) &&
                            <button
                              className="btn btn-default btn-sm btn-sm-extra" style={{ width: 100 }}
                              onClick={() => { this.newItem(this.state.data[item]) }}
                            >
                              <Fa.FaPlus /> Tambah sub
                        </button>
                          }
                          {this.state.data[item].role !== 'ORG' && !this.state.data[item].hasChild &&
                            <button
                              className={'btn btn-danger btn-sm btn-sm-extra ' + (this.state.data[item].hasChild && 'disabled')} style={{ float: 'right' }}
                              onClick={() => { this.delete(this.state.data[item]) }}
                            >
                              <Fa.FaTrashO />
                            </button>
                          }
                          {this.state.data[item].role !== 'ORG' &&
                            <button
                              className={'btn btn-default btn-sm btn-sm-extra'} style={{ float: 'right' }}
                              onClick={() => { this.editItem(this.state.data[item]) }}
                            >
                              <Fa.FaEdit />
                            </button>
                          }
                          <span
                            style={{ float: 'right' }}
                          >
                            {this.state.data[item].role !== 'ORG' && this.state.data[item].holder &&
                              <span>
                                {this.state.data[item].holderName || this.state.data[item].pltName} -
                            </span>
                            }
                          </span>
                        </li>
                      })}
                    </ul>
                  }
                </div>
              }
              {(this.state.mode === 'new' || this.state.mode === 'edit') &&
                <div className="row" style={{ margin: 15 }}>
                  <div className="col-md-12">
                    {this.state.currentParent && this.state.currentParent.name &&
                      <div className="form-group row">
                        <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Induk</label>
                        <div className="col-md-9" >
                          <input
                            className="form-control"
                            name="parentName"
                            placeholder="Induk"
                            value={this.state.currentParent.name}
                            disabled
                          />
                        </div>
                      </div>
                    }
                    {this.state.role !== 'ORG' &&
                      <div className="form-group row">
                        <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Unit</label>
                        <div className="col-md-9" >
                          <select
                            className="form-control"
                            name="role"
                            placeholder="Unit"
                            value={this.state.unitId}
                            onChange={(e) => {
                              this.setState({ unitId: e.target.value });
                            }}
                          >
                            <option key="" value="">Mengikuti atasan</option>)
                            {this.state.units.map((item) => {
                              return (<option key={item.id} value={item.id}>{item.name}</option>)
                            })}
                          </select>
                        </div>
                      </div>
                    }
                    {this.state.role !== 'ORG' &&
                      <div className="form-group row">
                        <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Role</label>
                        <div className="col-md-9" >
                          <select
                            className="form-control"
                            name="role"
                            placeholder="Role"
                            value={this.state.role}
                            onChange={(e) => {
                              this.setState({ role: e.target.value });
                            }}
                            disabled={this.state.hasChild}
                          >
                            <option value="PUK">PUK</option>
                            <option value="PUKS">PUKS</option>
                            <option value="PUA">PUA</option>
                          </select>
                        </div>
                      </div>
                    }

                    {this.state.role === 'PUA' &&
                      <div className="form-group row">
                        <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Kop Surat Bawaan</label>
                        <div className="col-md-9" >
                          <select
                            className="form-control"
                            name="letterHeaderID"
                            placeholder="Kop Surat Bawaan"
                            value={this.state.letterHeaderID}
                            onChange={(e) => {
                              this.setState({ letterHeaderID: e.target.value });
                            }}
                          >
                            {this.state.letterHeaders.map((item) => {
                              return (<option key={item.id} value={item.id}>{item.name}</option>)
                            })}

                          </select>
                        </div>
                      </div>
                    }
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Nama struktur <span style={{ color: 'red' }}>*</span></label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="name"
                          placeholder="Nama struktur"
                          value={this.state.name}
                          onChange={(e) => { this.setState({ name: e.target.value }) }}
                        />
                        <small id="usernameHelp" className="form-text text-muted">Harap diisi</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Nama jabatan penanda tangan </label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="signerName"
                          placeholder="Nama jabatan penanda tangan"
                          value={this.state.signerName}
                          required={false}
                          onChange={(e) => { this.setState({ signerName: e.target.value }) }}
                        />
                      </div>
                    </div>
                    {this.state.role !== 'ORG' &&
                      <div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Pemegang jabatan </label>
                          <div className="col-md-9" >
                            <Switch
                              onChange={this.jabatanSwitched}
                              checked={this.state.jabatanIsActive}
                            />
                            <br />
                            <AddressBookPersonal
                              style={{ float: 'right' }}
                              single={true}
                              titleless={true}
                              item={this.state}
                              name={'holder'}
                              onChange={this.handleSelectedPersonForForm}
                            />
                            {this.state.holderName && <span>&nbsp;&nbsp;{this.state.holderName}</span>}
                            <small id="usernameHelp" className="form-text text-muted">Harap diisi</small>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Pelaksana Tugas</label>
                          <div className="col-md-9" >
                            <Switch
                              onChange={this.pltSwitched}
                              checked={this.state.pltState === 'ACTIVE'}
                            />
                            <br />
                            <AddressBookPersonal
                              style={{ float: 'right' }}
                              single={true}
                              item={this.state}
                              name={'plt'}
                              onChange={this.handleSelectedPersonForForm}
                            />
                            {this.state.pltName && <span>&nbsp;&nbsp;{this.state.pltName}</span>}
                            <small id="usernameHelp" className="form-text text-muted">Harap diisi</small>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Asisten</label>
                          <div className="col-md-9" >
                            <Switch
                              onChange={this.assistantSwitched}
                              checked={this.state.assistantState === 'ACTIVE'}
                            />
                            <br />
                            <AddressBookPersonal
                              style={{ float: 'right' }}
                              single={true}
                              item={this.state}
                              name={'assistant'}
                              onChange={this.handleSelectedPersonForForm}
                            />
                            {this.state.assistantName && <span>&nbsp;&nbsp;{this.state.assistantName}</span>}
                            <small id="usernameHelp" className="form-text text-muted">Harap diisi</small>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Delegasi</label>
                          <div className="col-md-9" >
                            <Switch
                              onChange={this.delegationSwitched}
                              checked={this.state.delegationState === 'ACTIVE'}
                            />
                            <br />
                            <AddressBookPersonal
                              style={{ float: 'right' }}
                              single={true}
                              item={this.state}
                              name={'delegated'}
                              onChange={this.handleSelectedPersonForForm}
                            />
                            {this.state.delegatedName && <span>&nbsp;&nbsp;{this.state.delegatedName}</span>}
                            <small id="usernameHelp" className="form-text text-muted">Harap diisi</small>
                          </div>
                        </div>
                        {this.state.role && this.state.role === 'PUA' &&
                          <div className="form-group row">
                            <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Dashboard Pimpinan</label>
                            <div className="col-md-9" >
                              <Switch
                                onChange={this.leaderDashboardEnabledSwitch}
                                checked={this.state.leaderDashboardEnabled  === true}
                              />
                            </div>
                          </div>
                        }
                        {this.state.delegationState && this.state.delegationState === 'ACTIVE' &&
                          <div>
                            <DateTimePicker
                              name="delegationStartDate"
                              value={this.state.delegationStartDate}
                              label="Mulai didelegasikan"
                              required={true}
                              onChange={this.inputEventHandler}
                              labelWidth="3"
                              fieldWidth="9"
                            />
                            <DateTimePicker
                              name="delegationEndDate"
                              value={this.state.delegationEndDate}
                              label="Selesai didelegasikan"
                              required={true}
                              onChange={this.inputEventHandler}
                              labelWidth="3"
                              fieldWidth="9"
                            />
                          </div>
                        }
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Direktorat</label>
                          <div className="col-md-9" >
                            <input
                              className="form-control"
                              name="direktorat"
                              placeholder="Direktorat"
                              value={this.state.direktorat}
                              onChange={(e) => { this.setState({ direktorat: e.target.value }) }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Unit Penyimpan Berkas</label>
                          <div className="col-md-9" >
                            <Switch
                              onChange={this.archiverUnitSwitched}
                              checked={this.state.archiverUnit}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Kode Unit</label>
                          <div className="col-md-9" >
                            <input
                              className="form-control"
                              name="kodeUnit"
                              placeholder="Kode unit"
                              value={this.state.kodeUnit}
                              onChange={(e) => { this.setState({ kodeUnit: e.target.value }) }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Kode Jabatan</label>
                          <div className="col-md-9" >
                            <input
                              className="form-control"
                              name="kodeJabatan"
                              placeholder="Kode jabatan"
                              value={this.state.kodeJabatan}
                              onChange={(e) => { this.setState({ kodeJabatan: e.target.value }) }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Pencacah Nomor Surat</label>
                          <div className="col-md-9">
                            <Switch
                              onChange={this.letterCounterDisabledSwitched}
                              disabled={this.state.kodeJabatan === ""}
                              checked={this.state.letterCounterDisabled === false}
                            />
                            <br/>
                            {this.state.letterCounterDisabled === false &&
                              <LetterCounterRef
                                id={this.state.id}
                                text="Setelan Nomor Surat"
                              />
                            }
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Kota</label>
                          <div className="col-md-9" >
                            <input
                              className="form-control"
                              name="city"
                              placeholder="Kota"
                              value={this.state.city}
                              onChange={(e) => { this.setState({ city: e.target.value }) }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Lokasi</label>
                          <div className="col-md-9" >
                            <input
                              className="form-control"
                              name="location"
                              placeholder="Lokasi"
                              value={this.state.location}
                              onChange={(e) => { this.setState({ location: e.target.value }) }}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    {this.state.loading ?
                      <Spinner /> :
                      <div style={{ float: 'right' }}>
                        <button className="btn btn-default" onClick={this.back}>Batal</button>&nbsp;&nbsp;
                        <button className="btn btn-success" onClick={this.state.mode === 'new' ? this.create : () => { this.update(null, null) }}>Simpan</button>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
