import React, { Component } from 'react';
import Editor from '../../../../../components/Editor';

export default class component extends Component {
  render() {
    const { inputEventHandler } = this.props;
    return (
      <form className="form-horizontal" style={{ paddingTop: 10 }}>
        <Editor
          name="contentAbstract"
          label="Penjelasan Isi Surat"
          required={true}
          content={this.props.parentState.contentAbstract}
          onChange={inputEventHandler}
          config={{
            height: 600,
          }}
        />
      </form>
    );
  }
}
