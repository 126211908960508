import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import './styles.css';
import swal from 'sweetalert';
import Blob from '../../../../../../services/Blob';
import Document from '../../../../../../services/Document';
import ManualLetterArchive from '../../../../../../services/ManualLetterArchive';
import Spinner from '../../../../../../components/Spinner';
import Pagination from '../../../../../../components/Pagination';

const BlobService = new Blob();
const DocumentService = new Document();
const ManualLetterArchiveService = new ManualLetterArchive();

const allowedMimeType = [
  // Basic document format
  'application/pdf',
  'application/rtf',
  // Images
  'image/png',
  'image/jpeg',
  // Compression
  'application/zip',
  'application/vnd.rar',
  // Microsoft Office's mimetypes
  'application/msword',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  // Open Document Format
  // https://github.com/qt-haiku/LibreOffice/blob/master/sysui/desktop/solaris/mime.types
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.text-flat-xml',
  'application/vnd.oasis.opendocument.text-template',
  'application/vnd.oasis.opendocument.text-web',
  'application/vnd.oasis.opendocument.text-master',
  'application/vnd.oasis.opendocument.graphics',
  'application/vnd.oasis.opendocument.graphics-flat-xml',
  'application/vnd.oasis.opendocument.graphics-template',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.presentation-flat-xml',
  'application/vnd.oasis.opendocument.presentation-template',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.spreadsheet-flat-xml',
  'application/vnd.oasis.opendocument.spreadsheet-template',
  'application/vnd.oasis.opendocument.chart',
  'application/vnd.oasis.opendocument.formula',
  'application/vnd.oasis.opendocument.image',
  'application/vnd.sun.xml.writer',
  'application/vnd.sun.xml.writer.template',
  'application/vnd.sun.xml.writer.global',
  'application/vnd.stardivision.writer',
  'application/vnd.stardivision.writer-global',
  'application/vnd.sun.xml.calc',
  'application/vnd.sun.xml.calc.template',
  'application/vnd.stardivision.calc',
  'application/vnd.stardivision.chart',
  'application/vnd.sun.xml.impress',
  'application/vnd.sun.xml.impress.template',
  'application/vnd.stardivision.impress',
  'application/vnd.sun.xml.draw',
  'application/vnd.sun.xml.draw.template',
  'application/vnd.stardivision.draw',
  'application/vnd.sun.xml.math',
  'application/vnd.stardivision.math',
  'application/vnd.sun.xml.base',
  'application/vnd.openofficeorg.extension',
  // XPS
  // http://www.ecma-international.org/publications/standards/Ecma-388.htm
  'application/vnd.ms-xpsdocument',
  'application/oxps',
]

const maxSize = 22020000;

export default class FileUploder extends Component {
  state = {
    elementId: `upload-btn-${(new Date()).valueOf().toString()}`,
    files: this.props.files,
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    searchString: '',
    loading: false,
    buttonLoading: false,
    isInternalAttachment: false,
  }

  handleFile = (e) => {
    this.setState({ buttonLoading: true });
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!(allowedMimeType.indexOf(file.type) > -1)) {
      this.setState({ buttonLoading: false });
      swal({
        title: '',
        type: 'warning',
        text: 'Jenis berkas ini tidak diizinkan untuk diunggah.',
      });
      return;
    }
    if (parseInt(file.size, 10) > maxSize) {
      this.setState({ buttonLoading: false });
      swal({
        title: '',
        type: 'warning',
        text: 'Ukuran maksimal berkas adalah 20MB.',
      });
      return;
    }
    reader.onloadend = () => {
      let files = this.props.files;
      let timestamp = (new Date()).valueOf().toString();
      file.blobType = this.props.blobType? this.props.blobType:"";
      files.push({ file: file, timestamp: timestamp, state: 'uploading' });
      this.props.onChange(files);
      BlobService.upload(file)
        .then((blobUuid) => {
          let files = this.props.files;
          for (var i in files) {
            if (timestamp === files[i].timestamp) {
              files[i].state = 'done';
              files[i].uuid = blobUuid;
              files[i].isInternalAttachment = this.state.isInternalAttachment;
            }
          }
          this.setState({ buttonLoading: false });
          this.props.onChange(files);
        })
        .catch((err) => {
          this.setState({isInternalAttachment: false})

          let files = this.props.files;
          for (var i in files) {
            if (timestamp === files[i].timestamp) {
              files[i].state = 'failed';
            }
          }
          this.setState({ buttonLoading: false });
          this.props.onChange(files);
        })
    }
    reader.readAsDataURL(file);
  }

  deleteFile = (index) => {
    let files = this.props.files;
    let deletedFile = files[index]
    files.splice(index, 1);
    this.props.onChange(files, deletedFile);
    this.forceUpdate();
  }

  pickDocument = (doc) => {
    let files = this.props.files;
    let timestamp = (new Date()).valueOf().toString();
    let file = {
      name: doc.filename,
    }
    files.push({ file: file, timestamp: timestamp, state: 'done', uuid: doc.blobId });
    this.props.onChange(files);
    this.hideModal();
  }

  showModal = (mode) => {
    this.setState({ showModal: mode }, () => {
      this.page(1);
    });
  }

  hideModal = () => {
    this.setState({ showModal: '' });
  }

  page = (page) => {
    this.setState({ loading: true, searchString: '' });
    let opt = { page: page, limit: this.state.limit, search: this.state.searchString }
    if (this.state.showModal === 'documentRepo') {
      DocumentService.getList(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    } else {
      ManualLetterArchiveService.getList(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    }
  }

  next = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = { page: this.state.page + 1, limit: this.state.limit, search: this.state.searchString }
    if (this.state.showModal === 'documentRepo') {
      DocumentService.getList(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    } else {
      ManualLetterArchiveService.getList(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    }
  }

  prev = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = { page: this.state.page - 1, limit: this.state.limit, search: this.state.searchString }
    if (this.state.showModal === 'documentRepo') {
      DocumentService.getList(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    } else {
      ManualLetterArchiveService.getList(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    }
  }

  reload = () => {
    this.setState({ loading: true });
    let opt = { page: this.state.page, limit: this.state.limit, search: this.state.searchString }
    if (this.state.showModal === 'documentRepo') {
      DocumentService.getList(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    } else {
      ManualLetterArchiveService.getList(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    }
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  handleInternalAttachment = (isInternalAttachment) => {
    this.setState({ isInternalAttachment: isInternalAttachment })
  }

  render() {
    return (
      <div
        className={'file-uploader'}
        style={{ display: this.props.minimal ? 'inline-block' : 'block', marginTop: this.props.minimal ? 0 : 15 }}
      >
        {!this.props.minimal && <label>Lampiran {this.props.required && <span style={{ color: 'red' }}>*</span>}</label>}
        {!this.props.minimal && <br />}
        {!this.props.minimal && <span style={{ color: 'red' }}>Ukuran berkas unggahan maksimal 20MB</span>}
        {!this.props.minimal && <br />}
        <input
          id={this.state.elementId}
          type="file"
          onChange={this.handleFile}
          multiple={true} style={{ display: 'none', fontSize: 10 }}
        />
        {this.props.single && this.props.files.length < 1 &&
          (!this.state.buttonLoading ?
            <button
              type="button"
              className={'btn ' + (this.props.minimal ? 'btn-default' : 'btn-primary')}
              onClick={() => { document.getElementById(this.state.elementId).click() }}
            >
              <Fa.FaUpload />
              {!this.state.buttonLoading && (this.props.buttonLabel ? ' ' + this.props.buttonLabel : ' Unggah Lampiran')}
            </button>
            : <span> Mengunggah... <Spinner inline={true} height={50} /></span>
          )
        }
        {!this.props.single &&
          <div>
            <button
              type="button"
              className={'btn btn-primary'}
              onClick={() => { 
                document.getElementById(this.state.elementId).click();
                this.handleInternalAttachment(false);
              }}
            >
              <Fa.FaUpload /> Unggah Lampiran Surat
          </button>
          &nbsp;&nbsp;
          <button
              type="button"
              className={'btn btn-primary'}
              onClick={() => { this.showModal('manualLetterArchive') }}
            >
              <Fa.FaFileO /> Pilih dari arsip surat manual lama
          </button>
          </div>
        }
        <ul style={{ marginTop: 15, fontSize: 14 }}>
          {this.props.files.map((item, index) => {
            if (item.isInternalAttachment) return <span></span>
            return <li key={index}>
              {item.file.name}
              {item.state === 'uploading' &&
                <span>&nbsp;&nbsp;
                  <Spinner
                    inline={true}
                    height={32}
                  />
                </span>
              }
              {item.state === 'done' &&
                <span>&nbsp;&nbsp;
                <Fa.FaTrashO
                    color="red"
                    style={{ cursor: 'pointer' }}
                    onClick={() => { this.deleteFile(index) }}
                  />
                </span>
              }
              {item.state === 'failed' &&
                <span>&nbsp;&nbsp;
                <Fa.FaInfo color="red" />
                  <Fa.FaTrashO color="red"
                    style={{ cursor: 'pointer' }}
                    onClick={() => { this.deleteFile(index) }}
                  />
                </span>
              }
            </li>
          })}
        </ul>
        {!this.props.single && !this.props.hideInternalAttachment &&
        <button
            type="button"
            className={'btn btn-primary'}
            onClick={() => { 
              document.getElementById(this.state.elementId).click();
              this.handleInternalAttachment(true);
            }}
          >
            <Fa.FaUpload /> Unggah Lampiran Pendukung
        </button>}
        <ul style={{ marginTop: 15, fontSize: 14 }}>
          {this.props.files.map((item, index) => {
            if (!item.isInternalAttachment) return <span></span>
            return <li key={index}>
              {item.file.name}
              {item.state === 'uploading' &&
                <span>&nbsp;&nbsp;
                  <Spinner
                    inline={true}
                    height={32}
                  />
                </span>
              }
              {item.state === 'done' &&
                <span>&nbsp;&nbsp;
                <Fa.FaTrashO
                    color="red"
                    style={{ cursor: 'pointer' }}
                    onClick={() => { this.deleteFile(index) }}
                  />
                </span>
              }
              {item.state === 'failed' &&
                <span>&nbsp;&nbsp;
                <Fa.FaInfo color="red" />
                  <Fa.FaTrashO color="red"
                    style={{ cursor: 'pointer' }}
                    onClick={() => { this.deleteFile(index) }}
                  />
                </span>
              }
            </li>
          })}
        </ul>
        <div
          className="modal modal-open"
          style={{ display: (this.state.showModal && this.state.showModal.length > 0) ? 'block' : 'none' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Pilih berkas
                </h4>
                <span className="close" onClick={this.hideModal}><Fa.FaClose /></span>
                <hr />
              </div>
              <div className="modal-body">
                <div className="row" style={{ padding: 15 }}>
                  <div className="col-sm-12 col-md-6 col-lg-8">
                    <div className="input-group input-group-sm">
                      <span className="input-group-btn">
                        <span className="btn btn-success">
                          <Fa.FaRefresh />
                        </span>
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        onChange={this.handleSearchString}
                        value={this.state.searchString}
                      />
                      {this.state.searchString && this.state.searchString.length > 0 &&
                        <span className="input-group-btn">
                          <span className="btn btn-default" onClick={this.dismissSearch}>
                            <Fa.FaClose />
                          </span>
                        </span>
                      }
                      <span className="input-group-btn">
                        <span className="btn btn-default" onClick={this.search}>
                          <Fa.FaSearch />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div style={{ float: 'right' }}>
                      <Pagination
                        disabled={this.state.loading}
                        page={this.state.page}
                        limit={this.state.limit}
                        total={this.state.total}
                        prev={this.prev} next={this.next}
                        toPage={this.page} />
                    </div>
                  </div>
                  <hr />
                </div>
                {this.state.loading ? <Spinner /> :
                  <table className="table table-hover">
                    <thead>
                      {this.state.showModal === 'documentRepo' &&
                        <tr>
                          <th>Deskripsi</th>
                          <th>Nama berkas</th>
                          <th>Waktu</th>
                        </tr>
                      }
                      {this.state.showModal === 'manualLetterArchive' &&
                        <tr>
                          <th>Perihal</th>
                          <th>Nomor surat</th>
                          <th>Nama berkas</th>
                          <th>Waktu</th>
                        </tr>
                      }
                    </thead>
                    <tbody>
                      {this.state.showModal &&
                        this.state.showModal === 'documentRepo' &&
                        this.state.data &&
                        Object.keys(this.state.data).map((item, index) => {
                          return <tr
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() => { this.pickDocument(this.state.data[item]) }}
                          >
                            <td>{this.state.data[item].description}</td>
                            <td>{this.state.data[item].filename}</td>
                            <td>{this.state.data[item].timestamp}</td>
                          </tr>
                        })}
                      {this.state.showModal &&
                        this.state.showModal === 'manualLetterArchive' &&
                        this.state.data &&
                        Object.keys(this.state.data).map((item, index) => {
                          return <tr
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() => { this.pickDocument(this.state.data[item]) }}
                          >
                            <td>{this.state.data[item].subject}</td>
                            <td>{this.state.data[item].referenceNumber}</td>
                            <td>{this.state.data[item].filename}</td>
                            <td>{this.state.data[item].timestamp}</td>
                          </tr>
                        })}
                    </tbody>
                  </table>
                }
                {!(this.state.total > 0) && !this.state.loading &&
                  <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                    <span style={{ fontSize: 32 }}>
                      <Fa.FaInbox />
                    </span>
                    <br />
                    <h4>Tidak ada dokumen terunggah</h4>
                  </div>
                }
              </div>
              <div className="modal-footer">
                <span className="btn btn-default" onClick={this.hideModal}>Cancel</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
} 
