import React, { Component } from 'react';
import Org from '../../../../../services/Org';

/*

Usage : 

<Component label="Perihal" 
  name="particularFieldName" value={this.state.particularFieldName} 
  onChange={this.inputEventHandler} 
  required={true} 
  maxChar={500} 
  labelWidth=2
  fieldWidth=8
  disabled=false
  additionalLabel={'Hindari menyalin perihal dari Ms. Word atau situs web.'}
  options={[{value:"HANDWRITTEN",str:"Tanda tangan basah"}, {value:"SCANNED",str:"Tanda tangan scan"}]}
  defaultValue="SCANNED"
/>

*/

const OrgService = new Org();

class component extends Component {
  state = {
    value : '',
    options : []
  }
  componentDidMount = () => {
    var opts = [{name:'-'}];
    OrgService.getSuperior(this.props.signer).then((v) => {
      if (v && v.name) {
      	opts[1] = v;
      	console.log(v)
      }
      this.setState({options: opts });
    });
    if (this.props.value && this.props.value.length > 0) {
      this.setState({value: this.props.value}, () => {
        console.log(this.state);
        let e = { target : { name : this.props.name, value : this.state.value }}
        this.onChange(e);
      });
    } else {
      this.setState({value: this.props.defaultValue}, () => {
        console.log(this.state);
        let e = { target : { name : this.props.name, value : this.state.value }}
        this.onChange(e);
      });
    }
  }
  onChange = (event) => {
    this.setState({value : event.target.value});
    this.props.onChange(event);
  }

  render() {
    const { 
      label,
      name,
      required,
      labelWidth,
      fieldWidth,
      disabled,
    } = this.props;
    return (
      <div className="form-group row" style={{marginTop:10}}>
        <label className={`col-md-${labelWidth ? labelWidth.toString() : '1'} col-sm-${labelWidth ? labelWidth.toString() : '2'} control-label`}>
          {label}
          &nbsp;<span className="red">{required && '*'}</span>
        </label>
        <div className={`col-md-${fieldWidth ? fieldWidth.toString() : '10'} col-sm-${fieldWidth ? fieldWidth.toString() : '10'}`}>
          <select name={name} value={this.state.value} onChange={this.onChange} required={required} className="form-control" disabled={disabled}>
          {this.state.options.map((item, index) => {
            console.log(this.state.options, item)
              return (<option key={index} value={item.id} selected={item.id===this.props.value ? 'selected':''}>{item.name}</option>)
            })}
          </select>
        </div>
      </div>
    );
  }
}

export default component;
