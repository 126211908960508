import React, { Component } from 'react';
import DragList from 'react-drag-list';
import * as Fa from 'react-icons/lib/fa';
import hash from 'hash.js'
import './styles.css';

export default class AddressBookSortererModal extends Component {
  state = {
    showModal : false,
    loading : false,
    selected : [],
    sorted : '',
  }

  componentDidMount = () => {
    if (this.props.value && this.props.value.raw && this.props.value.raw.length > 0) {
      this.setState({selected : this.props.value.raw, sorted : this.props.value.str});
    }
  }

  componentWillReceiveProps = (e) => {
    if (this.props.value && this.props.value.raw && this.props.value.raw.length > 0) {
      this.setState({selected : this.props.value.raw, sorted : this.props.value.str});
    }
	}

  showModal = () => {
    console.log('Show modal');
    let selected = [];
    // Add from existing sorted
    if (this.props.value && this.props.value.raw && this.props.value.raw.length > 0) {
      for (let i in this.props.value.raw) {
        selected.push(this.props.value.raw[i]);
      }
    }
    for (let i in this.props.selected) {
      let exists = false;
      for (let j in selected) {
        if (selected[j].recipientUuid === this.props.selected[i].recipientUuid) {
          exists = true;
          break;
        }
      }
      if (!exists) {
        selected.push(this.props.selected[i]);
      }
    }
    for (let i in this.props.extern) {
      let exists = false;
      let id = hash.sha256().update(this.props.extern[i]).digest('hex');
      for (let j in selected) {
        if (selected[j].recipientUuid === id) {
          exists = true;
          break;
        }
      }
      if (!exists && this.props.extern[i]) {
        let obj = {
          recipientUuid : id,
          recipientName : this.props.extern[i],
          recipientType : (this.props.cc ? 'EXTERN_CC_RECIPIENT' : 'EXTERN_RECIPIENT'),
        }
        console.log(obj);
        selected.push(obj);
      }
    }
    // Check for deprecated
    let deprecated = [];
    for (let i in selected) {
      let selectedExist = false;
      let externExist = false;
      for (let j in this.props.selected) {
        if (selected[i].recipientUuid === this.props.selected[j].recipientUuid) {
           selectedExist = true;
           break;
        }
      }
      for (let k in this.props.extern) {
        let id = hash.sha256().update(this.props.extern[k]).digest('hex');
        if (selected[i].recipientUuid === id) {
          externExist = true;
          break;
        }
      }
      if (!selectedExist && !externExist) {
        deprecated.push(i);
      }
    }
    deprecated.reverse();
    for (let i in deprecated) {
      selected.splice(deprecated[i],1);
    }
    this.setState({showModal : true, loading : true, selected: selected});
  }
  hideModal = () => {
    this.setState({showModal : false});
  }

  ok = () => {
    let e = {
      target : {
        name : this.props.name,
        value : ''
      }
    }
    e.target.raw = []
    for (var i in this.state.selected) {
      if (i > 0) {
        e.target.value = e.target.value.replace(/\n/g,'');
        e.target.value += '; ';
      }
      e.target.value += this.state.selected[i].recipientName;
      if (this.props.name.toLowerCase().indexOf('recipient') > -1) {
        e.target.raw.push({
          recipientUuid : this.state.selected[i].recipientUuid || '', // extern recp has no uuid
          recipientName : this.state.selected[i].recipientName,
          recipientType : this.state.selected[i].recipientType,
          recipientOrder : parseInt(i, 10) + 1,
        });
      }
    }
    this.setState({sorted : e.target.value});
    this.props.onChange(e);
    this.hideModal();
  }

  render() {
    const { 
      label,
      name,
      required,
      vertical,
      row,
      labelWidth,
      fieldWidth,
    } = this.props;
    return (
      <div className="form-group row" style={{marginTop:10}}>
        <label 
          className={`col-md-${labelWidth ? labelWidth : 1} col-sm-2 control-label`} 
          style={{textAlign : vertical ? 'left' : 'right', padding : vertical ? 0 : 15}}
        >
          {label}
          &nbsp;<span className="red">{required && '*'}</span>
        </label>
        <div 
          className={`col-md-${fieldWidth ? fieldWidth : '10'} col-sm-${fieldWidth ? fieldWidth : '10'}`}
          style={{padding : vertical ? 0 : 15}}
        >
          <textarea
            onClick={this.showModal}
            name={name}
            value={this.state.sorted}
            onChange={this.onChange}
            required={required}
            className="form-control"
            rows={row ? row : 4}
            placeholder={this.props.placeholder}
            style={{whiteSpace:'pre-line'}}
          />
        </div>
        <div className="modal modal-open" style={{display:this.state.showModal ? 'block' : 'none'}}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {this.props.title}
                </h4>
                <span className="close" onClick={this.hideModal}><Fa.FaClose/></span>
                <hr/>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 col-sm-12" style={{maxHeight: "400px", overflow: "scroll"}}>
                    <div style={{marginTop:10}}>
                      <DragList
                        dataSource={this.state.selected}
                        rowKey="recipientUuid"
                        row={(record, index) => (
                          <div key={index} style={{ color: record.color }}>
                            <div className="drag-list-item" style={{height:40}}>
                              <Fa.FaSort size={20}/>&nbsp;
                              {record.recipientName}
                            </div>
                          </div>
                        )}
                        handles={false}
                        className="simple-drag"
                        rowClassName="simple-drag-row"
                        onUpdate={this._handleUpdate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div style={{float:'right'}}>
                  <span className="btn btn-primary" style={{marginRight:10}} onClick={this.ok}>OK</span>
                  <span className="btn btn-default" onClick={this.hideModal}>Cancel</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
