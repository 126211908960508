import React, { Component } from 'react';
import './../styles.css';
import Config from "../../../../config";
import Auth from '../../../../services/Auth';

const AuthService = new Auth();
export default class LetterReadLembarRalat extends Component {

  SignCreator = (data, index) => {
    return data.split('|')[index] ? data.split('|')[index] : ''
  }

  render() {
    const {
      number,
      contents,
      timestamp,
      letterDate,
      letterType,
      scannedSignature,
      signerName,
      signerOrg,
      signerOrgSignerName,
      signerNIP,
      onBehalfOf,
      onBehalfOfTitle,
      pelaksana,
      city,
      qrImage,
      letterState,
      letterHeaderLogo,
      signatureType,
      letterHeaderBody
    } = this.props.parentState.currentLetter;


    const {
      ccRecipients,
      settings,
      manualLetterReferenceNumber,
      manualLetterDate,
      query
    }=this.props.parentState;

    return (
      <div
      style={{
        background: 'white',
        width: '210mm',
        margin: '0 auto',
        marginTop: '15px',

        padding: '15mm 20mm 20mm 20mm',
      }}
    >
       <div
        id="letter-body"
        style={{
          background: 'white',
          width: '165mm',
          margin: '0 auto',
        }}
      >
        <div
          style={{
            fontFamily: 'Arial',
          }}
        >
          <div>
            <div>
            {letterHeaderLogo&& letterHeaderLogo.length > 0 &&
              <div
                style={{
                  display: 'inline-block',
                  textAlign: 'center',
                  width: '30mm',
                  height: '30mm',
                  float: 'left',
                  position: 'absolute'
                }}
              >
                <img
                  src={Config.apiHost + "/api/v1/blob/unrestricted/" + letterHeaderLogo}
                  onError={(e) => { console.log(e); e.target.src = Config.variant.images.letterLogo }}
                  alt=""
                  style={{ width: '31mm' }}
                />
              </div>
            }
             <div style={{
                display: 'inline-block',
                textAlign: 'center',
                width: (letterHeaderLogo&& letterHeaderLogo.length > 0) ? '170mm' : '100%',
                minHeight: '35mm',
                lineHeight: '0.8em',
                paddingLeft: (letterHeaderLogo&& letterHeaderLogo.length > 0) ? '30mm' : 0
              }}>
                <div dangerouslySetInnerHTML={{ __html: letterHeaderBody }}>
                </div>
              </div>
              <hr style={{ height: 1, border: 'none', color: '#333', background: 'transparent', borderTop: 'solid 1px #333', marginTop: 0, marginBottom: 0 }} />
              <hr style={{ height: 1, border: 'none', color: '#333', background: 'transparent', borderTop: 'solid 2px #333', marginTop: 0 }} />
              <div
                style={{
                  lineHeight: '1.1em',
                }}
              >
                <div style={{ textAlign: 'center', margin: 15 }}>
                  <div>
                    LEMBAR RALAT
                  </div>
                  <span>NOMOR {this.props.renderReferenceNumber(letterState, number, manualLetterReferenceNumber)}</span>
                  <div style={{marginTop: 20}}>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: contents
            }}
            style={{
              lineHeight: '1.1em',
              marginTop: '5mm',
              marginBottom: '5mm',
            }}
          >
          </div>
          <div style={{ width: '100%', height: '200px', marginTop: '10mm' }}>
            {letterState === 'SENT' ?
              <div style={{
                width: 150,
                height: 199,
                float: 'left',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: "url('" + qrImage + "')"
              }}></div>
              :
              <span id="qr-code"></span>
            }
            <div style={{ float: 'right', width: '80mm' }}>
              <table>
                <tbody>
                <tr>
                      <td style={{ verticalAlign: 'top' }} width="20"></td>
                      <td>
                        Ditetapkan di {city ? city : ''} <br />
                        pada tanggal {this.props.renderLetterDate(letterState, letterDate, this.props.parentState.manualLetterDate, timestamp)} <br />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        {onBehalfOf && onBehalfOfTitle && onBehalfOfTitle.length > 0 && 'a.n.'}
                        {pelaksana && pelaksana === 'HARIAN' && 'Plh.'}
                        {pelaksana && pelaksana === 'TUGAS' && 'Plt.'}
                        &nbsp;
                                    </td>
                      <td>
                        {onBehalfOf && onBehalfOfTitle && onBehalfOfTitle.length > 0 ?
                          <div>
                            {onBehalfOfTitle}
                            <br />
                            {signerOrg}
                          </div>
                          :
                          (!pelaksana || (pelaksana && pelaksana.length < 1) ?
                              <span>{signerOrg}</span>
                            :
                            (signerOrgSignerName ? signerOrgSignerName : signerOrg))
                        }
                      </td>
                    </tr>
                  <tr>
                    <td></td>
                    <td style={{ padding: '7px 1px' }}>
                      <div style={{ height: '25mm' }}>
                        {scannedSignature &&
                          (letterState === 'SENT' || 
                          (query.action === 'finalization' && AuthService.getRoles().role === 'FINALIZER')) &&
                          (signatureType !== 'HANDWRITTEN' || 
                          letterType === 'TNDE_MEMO') &&
                          <img
                            src={Config.apiHost + "/api/v1/blob/unrestricted/" + scannedSignature}
                            alt=""
                            onError={(e) => { e.target.src = '/images/blank.png' }}
                            style={{ height: '25mm' }}
                          />
                        }
                        <br />
                      </div>
                      {signerName}
                      <br />
                      {settings.useNIPOnLetter === 'TRUE' && <span>NIP. {signerNIP}</span>}
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {ccRecipients.length > 0 &&
          <div>
            {onBehalfOfTitle && <div style={{ marginBottom: (letterType === 'TNDE_SURAT_TUGAS') ? 45 : 35 }}></div>}
            Tembusan: <br />
            {ccRecipients && ccRecipients.map((item, index) => {
              return <div key={index}>
                {!(index === 0 && ccRecipients.length === 1) && <span>{parseInt(index, 10) + 1}.</span>} {item.recipientName}
              </div>
            })}
          </div>}
          <div style={{ fontSize: '11pt', marginTop: 30}}>
            <span className='kp'></span>
          </div>
        </div>
      </div>
    </div>
    )
  }
}