import React, { Component } from 'react';
import Spinner from '../../../../components/Spinner';
import User from '../../../../services/User';
import Unit from '../../../../services/Unit';
import Pagination from '../../../../components/Pagination';
import * as Fa from 'react-icons/lib/fa';
import { getTimeFromTimeStamp, getDateFromTimeStamp, renderReports } from './exts';
import Filter from "../Filter";
import swal from 'sweetalert-react';
import './style.css';

const UserService = new User();
const UnitService = new Unit();

export default class MonotorPresence extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    loading: false,
    letterDateStart: null,
    letterDateEnd: null,
    exportLoading: false,
    units: [],
    selectedUnitId: ''
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    if (window.store.getState().rootReducers.currentUser.role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }

    this.setState({ loading: true }, () => {
      this.loadUnit();
      this.reload({ page: this.state.page, limit: this.state.limit, });
    })
  }

  loadUnit = () => {
    const opt = { page: this.state.page, limit: 9999 }
    UnitService.getAll(opt)
      .then((result) => {
        this.setState({ units: result.data || [] })
      })
      .catch(() => {
        this.setState({ units: [] });
      });
  }

  reload = (opt) => {
    if (this.state.letterDateStart && this.state.letterDateEnd) {
      opt.startDate = this.state.letterDateStart.toISOString();
      opt.endDate = this.state.letterDateEnd.toISOString();
    }
    if (this.state.selectedUnitId) {
      opt.unitId = this.state.selectedUnitId;
    }

    UserService.getMonitorPresence(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.presences || [],
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ data: [], loading: false, });
      });
  }

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true }, () => {
      this.reload({ page: page, limit: this.state.limit });
    });
  }

  next = () => {
    this.setState({ loading: true }, () => {
      this.reload({ page: this.state.page + 1, limit: this.state.limit });
    });
  }

  prev = () => {
    this.setState({ loading: true }, () => {
      this.reload({ page: this.state.page - 1, limit: this.state.limit });
    });
  }

  filterHandler = (filter) => {
    this.setState({ loading: true, ...filter }, () => {
      this.reload({
        page: this.state.page,
        limit: this.state.limit,
        total: this.state.total
      });
    });
  }

  exportProcess = (exportAs, exportType, startDate, endDate) => {
    let opt = { exportAs };
    if (startDate && endDate) {
      opt = {
        ...opt,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }
    }
    if (this.state.selectedUnitId) {
      opt.unitId = this.state.selectedUnitId;
    }

    this.setState({ exportLoading: true }, () => {
      UserService.getMonitorPresenceExport(opt)
        .then((result) => {
          this.setState({ exportLoading: false }, () => {
            let fileName = "Rekap-Monitor-Presensi"
            switch (result.type) {
              case "text/csv":
                fileName += ".csv"
                break;
              case "application/pdf":
                fileName += ".pdf"
                break;
              case "application/octet-stream":
                fileName += ".xlsx"
                break;
              default:
                swal({
                  title: '',
                  text: `Gagal menyiapkan berkas, coba reset filter dan ulangi kembali.`,
                  confirmButtonText: 'OK'
                });
                return;
            }
            this.downloadExportedPresence(
              new Blob([result], { type: result.type }),
              fileName
            );
          });
        })
        .catch(() => {
          this.setState({ data: [], loading: false, });
        });
    })
  }

  downloadExportedPresence = (data, name) => {
    const fileURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.target = '_blank';
    tempLink.setAttribute('download', name);
    tempLink.click();
  }

  handleFilterUnit = (e) => {
    this.setState({ loading: true, selectedUnitId: e.target.value }, () => {
      this.reload({ page: 1, limit: 10 });
    });
  }

  clearFilterUnit = () => {
    this.setState({ loading: true, selectedUnitId: '' }, () => {
      this.reload({ page: 1, limit: 10 });
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">

          <h4>Monitor Presensi</h4>
          <Filter
            startDate={this.state.letterDateStart}
            endDate={this.state.letterDateEnd}
            filterHandler={this.filterHandler}
            exportProcess={this.exportProcess}
            exportVisible={true}
            exportLoading={this.state.exportLoading}
          />

          <div className="box box-secondary">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-3 col-md-3">
                  <div className="input-group">
                    <select
                      className="custom-select"
                      name="selectedUnitId"
                      onChange={this.handleFilterUnit}
                      disabled={this.state.data.length > 0 ? false : true}
                    >
                      <option value='' disabled selected={!this.state.selectedUnitId}>Filter Unit</option>
                      {Object.keys(this.state.units).map((item, index) => {
                        const unit = this.state.units[item];
                        return <option key={index} value={unit.id}>{unit.name}</option>
                      })}
                    </select>
                    <span className="input-group-btn">
                      <span className="btn btn-success" onClick={this.clearFilterUnit}>
                        <Fa.FaRefresh />
                      </span>
                    </span>
                  </div>
                </div>
                <hr />
              </div>
              <div className="table-responsive">
                {this.state.loading ? <Spinner /> :
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nama pengguna</th>
                        <th>Jabatan</th>
                        <th>Unit</th>
                        <th>Tanggal</th>
                        <th>Jam Masuk</th>
                        <th>Jam Pulang</th>
                        <th>Laporan/Memo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(this.state.data).map((item, index) => {
                        return <tr key={index}>
                          <td>{this.state.data[item].name}</td>
                          <td>{this.state.data[item].organization}</td>
                          <td>{this.state.data[item].unitName}</td>
                          <td>{getDateFromTimeStamp(this.state.data[item].loginAt)}</td>
                          <td>{getTimeFromTimeStamp(this.state.data[item].loginAt)}</td>
                          <td>{getTimeFromTimeStamp(this.state.data[item].logoutAt, true)}</td>
                          <td>
                            <ul>
                              {renderReports(this.state.data[item].laporan)}
                            </ul>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                }
              </div>
            </div>
            <div className="box-footer">
              <Pagination
                disabled={this.state.loading}
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prev} next={this.next}
                toPage={this.page} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
