import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import DispositionAction from '../../../services/DispositionAction';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';

const DispositionActionService = new DispositionAction();

export default class DispositionActions extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    data: [],
    pagination: [],
    loading: false

  }

  componentDidMount = () => {
    let currentUser = JSON.parse(localStorage.getItem("current_user"))
    if (currentUser.role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.setState({ loading: true }, () => {
      let opt = { page: this.state.page, limit: this.state.limit }
      DispositionActionService.getAll(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data,
            loading: false
          })
        })
        .catch((err) => {
          this.setState({
            loading: false,
            data: []
          })
        })
    })
  }

  next = () => {
    this.setState({ loading: true })
    let opt = { page: this.state.page + 1, limit: this.state.limit }
    DispositionActionService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false
        })
      })
      .catch((err) => {
        this.setState({
          loading: false,
          data: []
        })
      })
  }

  prev = () => {
    this.setState({ loading: true })
    let opt = { page: this.state.page - 1, limit: this.state.limit }
    DispositionActionService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false
        })
      })
      .catch((err) => {
        this.setState({
          loading: false,
          data: []
        })
      })
  }

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true });
    let opt = { page: page, limit: this.state.limit }
    DispositionActionService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  reload = () => {
    this.page()
  }

  read = (id) => {
    window.browserHistory.push('/settings/disposition-action/' + id)
  }

  add = () => {
    window.browserHistory.push('/settings/disposition-action/new')
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div style={{ float: 'right' }}>
            <span className="btn btn-default" onClick={this.add} >
              <Fa.FaPlus /> Tambah Aksi Disposisi
            </span>
          </div>
          <h4>Aksi Disposisi</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div style={{ float: 'right' }}>
                    <Pagination
                      disabled={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.state.prev} next={this.state.next}
                      toPage={this.page}
                    />
                  </div>
                </div>
                <hr />
              </div>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Nama aksi disposisi</th>
                      <th>Tanggal Ditambahkan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((item, index) => {
                      return <tr key={index} style={{ cursor: "pointer" }} onClick={() => { this.read(item.id) }}>
                        <td>{item.name}</td>
                        <td>
                          {date.format(item.createdAt, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <br />
                  <h4>Tidak ada data</h4>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}