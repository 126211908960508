import loginCompanyLogo from './images/company-logo.png'
import backgroundImage from './images/background.jpg'
import navigationLogo from './images/company-logo-nav.png'
import letterLogo from './images/logo_kop.jpg'
import favIcon from './images/icon-blu-128x128.png'
import styles from './styles.css'

const config = () => {
    return {
        "name": "ppkblu",
        "applicationTitle": "Office Automation Direktorat Pembinaan PK BLU",
        "footer": "Kementerian Keuangan Republik Indonesia",
        "images": {
            "companyLogo": loginCompanyLogo,
            "backgroundImage": backgroundImage,
            "navigationLogo": navigationLogo,
            "letterLogo": letterLogo,
            "favIcon": favIcon
        }, 
        "styles": styles
    }
}
export default config()
