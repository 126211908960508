import axios from 'axios'
import config from '../config'
import Misc from './Misc';
import Error from './Error';
const MiscService = new Misc();
const ErrorService = new Error();
export default class Logger {
    historyLogger = (history, action) => {
        return this.info("PAGE VISIT", action + "|" + history.pathname)
    }
    info = (action, description, duration) => {
        return this.log("INFO", action, description, duration)
    }
    debug = (action, description, duration) => {
        return this.log("DEBUG", action, description, duration)
    }
    error = (action, description, duration) => {
        return this.log("ERROR", action, description, duration)
    }
    log = (level = "INFO", action, description, duration) => {
        return axios.post(config.apiHost + "/api/v1/logs", {
            level,
            action,
            description,
            duration
        }, {
            headers: MiscService.generateHeaders(),
            disableLogger: true,
        }).catch(ErrorService.handle)
    }
}