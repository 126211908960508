import React, { Component } from 'react';
import Spinner from '../../../components/Spinner';
import User from '../../../services/User';
import Pagination from '../../../components/Pagination';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
const UserService = new User();

export default class MonotorLog extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    loading: false,
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    if (window.store.getState().rootReducers.currentUser.role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.setState({ loading: true }, () => {
      let opt = { page: this.state.page, limit: this.state.limit, search: this.state.searchString }
      UserService.getMonitorLog(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.activities || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: page, limit: this.state.limit, search: this.state.searchString }
    UserService.getMonitorLog(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.activities || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: this.state.page + 1, limit: this.state.limit, search: this.state.searchString }
    UserService.getMonitorLog(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.activities || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: this.state.page - 1, limit: this.state.limit, search: this.state.searchString }
    UserService.getMonitorLog(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.activities || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  newUser = () => {
    window.browserHistory.push('/settings/user/new');
  }

  reload = () => {
    this.page()
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  resolveActivity = (a) => {
    const activities = {
      "LOGIN": "Login",
      "LOGOUT": "Logout",
      "INSERT": "Memasukkan data",
      "UPSERT": "Menambah data",
      "EDIT": "Menyunting data",
      "DELETE": "Menghapus data",
      "QUERY": "Memuat data",
      "VIEW": "Melihat data"
    }

    const resolved = activities[a]
    if (!resolved) {
      return "Tidak diketahui"
    }
    return resolved
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          
          <h4>Monitor Log</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
               <div style={{ float: 'right',margin:15 }}>
                 <Pagination
                   disabled={this.state.loading}
                   page={this.state.page}
                   limit={this.state.limit}
                   total={this.state.total}
                   prev={this.prev} next={this.next}
                   toPage={this.page} />
               </div>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Nama pengguna</th>
                      <th>Waktu Terakhir</th>
                      
                      <th>Aktivitas Terakhir</th>
                      <th>Modul Terakhir</th>
                  
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(this.state.data).map((item, index) => {
                      return <tr key={index}>
                        <td>{this.state.data[item].name}</td>
                        <td>{date.format(new Date(this.state.data[item].timestamp), 'ddd, DD MMMM YYYY - HH:mm:ss', { locale: dateLocaleId })}</td>
                        <td>{this.resolveActivity(this.state.data[item].activity)}</td>
                        <td>{this.state.data[item].module}</td>
                       
                        <td>{this.state.data[item].description}</td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }

            </div>
          </div>
        </div>
      </div>
    );
  }
}
