import axios from "./Axios";
import Config from "../config";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Document {

  getList = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/document-repositories?box=${opt.box}&page=${opt.page}&limit=${opt.limit}&sort=DESC`;
      if (opt.search && opt.search.length > 0) {
        url += `&search=${opt.search}`;
      }
      axios.get(url, 
      {
        headers: MiscService.generateHeaders()
      }  
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  createDocument = (document) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/document-repositories",
        document,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  deleteDocument = (documentId) => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/document-repository/" + documentId, 
      {
        headers: MiscService.generateHeaders()
      }
      )
      .then((response) => {
        resolve();
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  readDocument = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/document-repository/" + id, 
      {
        headers: MiscService.generateHeaders()
      }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }
}

export default Document;
