import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import './styles.css';
import dateLocaleId from 'date-fns/locale/id';
import Letter from '../../../services/Letter';
import stripTags from '../../../utils'
import date from 'date-fns';
import Spinner from '../../../components/Spinner';
import Constants from '../../../consts';

const LetterService = new Letter();

export default class LetterReadDispositionHistory extends Component {
  state = {
    loading: false,
    dispositionHistories: [],
    actionMap: Constants.actionMap,
  }
  componentDidMount = () => {
    let currentUser = JSON.parse(localStorage.getItem('current_user'));
    this.setState({ loading: true }, () => {
      let tempState = {
        currentLetter: this.props.currentLetter,
        loading: false,
        dispositionHistories: [],
        actionMap: Constants.actionMap,
      }
      LetterService.getDispositions({
        letterId: this.props.currentLetter.id,
        page: 1,
        limit: 999,
        history: true,
        assistantId: currentUser.assistantId && currentUser.assistantId.length > 0 ? currentUser.assistantId : null,
      })
        .then((result) => {
          let data = {};
          for (let i in result.data) {
            let date = new Date(result.data[i].time);
            let dateStr = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}-${date.getHours()}-${date.getMinutes()}`
            if (!data[result.data[i].originator + '-' + dateStr]) {
              data[result.data[i].originator + '-' + dateStr] = {
                id: result.data[i].id,
                originator: result.data[i].originator,
                originatorName: result.data[i].originatorName,
                time: result.data[i].time,
                recipients: [],
              }
            }
            if (result.data[i].notes && result.data[i].notes.length > 0) {
              data[result.data[i].originator + '-' + dateStr].notes = result.data[i].notes;
            }
            if (result.data[i].actions && result.data[i].actions.length > 0) {
              data[result.data[i].originator + '-' + dateStr].actions = result.data[i].actions;
            }
            data[result.data[i].originator + '-' + dateStr].recipients.push(result.data[i].recipients[0].split('|')[1]);
          }
          let dispositionHistories = [];
          for (let key in data) {
            dispositionHistories.push(data[key]);
          }
          tempState = { ...tempState, ...{ loading: false, dispositionHistories: dispositionHistories } }
          return Promise.resolve(tempState)
        })
        .then((tempState) => {
          tempState = { ...tempState, ...{ loading: false } }
          console.log("FINAL SET HISTORY AND REFERENCING STATE", tempState)
          this.setState(tempState);
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false, dispositionHistories: [] });
        });
    });
  }
  render() {
    return (
      <div>
        <div>
          <div style={{ display: 'inline-block', verticalAlign: 'top', margin: 10, marginLeft: 0 }}>
            <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={this.props.back} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'inline-block' }}>
            <h4>Riwayat Disposisi</h4>
            <span> Nomor surat : {this.props.currentLetter.number} <br /> {stripTags(this.props.currentLetter.subject)}</span>
          </div>
        </div>
        {this.state.loading && <Spinner />}
        {!this.state.loading && (!this.state.dispositionHistories || (this.state.dispositionHistories && this.state.dispositionHistories.length === 0)) &&
          <div className="alert alert-warning" style={{ marginTop: 15 }}><Fa.FaInfo />&nbsp;Belum ada disposisi.</div>
        }
        {!this.state.loading && this.state.dispositionHistories && this.state.dispositionHistories.length > 0 &&
          <div className="row" style={{ marginTop: 15 }}>
            <div className="col-md-12">
              <div className="box">
                {this.state.dispositionHistories && this.state.dispositionHistories.map((item, index) => {
                  return <div className="box-body" key={index}>
                    <table className="table table-bordered table-read-detail">
                      <thead>
                        <tr className="active">
                          <th colSpan="2">
                            {date.format(item.time, 'DD MMMM YYYY, HH:mm', { locale: dateLocaleId })}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="20%">Dari</td>
                          <td width="80%">
                            {item.originatorName}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Untuk</td>
                          <td width="80%" style={{ paddingBottom: 0 }}>
                            <ul>
                              {item.recipients && item.recipients.map((recipient, recipientIndex) => {
                                return <li key={recipientIndex}>{recipient}</li>
                              })}
                            </ul>
                          </td>
                        </tr>
                        {item.actions && item.actions.length > 0 &&
                          <tr>
                            <td width="20%">Nota tindakan</td>
                            <td width="80%">
                              {item.actions && item.actions.map((action, actionIndex) => {
                                return <span className="alert alert-comment" key={actionIndex} style={{ padding: '5px 10px 5px 10px', marginRight: 10 }}>{this.state.actionMap[action]}</span>
                              })}
                            </td>
                          </tr>
                        }
                        {item.notes && item.notes.length > 0 &&
                          <tr>
                            <td width="20%">Catatan</td>
                            <td width="80%">
                              {item.notes}
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                })}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
