import React, { Component } from 'react';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import Spinner from '../../../../components/Spinner'
import RapatSvc from '../../../../services/Rapat'
import './rapat.css'
import RapatRoom from './RapatRoom';
import stripTags from '../../../../utils'

const RapatService = new RapatSvc()

export default class Rapat extends Component {
  state = {
    title: '',
    started: false,
    startTime: '',
    endTime: '',
    participants: [],
    loading: true,
    joined: false,
    unauthorized: false,
    noSchedule: false,
    notStarted: false,
    alreadyEnded: false,
    generalError: false,
    error: false
  }

  setError(e) {
    if (e.response) {
      if (e.response.data && e.response.data.message) {
        const msg = e.response.data.message

        if (msg === "unauthorized" || msg === "forbidden") {
          this.setState({ unauthorized: true, loading: false, error: true })
        }
        else if (msg === "internal-error") {
          this.setState({ generalError: true, loading: false, error: true })
        }
        else if (msg === "no-schedule") {
          this.setState({ noSchedule: true, loading: false, error: true })
        }
        else if (msg === "not-yet-started") {
          this.setState({ notStarted: true, loading: false, error: true })
        }
        else if (msg === "already-ended") {
          this.setState({ alreadyEnded: true, loading: false, error: true })
        } else {
          this.setState({ generalError: true, loading: false, error: true })
        }
      } else {
        this.setState({ generalError: true, loading: false, error: true })
    
      }
    } else {
      this.setState({ generalError: true, loading: false, error: true })
      
    }
  }

  componentDidMount = () => {
    console.log(this.props)

    if (this.props.match && this.props.match.params && this.props.match.params.uuid) {
      RapatService.status(this.props.match.params.uuid).then((v) => {
        this.setState({
          title: v["title"],
          startTime: new Date(v["start-time"]),
          endTime: new Date(v["end-time"]),
          participants: v["participants"],
          loading: false
        })
      }).catch((e) => {
        this.setError(e)
      })
    }
  }

  exit = () => {
    RapatService.checkOut(this.props.match.params.uuid).then((v) => {
    })
    this.setState({ joined: false })
  }

  join = () => {
    this.setState({ loading: true })

    RapatService.checkIn(this.props.match.params.uuid).then((v) => {
      this.setState({
        loading: false,
        joined: true,
      })
    }).catch((e) => {
      this.setError(e)
    })
  }

  render = () => {
    console.log("Omama")
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Rapat daring: {stripTags(this.state.title)}</h4>

          {this.state.loading && (
            <Spinner />
          )}

          {this.state.loading === false &&
            this.state.generalError === true && (
              <div className="box boxinfo">
                 <div className="alert alert-warning">
                 <h4>Mohon maaf</h4>
                 Ruang rapat tidak dapat dibuka karena alasan teknis. Mohon hubungi Administrator.
                 </div>
              </div>
            )}

{this.state.loading === false &&
            this.state.unauthorized === true && (
              <div className="box boxinfo">
                 <div className="alert alert-warning">
                 <h4>Mohon maaf</h4>
                 Ruang rapat tidak dapat dibuka karena nama Anda tidak tercantum sebagai peserta rapat.
                 </div>
              </div>
            )}

{this.state.loading === false &&
  this.state.notStarted === true && (
    <div className="box boxinfo">
       <div className="alert alert-warning">
       <h4>Mohon maaf</h4>
       Ruang rapat tidak dapat dibuka karena rapat belum dimulai.
       </div>
    </div>
  )}

{this.state.loading === false &&
  this.state.alreadyEnded === true && (
    <div className="box boxinfo">
       <div className="alert alert-warning">
       <h4>Mohon maaf</h4>
       Ruang rapat tidak dapat dibuka karena rapat sudah berakhir.
       </div>
    </div>
  )}

{this.state.loading === false &&
  this.state.noSchedule === true && (
    <div className="box boxinfo">
       <div className="alert alert-warning">
       <h4>Mohon maaf</h4>
       Ruang rapat ini sudah tidak tersedia lagi.
       </div>
    </div>
  )}


          {this.state.loading === false &&
            this.state.joined === true && (
              <div>
                <button className="btn btn-primary" onClick={this.exit.bind(this)}>Keluar</button>
                <RapatRoom id={this.props.match.params.uuid}/>
              </div>
            )}

          {this.state.loading === false &&
            this.state.error === false &&
            this.state.joined === false && (

              <div className="box boxinfo">
                <form className="form-horizontal" style={{ padding: '20px' }}>
                  <div className="form-group row">
                    <label className="col-md-2 col-sm-2 control-label"> Perihal</label>
                    <div className="col-md-10 col-sm-10">{stripTags(this.state.title)}

                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-2 col-sm-2 control-label"> Dimulai pada</label>
                    <div className="col-md-10 col-sm-10">
                      <span style={{ fontSize: 12 }}>{date.format(this.state.startTime, 'dddd, DD/MM/YYYY HH:mm', { locale: dateLocaleId })}</span>

                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-2 col-sm-2 control-label"> Berakhir pada</label>

                    <div className="col-md-10 col-sm-10">
                      <span style={{ fontSize: 12 }}>{date.format(this.state.endTime, 'dddd, DD/MM/YYYY HH:mm', { locale: dateLocaleId })}</span>

                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-2 col-sm-2 control-label">Partisipan:</label>
                    <div className="col-md-10 col-sm-10">
                      <ul>
                        {this.state.participants.map((item) => {
                          if (item['role'] === 'PARTICIPANT' || item['role'] === 'MODERATOR') {
                            return <li key={item['participant-id']}> {item['name']}</li>
                          } else {
                            return <span/>
                          }
                        })}
                      </ul>
                    </div>
                  </div>


                  <div className="form-group row">
                    <label className="col-md-2 col-sm-2 control-label"> </label>

                    <div className="col-md-10 col-sm-10">
                      <button className="btn btn-primary" onClick={this.join.bind(this)}>Masuk</button>
                    </div>
                  </div>

                </form>
              </div>
            )}
        </div>

      </div>
    )
  }
}