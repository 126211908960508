import axios from "./Axios";
import Config from "../config";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Settings {

  getSettings = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/v1/settings?limit=99`
      axios.get(url, 
      {
        headers: MiscService.generateHeaders()
      })
      .then((response) => {
        let obj = {};
        for (var i in response.data.data) {
          obj[response.data.data[i].key] = response.data.data[i].value;
        }
        resolve(obj);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  update = (obj) => {
    let keys = Object.keys(obj);
    let key = keys[0];
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/setting/" + key,
        { key: key, value: obj[key] },
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getWhatsAppInfo=()=>{
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/whatsapp-status",
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

}

export default Settings;
