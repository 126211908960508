import Config from "../config";
import axios from "./Axios";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

const allowedMimeType = [
  'image/png',
  'image/jpeg',
]

class Blob {

  upload = (file) => {
    return new Promise((resolve, reject) => {
      if (file.type === 'application/octet-stream') {
        reject(new Error('Executable/binary is not allowed.'));
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('contentType', file.type);
      formData.append('blobType', file.blobType)
      let headers = { 'Authorization': localStorage.getItem('access_token') }
      if (localStorage.getItem('impersonator_token_id') !== null && localStorage.getItem('impersonator_token_id').length > 0) {
        headers['X-Impersonator-Token-Id'] = localStorage.getItem('impersonator_token_id')
      }
      const options = {
        method: 'POST',
        body: formData,
        headers: headers
      }
      fetch(Config.apiHost + '/api/v1/blob', options)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  uploadUnrestricted = (file) => {
    return new Promise((resolve, reject) => {
      if (!(allowedMimeType.indexOf(file.type) > -1)) {
        reject(new Error('This kind of file is not allowed.'));
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('contentType', file.type);
      let headers = { 'Authorization': localStorage.getItem('access_token') }
      if (localStorage.getItem('impersonator_token_id') !== null && localStorage.getItem('impersonator_token_id').length > 0) {
        headers['X-Impersonator-Token-Id'] = localStorage.getItem('impersonator_token_id')
      }
      const options = {
        method: 'POST',
        body: formData,
        headers: headers
      }
      fetch(Config.apiHost + '/api/v1/blob/unrestricted', options)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  uploadDataURI = (dataURI) => {
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    return new Promise((resolve, reject) => {
      fetch(dataURI)
        .then(res => res.blob())
        .then(blob => {
          const formData = new FormData()
          formData.append('file', blob)
          formData.append('contentType', mimeString);
          let headers = { 'Authorization': localStorage.getItem('access_token') }
          if (localStorage.getItem('impersonator_token_id') !== null && localStorage.getItem('impersonator_token_id').length > 0) {
            headers['X-Impersonator-Token-Id'] = localStorage.getItem('impersonator_token_id')
          }
          const options = {
            method: 'POST',
            body: formData,
            headers: headers
          }
          return fetch(Config.apiHost + '/api/v1/blob/unrestricted', options)
        })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  downloadToDataURI = (url) => {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        responseType: 'arraybuffer',
        headers: MiscService.generateHeaders()
      })
        .then((res) => {
          const datauri = 'data:' + res.headers['content-type'] + ';base64,' + new Buffer(res.data, 'binary').toString('base64');
          resolve({ uri: datauri, type: res.headers['content-type'] });
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        });
    });
  }

  uploadInvitationBlob = (blob) => {
    return new Promise((resolve, reject) => {
      const file = new File([blob], "oa-daftar-undangan.pdf", {type: "application/pdf"})
      this.upload(file).then((lastInsertId) => {
          resolve(lastInsertId)
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })

    })
  }
}

export default Blob;
