import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Letter from '../../../../../services/Letter';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import stripTags from '../../../../../utils'
import '../styles.css';
import config from '../../../../../config';
import ReactTooltip from 'react-tooltip';

const LetterService = new Letter();

export default class Disposition extends Component {
  state = {
    currentUser: {},
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    searchString: '',
    loading: false,
  }

  componentDidMount = () => {
    var currentUser = JSON.parse(localStorage.getItem('current_user'));
    this.setState({ currentUser: currentUser });
    window.store.dispatch(window.actions.showPageTitle(true));
    this.setState({ loading: true }, () => {
      LetterService.getDispositions({
        page: 1,
        limit: 10, search: this.state.searchString,
        recipientOrgId: this.state.currentUser.organization.id,
        recipientPltId: this.state.currentUser.pltId,
        recipientDelegatedId: this.state.currentUser.delegatedId,
        recipientUuid: this.state.currentUser.id,
        listType: "INBOX",
        throughAssistant: config.dispositionThroughAssistant
      })
        .then((result) => {
          let resultData = result.data
          let dataWithPriority = []
          let dataWithoutPriority = []
          resultData.forEach(data => {
            if (data.deadline.indexOf('0001-01') < 0 && data.statusIsClosed === false)
              dataWithPriority[dataWithPriority.length] = data
            else
              dataWithoutPriority[dataWithoutPriority.length] = data
          })
          dataWithoutPriority.forEach(data => {
            data.deadline = null
          })
          dataWithPriority.sort((a, b) => {
            let deadlineA = (new Date(a.deadline)).valueOf()
            let deadlineB = (new Date(b.deadline)).valueOf()
            return deadlineA - deadlineB
          })
          dataWithPriority.push(...dataWithoutPriority)
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: dataWithPriority || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  page = (page) => {
    this.setState({ loading: true, searchString: '' });
    let opt = {
      page: page,
      limit: this.state.limit,
      search: this.state.searchString,
      recipientOrgId: this.state.currentUser.organization.id,
      recipientPltId: this.state.currentUser.pltId,
      recipientDelegatedId: this.state.currentUser.delegatedId,
      recipientUuid: this.state.currentUser.id,
      listType: "INBOX",
    }
    LetterService.getDispositions(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = {
      page: this.state.page + 1,
      limit: this.state.limit,
      search: this.state.searchString,
      recipientOrgId: this.state.currentUser.organization.id,
      recipientPltId: this.state.currentUser.pltId,
      recipientDelegatedId: this.state.currentUser.delegatedId,
      recipientUuid: this.state.currentUser.id,
      listType: "INBOX",
    }
    LetterService.getDispositions(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = {
      page: this.state.page - 1,
      limit: this.state.limit,
      search: this.state.searchString,
      recipientOrgId: this.state.currentUser.organization.id,
      recipientPltId: this.state.currentUser.pltId,
      recipientDelegatedId: this.state.currentUser.delegatedId,
      recipientUuid: this.state.currentUser.id,
      listType: "INBOX",
    }
    LetterService.getDispositions(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  reload = (options) => {
    options = options || {}
    this.setState({ loading: true });
    let opt = {
      page: this.state.page,
      limit: this.state.limit,
      search: this.state.searchString,
      recipientOrgId: this.state.currentUser.organization.id,
      recipientPltId: this.state.currentUser.pltId,
      recipientDelegatedId: this.state.currentUser.delegatedId,
      recipientUuid: this.state.currentUser.id,
      listType: "INBOX",
      markAllAsRead: options.markAllAsRead
    }
    LetterService.getDispositions(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  read = (id) => {
    console.log(id);
    window.browserHistory.push('/read/' + id);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Kotak Masuk Disposisi</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <div className="input-group input-group-sm">
                    <span className="input-group-btn">
                      <span className="btn btn-success">
                        <Fa.FaRefresh />
                      </span>
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      onChange={this.handleSearchString}
                      value={this.state.searchString}
                      onKeyPress={(e) => { if (e.key === 'Enter') this.search() }}
                    />
                    {this.state.searchString && this.state.searchString.length > 0 &&
                      <span className="input-group-btn">
                        <span className="btn btn-default" onClick={this.dismissSearch}>
                          <Fa.FaClose />
                        </span>
                      </span>
                    }
                    <span className="input-group-btn">
                      <span className="btn btn-default" onClick={this.search}>
                        <Fa.FaSearch />
                      </span>
                    </span>
                    <span className="input-group-btn">
                      <span className="btn btn-default" onClick={() => { this.reload({ markAllAsRead: true }) }}>
                        Tandai semua sebagai sudah dibaca
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div style={{ float: 'right' }}>
                    <Pagination
                      disabled={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prev} next={this.next}
                      toPage={this.page} />
                  </div>
                </div>
                <hr />
              </div>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>No. Surat</th>
                      <th>Dari</th>
                      <th>Perihal</th>
                      <th>Tanggal Disposisi</th>
                      <th>Tenggat waktu</th>
                      <th>Prioritas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && Object.keys(this.state.data).map((item, index) => {
                      let deadlineTimeLeft = ((new Date(this.state.data[item].deadline)).valueOf() - (new Date()).valueOf()) / 1000 
                      let backgroundColor = this.state.data[item].deadline === null || this.state.data[item].deadline.statusIsClosed ? "#ffffff" 
                      : deadlineTimeLeft < 172800 ? "#ffe2e2" 
                      : deadlineTimeLeft < 432000 ? "#ffe0b0" : "#ebf2f9"
                      return <tr key={index} onClick={() => { this.read(this.state.data[item].letterId) }} style={{ cursor: 'pointer', backgroundColor , fontWeight: this.state.data[item].isUnread ? 'bold' : 'normal' }}>
                        <td>{this.state.data[item].letterRefNumber}</td>
                        <td>{stripTags(this.state.data[item].letterSigner)}</td>
                        <td>{stripTags(this.state.data[item].letterSubject)}</td>
                        <td>
                          {date.format(this.state.data[item].time, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>
                        {this.state.data[item].originator !== this.state.currentUser.id && <td>
                          {this.state.data[item].deadline !== null &&  this.state.data[item].deadline.indexOf('0001-01') < 0 && date.format(this.state.data[item].deadline, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>}
                        {this.state.data[item].originator !== this.state.currentUser.id && <td>
                          {this.state.data[item].deadline !== null && this.state.data[item].statusIsClosed === false && <span>
                            {deadlineTimeLeft< 172800 ?
                              <span className="alert alert-danger">Tinggi</span> :
                              deadlineTimeLeft< 432000 ?
                                <span className="alert alert-warning">Sedang</span> :
                                deadlineTimeLeft>= 432000 &&
                                <span className="alert alert-primary">Rendah</span>}
                          </span>}
                        </td>}
                        <td style={{ textAlign: 'center' }}>
                          {this.state.data[item].statusIsClosed && 
                            <span>
                              <Fa.FaClose data-tip data-for="closed" style={{ marginLeft: 5 }} />
                              <ReactTooltip id="closed" place="top" effect="solid" multiline={true}>
                                Disposisi telah ditutup
                              </ReactTooltip>
                            </span>
                          }
                          {this.state.data[item].labels && this.state.data[item].labels.indexOf('DISPOSITIONED') > -1 && 
                            <span>
                              <Fa.FaShareAlt data-tip data-for="disposition" style={{ marginLeft: 5 }} />
                              <ReactTooltip id="disposition" place="top" effect="solid" multiline={true}>
                                Surat telah<br/>
                                didisposisi
                              </ReactTooltip>
                            </span>
                          }
                          {this.state.data[item].labels && this.state.data[item].labels.indexOf('PROCESS') > -1 && 
                            <span>
                              <Fa.FaHourglass data-tip data-for="process" style={{ marginLeft: 5 }} />
                              <ReactTooltip id="process" place="top" effect="solid" multiline={true}>
                                Surat dalam<br/>
                                proses
                              </ReactTooltip>
                            </span>
                          }
                          {this.state.data[item].labels && this.state.data[item].labels.indexOf('FINAL') > -1 && 
                            <span>
                              <Fa.FaEdit data-tip data-for="final" style={{ marginLeft: 5 }} />
                              <ReactTooltip id="final" place="top" effect="solid" multiline={true}>
                                Surat dalam<br/>
                                finalisasi
                              </ReactTooltip>
                            </span>
                          }
                          {this.state.data[item].labels && this.state.data[item].labels.indexOf('REFERENCED') > -1 && this.state.data[item].labels.indexOf('PROCESS') < 0 && 
                            <span>
                              <Fa.FaCheck data-tip data-for="reference" style={{ marginLeft: 5 }} />
                              <ReactTooltip id='reference' place="top" effect="solid" multiline={true}>
                                Surat telah<br/>
                                terkirim
                              </ReactTooltip>
                            </span>
                          }
                            {this.state.data[item].isCurrentUserAPelaksana && 
                            <span>
                              <Fa.FaUser style={{ marginLeft: 5 }} />
                            </span>
                          }
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <span style={{ fontSize: 32 }}>
                    <Fa.FaInbox />
                  </span>
                  <br />
                  <h4>Tidak ada disposisi</h4>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
