import React, { Component } from 'react';
import LetterFormTextInput from './LetterFormTextInput';
import LetterFormSelectInput from './LetterFormSelectInput';
import LetterFormPerProcurationem from './LetterFormPerProcurationem';

import * as Fa from 'react-icons/lib/fa';
import DateTimePicker from '../../../components/DateTimePicker';
import User from '../../../../../services/User';
import Cache from '../../../../../services/Cache';
import Letter from '../../../../../services/Letter';
import Unit from '../../../../../services/Unit';

import Spinner from '../../../../../components/Spinner';
import async from 'async';

const UserService = new User();
const CacheService = new Cache();
const LetterService = new Letter()
const UnitService = new Unit()

export default class component extends Component {
  state = {
    signers: [],
    signersError: false,
    letterHeaders: [],
    defaultLetterHeader: '',
  }
  componentDidMount = () => {
    let e = {
      target: {
        name: 'formAtributTabClicked',
        value: true,
      }
    }
    this.props.inputEventHandler(e);

    // Fetch letterHeader
    CacheService.getCacheDataFromStorage('letter-header')
      .then((data) => {
        let arr = []
        let defaultLetterHeaderId = ""
        for (let i in data) {
          if (data[i].isDefault) {
            defaultLetterHeaderId = data[i].id

            arr.push({ value: data[i].id, str: data[i].name });
          }
        }
        for (let i in data) {
          if (!data[i].isDefault) {
            arr.push({ value: data[i].id, str: data[i].name });
          }
        }
        const puaID = this.props.parentState.signers.raw[0]
        if (puaID) {
          LetterService.getLetterHeaderForPUA(puaID).then((result) => {

            if (result && result.data && result.data.letterHeaderID) {
              defaultLetterHeaderId = result.data.letterHeaderID
            }

            this.setState({
              letterHeaderLoading: false,
              letterHeaders: arr
            })

            let e = {
              target: {
                name: 'letterHeaderId',
                value: defaultLetterHeaderId,
              }
            }
            this.props.inputEventHandler(e);
          })
        } else {
          this.setState({
            letterHeaderLoading: false,
            letterHeaders: arr
          })
        }


      })
      .catch((err) => {
        this.setState({ letterHeaderLoading: false });
      });

    // Fetch signers data
    this.setState({ loading: true }, () => {
      async.each(this.props.parentState.signers.raw, (signerUuid, cb) => {
        UserService.get(signerUuid)
          .then((signer) => {
            let signers = this.state.signers;
            signers.push(signer);
            if (signer.organization.plt && signer.organization.plt.length > 0) {
              let e = {
                target: {
                  name: 'pelaksana',
                  value: 'TUGAS',
                }
              }
              this.props.inputEventHandler(e);
            } else if (signer.organization.delegated && signer.organization.delegated.length > 0) {
              let e = {
                target: {
                  name: 'pelaksana',
                  value: 'HARIAN',
                }
              }
              this.props.inputEventHandler(e);
            }
            if (signer.organization.city && signer.organization.city.length > 0 && this.props.letterType === 'TNDE_SURAT_TUGAS') {
              let e = {
                target: {
                  name: 'city',
                  value: signer.organization.city,
                }
              }
              this.props.inputEventHandler(e);
            }
            let signerName = signer.name;
            if (signer.nip && signer.nip.length > 0) {
              signerName += '|' + signer.nip;
            }
            if (signer.organization.plt && signer.organization.plt.length > 0) {
              signerName = signer.organization.pltName;
              if (signer.organization.pltNip && signer.organization.pltNip.length > 0) {
                signerName += '|' + signer.organization.pltNip;
              }
            }
            if (signer.organization.delegated && signer.organization.delegated.length > 0) {
              signerName = signer.organization.delegatedName;
              if (signer.organization.delegatedNip && signer.organization.delegatedNip.length > 0) {
                signerName += '|' + signer.organization.delegatedNip;
              }
            }
            if (this.props.letterType === 'TNDE_MEMO') {
              signerName = this.state.currentUser.name + '|' + this.state.currentUser.nip;
            }
            let e = {
              target: {
                name: 'signerNames',
                value: [signerName],
              }
            }
            this.props.inputEventHandler(e);
            this.setState({ signers: signers }, () => {
              UnitService.findUnit(signerUuid)
                .then((result) => {
                  if (result && result.unitId && result.unitId.length > 0) {
                    let e = {
                      target: {
                        name: 'unit',
                        value: result.unitId,
                      }
                    }
                    this.props.inputEventHandler(e);
                  }
                  cb();
                })
                .catch((err) => {
                  cb(err)
                })
            });
          })
          .catch((err) => {
            console.log(err);
            cb(err);
          });
      }, (err) => {
        if (err) {
          console.log(err);
          this.setState({ signersError: true });
        }
        this.setState({ loading: false });
        console.log(this.state.signers);
      });
    });
  }

  render() {

    const { inputEventHandler } = this.props;

    return (
      <form className="form-horizontal" style={{ paddingTop: 10 }}>

        <div>
          <div className="box box-primary box-solid" style={{ marginTop: 15 }}>
            <div
              className="box-header with-border"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.props.setParentState({
                  attributeContainerCollapse: !this.props.parentState.attributeContainerCollapse
                });
              }}
            >
              <h4 className="box-title">
                Detail Atribut Surat
            </h4>
              <div className="box-tools pull-right pointer">
                {this.props.parentState.attributeContainerCollapse ?
                  <Fa.FaChevronDown size={18} />
                  :
                  <Fa.FaChevronUp size={18} />
                }
              </div>
            </div>
            <div
              className={'body-body ' + (this.props.parentState.attributeContainerCollapse && 'collapse')}
            >
              {this.state.letterHeaderLoading ? <Spinner /> : 
              this.props.parentState.letterType !== 'TNDE_TELAAHAN_STAF' &&
                <LetterFormSelectInput
                  name="letterHeaderId"
                  value={this.props.parentState.letterHeaderId}
                  label="Templat kop surat"
                  labelWidth="2"
                  fieldWidth="8"
                  onChange={inputEventHandler}
                  options={this.state.letterHeaders}
                />
              }
              <LetterFormTextInput
                name="customAttachment"
                value={this.props.parentState.customAttachment.length > 0 ? this.props.parentState.customAttachment :
                  (this.props.parentState.files.length === 0 ? "" : 
                  this.props.parentState.files.filter(val => !val.isInternalAttachment).length + ' berkas')}
                label="Lampiran"
                labelWidth="2"
                fieldWidth="8"
                onChange={inputEventHandler}
                readonly={false}
              />
              <LetterFormSelectInput
                name="signatureType"
                value={this.props.parentState.signatureType}
                label="Tanda tangan"
                labelWidth="2"
                fieldWidth="8"
                onChange={inputEventHandler}
                options={this.props.parentState.letterType === 'TNDE_MEMO' ?
                  [{ value: "SCANNED", str: "Tanda tangan scan" }] :
                  [
                    { value: "HANDWRITTEN", str: "Tanda tangan basah" },
                    { value: "SCANNED", str: "Tanda tangan scan" }
                  ]
                }
                defaultValue="HANDWRITTEN"
              />
              <DateTimePicker
                name="deadline"
                value={this.props.parentState.deadline}
                label="Tenggat Waktu"
                required={false}
                onChange={inputEventHandler}
                labelWidth="2"
                fieldWidth="8"
              />
              <LetterFormSelectInput
                name="importance"
                value={this.props.parentState.importance}
                label="Sifat"
                labelWidth="2"
                fieldWidth="8"
                onChange={inputEventHandler}
                options={[
                  { value: "NORMAL", str: "Biasa" },
                  { value: "LIMITED", str: "Terbatas" },
                  { value: "SECRET", str: "Rahasia" },
                  { value: "TOP_SECRET", str: "Sangat Rahasia" },
                  { value: "IMPORTANT", str: "Segera" },
                  { value: "VERY_IMPORTANT", str: "Sangat Segera" }
                ]}
                defaultValue="NORMAL"
              />
              <LetterFormTextInput
                name="year"
                value={this.props.parentState.year}
                label="Tahun"
                labelWidth="2"
                fieldWidth="8"
                onChange={inputEventHandler}
                readonly={true}
              />
              <LetterFormTextInput
                name="letterId"
                value={this.props.parentState.letterId}
                label="Nomor Surat"
                labelWidth="2"
                fieldWidth="8"
                onChange={inputEventHandler}
                readonly={true}
              />
            </div>
          </div>
          {this.props.letterType !== 'TNDE_MEMO' &&
            <div className="box box-primary box-solid" style={{ marginTop: 15 }}>
              <div
                className="box-header with-border"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.props.setParentState({
                    signerContainerCollapse: !this.props.parentState.signerContainerCollapse
                  })
                }}
              >
                <h4 className="box-title">
                  Data Penanda Tangan Surat
            </h4>
                <div className="box-tools pull-right pointer">
                  {this.props.parentState.signerContainerCollapse ?
                    <Fa.FaChevronDown size={18} />
                    :
                    <Fa.FaChevronUp size={18} />
                  }
                </div>
              </div>
              <div className={'body-body ' + (this.props.parentState.signerContainerCollapse && 'collapse')}>
                {!this.state.loading &&
                  (this.state.signers && this.state.signers.length < 1) &&
                  !this.state.signersError &&
                  <div></div>
                }
                {!this.state.loading &&
                  this.state.signers &&
                  this.state.signers.length < 1 &&
                  this.state.signersError &&
                  <div style={{ margin: 15 }}>Gagal mengambil data penanda tangan.</div>
                }
                {this.state.loading ? <Spinner /> :
                  (this.state.signers && this.state.signers.length > 0 &&
                    <div className={'body-body attribute-signer-container'}>
                      <LetterFormPerProcurationem
                        name="perProcurationem"
                        signer={this.state.signers[this.state.signers.length - 1].organization.id}
                        value={this.props.parentState.perProcurationem}
                        label="Atas Nama"
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                      />
                    </div>

                  )}
              </div>
              <div className={'body-body ' + (this.props.parentState.signerContainerCollapse && 'collapse')}>
                {!this.state.loading &&
                  this.state.signers &&
                  this.state.signers.length < 1 &&
                  !this.state.signersError &&
                  <div style={{ margin: 15 }}>Belum ada penanda tangan yang dipilih.</div>
                }
                {!this.state.loading &&
                  this.state.signers &&
                  this.state.signers.length < 1 &&
                  this.state.signersError &&
                  <div style={{ margin: 15 }}>Gagal mengambil data penanda tangan.</div>
                }
                {this.state.loading ? <Spinner /> :
                  (this.state.signers && Object.keys(this.state.signers).map((i, index) => {
                    return <div className={'body-body attribute-signer-container'} key={index}>
                      <LetterFormTextInput
                        name=""
                        value={this.state.signers[i].organization.kodeUnit}
                        label="Kode Direksi/SM"
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                        required={true}
                        disabled={true}
                      />
                      <LetterFormTextInput
                        name=""
                        value={
                          this.state.signers[i].organization.pltName ?
                            this.state.signers[i].organization.pltName + ' (Pelaksana Tugas)' : (this.state.signers[i].organization.delegatedName ?
                              this.state.signers[i].organization.delegatedName + ' (Pelaksana Harian)' : this.state.signers[i].name)
                        }
                        label="Nama Pejabat"
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                        required={true}
                        disabled={true}
                      />
                      <LetterFormTextInput
                        name=""
                        value={
                          this.state.signers[i].organization.pltNip && this.state.signers[i].organization.pltNip.length > 0 ? this.state.signers[i].organization.pltNip : (this.state.signers[i].organization.delegatedNip && this.state.signers[i].organization.delegatedNip.length > 0 ? this.state.signers[i].organization.delegatedNip : this.state.signers[i].nip)
                        }
                        label="NIP"
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                        required={true}
                        disabled={true}
                      />
                      <LetterFormTextInput
                        name=""
                        value={this.state.signers[i].organization.direktorat}
                        label="Direktorat"
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                        required={true}
                        disabled={true}
                      />
                      <LetterFormTextInput
                        name=""
                        value={this.state.signers[i].organization.name}
                        label="Nama Jabatan"
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                        disabled={true}
                      />
                      <LetterFormTextInput
                        name=""
                        value={this.state.signers[i].organization.kodeJabatan}
                        label="Kode Jabatan"
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                        disabled={true}
                      />

                      <LetterFormTextInput
                        name=""
                        label="Lokasi Kedudukan"
                        value={this.state.signers[i].organization.location}
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                        readonly={true}
                      />
                      <LetterFormTextInput
                        name=""
                        label="Kode Unit"
                        value={this.state.signers[i].organization.kodeUnit}
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                        readonly={true}
                      />
                      <LetterFormTextInput
                        name=""
                        value={this.state.signers[i].organization.city}
                        label="Kota"
                        labelWidth="2"
                        fieldWidth="8"
                        onChange={inputEventHandler}
                        readonly={true}
                      />
                    </div>
                  })
                  )}
              </div>
            </div>
          }
        </div>
      </form>
    );
  }
}
