import React, { Component } from 'react';
import User from '../../../../services/User';
import Chat from '../../../../services/Chat';
import Spinner from '../../../../components/Spinner';
import ChatConversation from './ChatConversation';
import { toggleMode, visualModeEnum } from './ChatCommon';

import notificationSound from '../../../../default/sounds/eventually.ogg';

const ChatService = new Chat();
const UserService = new User();
export default class ChatList extends Component {

  constructor(props) {
    super(props);
    window.WSService.sub("chatNotification", (id) => {
      id = id.split(':')[1];
      this.handleNotification(id);
    });
  }

  handleNotification = (id) => {
    let found = false;
    for (let item of this.state.activeChats) {;
      found = item.id === id;
      if (found) break;
    }
    if (!found) {
      UserService.get(id)
        .then(value => {
          this.newConversation({
            id,
            name: value.name
          });
          return new Promise((resolve) => {
            setInterval(() => {
              if (this.state.chatMaps[id].state.messages.length > 0) {
                resolve(this.state.chatMaps[id].state);
              }
            }, 500);
          });
        })
        .then(res => {
          const { messages, title } = res;
          this.notificationPopup(title, messages[messages.length - 1]["message-contents"]);
        })
    } else {
      this.state.chatMaps[id].loadMessages()
        .then(res => {
          const chatMapState = this.state.chatMaps[id].state;
          const lastMessage = res[res.length - 1];
          this.notificationPopup(chatMapState.title, lastMessage["message-contents"]);
        });
    }
  }

  notificationPopup = (name, message) => {
    Notification.requestPermission().then((result) => {
      if (result) {
        new Audio(notificationSound).play();
        new Notification(name, { body: message });
      }
    });
  }
  state = {
    people: [],
    loading: true,
    visualMode: visualModeEnum.COLLAPSED,
    activeChats: [],
    searchString: '',
    chatMaps: {}
  }

  onWindowClick = () => {
    this.setState({ visualMode: toggleMode(this.state.visualMode) });
  }

  loadPeople = () => {
    var searchString = '';
    if (this.state.searchString.length > 3) {
      searchString = '/' + this.state.searchString;
    }
    return ChatService.listPeople(searchString).then( data => {
      this.setState({ people:data.people, loading: false });
    })
  }


  onChangeSearchString = (event) => {
    var searchString = event.target.value;

    if (searchString && searchString.length > 3) {
      this.setState({ searchString: searchString }, () => {
        this.loadPeople();
        this.lastSearchLength = searchString.length;
      })
    } else {
      if (this.lastSearchLength === 4 && searchString.length === 3) {
        this.lastSearchLength = 0;
        this.setState({ searchString }, async () => {
          await this.loadPeople();
          const {unreadConv, people} = this.state
          if(unreadConv && unreadConv.length > 0){
            unreadConv.forEach((unread) => {
              for(let i in people){
                if(people[i].id === unread.id){
                  people.splice(i,1)
                  break;
                }
              }
            })
            people.unshift(...unreadConv)
          }
        });
      } else {
        this.setState({ searchString: searchString });
      }

    }
  }

  getOnlineUsers = () => {
    ChatService.getOnlineUsers()
    .then(res => {
      const ids = res["user-ids"];
      let people = this.state.people;
      let onlineUsers = []
      ids.forEach(id => {
        for(let i in people){
          if(people[i].id === id){
            let person = people.splice(i,1)[0]
            person.online = true
            onlineUsers.push(person)
            break;
          }
        }
      });
      onlineUsers.sort((a, b) => {
        return a.name < b.name ? -1 :1;
      })
      people.unshift(...onlineUsers)
      this.setState({people});
    })
    .catch(console.error)
  }

  loadUnreadMessages = () => (
    ChatService.getUnreadMessages()
      .then(res => {
        let totalUnreads = 0;
        let unreadConv = []
        let people = this.state.people 
        for(let uuid in res){
            for(let i in people){
            if(people[i].id === uuid){
              totalUnreads += res[uuid]
              let conv = people.splice(i,1)[0]
              conv.unread = res[uuid]
              unreadConv.push(conv)
              break;
            }
          }
        }
        unreadConv.sort((a, b) => {
          return a.name < b.name ? -1 :1;
        })
        people.unshift(...unreadConv)
        totalUnreads = totalUnreads === 0 ? undefined : totalUnreads
        this.setState({totalUnreads, people, unreadConv})
      })
      .catch(console.error)
    )
  componentDidMount = async () => {
    this.lastSearchLength = 0;
    await this.loadPeople();
    await this.loadUnreadMessages();
    ChatService.checkIn();
    this.getOnlineUsers();
    this.onlineStatusInterval = setInterval(() => {
      ChatService.checkIn();
      this.getOnlineUsers();
    },1000 * 60);
  }

  onChildExit = (idx) => {
    var activeChats = this.state.activeChats;
    activeChats.splice(idx, 1);
    var i = 0;
    for (var conversation of activeChats) {
      conversation.idx = i++;
    }
    this.setState({ activeChats: activeChats });
  }

  newConversation = (item) => {
    if (!item) { return }
    for (var conversation of this.state.activeChats) {
      if (conversation.id === item.id) {
        return;
      }
    }
    var activeChats = this.state.activeChats;
    item.idx = activeChats.length;
    activeChats.push(item);
    this.setState({ activeChats: activeChats });
    let index = this.state.people.findIndex((people) => {
      return people.id === item.id
    })
    let totalUnreads = this.state.totalUnreads ? this.state.totalUnreads : 0
    let unreads = this.state.people[index].unread ? this.state.people[index].unread : 0
    totalUnreads = totalUnreads-unreads === 0 ? undefined : totalUnreads-unreads
    this.setState({totalUnreads})
    this.state.people[index].unread = undefined
  }

  componentWillUnmount = () => {
    clearInterval(this.onlineStatusInterval)
  }
  render() {
    return (
      <div className="chat-list">
        {this.state.activeChats.map((item, index) => {
          return (<ChatConversation key={index} chatMaps={this.state.chatMaps} value={item} onChildExit={this.onChildExit} />);
        })}
        <div className={`chat-list-window ${this.state.visualMode}`}>
          <div onClick={this.onWindowClick.bind(this)} className="chat-title-box" >Obrolan <span className="unread-count" style={{lineHeight: '2em', margin: '0.5em'}}>{this.state.totalUnreads}</span></div>
          <div className="chat-search-box">
            <input onChange={this.onChangeSearchString.bind(this)} type="text" value={this.state.searchString} placeholder="Cari..."></input>
          </div>

          <div className="chat-list-entries">
            {this.state.loading === true && (<div className="data-loading"><Spinner /></div>)}
            {this.state.people.map((item, pIndex) => {
              return (
              <li key={""+pIndex + item.online+ item.unread} onClick={() => { this.newConversation(item) }}><span className={item.online ? "chat-item-online":"chat-item"}>&#x25c9;</span> {item.name}<span className="unread-count">{item.unread}</span></li>
              );
            })}
            {this.state.searchString === '' && (
              <span className="chat-item-hint">Jika Anda tidak menemukan nama orang yang ingin Anda ajak bicara, silakan ketikkan namanya kotak pencarian di atas.</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
