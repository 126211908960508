// Libs
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import GA from "./GoogleAnalytics";

import Config from './config'

// Assets
import './scenes/AdminShell/styles.css';
import './scenes/AdminShell/chat.css';

// Pages, components
import Header from './scenes/AdminShell/components/Header';
import Sidebar from './scenes/AdminShell/components/Sidebar';

import Dashboard from './scenes/AdminShell/components/Dashboard';
import DashboardHelpdesk from './scenes/AdminShell/components/DashboardHelpdesk'
import UserProfile from './scenes/AdminShell/components/UserProfile';

// Compose
import Compose from './scenes/AdminShell/components/Compose';

import LetterRead from './scenes/AdminShell/components/LetterRead';
import LetterReadRedirectHelper from './scenes/AdminShell/components/LetterReadRedirectHelper';

// Inbox
import InboxAll from './scenes/AdminShell/components/Inbox/components/InboxAll';
import InboxNotaDinas from './scenes/AdminShell/components/Inbox/components/InboxNotaDinas';
import InboxSuratTugas from './scenes/AdminShell/components/Inbox/components/InboxSuratTugas';
import InboxSuratUndangan from './scenes/AdminShell/components/Inbox/components/InboxSuratUndangan';
import InboxSurat from './scenes/AdminShell/components/Inbox/components/InboxSurat';
import InboxMemo from './scenes/AdminShell/components/Inbox/components/InboxMemo';
import InboxOA2OA from './scenes/AdminShell/components/Inbox/components/InboxOA2OA';
import InboxDisposition from './scenes/AdminShell/components/Inbox/components/InboxDisposition';
import InboxTanggapan from './scenes/AdminShell/components/Inbox/components/InboxTanggapan';
import InboxLaporan from './scenes/AdminShell/components/Inbox/components/InboxLaporan';
import InboxSuratKeterangan from './scenes/AdminShell/components/Inbox/components/InboxSuratKeterangan';
import InboxLembarRalat from './scenes/AdminShell/components/Inbox/components/InboxLembarRalat';
import InboxPengumuman from './scenes/AdminShell/components/Inbox/components/InboxPengumuman';
import InboxTelaahanStaf from './scenes/AdminShell/components/Inbox/components/InboxTelaahanStaf';

// Outbox
import OutboxAll from './scenes/AdminShell/components/Outbox/components/OutboxAll';
import OutboxNotaDinas from './scenes/AdminShell/components/Outbox/components/OutboxNotaDinas';
import OutboxSuratTugas from './scenes/AdminShell/components/Outbox/components/OutboxSuratTugas';
import OutboxSuratUndangan from './scenes/AdminShell/components/Outbox/components/OutboxSuratUndangan';
import OutboxSurat from './scenes/AdminShell/components/Outbox/components/OutboxSurat';
import OutboxMemo from './scenes/AdminShell/components/Outbox/components/OutboxMemo';
import OutboxDisposition from './scenes/AdminShell/components/Outbox/components/OutboxDisposition';
import OutboxCanceled from './scenes/AdminShell/components/Outbox/components/OutboxCanceled';
import OutboxManual from './scenes/AdminShell/components/Outbox/components/OutboxManual';
import OutboxLaporan from './scenes/AdminShell/components/Outbox/components/OutboxLaporan';
import OutboxSuratKeterangan from './scenes/AdminShell/components/Outbox/components/OutboxSuratKeterangan';
import OutboxLembarRalat from './scenes/AdminShell/components/Outbox/components/OutboxLembarRalat';
import OutboxPengumuman from './scenes/AdminShell/components/Outbox/components/OutboxPengumuman';
import OutboxTelaahanStaf from './scenes/AdminShell/components/Outbox/components/OutboxTelaahanStaf';

// Disposition
import DispositionBox from './scenes/AdminShell/components/DispositionBox';

// Processing
import ProcessingNeedApproval from './scenes/AdminShell/components/Processing/components/ProcessingNeedApproval';
import ProcessingNeedOpinion from './scenes/AdminShell/components/Processing/components/ProcessingNeedOpinion';
import ProcessingStatus from './scenes/AdminShell/components/Processing/components/ProcessingStatus';
import ProcessingRequester from './scenes/AdminShell/components/Processing/components/ProcessingRequester';
import ProcessingDraft from './scenes/AdminShell/components/Processing/components/ProcessingDraft';
import ProcessingFinalization from './scenes/AdminShell/components/Processing/components/ProcessingFinalization';
import ProcessingFinalized from './scenes/AdminShell/components/Processing/components/ProcessingFinalized';

// Archive
import ArchiveExternal from './scenes/AdminShell/components/Archive/components/ArchiveExternal';
import ArchiveInternal from './scenes/AdminShell/components/Archive/components/ArchiveInternal';
import ArchiveDisposition from './scenes/AdminShell/components/Archive/components/ArchiveDisposition';
import ManualLetterArchive from './scenes/AdminShell/components/ManualLetterArchive';

// Tools
import Delegation from './scenes/AdminShell/components/Tools/components/Delegation';
import Assistant from './scenes/AdminShell/components/Tools/components/Assistant';
import AgendaReport from './scenes/AdminShell/components/Tools/components/AgendaReport';

import Rapat from './scenes/AdminShell/components/Rapat/Rapat';

import HelpdeskPage from './scenes/AdminShell/components/HelpdeskPage';

// Settings, acessible by ADMIN users only
import Users from './scenes/AdminShell/components/Users';
import UserGroups from './scenes/AdminShell/components/UserGroups';
import UserEdit from './scenes/AdminShell/components/UserEdit';
import Orgs from './scenes/AdminShell/components/Organizations';
import Classification from './scenes/AdminShell/components/Classification';
import LetterTemplate from './scenes/AdminShell/components/LetterTemplate';
import SatkerSetting from './scenes/AdminShell/components/SatkerSetting';
import ExternalLetterRecipientSetting from './scenes/AdminShell/components/ExternalLetterRecipientSetting';
import LetterHeader from './scenes/AdminShell/components/LetterHeaders';
import MonitorLog from './scenes/AdminShell/components/MonitorLog';
import MonitorWhatsapp from './scenes/AdminShell/components/MonitorWhatsapp';

import Units from './scenes/AdminShell/components/Units';
import UnitEdit from './scenes/AdminShell/components/UnitEdit';
import DispositionActions from './scenes/AdminShell/components/DispositionActions';
import DispositionActionEdit from './scenes/AdminShell/components/DispositionActionEdit';

// Scenes, components
import Login from './scenes/Login';
import ResetPassword from './scenes/ResetPassword';
import ResetPIN from './scenes/ResetPIN';
import Help from './scenes/Help';

// Chat
import ChatList from './scenes/AdminShell/components/Chat/ChatList';

// Presences
import MonitorPresence from './scenes/AdminShell/components/presences/MonitorPresence';
import Presence from './scenes/AdminShell/components/presences/Presence';

import IdleTimer from 'react-idle-timer'

import Favicon from 'react-favicon'

import UserSvc from './services/User';
import AuthSvc from './services/Auth';
import Cache from './services/Cache';
import Logger from './services/Logger';
import Error from './services/Error';

const UserService = new UserSvc();
const AuthService = new AuthSvc();
const CacheService = new Cache();
const LoggerService = new Logger();
const ErrorService = new Error();
const WSService = window.WSService

const MaxLogoutCounter = 10
var logoutTimer = null

class AppRouter extends Component {
  state = {
    logoutCounter: MaxLogoutCounter
  }
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.unlistenHistory = this.props.history.listen(LoggerService.historyLogger)
  }

  _onAction(e) {
    //ignore
  }

  _onActive(e) {
    //ignore
  }

  logoutNow() {
    AuthService.logout()
  }

  idleLogout() {
    logoutTimer = setInterval(() => {
      var counter = this.state.logoutCounter - 1
      if (counter === 0) {
        clearInterval(logoutTimer);
        this.logoutNow();
      }
      this.setState({
        logoutCounter: counter
      })
    }, 1000)
  }

  componentWillUnmount(){
    this.unlistenHistory()
  }

  _onIdle(e) {
    this.setState({
      loggingOut: true
    })
    this.idleLogout();
  }
  componentWillMount = () => {
    let token = AuthService.getToken();
    let currentUserStr = window.localStorage.getItem('current_user');
    if (window.store.getState().router.location.pathname.toString().indexOf('reset-password') > -1 || window.store.getState().router.location.pathname === '/help' || window.store.getState().router.location.pathname.includes('/helpdesk/panel')) {
      // Do nothing
    } else if (!(token && token.length > 0) && window.store.getState().router.location.pathname !== '/login') {
      window.location = '/login?redirect=' + window.location.pathname.toString();
    } else if (!(window.store.getState().router.location.pathname.toString().indexOf('reset-password') > -1)) {
      try {
        let currentUser = JSON.parse(currentUserStr);
        if (!currentUser || (currentUser && !currentUser.id)) {
          return;
        }
        this.setState({ currentUser: currentUser });
        UserService.get(currentUser.id)
          .then((data) => {
            if (currentUser && currentUser.id === data.id) {
              window.store.dispatch(window.actions.loggedIn({ accessToken: token, currentUser: currentUser }));
              if (!window.oaWS) {
                WSService.init();
              }
            } else if (window.store.getState().router.location.pathname !== '/login') {
              window.location = '/login?redirect=' + window.location.pathname.toString();
            }
          })
          .catch((err) => {
            if (err.response && err.response.status && err.response.status === 401) {
              if (window.store.getState().router.location.pathname !== '/login') {
                window.location = '/login?redirect=' + window.location.pathname.toString();
              }
            }
          });
          let loadDuration = Math.ceil(window.performance.timing.domComplete - window.performance.timing.navigationStart)
          if (loadDuration < 0) {
            window.onload = () => {
              loadDuration = Math.ceil(window.performance.timing.domComplete - window.performance.timing.navigationStart)
              LoggerService.info("FIRST LOAD", "", loadDuration)
            }
          } else {
            LoggerService.info("FIRST LOAD", "", loadDuration)
          }
      } catch (e) {
        console.log(e);
        if (window.store.getState().router.location.pathname !== '/login') {
          window.location = '/login?redirect=' + window.location.pathname.toString();
        }
      }
    }
  }

 
  componentDidUpdate() {
      CacheService.getCacheVersions()
      .then((newVersions) => {
          newVersions.map((row)=>{
            CacheService.getCacheVersionFromStorage(row.cache_type)
            .then((currentVersion) => {
              if(currentVersion !== row.timestamp){
                CacheService.cachingData(newVersions)
              }
            })
            .catch(() =>CacheService.cachingData(newVersions));  
          })
      })
      .catch((err) => ErrorService.handle(err));    
  }

  render() {
    return (
      <div className={(window.location.pathname === '/login' || window.location.pathname.indexOf('reset-password') > -1 || window.location.pathname === '/help') ? '' : 'wrapper'}>

        {this.state.loggingOut === true &&
          <div style={{ position: 'absolute', width: '100%', height: '100%',  zIndex: 9000, background: 'rgba(20, 20, 20, 0.7)' }}>
            <div style={{ position: 'absolute', 
            top: '50%', 
            left: '50%', 
            zIndex: 9100, 
            background: '#fff', 
            width: '400px',
            height: '100px',
            marginTop: '-20px',
            marginLeft: '-50px',
   
            borderRadius: '10px',
            padding: '20px' }}>
              Anda akan dikeluarkan dari sistem secara otomatis dalam {this.state.logoutCounter} detik.
              <br/><br/>
              <div className='text-center'>      
              <button className="btn btn-primary" onClick={()=>{ 
                clearInterval(logoutTimer);
                this.setState({ loggingOut: false });
              }}>Batal</button>
              <span style={{margin: '10px'}}></span>
              <button className="btn btn-danger" onClick={()=>{ 
                this.logoutNow();
              }}>Keluar sekarang</button>

              </div> 
            </div>
          </div>
        }

        <div id="OA-VERSION" style={{ display: "none" }}>{process.env.REACT_APP_OA_BRANCH}-{process.env.REACT_APP_OA_VERSION}</div>
        <Favicon url={Config.variant.images.favIcon} />

        {/* Header */}
        <Header />
        {/* Sidebar */}
        {window.location.pathname.indexOf('/login') < 0 && window.location.pathname.indexOf('reset-password') < 0 &&
        <Sidebar />
        }
        {/* Content */}
        {!AuthService.getToken() && !window.store.getState().router.location.pathname.includes('/helpdesk/panel')?
          <main id="main">
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset-password/:uuid" component={ResetPassword} />
            <Route exact path="/help" component={Help} />
            { GA.init() && <GA.RouteTracker /> }
          </main>
          :
          <main id="main">
            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={Config.autoLogoutTimer} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset-password/:uuid" component={ResetPassword} />
            <Route exact path="/help" component={Help} />
            { GA.init({userId: this.state.currentUser? this.state.currentUser.id: null }) && <GA.RouteTracker /> }
            <Switch>
              <Route
                render={(props) => {
                  let currentUserStr = window.localStorage.getItem('current_user');
                  let currentUser = JSON.parse(currentUserStr);
                  try {
                    if (currentUser && currentUser.PINLastChanged && ((new Date()).valueOf() - (new Date(currentUser.PINLastChanged)).valueOf()) / 1000 > 7884000 &&
                    !(window.location.toString().indexOf('user-profile#forceChangePIN') > -1 || window.location.toString().indexOf('login') > -1 || window.location.toString().indexOf('reset-pin') > -1 )) {
                      window.location = '/user-profile#forceChangePIN?callback=' + window.location.pathname.toString();
                      return;
                    }
                  } catch (e) {
                    // Do nothing, already handled at above
                  }
                  if ((props.location.pathname !== '/login' && props.location.pathname.indexOf('reset-password')) < 0 && props.location.pathname !== '/help' ) {
                    return (
                      <div>
                        <div className="content-wrapper sidebar-affected" > 
                          <div style={{ overflowY: 'scroll', height: '90vh', marginLeft: window.innerHeight > 900 ? 40:0}}>
                            <section className="content-header">
                              <h1>
                              </h1>
                            </section>
                            <section className="content">
                              <Route exact path="/helpdesk/panel/:uuid" component={DashboardHelpdesk} />
                              <Route exact path="/" component={Dashboard} />
                              <Route exact path="/login" component={Login} />
                              <Route exact path="/reset-password/:uuid" component={ResetPassword} />
                              <Route exact path="/reset-pin/:uuid" component={ResetPIN} />
                              <Route exact path="/user-profile" component={UserProfile} />
                              {/* Compose */}
                              <Route exact path="/compose/:letterType" component={Compose} />

                              <Route exact path="/read/:uuid" component={LetterRead} />
                              <br/>
                              <Route exact path="/read-redirect-helper/:uuid" component={LetterReadRedirectHelper} />
                              {/* Inbox */}
                              <Route exact path="/inbox/all" component={InboxAll} />
                              <Route exact path="/inbox/nota-dinas" component={InboxNotaDinas} />
                              <Route exact path="/inbox/surat-tugas" component={InboxSuratTugas} />
                              <Route exact path="/inbox/surat-undangan" component={InboxSuratUndangan} />
                              <Route exact path="/inbox/surat" component={InboxSurat} />
                              <Route exact path="/inbox/memo" component={InboxMemo} />
                              {currentUser && currentUser.isExternalLetterRecipient && <Route exact path="/inbox/oa2oa" component={InboxOA2OA} />}
                              <Route exact path="/inbox/laporan" component={InboxLaporan} />
                              <Route exact path="/inbox/disposition" component={InboxDisposition} />
                              <Route exact path="/inbox/tanggapan" component={InboxTanggapan} />
                              <Route exact path="/inbox/surat-keterangan" component={InboxSuratKeterangan} />
                              <Route exact path="/inbox/lembar-ralat" component={InboxLembarRalat} />
                              <Route exact path="/inbox/pengumuman" component={InboxPengumuman} />
                              <Route exact path="/inbox/telaahan-staf" component={InboxTelaahanStaf} />
                              {/* Outbox */}
                              <Route exact path="/outbox/all" component={OutboxAll} />
                              <Route exact path="/outbox/nota-dinas" component={OutboxNotaDinas} />
                              <Route exact path="/outbox/surat-tugas" component={OutboxSuratTugas} />
                              <Route exact path="/outbox/surat-undangan" component={OutboxSuratUndangan} />
                              <Route exact path="/outbox/surat" component={OutboxSurat} />
                              <Route exact path="/outbox/memo" component={OutboxMemo} />
                              <Route exact path="/outbox/laporan" component={OutboxLaporan} />
                              <Route exact path="/outbox/disposition" component={OutboxDisposition} />
                              <Route exact path="/outbox/canceled" component={OutboxCanceled} />
                              <Route exact path="/outbox/manual" component={OutboxManual} />
                              <Route exact path="/outbox/surat-keterangan" component={OutboxSuratKeterangan} />
                              <Route exact path="/outbox/lembar-ralat" component={OutboxLembarRalat} />
                              <Route exact path="/outbox/pengumuman" component={OutboxPengumuman} />
                              <Route exact path="/outbox/telaahan-staf" component={OutboxTelaahanStaf} />
                              {/* DIsposition */}
                              {currentUser && currentUser.organization && currentUser.organization.leaderDashboardEnabled && <Route exact path="/disposition/:type" component={DispositionBox} />}
                              {/* Processing */}
                              <Route exact path="/processing/need-approval" component={ProcessingNeedApproval} />
                              <Route exact path="/processing/need-opinion" component={ProcessingNeedOpinion} />
                              <Route exact path="/processing/status" component={ProcessingStatus} />
                              <Route exact path="/processing/requester" component={ProcessingRequester} />
                              <Route exact path="/processing/draft" component={ProcessingDraft} />
                              <Route exact path="/processing/finalization" component={ProcessingFinalization} />
                              <Route exact path="/processing/finalized" component={ProcessingFinalized} />
                              {/* Archive */}
                              <Route exact path="/archive/manual-letter-archive" component={ManualLetterArchive} />
                              <Route exact path="/archive/external" component={ArchiveExternal} />
                              <Route exact path="/archive/internal" component={ArchiveInternal} />
                              <Route exact path="/archive/disposition" component={ArchiveDisposition} />
                              {/* Tools */}
                              <Route exact path="/tools/delegation" component={Delegation} />
                              <Route exact path="/tools/assistant" component={Assistant} />
                              <Route exact path="/tools/agenda-report" component={AgendaReport} />
                              <Route exact path="/tools/rapat" component={Rapat} />
                              <Route exact path="/tools/rapat/:uuid" component={Rapat} />

                              {/* Settings ('ADMIN') */}
                              <Route exact path="/settings/satker" component={SatkerSetting} />
                              {Config.gerbang && <Route exact path="/settings/external_letter_recipient" component={ExternalLetterRecipientSetting} />}
                              <Route exact path="/settings/users" component={Users} />
                              <Route exact path="/settings/user-groups" component={UserGroups} />
                              <Route exact path="/settings/user/:uuid" component={UserEdit} />
                              <Route exact path="/settings/units" component={Units} />
                              <Route exact path="/settings/unit/:uuid" component={UnitEdit} />
                              <Route exact path="/settings/organizations" component={Orgs} />
                              <Route exact path="/settings/classification" component={Classification} />
                              <Route exact path="/settings/letter-template" component={LetterTemplate} />
                              <Route exact path="/settings/letter-header" component={LetterHeader} />
                              <Route exact path="/settings/disposition-actions" component={DispositionActions} /> 
                              <Route exact path="/settings/disposition-action/:uuid" component={DispositionActionEdit} />
                              
                              <Route exact path="/helpdesk" component={HelpdeskPage} />
                              <Route exact path="/settings/monitor" component={MonitorLog} />
                              <Route exact path="/settings/monitor-wa" component={MonitorWhatsapp} />

                              {Config.variant.name === 'ppkblu' &&
                                <Route exact path="/settings/monitor-presence" component={MonitorPresence} />
                              }
                              {Config.variant.name === 'ppkblu' &&
                                <Route exact path="/presence" component={Presence} />
                              }
                            </section>
                          </div>
                        </div>
                        <footer className="main-footer" style={{paddingLeft:'55px'}}>
                          <div className="pull-right hidden-xs">
                            Copyright &copy; {(new Date()).getFullYear()} {Config.variant.footer || "Surelintas.id"}
                          </div>
                        </footer>
                        <div className="control-sidebar-bg"></div>
                        <ChatList />
                      </div>
                    )
                  } else {
                    return (<div />)
                  }
                }}
              />
            </Switch>
          </main>
        }
      </div>
    );
  }
}

export default AppRouter;
