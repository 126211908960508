import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Config from '../../../config'
import { connect } from "react-redux";
import * as Fa from 'react-icons/lib/fa';
import Auth from '../../../services/Auth';

const AuthService = new Auth();

const mapper = state => {
  return {
    currentUser: state.rootReducers.currentUser,
    sidebarCollapse: state.rootReducers.sidebarCollapse,
    'location': state.router.location
  }
}


const dispatcher = dispatch => {
  return {
    toggleSidebar: isCollapse => dispatch(window.actions.toggleSidebar(isCollapse))
  }
}


class Header extends Component {
  toggleSidebar = () => {
    console.log('Header : toggle. current : ' + this.props.sidebarCollapse);
    window.store.dispatch(window.actions.toggleSidebar(!this.props.sidebarCollapse));
  }
  render() {
    if (!AuthService.getToken() && !window.store.getState().router.location.pathname.includes('/helpdesk/panel')) {
      return (<div />)
    }
    return (
      <div>
        {this.props.location.pathname !== '/login' && this.props.location.pathname.indexOf('reset-password') < 0 &&
          <header className="main-header">
            <Link to="/" className="logo" style={{ cursor: 'pointer' }}>
              <span className="logo-mini"><img src={Config.variant.images.navigationLogo} alt="navlogo" /></span>
              <span className="logo-lg"><img src={Config.variant.images.navigationLogo} alt="navlogo" /></span>
            </Link>
            <nav className="navbar navbar-static-top" style={{ padding: 0 }}>
              <a style={{ cursor: 'pointer' }} data-toggle="push-menu" role="button" onClick={this.toggleSidebar}>
                <Fa.FaBars style={{ color: 'white', fontSize: 16, lineHeight: '12px' }} />
              </a>
              <div className="navbar-custom-menu">
                <ul className="nav navbar-nav">
                  <li className="dropdown user user-menu">
                  </li>
                </ul>
              </div>
            </nav>
          </header>
        }
      </div>
    );
  }
}

export default connect(mapper, dispatcher)(Header);
