import React, { Component } from 'react'
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';

export default class ChatMessage extends Component {
    state = {
        senderStyle: 'mine'
    }

    componentDidMount = () => {
        var senderStyle = 'others'
        if (this.props.service.getCurrentUser() === this.props.value['sender']) {
            senderStyle = 'mine'
        }
        this.setState({
            senderStyle: senderStyle
        })
    }

    render() {
        return (
            <li key={this.props.value['message-id']} className={this.state.senderStyle}>{this.props.value['message-contents']}<br/>
            <span className="message-time">{date.format(this.props.value['timestamp'], 'DD/MM/YYYY HH:mm', { locale: dateLocaleId })}</span>
            </li>
        )
    }
}