import React, { Component } from 'react';
import swal from 'sweetalert';
import Spinner from '../../../components/Spinner';
import Auth from '../../../services/Auth';
import Settings from '../../../services/Settings';

const AuthService = new Auth();
const SettingService = new Settings();

const emailAddressRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default class ResetPINOptionsModal extends Component {
  state = {
    showModal: true,
    loading: false,
    whatsapp: false,
    email: false,
    whatsappStatus:false

  };
  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
    if (this.props.onCanceled) this.props.onCanceled();
  };
  
  async componentDidMount()  {
    await SettingService.getWhatsAppInfo().then((data)=>{
      this.setState({
       whatsappEnable:data.isWhatsappSessionActive
      })
     }).catch((err)=>console.log(err))
   }
 
  ok = () => {
    let isEmailValid = this.props.emailAddress && emailAddressRe.test(this.props.emailAddress)
    if(!this.state.whatsapp && this.state.email && !isEmailValid){
      swal({
        title: '',
        type: 'warning',
        text: 'Alamat email tidak valid.',
      });
      return;
    }else if (!this.state.email && this.state.whatsapp && !this.props.phoneNumber){
      swal({
        title: '',
        type: 'warning',
        text: 'Akun ini belum mempunyai nomor telepon.',
      });
      return;
    }else if (this.state.email && this.state.whatsapp && !this.props.phoneNumber && !isEmailValid){
      swal({
        title: '',
        type: 'warning',
        text: 'Alamat email dan nomor telepon tidak valid.',
      });
      return;
    }
    this.setState({ loading: true }, () => {
      AuthService.requestPINReset(this.props.uuid, this.state.email, this.state.whatsapp)
      .then(() => {
        let text = this.state.email && this.state.whatsapp ? 
          'Silahkan periksa email atau WhatsApp pengguna' :
            this.state.email ? 'Silahkan periksa email pengguna' :
              'Silahkan periksa WhatsApp pengguna'
        swal({
          title: '',
          type: 'warning',
          text,
        });
      })
      .finally(() => {
        this.setState({ loading: false})
        this.hideModal()
      })      
    })
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') this.ok();
  };

  render() {
    return (
      <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="PINResetOption" style={{fontSize:"1.25em"}}>Setel ulang PIN lewat : </label>
                <br/>
                {this.state.whatsappEnable &&

                  <label
                    className="checkbox-inline pointer label-font-weight-normal"
                  >
                    <input
                      type="checkbox"

                      name="PINResetOption"
                      onChange={() => { this.setState({ whatsapp: !this.state.whatsapp }); }}
                    /> WhatsApp
                </label>
                }
                <br/>
                <label
                  className="checkbox-inline pointer label-font-weight-normal"
                >
                  <input
                    type="checkbox"
                    name="PINResetOption"
                    onChange={() => { this.setState({ email: !this.state.email }); }}
                  /> Email
                </label>
              </div>
            </div>
            <div className="modal-footer">
              {this.state.loading ?
                <Spinner />
                :
                <div>
                  <button className='btn btn-primary' style={{ marginRight: 10 }} onClick={this.ok} disabled={!(this.state.email || this.state.whatsapp)}>
                    OK
                  </button>
                  <button className="btn btn-default" onClick={this.hideModal}>Batal</button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
