import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Helpdesk from './Helpdesk'

export default class HelpdeskPage extends Component {
  
  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="box box-success">
            <div className="box-body">
              <Helpdesk header={true} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}