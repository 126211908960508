import React, { Component } from 'react';
import './styles.css';
import Config from "../../../config";
import Auth from '../../../services/Auth';

const AuthService = new Auth();

export default class LetterReadPengumuman extends Component {

  render() {
    const {
      number,
      contents,
      timestamp,
      letterDate,
      letterType,
      scannedSignature,
      signerName,
      signerOrg,
      signerOrgSignerName,
      signerNIP,
      onBehalfOf,
      onBehalfOfTitle,
      pelaksana,
      city,
      qrImage,
      letterState,
    } = this.props.parentState.currentLetter;
    return (
      <div
        style={{
          background: 'white',
          width: '210mm',
          margin: '0 auto',
          marginTop: '15px',

          padding: '15mm 20mm 20mm 20mm',
        }}
      >
         <div
          id="letter-body"
          style={{
            background: 'white',
            width: '165mm',
            margin: '0 auto',
          }}
        >
          <div
            style={{
              fontFamily: 'Arial',
            }}
          >
            <div>
              <div>
              {this.props.parentState.currentLetter.letterHeaderLogo && this.props.parentState.currentLetter.letterHeaderLogo.length > 0 &&
                <div
                  style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    width: '30mm',
                    height: '30mm',
                    float: 'left',
                    position: 'absolute'
                  }}
                >
                  <img
                    src={Config.apiHost + "/api/v1/blob/unrestricted/" + this.props.parentState.currentLetter.letterHeaderLogo}
                    onError={(e) => { console.log(e); e.target.src = Config.variant.images.letterLogo }}
                    alt=""
                    style={{ width: '31mm' }}
                  />
                </div>
              }
               <div style={{
                  display: 'inline-block',
                  textAlign: 'center',
                  width: (this.props.parentState.currentLetter.letterHeaderLogo && this.props.parentState.currentLetter.letterHeaderLogo.length > 0) ? '170mm' : '100%',
                  minHeight: '35mm',
                  lineHeight: '0.8em',
                  paddingLeft: (this.props.parentState.currentLetter.letterHeaderLogo && this.props.parentState.currentLetter.letterHeaderLogo.length > 0) ? '30mm' : 0
                }}>
                  <div dangerouslySetInnerHTML={{ __html: this.props.parentState.currentLetter.letterHeaderBody }}>
                  </div>
                </div>
                <hr style={{ height: 1, border: 'none', color: '#333', background: 'transparent', borderTop: 'solid 1px #333', marginTop: 0, marginBottom: 0 }} />
                <hr style={{ height: 1, border: 'none', color: '#333', background: 'transparent', borderTop: 'solid 2px #333', marginTop: 0 }} />
                <div
                  style={{
                    lineHeight: '1.1em',
                  }}
                >
                  <div style={{ textAlign: 'center', margin: 15 }}>
                    <div>
                      PENGUMUMAN
                    </div>
                    <span>NOMOR {this.props.renderReferenceNumber(letterState, number, this.props.parentState.manualLetterReferenceNumber)}</span>
                    <div style={{marginTop: 20}}>
                      TENTANG<br/>
                      <div dangerouslySetInnerHTML={{ __html: this.props.parentState.currentLetter.subject }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: contents
              }}
              style={{
                lineHeight: '1.1em',
                marginTop: '5mm',
                marginBottom: '5mm',
              }}
            >
            </div>
            <div style={{ width: '100%', height: '200px', marginTop: '10mm' }}>
              {letterState === 'SENT' ?
                <div style={{
                  width: 150,
                  height: 199,
                  float: 'left',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundImage: "url('" + qrImage + "')"
                }}></div>
                :
                <span id="qr-code"></span>
              }
              <div style={{ float: 'right', width: '80mm' }}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'top' }} width="20">
                      </td>
                      <td>
                        <div>
                          {city && city !== '' ?
                            'Ditetapkan di ' + city
                            :
                            <span>Ditetapkan di&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          }
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top' }} width="20"></td>
                      <td>pada tanggal &nbsp;{this.props.renderLetterDate(letterState, letterDate, this.props.parentState.manualLetterDate, timestamp)}</td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        {onBehalfOf && onBehalfOfTitle && onBehalfOfTitle.length > 0 && 'a.n.'}
                        {pelaksana && pelaksana === 'HARIAN' && 'Plh.'}
                        {pelaksana && pelaksana === 'TUGAS' && 'Plt.'}
                        &nbsp;
                      </td>
                      <td>
                        {onBehalfOf && onBehalfOfTitle && onBehalfOfTitle.length > 0 ?
                          <div>
                            {onBehalfOfTitle}
                            <br />
                            {signerOrg}
                          </div>
                          :
                          (this.props.parentState.currentLetter.letterType === 'TNDE_NOTA_DINAS' && (!pelaksana || (pelaksana && pelaksana.length < 1)) ?
                            (Config.isSignerOrgVisible === 'true' ? <span>{signerOrg}</span> : <span></span>)
                            :
                            (signerOrgSignerName ? signerOrgSignerName : signerOrg))
                        }
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style={{ padding: '7px 1px' }}>
                        <div style={{ height: '25mm' }}>
                          {scannedSignature &&
                            (this.props.parentState.currentLetter.letterState === 'SENT' || 
                            (this.props.parentState.query.action === 'finalization' && AuthService.getRoles().role === 'FINALIZER')) &&
                            (this.props.parentState.currentLetter.signatureType !== 'HANDWRITTEN' || 
                            this.props.parentState.currentLetter.letterType === 'TNDE_MEMO') &&
                            <img
                              src={Config.apiHost + "/api/v1/blob/unrestricted/" + scannedSignature}
                              alt=""
                              onError={(e) => { e.target.src = '/images/blank.png' }}
                              style={{ height: '25mm' }}
                            />
                          }
                          <br />
                        </div>
                        {signerName}
                        <br />
                        {this.props.parentState.settings.useNIPOnLetter === 'TRUE' && <span>NIP. {signerNIP}</span>}
                      </td>
                    </tr>
                    <tr>
                      <td>
                      </td>
                      <td>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {this.props.parentState.ccRecipients.length > 0 &&
            <div>
              {onBehalfOfTitle && <div style={{ marginBottom: (letterType === 'TNDE_SURAT_TUGAS') ? 45 : 35 }}></div>}
              Tembusan: <br />
              {this.props.parentState.ccRecipients && this.props.parentState.ccRecipients.map((item, index) => {
                return <div key={index}>
                  {!(index === 0 && this.props.parentState.ccRecipients.length === 1) && <span>{parseInt(index, 10) + 1}.</span>} {item.recipientName}
                </div>
              })}
            </div>}
            <div style={{ fontSize: '11pt', marginTop: 30}}>
              <span className='kp'></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}