import React, { Component } from 'react';
import qs from 'qs';
export class LetterReadRedirectHelperComponent extends Component {
  state = {
  }

  componentDidMount = () => {
    let queryStr = window.location.href.split("?")[1] || '';
    let query = qs.parse(queryStr);
    let urlStr = '/read/' + this.props.uuid + '?redirectByHelper=true'
    if (query.action) urlStr += '&action=' + query.action;
    window.browserHistory.push(urlStr);
  }

  render() {
    return <div>Redirecting...</div>
  }
}

const LetterReadRedirectHelper = ({ match }) => (
  <LetterReadRedirectHelperComponent uuid={match.params.uuid} action={match.params.action} />
)

export default LetterReadRedirectHelper;

