import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Spinner from '../../../components/Spinner';
import stripTags from '../../../utils';
import './styles.css';

export default class LetterBundleModal extends Component {
  state = {
    showModal: false,
    loading: false,
    bundle: [],
  }

  showModal = () => {
    this.setState({ showModal: true });
  }

  hideModal = () => {
    this.setState({ showModal: false });
  }

  handleClick = (index) => {
    let splitted = this.props.bundle[index].split('|');
    this.hideModal();
    setTimeout(() => {
      if (splitted.length === 4) {
        if (splitted[2] === 'PROCESS' &&
        !this.props.isBundlerBeingFinalized &&
        !this.props.isCurrentUserOriginator &&
        this.props.isCurrentUserProcessChainHolder) {
          window.browserHistory.push('/read-redirect-helper/' + splitted[0] + '?action=process');
        } else {
          window.browserHistory.push('/read-redirect-helper/' + splitted[0]);
        }
      } else {
        window.browserHistory.push('/read-redirect-helper/' + splitted[0]);
      }
    }, 200);
  }

  render() {
    return (
      <div className="form-group row" style={{ margin: 0, marginTop: 10 }}>
        <button className="btn letter-btn btn-default" style={{ margin: 0 }} onClick={this.showModal}><Fa.FaFileO /> Paket Surat</button>
        <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {this.props.modalLabel}
                </h4>
                <span className="close" onClick={this.hideModal}><Fa.FaClose /></span>
                <hr />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="box box-warning" style={{ marginTop: 10, minHeight: 400 }}>
                      {this.state.loading ?
                        <Spinner />
                        :
                        <ul style={{ padding: 0, height: 400, overflow: 'auto' }}>
                          {this.props.bundle && Object.keys(this.props.bundle).map((item, index) => {
                            return <li
                              key={index}
                              style={{
                                padding: 10,
                                paddingLeft: 15,
                                cursor: 'pointer',
                                borderBottom: '1px solid lightgrey',
                              }}
                              onClick={() => { this.handleClick(index) }}
                            >
                              {this.props.bundle[item].split('|').length > 1 && <span>{stripTags(this.props.bundle[item].split('|')[1])}</span>}
                            </li>
                          })}
                        </ul>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
