import React, { Component } from 'react';
import swal from 'sweetalert';
import Spinner from '../../../components/Spinner';
export default class CredentialResetModal extends Component {
  state = {
    showModal: true,
    loading: false,
    newCred: '',
    username: '',
  };
  showModal = () => {
    this.setState({ showModal: true });
    setTimeout(() => {
      if (this.props.mode === 'single') {
        this.usernameInputElement.focus();
      } else {
        this.newCredInputElement.focus();
      }
    }, 500);
  };
  hideModal = () => {
    this.setState({ showModal: false });
    if (this.props.onCanceled) this.props.onCanceled();
  };

  valid = (username, newCred) => {
    const isNewCredValid = newCred && newCred.length > 4;
    return this.props.mode === 'single' ? isNewCredValid && username && username.length > 1 : isNewCredValid;
  };

  ok = () => {
    if (!this.valid(this.state.username, this.state.newCred)) {
      swal({
        title: '',
        type: 'warning',
        text: 'Data kurang lengkap',
      });
      return;
    }
    let payload = this.props.mode === 'single' ? { newCred: this.state.newCred, username: this.state.username } : this.state.newCred;
    this.setState({ loading: true }, () => {
      this.props.onConfirm(payload).then(() => {
        swal({
          title: '',
          type: 'success',
          text: 'Sukses',
        });
      }).finally(() => {
        this.setState({ loading: false });
        this.hideModal();
      });
    });
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') this.ok();
  };

  render() {
    return (
      <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              {
                this.props.mode === 'single' &&
                <div className="form-group">
                  <label htmlFor="username">Nama Pengguna</label>
                  <input
                    className="form-control"
                    type="text"
                    name="username"
                    placeholder="Nama Pengguna"
                    value={this.state.username ? this.state.username : ''}
                    onChange={(e) => { this.setState({ username: e.target.value }); }}
                    disabled={this.loading}
                    onKeyPress={this.onKeyPress}
                    ref={usernameInputElement => this.usernameInputElement = usernameInputElement}
                    autoFocus
                  />
                </div>
              }
              <div className="form-group">
                <label htmlFor="password">{this.props.for}</label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  aria-describedby="passwordHelp"
                  placeholder={this.props.for}
                  value={this.state.newCred ? this.state.newCred : ''}
                  onChange={(e) => { this.setState({ newCred: e.target.value }); }}
                  disabled={this.loading}
                  onKeyPress={this.onKeyPress}
                  ref={newCredInputElement => this.newCredInputElement = newCredInputElement}
                  autoFocus
                />
              </div>
            </div>
            <div className="modal-footer">
              {this.state.loading ?
                <Spinner />
                :
                <div>
                  <button className='btn btn-primary' style={{ marginRight: 10 }} onClick={this.ok} disabled={!this.valid(this.state.username, this.state.newCred)}>
                    Ubah
                  </button>
                  <button className="btn btn-default" onClick={this.hideModal}>Batal</button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
