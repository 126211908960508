import React, { Component } from 'react';

/*

Usage : 

<Component 
  label="Perihal" 
  name="particularFieldName" 
  onChange={this.inputEventHandler} 
  required={true} 
  maxChar={500} 
  row={2}
  vertical={true}
  additionalLabel={'Hindari menyalin perihal dari Ms. Word atau situs web.'}
/>

*/

export default class component extends Component {
  state = {
    value: '',
  }

  componentDidMount = () => {
    this.setState({ value: this.props.value || '' })
  }

  onChange = (event) => {
    if (this.props.maxChar && this.props.maxChar > 0 && event.target.value.length > this.props.maxChar) {
      return;
    }
    this.setState({ value: event.target.value });
    this.props.onChange(event);
  }
  onBlur = () => {
    // Do nothing
  }

  render() {
    const {
      label,
      name,
      required,
      maxChar,
      row,
      vertical,
      additionalLabel,
      disabled,
    } = this.props;
    return (
      <div className={`form-group ${!vertical && 'row'}`} style={{ marginTop: 0, marginBottom: 0, paddingTop: 0 }}>
        <label
          className={`col-md-1 col-sm-2 control-label`}
          style={{ textAlign: vertical ? 'left' : 'right', padding: vertical ? 0 : 15 }}
        >
          {label}
          &nbsp;<span className="red">{required && '*'}</span>
        </label>
        <div
          className={`col-md-${this.props.fieldWidth ? this.props.fieldWidth : '10'} col-sm-${this.props.fieldWidth ? this.props.fieldWidth : '10'}`}
          style={{ padding: vertical ? 0 : 15 }}
        >
          <textarea
            name={name}
            value={this.state.value}
            onChange={this.onChange}
            required={required}
            className="form-control"
            rows={row ? row : 4} disabled={disabled}
            onBlur={this.props.onBlur || this.onBlur}
            autoComplete="off"
          />
          {additionalLabel ? <span style={{ float: 'left', color: 'red' }}>{additionalLabel}</span> : ''}
          {maxChar && maxChar > 0 ? <span style={{ float: 'right' }}>{this.state.value.length}/{maxChar}</span> : ''}
        </div>
      </div>
    );
  }
}
