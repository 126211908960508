export function toggleMode(mode) {
    var newMode;
    if (mode === visualModeEnum.COLLAPSED) {
        newMode = visualModeEnum.EXPANDED
    } else {
        newMode = visualModeEnum.COLLAPSED    
    }

    return newMode;
}

export const visualModeEnum = {
    EXPANDED: 'expanded',
    COLLAPSED: 'collapsed',
}
