import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Spinner from '../../../components/Spinner';
import './styles.css';
import LetterRefCounter from '../../../services/LetterRefCounter';
import swal from 'sweetalert';
import config from '../../../config';

const LetterRefCounterService = new LetterRefCounter();

var inputKemenkeu = {
  notaDinas : {
    letterCode : 'ND',
    counter : 0,
    letterWord : 'Nota Dinas'
  },
  suratTugas : {
    letterCode : 'ST',
    counter : 0, 
    letterWord : 'Surat Tugas'
  },
  suratUndangan : {
    letterCode : 'UND',
    counter : 0,
    letterWord : 'Surat Undangan'
  },
  suratDinas : {
    letterCode : 'S',
    counter : 0,
    letterWord : 'Surat Dinas'
  },
  suratKeterangan : {
    letterCode : 'KET',
    counter : 0,
    letterWord : 'Surat Keterangan'
  },
  lembarRalat : {
    letterCode : 'RAL',
    counter : 0,
    letterWord : 'Lembar Ralat'
  },
  laporan : {
    letterCode : 'LAP',
    counter : 0,
    letterWord : 'Laporan'
  },
  pengumuman : {
    letterCode : 'PENG',
    counter : 0,
    letterWord : 'Pengumuman'
  }
};

export default class LetterRefCounterModal extends Component {
  state = {
    showModal : false,
    loading : false,
    input : (config.variant.name === 'ppkblu' || 
    config.variant.name === 'lman' ||
    config.variant.name === 'ppkblu' ) ? inputKemenkeu : {
      notaDinas : {
        letterCode : 'ND',
        counter : 0,
        letterWord : 'Nota Dinas'
      },
      suratTugas : {
        letterCode : 'ST',
        counter : 0, 
        letterWord : 'Surat Tugas'
      },
      suratUndangan : {
        letterCode : 'UND',
        counter : 0,
        letterWord : 'Surat Undangan'
      },
      suratDinas : {
        letterCode : 'S',
        counter : 0,
        letterWord : 'Surat Dinas'
      }
    }
  }

  componentDidMount = () => {
  }

  showModal = () => {
    this.setState({ loading: true, showModal: true }, () => {
      LetterRefCounterService.get(this.props.id)
      .then((result) => {
        this.setState({ loading: false })
        let obj = this.state.input
        Object.keys(obj).map((item) => {
          for (let i in result.data) {
            if (obj[item].letterCode === result.data[i].letterCode) {
              obj[item].counter = result.data[i].counter
            }
          }
        })
        this.setState({ input: obj })
      })
      .catch((err) => {
        console.log(err)
        this.setState({ loading: false })
      })
    })
  }

  hideModal = () => {
    this.setState({ showModal: false });
  }

  onSave = () => {
    let payload = { data: [] }
    Object.keys(this.state.input).map((item) => {
      if (parseInt(this.state.input[item].counter, 10) > 0) {
        payload.data.push(
          {
            letterCode: this.state.input[item].letterCode,
            counter: parseInt(this.state.input[item].counter, 10)
          }
        )
      }
    })
    this.setState({ loading: true }, () => {
      LetterRefCounterService.upsert(payload, this.props.id)
      .then((result) => {
        swal({
          title: '',
          text: 'Berhasil Menyimpan',
          closeOnClickOutside: true,
          closeOnEsc: true,
          type: 'success'
        })
        this.setState({ loading: false, showModal: false })
      })
      .catch((err) => {
        let msg = `<p style="text-align:center;margin-bottom: 5px;font-family: sans-serif;"> Gagal Menyimpan </p>
                          <p style="text-align:center;font-family: sans-serif;">Silahkan isi kode jabatan terlebih dahulu</p>`;
              if (err.response.data.message.indexOf('Please fill the kode jabatan first') > -1) {
                swal({
                  title: '',
                  text: msg,
                  type: 'error',
                  closeOnClickOutside: true,
                  closeOnEsc: true,
                  html: true
                })
              } else {
                swal({
                  title: '',
                  text: 'Gagal Menyimpan',
                  closeOnClickOutside: true,
                  closeOnEsc: true,
                  type: 'error',
                })
              }
        this.setState({ loading: false, showModal: false })
        console.log(err)
      })
    })
  }

  onChange = (e, key) => {
    let obj = this.state.input
    obj[key].counter = e.target.value
    this.setState({ input: obj})
  }

  render() {

    return (
      <span>
        <button type="button" onClick={this.showModal} className={this.props.primary ? "btn btn-primary" : "btn btn-default"}>
          <Fa.FaCog /> {this.props.text}
        </button>
        <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Setel Nomor Surat
                </h4>
                <hr />
              </div>
              <div className="modal-body">
                <div className="box box-success" style={{ marginTop: 15 }}>
                  <div className="box box-body">
                    {this.state.loading ? <Spinner/> :
                      <div className="row">
                        <div className="col-sm-12">
                          {this.state.input && Object.keys(this.state.input).map((item, index) => (
                            <div className="form-group row" key={index}>
                              <label style={{ textAlign: 'right' }} className="col-sm-3">{this.state.input[item].letterWord}</label>
                              <div className="col-sm-9" >
                                <input
                                  className="form-control"
                                  name={this.state.input[item].letterCode}
                                  value={this.state.input[item].counter}
                                  onChange={(e) => {this.onChange(e, item)}}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <span className="btn btn-default" onClick={this.hideModal}>Batal</span>
                <span className="btn btn-primary" onClick={this.onSave}>Simpan</span>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}
