import React, { Component } from 'react';

export default class Alert extends Component {
  render() {
    return (
      <div className="alert alert-danger">
        <span>{this.props.message} <span onClick={() => window.browserHistory.push('/help')} style={{cursor: 'pointer', textDecoration: 'underline'}}>{this.props.help}</span></span>
      </div>
    );
  }
}
