import React, { Component } from 'react';
import Outbox from './Outbox.js';

export default class OutboxSuratKeterangan extends Component {
  render() {
    return (
      <Outbox type='TNDE_PENGUMUMAN'/>
    );
  }
}
