import React, { Component } from 'react';

/*

Usage : 

<Component label="Perihal" 
  name="particularFieldName" value={this.state.particularFieldName} 
  onChange={this.inputEventHandler} 
  required={true} 
  maxChar={500} 
  labelWidth=2
  fieldWidth=8
  disabled=false
  additionalLabel={'Hindari menyalin perihal dari Ms. Word atau situs web.'}
  options={[{value:"HANDWRITTEN",str:"Tanda tangan basah"}, {value:"SCANNED",str:"Tanda tangan scan"}]}
  defaultValue="SCANNED"
/>

*/

class component extends Component {
  state = {
    value : '',
  }
  componentDidMount = () => {
    if (this.props.value && this.props.value.length > 0) {
      this.setState({value: this.props.value}, () => {
        console.log(this.state);
        let e = { target : { name : this.props.name, value : this.state.value }}
        this.onChange(e);
      });
    } else {
      this.setState({value: this.props.defaultValue}, () => {
        console.log(this.state);
        let e = { target : { name : this.props.name, value : this.state.value }}
        this.onChange(e);
      });
    }
  }
  onChange = (event) => {
    if (this.props.maxChar && this.props.maxChar > 0 && event.target.value.length > this.props.maxChar) {
      return;
    }
    this.setState({value : event.target.value});
    this.props.onChange(event);
  }

  render() {
    const { 
      label,
      name,
      required,
      maxChar,
      labelWidth,
      fieldWidth,
      additionalLabel,
      disabled,
      options,
    } = this.props;
    return (
      <div className="form-group row" style={{marginTop:10}}>
        <label className={`col-md-${labelWidth ? labelWidth.toString() : '1'} col-sm-${labelWidth ? labelWidth.toString() : '2'} control-label`}>
          {label}
          &nbsp;<span className="red">{required && '*'}</span>
        </label>
        <div className={`col-md-${fieldWidth ? fieldWidth.toString() : '10'} col-sm-${fieldWidth ? fieldWidth.toString() : '10'}`}>
          <select name={name} value={this.state.value} onChange={this.onChange} required={required} className="form-control" disabled={disabled}>
            {Object.keys(options).map((item, index) => {
              return (<option key={index} value={options[item].value} selected={options[item].value===this.props.value ? 'selected':''}>{options[item].str}</option>)
            })}
          </select>
          { additionalLabel ? <span style={{float:'left', color:'red'}}>{additionalLabel}</span> : '' }
          { maxChar && maxChar > 0 ? <span style={{float:'right'}}>{this.state.value.length}/{maxChar}</span> : '' }
        </div>
      </div>
    );
  }
}

export default component;
