import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import swal from 'sweetalert';
import DateTimePicker from '../../DateTimePicker';

export default class AgendaReport extends Component {
  state = {
    currentUser: 'User Demo Office Automation01', // TODO fetch from redux
    letterType: '',
    documentType: '',
  }

  download = () => {
    swal({
      title: '',
      type: 'warning',
      text: 'Downloaded : ' + JSON.stringify(this.state)
    });
  }

  render() {
    return (
      <div className="box box-success">
        <div className="box-body" style={{ padding: 15 }}>
          <div className="form-grout row">
            <label className="control-label col-md-2">Pemohon</label>
            <div className="col-md-7">
              {this.state.currentUser}
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-2">Tipe Dokumen</label>
            <div className="col-md-7">
              <select className="form-control" value={this.state.documentType} onChange={(e) => { this.setState({ documentType: e.target.value }) }}>
                <option>Surat Masuk</option>
                <option>Surat Keluar</option>
                <option>Surat Disposisi</option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-2">Tipe Surat</label>
            <div className="col-md-7">
              <select className="form-control" value={this.state.letterType} onChange={(e) => { this.setState({ letterType: e.target.value }) }}>
                <option>Semua</option>
                <option>Surat Masuk Manual</option>
                <option>Nota Dinas</option>
                <option>Surat Tugas</option>
                <option>Surat Undangan</option>
                <option>Surat</option>
                <option>Memo</option>
                <option>Batal</option>
                <option>Surat Sudah Finalisasi</option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-2">Tanggal awal</label>
            <div className="col-md-7">
              <DateTimePicker
                value={this.state.startDate}
                onChange={(e) => { this.setState({ startDate: e.target.value }) }}
                fieldWidth={12}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-2">Tanggal akhir</label>
            <div className="col-md-7">
              <DateTimePicker
                value={this.state.startDate}
                onChange={(e) => { this.setState({ endDate: e.target.value }) }}
                fieldWidth={12}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
            </div>
            <div className="col-md-7">
              <button className="btn btn-primary" onClick={this.download}>
                <Fa.FaFile /> Unduh
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
