import React, { Component } from 'react';
import Outbox from './Outbox.js';

export default class OutboxLaporan extends Component {
  render() {
    return (
      <Outbox type='TNDE_LAPORAN'/>
    );
  }
}
