import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import asyncLib from 'async';
import './styles.css';
import Spinner from '../../../components/Spinner';
import Letter from '../../../services/Letter';
import Comment from '../../../services/Comment';
import Auth from '../../../services/Auth';
import Blob from '../../../services/Blob';
import AddressBookPersonal from './AddressBookPersonalModal';
import AddressBook from './AddressBookModal';
import Config from "../../../config";
import { connect } from "react-redux";
import LetterReadDetail from './LetterReadDetail';
import LetterReadDispositionHistory from './LetterReadDispositionHistory';
import LetterReadReferenceHistory from './LetterReadReferenceHistory';
import LetterReadCreateDisposition from './LetterReadCreateDisposition';
import LetterReadDisposition from './LetterReadDisposition';
import LetterBundleModal from './LetterBundleModal';
import InvitationListPDF from './InvitationListPDF';
import ProcessActionModal from './ProcessActionModal';
import FileUploader from './Compose/components/FileUploader/';
import DateTimePicker from '../components/DateTimePicker';
import SwAlert from 'sweetalert-react';
import swal from 'sweetalert';
import qs from 'qs';
import Settings from '../../../services/Settings';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import AttachmentViewer from './AttachmentViewer';
import hex from 'hex.js';
import qr from 'qr-image';
import downloadjs from 'downloadjs';
import Rapat from '../../../services/Rapat';
import stripTags from '../../../utils';
import '../../../print.css';
import printJS from 'print-js';
import LetterReadForwardHistory from './LetterReadForwardHistory';
import LetterReadLaporan from "./LetterReadLaporan";
import LetterReadSuratKeterangan from './LetterReadSuratKeterangan';
import LetterReadLembarRalat from './LetterReads/LembarRalat';
import LetterReadDispositionMonitoring from './LetterReadDispositionMonitoring';
import LetterReadPengumuman from './LetterReadPengumuman';
import LetterReadTelaahanStaf from './LetterReadTelaahanStaf';

const mapper = state => {
  return {
    testerMode: state.rootReducers.testerMode
  };
};

const LetterService = new Letter();
const CommentService = new Comment();
const SettingsService = new Settings();
const AuthService = new Auth();
const BlobService = new Blob();
const RapatService = new Rapat();
const usePDF = true;
var isSent;

export class LetterReadComponent extends Component {
  state = {
    currentLetter: {},
    attachmentPreviews: [],
    loading: true,
    query: {},
    processing: {},
    proceed: false,
    manualLetterReferenceNumber: '',
    manualLetterDate: null,
    letterHistoryLoading: false,
    letterHistories: {},
    letterComments: {},
    combinedHistories: [],
    draftView: false,
    manualSignedAttachmentLoading: false,
    files: [],
    dispositionRecipientOrigin: null,
    isDerivedPIC: false,
    dispositions: [],
    forAttention: {},
  };

  regeneratePDF = () => {

    if (usePDF === false) {
      return;
    }
    const letterBody = window.document.getElementById("letter-body");

    if (!letterBody) {
      return;
    }

    if (this.state.regeneratePDF) {
      this.generatePdf(letterBody.innerHTML).then(async (v) => {
        var pdfURL = await LetterService.getPDFLetter(this.props.uuid);
        let attachmentPreviews = this.state.attachmentPreviews;
        attachmentPreviews.unshift({
          filename: this.state.currentLetter.number + ".pdf",
          type: "aplication/pdf",
          url: pdfURL,
        });
        this.setState({ attachmentPreviews, pdfURL });
      });
    }
  };

  renderReferenceNumber = (letterState, number, manualNumber) => {
    return <span>
      {letterState === 'SENT' &&
        (number ? number : '_____')
      }
      {letterState !== 'SENT' && (manualNumber === null || manualNumber === '') &&
        (number ? number : '_____')
      }
      {letterState !== 'SENT' && manualNumber.length > 0 &&
        (manualNumber ? manualNumber : '_____')
      }
    </span>;
  };

  renderLetterDate = (letterState, letterDate, manualLetterDate, timestamp) => {
    return <span>
      {letterState === 'SENT' && date.format(letterDate, 'DD MMMM YYYY', { locale: dateLocaleId })}
      {letterState !== 'SENT' &&
        (manualLetterDate === null ||
          manualLetterDate === '') &&
        date.format(timestamp, 'DD MMMM YYYY', { locale: dateLocaleId })}
      {letterState !== 'SENT' &&
        manualLetterDate !== null &&
        manualLetterDate !== '' &&
        date.format(manualLetterDate, 'DD MMMM YYYY', { locale: dateLocaleId })}
    </span>;
  };

  componentDidMount = () => {
    let queryStr = window.location.href.split("?")[1] || '';
    var query = qs.parse(queryStr);

    SettingsService.getSettings()
      .then((settings) => {
        this.setState({
          settings,
          query,
        }, () => {
          this.read(this.props.uuid).then((state) => {
            this.setState(state);
          });
        });
      })
      .catch((err) => {
        if (err.state) {
          this.setState(err.state);
        } else {
          this.setState({ loading: false });
        }
      });
  };

  // If this value is set, the current user is the recipient of a disposition
  setDispositionRecipientOrigin = (id, isDerivedPIC) => {
    this.setState({ dispositionRecipientOrigin: id, isDerivedPIC: isDerivedPIC });
  };
  reopenDispositionState = () => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin membuka kembali disposisi ini?',
    }, (ok) => {
      if (ok) this.setDispositionState("OPEN");
    });
  };

  setDispositionState = (action) => {
    this.setState({ loading: true }, () => {
      // Backward compatibility for old disposition (before closing feauture get implemented)
      const { currentLetter, currentUser } = this.state;
      asyncLib.eachSeries(this.state.dispositions, (disposition, cb) => {
        let obj = {
          action: action,
          statusId: disposition.statusId,
        };
        if (currentLetter.isCurrentUserAPelaksana) {
          obj.asPelaksana = currentLetter.isCurrentUserAPelaksana;
          obj.pelaksanaType = currentLetter.currentUserPelaksanaType;
        }
        LetterService.setDispositionState(obj)
          .then(() => {
            cb();
          })
          .catch((err) => {
            cb(err);
          });
      }, (result, err) => {
        if (err) {
          this.setState({ loading: false });
          return;
        }
        let payload = {
          recipientUuid: currentUser.id,
          recipientOrgId: currentUser.organization.id,
          letterId: currentLetter.id,
          page: 1,
          limit: 999,
          year: this.state.year ? this.state.year : (new Date()).getFullYear(),
          archive: this.state.archiveView
        };
        if (currentUser.pltId && currentUser.pltId.length > 0) {
          payload.recipientPltId = currentUser.pltId;
        }
        if (currentUser.delegatedId && currentUser.delegatedId.length > 0) {
          payload.recipientPltId = currentUser.delegatedId;
        }
        LetterService.getDispositions(payload)
          .then((result) => {
            this.setState({ loading: false, dispositions: result.data });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      });
    });
  };

  finalizePreparation = () => {
    if (this.state.files.length == 0 && this.state.currentLetter.signatureType === 'HANDWRITTEN') {
      swal({
        title: '',
        type: 'warning',
        text: 'Lampiran tanda tangan basah belum diunggah',
      });
      return;
    }
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin memfinalkan surat ini dan mengirimnya?',
    }, (ok) => {
      if (ok) {
        // If there is manual signed attachment, update the attachment first
        const { files, currentLetter } = this.state;
        if (files.length > 0) {
          currentLetter.attachments = currentLetter.attachments && currentLetter.attachments[0] ? currentLetter.attachments : [];
          for (var i in files) {
            if (files[i].state === 'done' && files[i].uuid && files[i].uuid.length === 36) {
              currentLetter.attachments.push(files[i].uuid);
            }
          }

          // Sanitize the uuid: signers, signerNames, via, approvers, classification
          if (currentLetter.signers && currentLetter.signers.length > 0) {
            let signers = [];
            for (let i in currentLetter.signers) {
              signers.push(currentLetter.signers[i].split('|')[0]);
            }
            currentLetter.signers = signers;
          }
          if (currentLetter.signerNames && currentLetter.signerNames.length > 0) {
            let signerNames = [];
            for (let i in currentLetter.signerNames) {
              signerNames.push(currentLetter.signerNames[i].split('|')[0]);
            }
            currentLetter.signerNames = signerNames;
          }
          if (currentLetter.via && currentLetter.via.length > 0) {
            let via = [];
            for (let i in currentLetter.via) {
              via.push(currentLetter.via[i].split('|')[0]);
            }
            currentLetter.via = via;
          }
          if (currentLetter.approvers && currentLetter.approvers.length > 0) {
            let approvers = [];
            for (let i in currentLetter.approvers) {
              approvers.push(currentLetter.approvers[i].split('|')[0]);
            }
            currentLetter.approvers = approvers;
          }
          if (currentLetter.classification && currentLetter.classification.length > 0) {
            currentLetter.classification = currentLetter.classification[i].split('|')[0];
          }

          LetterService.updateLetter(currentLetter)
            .then(() => this.finalize())
            .catch((err) => {
              console.log(err);
              this.setState({
                loading: false,
              });
            });
        } else {
          this.finalize().catch((err) => {
            console.log(err);
            this.setState({
              loading: false,
            });
          });
        }
      };
    });
  };

  getRapatParticipants = () => {
    let participants = [];
    let pMap = {}; // avoid duplicates
    const { currentLetter } = this.state;
    if (!currentLetter.recipients) {
      return participants;
    }
    if (currentLetter.recipients && currentLetter.recipients.length > 0) {
      currentLetter.recipients.map((item) => {
        const exists = pMap[item.recipientUuid];
        if (exists) return null;
        pMap[item.recipientUuid] = 1;
        return participants.push({
          "participant-id": item.recipientUuid,
          "role": "PARTICIPANT"
        });
      });
    }

    if (currentLetter.signers && currentLetter.signers.length > 0) {
      currentLetter.signers.map((item) => {
        let signerUuid = item.split('|')[0];
        if (!pMap[signerUuid]) {
          pMap[signerUuid] = 1;
          participants.push({
            "participant-id": signerUuid,
            "role": "MODERATOR"
          });
        }
        return true;
      });
    }

    if (currentLetter.approvers && currentLetter.approvers.length > 0) {
      currentLetter.approvers.map((item) => {
        let uuid = item.split('|')[0];
        if (!pMap[uuid]) {
          pMap[uuid] = 1;
          participants.push({
            "participant-id": uuid,
            "role": "PARTICIPANT"
          });
        }
        return true;
      });
    }

    let uuid = currentLetter.originator;
    if (!pMap[uuid]) {
      pMap[uuid] = 1;
      participants.push({
        "participant-id": uuid,
        "role": "PARTICIPANT"
      });
    }

    return participants;
  };

  isDispositionOriginator = () => {
    const { dispositions, currentUser } = this.state;
    for (let i in dispositions) {
      if (dispositions[i].originator === currentUser.organization.id) return true;
    }
    return false;
  };
  componentDidUpdate() {
    const letter = this.state.currentLetter;
    if (letter.letterType === "TNDE_UNDANGAN" && letter.letterState === "FINAL" && letter.recipients.length > 5) {
      const letterDate = date.format(letter.timestamp, 'DD MMMM YYYY', { locale: dateLocaleId });
      let HTMLSource = `
          <html>
          <head>
            <style type="text/css">
              @page {size: A4;position: relative;overflow: hidden;margin: 78px 0px 449px 0px;width: 794px;}
              .ft0{font: 14px 'Arial';}
              .ft1,td{font: 18px 'Arial';}
              .ft2{font: 20px 'Arial';}
              .behalf{width:100px; word-wrap:break-word;}
            </style>
          </head>
          <body>
            <page>
              <br/>
              <br/>
              <div style="text-align: left;padding-left: 520px;">
                <p class="ft0">LAMPIRAN</p>
                <p class="ft0" style="margin-top: 4px;">Undangan ${letter.signerOrg}</p>
                <p class="ft0" style="margin-top: -10px;">Nomor&nbsp&nbsp&nbsp: _number_</span></p>
                <p class="ft0" style="margin-top: -10px;">Tanggal&nbsp: ${letterDate}</span></p>
              </div>
              <p class="ft2" style="text-align: center;margin-top: 75px;">DAFTAR PEJABAT/PEGAWAI YANG DIUNDANG</p><br><br>\n`;
      for (let i in this.state.defaultRecipients) {
        HTMLSource += '<p class="ft1" style="text-align: left;padding-left: 80px;margin-top: -10px;">' +
          `${Number(i) + 1}. ${this.state.defaultRecipients[i].recipientName}` +
          '</p>\n';
      }
      HTMLSource += `
        <table style="text-align: left;padding-left: 520px;margin-top: 46px">
          <tbody>
            ${(letter.onBehalfOfTitle) ? `<tr>
              <td valign="top">a.n.</td>
              <td class="behalf">${letter.onBehalfOfTitle}</td>
            </tr>`: ``}
            <tr>
              <td></td>
              <td>${letter.signerOrg}</td>
            </tr>`;

      if (letter.signatureType === "SCANNED") {
        BlobService.downloadToDataURI(Config.apiHost + "/api/v1/blob/unrestricted/" + letter.scannedSignature)
          .then((res) => {
            HTMLSource += `
                <tr>
                  <td></td>
                  <td><img height="140" src="${res.uri}"></td>
                </tr>
                <tr>
                  <td></td>
                  <td>${letter.signerName}</td>
                </tr>
                <tr>
                  <td></td>
                  ${this.state.settings.useNIPOnLetter === 'TRUE' ? `<td>NIP. ${letter.signerNIP}</td>` : ""}
                </tr>
              </tbody></table></page></body></html>`;

            invitationHTMLSource = HTMLSource;
          }).catch((err) => {
            if (err.response.status === 404 || !letter.scannedSignature) {
              swal({
                title: 'Surat Tidak Dapat Difinalisasi',
                text: 'Surat ini membutuhkan Tanda Tangan Scan, Hubungi penanda tangan untuk melakukan penyetelan pada laman Profile.',
                confirmButtonText: "Kembali"
              }, () => {
                window.browserHistory.push('/processing/finalization');
              });
            }
          });
      } else {
        HTMLSource += `
            <tr>
              <td></td>
              <td style="height:140"></td>
            </tr>
            <tr>
              <td></td>
              <td>${letter.signerName}</td>
            </tr>
            <tr>
              <td></td>
              ${this.state.settings.useNIPOnLetter === 'TRUE' ? `<td>NIP. ${letter.signerNIP}</td>` : ""}
            </tr>
          </tbody></table></page></body></html>`;

        invitationHTMLSource = HTMLSource;
      }
    }
  }

  reallyFinalize = (payload) => {
    // Prepare PDF source
    const body = window.document.getElementById("letter-body").innerHTML;
    return LetterService.getCSSForPDF().then((v) => {
      var url = window.location.href;
      var arr = url.split("/");
      url = arr[0] + "//" + arr[2];
      var style = v.data.replace("media print", "media all");
      style = style.replace("@page ", "body");
      const html = "<html><head><style> " +
        style +
        " </style></head><body><div id='letter-body'>" +
        body.replace(/"\/static\/media/g, "\"" + url + "/static/media")
          /* https://repo.dev.citra.digital/OA/oa-general/issues/602 */
          // eslint-disable-next-line
          .replace(/\\/g, '\\\\') +
        "</div></body></html>";

      return this.imagesToBase64(html)
        .then((cleanHtml) => {
          if (usePDF) {
            payload.PDFHTMLSource = cleanHtml;
          }
          if (invitationHTMLSource && this.state.currentLetter.letterType === "TNDE_UNDANGAN" && this.state.currentLetter.letterState === "FINAL") {
            payload.invitationPDFHTMLSource = invitationHTMLSource;
          }

          return this.processLetter(payload);
        })
        .catch((err) => {
          console.log(err);
          // If it fail, keep process the payload
          return this.processLetter(payload);
        });
    });
  };

  processLetter = (payload) => {
    this.setState({ loading: true }, () => {
      LetterService.processLetter(this.state.currentLetter.id, payload)
        .then((result) => {
          swal({
            title: '',
            text: 'Surat berhasil difinalkan dan terkirim.'
          });
          window.browserHistory.push('/processing/finalized');
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            let msg;
            let errMessage = err.response.data.message;
            if (errMessage.indexOf("No kode_unit found") > -1) {
              msg = 'Penanda tangan belum memiliki kode unit. Silakan hubungi Administrator.';
            } else if (errMessage.indexOf("Approver kode_unit not found") > -1) {
              msg = 'Pemeriksa terakhir belum memiliki kode unit. Silakan hubungi Administrator.';
            }
            swal({ title: '', text: msg, type: 'warning' });
          }
          this.setState({ loading: false });
        });
    });
  };

  finalize = () => {
    const { manualLetterReferenceNumber, manualLetterDate, currentLetter } = this.state;
    let payload = {};
    payload.state = 'FINALIZED';

    if (manualLetterReferenceNumber && manualLetterReferenceNumber.length > 0) {
      payload.letterReferenceNumber = manualLetterReferenceNumber;
    }
    if (manualLetterDate) {
      payload.letterDate = manualLetterDate;
    }

    if (currentLetter.invitationRapatRoom === "booked") {
      payload.rapatRoom = "";
      const participants = this.getRapatParticipants();
      const startTime = Date.parse(currentLetter.invitationStartDateTime);
      const endTime = Date.parse(currentLetter.invitationEndDateTime);

      var newEndTime = currentLetter.invitationEndDateTime;
      if (endTime <= startTime) {
        var dd = new Date(0);
        const sixHours = 6 * 60 * 60 * 1000;
        dd.setUTCMilliseconds(startTime + sixHours);
        newEndTime = dd.toISOString();
      }


      return RapatService.create({
        title: currentLetter.subject,
        "start-time": currentLetter.invitationStartDateTime,
        "end-time": newEndTime,
        "view-type": "PRIVATE",
        participants
      }).then((v) => {
        payload.rapatRoom = v["rapat-id"];
        return this.reallyFinalize(payload);
      }).catch((err) => {
        throw (err);
      });
    } else {
      return this.reallyFinalize(payload);
    }
  };

  // Deprecated
  // PDF generation is now requested on backend
  generatePdf = (body) => {
    // body is only available when swal is not yet up

    return LetterService.getCSSForPDF().then((v) => {
      var url = window.location.href;
      var arr = url.split("/");
      url = arr[0] + "//" + arr[2];
      var style = v.data.replace("media print", "media all");
      style = style.replace("@page ", "body");
      const html = "<html><head><style> " +
        style +
        " </style></head><body><div id='letter-body'>" +
        body.replace(/"\/static\/media/g, "\"" + url + "/static/media")
          /* https://repo.dev.citra.digital/OA/oa-general/issues/602 */
          // eslint-disable-next-line
          .replace(/\\/g, '\\\\') +
        "</div></body></html>";
      return LetterService.createPDFLetter(html, this.state.currentLetter.id);
    });
  };

  // Deprecated
  // PDF generation is now requested on backend
  finalizePdf = (body) => {
    if (!usePDF) {
      return this.finalize();
    }

    return this.generatePdf(body).then((v) => {
      return this.finalize();
    }).catch(console.log);
  };

  downloadAttachment = (url, filename) => {
    BlobService.downloadToDataURI(url)
      .then((res) => {
        downloadjs(res.uri, filename, res.type);
      })
      .catch(console.log);
  };

  forwardToAssisted = () => {
    const { currentLetter } = this.state;
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin meneruskan surat ini sebagai asisten?',
    }, (ok) => {
      if (ok) {
        if (!currentLetter.assistedRecipient || (currentLetter.assistedRecipient && currentLetter.assistedRecipient.length < 1)) {
          swal({
            title: '',
            text: 'Surat ini tidak sedang dalam proses asistensi. Hubungi administrator.'
          });
          return;
        }
        this.setState({ loading: true }, () => {
          let item = {};
          item.recipientUuid = currentLetter.assistedRecipient;
          item.recipientName = currentLetter.assistedRecipient;
          item.recipientType = 'ASSISTED';
          let payload = [item];
          LetterService.forward(currentLetter.id, payload)
            .then(() => {
              swal({
                title: '',
                text: 'Surat berhasil diteruskan.'
              });
              window.browserHistory.push('/inbox/all');
            })
            .catch((err) => {
              swal({
                title: '',
                text: 'Surat tidak berhasil diteruskan.'
              });
              this.setState({
                loading: false,
              });
            });
        });
      }
    });
  };

  forwardReview = () => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin meneruskan surat ini sebagai pemeriksa?',
    }, (ok) => {
      if (ok) {
        if (this.state.isReviewed) {
          swal({
            title: '',
            text: 'Surat ini tidak sedang dalam proses pemeriksaan. Hubungi administrator.'
          });
          return;
        }
        this.setState({ loading: true }, () => {
          let item = {};
          item.recipientType = 'REVIEWED';
          item.recipientUuid = this.state.currentLetter.recipients[0].recipientUuid;
          item.recipientName = this.state.currentLetter.recipients[0].recipientName;
          let payload = [item];
          LetterService.forward(this.state.currentLetter.id, payload)
            .then((result) => {
              swal({
                title: '',
                text: 'Surat berhasil diteruskan.'
              });
              window.browserHistory.push('/inbox/all');
            })
            .catch((err) => {
              swal({
                title: '',
                text: 'Surat tidak berhasil diteruskan.'
              });
              this.setState({
                loading: false,
              });
            });
        });
      }
    });
  };

  reply = (type) => {
    let url = '/compose/' + type + '?reference=' + this.state.currentLetter.id + '|' + hex.hex(this.state.currentLetter.number);
    if (this.state.dispositions && this.state.dispositions.length > 0) {
      let statusIds = [];
      for (let i in this.state.dispositions) {
        if (this.state.dispositions[i].statusId && statusIds.indexOf(this.state.dispositions[i].statusId) < 0) {
          statusIds.push(this.state.dispositions[i].statusId);
        }
      }
      if (statusIds.length > 0) {
        url += '|';
        for (let i in statusIds) {
          if (i > 0) url += ',';
          url += statusIds[i];
        }
      }
    }
    window.browserHistory.push(url);
  };


  read = (id) => {
    this.setState({ mode: 'read', combinedHistories: [], loading: true });

    let tempState = { combinedHistories: [] };
    let currentUser;
    let defaultRecipients = [];
    let ccRecipients = [];
    let isSender = false;
    let isAssistant = false;
    let isReviewer = false;
    let isReviewed = true;
    let isProcessChainHolder = false;
    let isInProcessChain = false;
    let isEditable = false;
    let canBePulled = true;
    let isBundlerProcChainHolder = false;
    let isBundlerBeingFinalized = false;
    let isRecipient = false;
    let currentUserIsSigner = false;
    let isBccSecret = false;
    let isDispositionRecipient;
    let isReturned = false;
    let currentLetter = {};
    let forAttention = {};
    let isDoneAsProcessingLetter = false;

    return LetterService.readLetter(id)
      .then((result) => {
        currentUser = JSON.parse(localStorage.getItem('current_user'));
        // Determine whether the current user is the sender (konseptor, approver, via, signer)
        // or the recipients
        var senders = [];
        senders.push(result.originator);
        if (result.approvers && result.approvers.length > 0) {
          for (let i in result.approvers) {
            senders.push(result.approvers[i].split('|')[0]);
          }
        }
        if (result.via && result.via.length > 0) {
          for (let i in result.via) {
            senders.push(result.via[i].split('|')[0]);
          }
        }

        if (result.signers && result.signers.length > 0) {
          for (let i in result.signers) {
            senders.push(result.signers[i].split('|')[0]);
          }
        }

        currentLetter = Object.assign({}, result);
        if (currentLetter.letterState === 'FINAL') {
          isBundlerBeingFinalized = true;
        }

        // Is the current user a sender?
        isRecipient = result.isCurrentUserARecipient;
        isDispositionRecipient = result.isCurrentUserADispositionRecipient;
        if (result.isCurrentUserABundlerProcessChainHolder) {
          isEditable = true;
          isBundlerProcChainHolder = true;
        }
        isReturned = result.letterState === 'RETURNED';

        if (result.hasAskedOpinion) canBePulled = false;

        for (let i in senders) {
          if (currentUser.organization.id === senders[i]) currentUserIsSigner = true;

          if (currentUser.id === senders[i] ||
            currentUser.organization.id === senders[i] ||
            currentUser.delegatedId === senders[i] ||
            currentUser.pltId === senders[i]) {
            isEditable = true;
            isSender = true;
            break;
          }
        }
        // Lets check whether the current user is a recipient, if so, override isSender to false
        if (result.recipients) {
          result.recipients = result.recipients.filter((item, index) => index === result.recipients.findIndex((obj) => {
            return obj.recipientName === item.recipientName && obj.recipientType === item.recipientType;
          }));

          result.recipients = result.recipients.sort((a, b) => { return a.recipientOrder - b.recipientOrder; });
          let externViaInternal = [];

          // Is it a group?
          for (var i = result.recipients.length - 1; i >= 0; i--) {
            for (var j = result.recipients.length - 1; j >= 0; j--) {
              if (result.recipients[i].recipientUuid === result.recipients[j].parent && result.recipients[i].recipientUuid !== result.recipients[j].recipientUuid) {
                result.recipients[i].isGroup = true;
              }
            }
          }
          for (let i in result.recipients) {

            if (result.recipients[i].recipientUuid === currentUser.id ||
              currentUser.delegatedId === result.recipients[i].recipientUuid ||
              result.recipients[i].recipientUuid === currentUser.organization.id ||
              result.recipients[i].recipientUuid === currentUser.pltId) {
              isSender = false;
              if (result.recipients[i].recipientType === 'ASSISTANT') {
                isAssistant = true;
              }
              if (result.recipients[i].recipientType === 'REVIEWER' ||
                (result.recipients[i].recipientType === 'REVIEWER' && currentUser.delegatedId === result.recipients[i].recipientUuid) ||
                (result.recipients[i].recipientType === 'REVIEWER' && currentUser.pltId === result.recipients[i].recipientUuid)) {
                isReviewer = true;
              }
            }

            if (result.recipients[i].recipientType.indexOf('_REVIEWED') > -1) {
              isReviewed = false;
            }
            // Looking for bcc secret
            if (result.recipients[i].recipientType === 'BCC_SECRET' &&
              result.recipients[i].recipientUuid === currentUser.organization.id) {
              isBccSecret = true;
            }

            if (Config.manualLetterReviewerEnabled) {
              if (result.recipients[i].recipientUuid === result.reviewer) {
                currentLetter.reviewedRecipientName = result.recipients[i].recipientName;
              }
            }
            if (result.recipients[i].recipientUuid === result.assistedRecipient) {
              currentLetter.assistedRecipientName = result.recipients[i].recipientName;
            }
            // Skip if it's an assistant or delegated one
            if (result.recipients[i].recipientType === 'ASSISTANT' || result.recipients[i].recipientType === 'DELEGATED') {
              continue;
            }
            if (result.recipients[i].recipientType.indexOf('_FOR_ATTENTION') > -1) {
              forAttention = result.recipients[i];
              continue;
            }
            if (result.recipients[i].recipientType.toLowerCase().indexOf('cc') > -1 && result.recipients[i].recipientType !== "BCC_SECRET") {
              ccRecipients.push(result.recipients[i]);
            } else {
              if (result.recipients[i].recipientType === 'BCC_SECRET') continue;
              defaultRecipients.push(result.recipients[i]);
            }
            if (result.recipients[i].recipientType.toLowerCase().indexOf('via') > -1) {
              if (!currentLetter.externVia) currentLetter.externVia = [];
              externViaInternal.push(result.recipients[i].recipientName);
            }
          }
          externViaInternal.reverse();
          for (let i in externViaInternal) {
            currentLetter.externVia.unshift(externViaInternal[i]);
          }
        }

        // Exclude via, assistant and forward from defaultRecipients
        let tmpRecipients = [];
        for (let i in defaultRecipients) {
          let currRecipientType = defaultRecipients[i].recipientType.toLowerCase();
          if (
            !defaultRecipients[i].isGroup &&
            currRecipientType.indexOf('via') < 0 &&
            currRecipientType.indexOf('assistant') < 0 &&
            currRecipientType.indexOf('reviewer') < 0 &&
            currRecipientType.indexOf('delegation') < 0 &&
            currRecipientType.indexOf('forward') < 0
          ) {
            tmpRecipients.push(defaultRecipients[i]);
          }
        }
        defaultRecipients = tmpRecipients;

        // Determine whether the current user (non originator) is in process chain and can edit the letter
        if (result.processings && result.processings.length > 0) {
          result.processings.reverse();
          let prevState = '';
          for (let i in result.processings) {
            let currentProcessing = result.processings[i];
            if (currentProcessing.state === 'DONE' && currentUser.id === currentLetter.originator) {
              canBePulled = false;
            }
            if (currentProcessing.state === 'DONE' && (currentUser.id === currentProcessing.holder || currentUser.organization.id === currentProcessing.holder) && prevState === 'DONE') {
              canBePulled = false;
            }
            if ((currentProcessing.state === 'WAIT' || currentProcessing.state === 'PROCESS') && (currentUser.id === currentProcessing.holder || currentUser.organization.id === currentProcessing.holder)) {
              canBePulled = false;
            }
            if (currentUser.id === currentProcessing.holder ||
              currentUser.organization.id === currentProcessing.holder ||
              (currentUser.pltId && currentUser.pltId.length > 0 && currentUser.pltId === currentProcessing.holder) ||
              (currentUser.delegatedId && currentUser.delegatedId.length > 0 && currentUser.delegatedId === currentProcessing.holder)) {
              isEditable = true;
              isInProcessChain = true;
              // If so, let's override isRecipient flag.
              if (currentProcessing.state !== 'DONE') {
                isRecipient = false;
              }
              if (currentProcessing.state !== 'WAIT') {
                isReturned = false;
              }
              if (currentProcessing.state === 'PROCESS') {
                isProcessChainHolder = true;
              }
              if (currentProcessing.state === 'DONE') {
                isDoneAsProcessingLetter = true
              }
            }
            prevState = currentProcessing.state;
          }
          result.processings.reverse();
        }
        let payload = {
          recipientUuid: currentUser.id,
          recipientOrgId: currentUser.organization.id,
          letterId: currentLetter.id,
          page: 1,
          limit: 999,
          year: this.state.year ? this.state.year : (new Date()).getFullYear(),
          archive: this.state.archiveView
        };
        if (currentUser.pltId && currentUser.pltId.length > 0) {
          payload.recipientPltId = currentUser.pltId;
        }
        if (currentUser.delegatedId && currentUser.delegatedId.length > 0) {
          payload.recipientPltId = currentUser.delegatedId;
        }

        let files = this.state.files;
        for (let i in result.attachments) {
          let splitted = currentLetter.attachments[i].split(',');
          if (splitted.length === 5 && splitted[4] === "SIGNED_ATTACHMENT" && result.letterState !== 'SENT') {
            let file = {
              file: {
                name: splitted[3]
              },
              state: 'done',
              uuid: splitted[0],
            };
            files.push(file);
            delete currentLetter.attachments[i];
          }
        }
        this.setState({ files });
        return LetterService.getDispositions(payload);
      }).then((result) => {
        // Check whether the current user is a recipient of this letter's dispositions
        for (let i in result.data) {
          if (result.data[i].recipients[0].indexOf(currentUser.id) > -1) {
            isSender = false;
            break;
          }
        }
        // Check for attachments previews, only viewing pdf, png, jpg, and jpeg
        let previews = [];
        for (let i in currentLetter.attachments) {
          let splitted = currentLetter.attachments[i].split(',');
          if (splitted.length !== 5) {
            continue;
          }
          if (!(splitted[2] === 'application/pdf' || splitted[2] === 'image/png' || splitted[2] === 'image/jpeg' || splitted[2] === 'image/jpg')) {
            continue;
          }
          previews.push({
            filename: splitted[3],
            url: Config.apiHost + "/api/v1/blob/" + splitted[0],
            type: splitted[2],
          });
        }
        // QR image
        let qrImageStr = 'Nomor surat: ' + (currentLetter.number ? currentLetter.number : '_____');
        qrImageStr += ', Ditandatangani oleh: ' + currentLetter.signerName;
        qrImageStr += ', Tanggal: ' + date.format(currentLetter.letterDate, 'DD/MM/YYYY', { locale: dateLocaleId });
        qrImageStr += ', URL: ' + Config.apiHost + '/read/' + currentLetter.id;
        const qrSVG = qr.imageSync(qrImageStr, { type: 'svg', size: 2.8 });
        currentLetter.qrImage = `data:image/svg+xml;base64,${window.btoa(qrSVG)}`;
        tempState = {
          ...tempState, ...{
            dispositions: result.data, // dispositions from current promise scope
            isSender,
            currentUserIsSigner,
            isEditable,
            isAssistant,
            isReviewer,
            isReviewed,
            isBccSecret,
            isInProcessChain,
            isProcessChainHolder,
            isReturned,
            defaultRecipients,
            isRecipient,
            isDispositionRecipient,
            isBundlerProcChainHolder,
            isBundlerBeingFinalized,
            ccRecipients,
            currentLetter,
            loading: false,
            currentUser,
            attachmentPreviews: previews,
            currentPreview: 0,
            canBePulled,
            forAttention,
            isDoneAsProcessingLetter,
          }
        };

        if (this.state.query && this.state.query.action && this.state.query.action === 'draftView') {
          tempState = { ...tempState, ...{ draftView: true } };
        }
        if (this.state.query && this.state.query.archiveView && this.state.query.archiveView === 'true') {
          tempState = { ...tempState, ...{ archiveView: true } };
        }
        if (this.state.query && this.state.query.year) {
          tempState = { ...tempState, ...{ year: this.state.query.year } };
        }

        let proc = {
          holder: currentUser.id,
          holderUsername: currentUser.username,
        };
        if (tempState.currentLetter.isCurrentUserAPelaksana &&
          tempState.currentLetter.currentUserPelaksanaType &&
          tempState.currentLetter.currentUserPelaksanaType === 'TUGAS') {
          proc.holder = currentUser.pltId;
        }
        if (tempState.currentLetter.isCurrentUserAPelaksana &&
          tempState.currentLetter.currentUserPelaksanaType &&
          tempState.currentLetter.currentUserPelaksanaType === 'HARIAN') {
          proc.holder = currentUser.delegatedId;
        }
        let procByOrg = {
          holder: currentUser.organization.id,
          holderUsername: currentUser.username,
        };
        for (var i in tempState.currentLetter.processings) {
          const processing = tempState.currentLetter.processings[i];
          if (processing.state === 'PROCESS') {
            if (processing.holder === procByOrg.holder) {
              proc.holder = procByOrg.holder;
            }
            proc.state = processing.state;
            proc.id = processing.id;
            proc.role = processing.role.toLowerCase();
            break;
          }
        }

        tempState = { ...tempState, ...{ processing: proc } };
        return Promise.resolve(tempState);
      }).then((tempState) => {
        // Convert all source image(s) to base64
        return this.imagesToBase64(tempState.currentLetter.contents);
      }).then((contents) => {
        tempState.currentLetter.contents = contents;
        return Promise.resolve(tempState);
      }).then(async (tempState) => {

        if (this.state.query &&
          this.state.query.action &&
          (this.state.query.action === 'process' ||
            this.state.query.action === 'opinion')) {

          var result = await LetterService.getOpinion(currentLetter.id);
          if (result && result.data && result.data.length > 0) {
            let obj = result.data[0];
            result = await LetterService.getComments(result.data[0].id);
            obj.comments = result;
            for (let i in obj.comments.data) {
              LetterService.setReadStatus(obj.comments.data[i].id, 'COMMENT');
            }
            tempState = { ...tempState, ...{ opinion: obj } };
          } else {
            tempState = { ...tempState, ...{ opinion: {} } };
          }
        }

        if (tempState.processing.state === 'PROCESS' ||
          tempState.currentLetter.letterState === 'DRAFT' ||
          tempState.currentLetter.letterState === 'PROCESS' ||
          tempState.currentLetter.letterState === 'FINAL' ||
          tempState.currentLetter.letterState === 'RETURNED' ||
          tempState.currentLetter.letterState === 'CANCELED' ||
          tempState.currentLetter.letterState === 'SENT') {

          result = await LetterService.getHistories(tempState.currentLetter.id);

          tempState = { ...tempState, ...{ letterHistories: result, letterHistoryLoading: false } };
          result = await LetterService.getComments(tempState.currentLetter.id);
          tempState = { ...tempState, ...{ letterHistoryLoading: false, letterComments: result } };
          // Combine
          let combinedHistories = tempState.combinedHistories;
          for (let i in tempState.letterComments.data) {
            combinedHistories.push(tempState.letterComments.data[i]);
          }
          for (let i in tempState.letterHistories.data) {
            combinedHistories.push(tempState.letterHistories.data[i]);
          }
          combinedHistories.sort(function (a, b) { return new Date(a.timestamp) - new Date(b.timestamp); });
          tempState = { ...tempState, ...{ combinedHistories: combinedHistories } };
        }

        return Promise.resolve(tempState);
      }).then(async (tempState) => {
        let pdfURL;
        if (tempState.currentLetter.letterState === 'SENT') {
          try {
            pdfURL = await LetterService.getPDFLetter(this.props.uuid);
            tempState.attachmentPreviews.unshift({
              filename: tempState.currentLetter.number + ".pdf",
              type: "application/pdf",
              url: pdfURL,
            });
          } catch (e) {
            if (tempState.currentLetter && tempState.currentLetter.letterState === "SENT") {
              tempState.regeneratePDF = true;
            }
          }
        }
        tempState = { ...tempState, ...{ pdfURL, loading: false } };
        return Promise.resolve(tempState);
      }).catch((err) => {
        tempState = { ...tempState, ...{ loading: false } };
        console.log("ERR----------------------------------", err.response);
        console.log(err);
        if (err && err.message && err.message.indexOf('403') > -1) {
          swal({
            title: '',
            type: 'warning',
            text: 'Anda tidak memiliki hak akses terhadap surat ini.'
          });
          window.browserHistory.goBack();
          return;
        }
        err.state = tempState;
        this.setState(err.state);
      });
  };

  // This convert all images (that originated from it's own domain) to base64
  imagesToBase64 = (htmlString) => {
    return new Promise((resolve, reject) => {
      var imgAssets = [];
      var imgs = htmlString.split('<img');
      imgs.splice(0, 1);
      for (let i in imgs) {
        let asset = {};
        asset.url = imgs[i].trim().split('src="')[1].split('"')[0];
        if (asset.url.indexOf('data') === 0) continue;
        if (asset.url.indexOf(Config.apiHost.split(':')[0] + ':' + Config.apiHost.split(':')[1]) > -1) {
          if (imgs[i].split('/>').length > 1) { // img that ends with />
            asset.el = '<img' + imgs[i].split('/>')[0] + '/>';
          } else if (imgs[i].split('>').length > 1) { // img that ends with >
            asset.el = '<img' + imgs[i].split('>')[0] + '>';
          }
          imgAssets.push(asset);
        }
      }
      asyncLib.eachSeries(imgAssets, (asset, cb) => {
        fetch(asset.url, {}).then(function (response) {
          let prefix = `data:${response.headers.get('content-type').split(';')[0]};base64, `;
          if (prefix.indexOf('svg') > -1) { // Skip svg
            return cb();
          }
          response.arrayBuffer()
            .then((buffer) => {
              let b64str = window.btoa([].slice.call(
                new Uint8Array(buffer)).map((bin) => {
                  return String.fromCharCode(bin);
                }).join("")
              );
              let newEl = asset.el;
              let newUrl = prefix + b64str;
              newEl = newEl.replace(asset.url, newUrl);
              htmlString = htmlString.replace(asset.el, newEl);
              cb();
            })
            .catch(cb);
        });
      }, (err) => {
        if (err) {
          return reject();
        }
        resolve(htmlString);
      });
    });
  };

  back = () => {
    if (this.state.draftView) {
      this.setState({ draftView: true });
      window.browserHistory.push('/processing/draft');
    } else {
      if (this.state.query && this.state.query.redirectByHelper) {
        window.browserHistory.go(-3);
        setTimeout(() => {
          window.browserHistory.go(1);
        }, 200);
      } else {
        window.browserHistory.goBack();
      }
    }
  };
  backToRead = () => {
    this.setState({ mode: 'read' });
  };

  backToListFromDisposition = () => {
    setTimeout(() => {
      window.browserHistory.goBack();
    }, 500);
  };

  readDispositionMonitoring = () => {
    this.setState({ mode: 'readDispositionMonitoring' });
  };
  readDetail = () => {
    this.setState({ mode: 'readDetail' });
  };
  readDispositionHistory = () => {
    this.setState({ mode: 'readDispositionHistory' });
  };
  readReferenceHistory = () => {
    this.setState({ mode: 'readReferenceHistory' });
  };
  createDisposition = () => {
    this.setState({ mode: 'createDisposition' });
  };
  readDisposition = () => {
    this.setState({ mode: 'readDisposition' });
  };
  readForwardHistory = () => {
    this.setState({ mode: 'readForwardHistory' });
  };
  compose = () => {
    window.browserHistory.push('/compose/' + this.state.currentLetter.letterType + '?uuid=' + this.state.currentLetter.id);
  };
  proceed = (action) => {
    if (this.state.opinion && this.state.opinion.id && !this.state.opinion.isAnswered) {
      swal({
        title: '',
        type: 'warning',
        text: 'Konsep surat ini masih menunggu pendapat dari ' + this.state.opinion.recipientName
      });
      return;
    }
    this.setState({ proceed: true, action });
  };
  onProcessed = (action) => {
    this.setState({ proceed: false, query: {} });
  };
  onCanceled = () => {
    this.setState({ action: '', proceed: false });
  };
  attachmentPreviewNext = () => {
    this.setState({ currentPreview: this.state.currentPreview + 1 });
  };
  attachmentPreviewPrev = () => {
    this.setState({ currentPreview: this.state.currentPreview - 1 });
  };

  print = () => {
    const { attachmentPreviews, currentPreview, currentLetter } = this.state;
    if (!usePDF) {
      this.printFromHTML();
      return;
    } else if (attachmentPreviews && attachmentPreviews.length > 0 && currentLetter.letterState === "SENT") {
      const { url } = attachmentPreviews[currentPreview];
      BlobService.downloadToDataURI(url)
        .then((res) => {
          if (res.type === "application/pdf") {
            printJS({ printable: res.uri.split(',')[1], type: 'pdf', base64: true })
          } else if (res.type.split("/")[0] === "image") {
            printJS({ printable: res.uri, type: 'image' })
          }
        })
        .catch(console.log);
      return;
    }

    // with PDF feature
    if (isSent) {
      let url;
      LetterService.getPDFLetter(this.props.uuid).then((result) => {
        url = result;
        printJS(url, 'pdf');
      }).catch((error) => {
        console.log(error);
        // Fail safe for Mozilla Firefox since printJS does not support non-chromium browser
        // https://bugzilla.mozilla.org/show_bug.cgi?id=911444
        this.downloadAttachment(
          Config.apiHost + '/api/v1/pdf/view/' + this.props.uuid + '?download=true',
          this.state.currentLetter.letterType + '.pdf'
        );
      });
    } else {
      // On-process letter has no generated PDF yet,
      // print the HTML version instead
      this.printFromHTML();
    }
  };

  printFromHTML = () => {

    var body = document.getElementById('letter-body');
    if (!body) {
      swal({
        title: '',
        type: 'warning',
        text: 'Belum ada konten surat.'
      });
      return;
    }
    var printWindow = window.open('', '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, visible=none, height=600, width=1000', '');
    printWindow.document.write('<html><head><title>' + document.title + '</title>');
    printWindow.document.write('  <link rel="stylesheet" type="text/css" href="/print.css">');
    printWindow.document.write('</head><body id="letter-body" style="width:210mm; height: 297mm;">');
    printWindow.document.write(body.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close(); // necessary for IE >= 10
    printWindow.document.body.onload = function () {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };
  pullBack = () => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menarik surat ini dari proses?',
    }, (ok) => {
      if (ok) {
        setTimeout(() => {
          this.setState({ action: '', loading: true }, () => {
            const letter = this.state.currentLetter;
            if (this.state.currentUser.pltId === letter.originator && letter.originatorTitle.indexOf('Plt.') > -1) {
              letter.isCurrentUserAPelaksana = true;
              letter.currentUserPelaksanaType = 'TUGAS';
            } else if (this.state.currentUser.delegatedId === letter.originator && letter.originatorTitle.indexOf('Plh.') > -1) {
              letter.isCurrentUserAPelaksana = true;
              letter.currentUserPelaksanaType = 'HARIAN';
            }
            LetterService.pullBackLetter(letter)
              .then((lastInsertId) => {
                this.setState({ action: '', loading: false });
                this.setState({ draftView: true });

                if (this.state.currentUser.id === this.state.currentLetter.originator) {
                  window.browserHistory.push('/processing/draft');
                } else {
                  window.browserHistory.push('/processing/need-approval');
                }
              })
              .catch((err) => {
                this.setState({ action: '', loading: false });
                window.browserHistory.push('/processing/status');
              });
          });
        }, 500);
      }
    });
  };
  askOpinion = (e) => {
    if (!(e && e.target && e.target.value && e.target.raw)) {
      return;
    }
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin meminta pendapat kepada ' + e.target.value + ' terkait konsep surat ini?',
    }, (ok) => {
      if (ok) {
        this.setState({ action: '', loading: true }, () => {
          const payload = {
            letterId: this.state.currentLetter.id,
            recipientUuid: e.target.raw.askOpinionRecipient,
            recipientName: e.target.value,
          };
          LetterService.askOpinion(payload.letterId, payload)
            .then(() => {
              this.setState({ action: '', loading: false });
              this.read(this.props.uuid).then((state) => {
                this.setState(state);
              });
            })
            .catch(() => {
              this.setState({ action: '', loading: false });
            });
        });
      }
    });
  };
  cancelAskOpinion = (e) => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin membatalkan permintaan pendapat ini?',
    }, (ok) => {
      if (ok) {
        this.setState({ action: '', loading: true }, () => {
          LetterService.cancelAskOpinion(this.state.currentLetter.id)
            .then(() => {
              this.setState({ action: '', loading: false });
              this.read(this.props.uuid).then((state) => {
                this.setState(state);
              });
            })
            .catch(() => {
              this.setState({ action: '', loading: false });
            });
        });
      }
    });
  };
  comment = (opinion) => {
    this.setState({ opinion_spinner: true }, () => {
      var recipient = opinion.originator;
      if (opinion.originator === this.state.currentUser.id) {
        recipient = opinion.recipientUuid;
      }
      let obj = {
        itemId: opinion.id,
        recipients: [recipient],
        notes: this.state['opinion_comment'],
      };
      CommentService.create(obj)
        .then(() => {
          if (opinion.originator !== this.state.currentUser.id) {
            recipient = opinion.recipientUuid;
            LetterService.answerOpinion(opinion.letterId)
              .then(() => {
                this.setState({ opinion_spinner: false, opinion_comment: '' });
                this.read(this.props.uuid).then((state) => {
                  this.setState(state);
                });
              })
              .catch((err) => {
                console.log(err);
                this.setState({ opinion_spinner: false });
              });
            return;
          }
          this.setState({ opinion_spinner: false, opinion_comment: '' });
          this.read(this.props.uuid).then((state) => {
            this.setState(state);
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ opinion_spinner: false });
        });
    });
  };

  recompose = (letterType) => {
    window.browserHistory.push(`/compose/${letterType}?recompose=${this.props.uuid}`);
  };
  bundlerLetter = () => {
    if (this.state.currentLetter.bundlerLetterId) {
      window.browserHistory.push('/read-redirect-helper/' + this.state.currentLetter.bundlerLetterId);
    }
  };

  forwardLetter = (e) => {
    if (!(e && e.target && e.target.value && e.target.raw)) {
      return;
    }
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin meneruskan surat ini ?',
    }, (ok) => {
      if (ok) {
        setTimeout(() => {
          this.setState({ loading: true }, () => {
            let payload = e.target.raw;
            for (let i in payload) {
              payload[i].recipientType = 'FORWARD';
            }
            LetterService.forward(this.state.currentLetter.id, payload)
              .then(() => {
                this.read(this.props.uuid).then((state) => {
                  this.setState(state);
                  swal({
                    title: '',
                    text: 'Surat berhasil diteruskan',
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                    type: 'success'
                  });
                });
                this.setState({ loading: false });
              })
              .catch((err) => {
                let msg = `<p style="text-align:center;margin-bottom: 5px;font-family: sans-serif;">User terpilih sudah terdaftar sebagai penerima</p>
                          <p style="text-align:center;font-family: sans-serif;">Surat tidak berhasil diteruskan</p>`;
                if (err.response &&
                  err.response.data &&
                  err.response.data.message &&
                  err.response.data.message.indexOf('This user was a recipient') > -1) {
                  swal({
                    title: '',
                    text: msg,
                    type: 'error',
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                    html: true
                  });
                } else {
                  swal({
                    title: '',
                    text: 'Surat tidak berhasil diteruskan',
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                    type: 'error',
                  });
                }
                this.setState({ loading: false });
              });
          });
        }, 200);

      }
    });
  };

  render() {
    const { currentUser } = this.state
    const {
      subject,
      //originatorName,
      //deadline,
      recipients,
      number,
      contents,
      //notes,
      importance,
      timestamp,
      letterDate,
      attachments,
      internal_attachments,
      manualLetterSender,
      letterType,
      scannedSignature,
      signerName,
      signerOrg,
      signerOrgSignerName,
      signerNIP,
      onBehalfOf,
      onBehalfOfTitle,
      pelaksana,
      city,
      qrImage,
      letterState,
      customAttachment,
      isCurrentUserAPelaksana,
      isCurrentUserARecipient,
      currentUserPelaksanaType,
      externVia,
      via,
      bundle,
      bundleState,
      originator,
      letterHeaderLogo
    } = this.state.currentLetter;
    const useLetterHeader = this.state.settings && this.state.settings.useLetterHeader === "TRUE"
    const letterHeaderHRColor = useLetterHeader ? '#333' : 'transparent'
    isSent = letterState === 'SENT';
    const hasPDF = usePDF && isSent && this.state.pdfURL !== undefined;
    const hasContents = contents && contents.length > 0;
    const isFinal = letterState === 'FINAL';
    const autoAttachmentLength = (recipients && recipients.length > 5) ? 1 : 0;
    const showEditButton = ((currentUser && currentUser.id === originator) ||
      (currentUser && currentUser.organization.id === originator) ||
      (currentUser && currentUser.delegatedId === originator) ||
      (currentUser && currentUser.pltId === originator)) &&
      (this.state.isReturned || letterState === 'DRAFT') &&
      this.state.processing.role !== 'signer' &&
      (!this.state.query ||
        ((this.state.query.action === 'draftView') ||
          (this.state.isReturned && this.state.query.action === 'process'))
      );
    let isPelaksanaRecipient = false;
    if (recipients) {
      recipients.forEach(recipient => {
        if (recipient.recipientUuid === currentUser.pltId || recipient.recipientUuid === currentUser.delegatedId) {
          isPelaksanaRecipient = true;
        }
      });
    }
    let isDispositionPelaksanaRecipient = undefined
    if (this.state.dispositions) {
      isDispositionPelaksanaRecipient = false
      this.state.dispositions.forEach(disposition => {
        disposition.recipients.forEach(recipient => {
          let recipientUuid = recipient.split("|")[0]
          if (recipientUuid === currentUser.pltId || recipientUuid === currentUser.delegatedId) {
            isDispositionPelaksanaRecipient = true;
          }
        })
      });
    }
    const asPelaksanaRecipient = !isDispositionPelaksanaRecipient ? (isCurrentUserARecipient && isPelaksanaRecipient) : isDispositionPelaksanaRecipient
    if (isSent && hasContents) {
      setTimeout(() => { this.regeneratePDF(); }, 1000);
    }
    return (this.state.loading ? <Spinner /> :
      (this.state.currentLetter.id ?
        (this.state.mode === 'read' ?
          <div>
            <div>
              <div style={{ display: 'inline-block', verticalAlign: 'top', margin: 10, marginLeft: 0 }}>
                <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={this.back} style={{ cursor: 'pointer' }} />
              </div>
              <div style={{ display: 'inline-block' }}>
                <h4>{letterState === 'DRAFT' && '(Draf) '} {stripTags(subject)}</h4>
                {number &&
                  <span>Nomor surat : {number}</span>
                }
                {bundle && bundle.length > 0 &&
                  <div>
                    <LetterBundleModal
                      label={'Paket Surat'}
                      modalLabel={'Paket Surat'}
                      bundle={bundle}
                      isBundlerBeingFinalized={this.state.isBundlerBeingFinalized}
                      isCurrentUserOriginator={(originator === currentUser.id)}
                      isCurrentUserProcessChainHolder={this.state.isProcessChainHolder}
                      currentUser={currentUser}
                    />
                    <br />
                  </div>
                }
                {!(this.state.isRecipient && letterState === 'SENT') && bundleState && bundleState === 'BUNDLED' &&
                  <div>
                    <button
                      className="btn letter-btn btn-default"
                      style={{ margin: 0 }}
                      onClick={() => { this.bundlerLetter(); }}
                    >
                      <Fa.FaFileO />
                      {(
                        bundleState === 'BUNDLED' &&
                        this.state.processing.role === 'assistant'
                      ) ?

                        'Teruskan surat pengantar untuk menyetujui surat ini'
                        :
                        'Surat Pengantar'
                      }

                    </button>
                  </div>
                }
              </div>
              {((isSent && asPelaksanaRecipient) ||
                (!isSent && (this.state.processing.holder === currentUser.pltId ||
                  this.state.processing.holder === currentUser.delegatedId))) &&
                <div style={{ display: 'inline-block', float: 'right' }}>
                  <Fa.FaUser size={24} color={'#3c8dbc'} /> Sebagai Pelaksana
                  {currentUserPelaksanaType && currentUserPelaksanaType === 'HARIAN' && ' Harian '}
                  {currentUserPelaksanaType && currentUserPelaksanaType === 'TUGAS' && ' Tugas '}
                </div>
              }
              {this.state.isReviewer &&
                <div style={{ display: 'inline-block', float: 'right' }}>
                  <Fa.FaUser size={24} color={'#3c8dbc'} /> Sebagai Pemeriksa Surat manual
                </div>
              }
              {this.props.isTesterMode &&
                <div>
                  <br />
                  <button className="btn letter-btn btn-primary" onClick={() => { this.recompose(letterType); }}><Fa.FaEdit /> Buat ulang</button>
                </div>
              }
              {localStorage.getItem('impersonator_token_id') === null &&
                <div>
                  {this.state.query &&
                    this.state.query.action &&
                    this.state.query.action === 'process' &&
                    this.state.isReturned === false &&
                    this.state.isDoneAsProcessingLetter === false &&
                    !(
                      bundleState === 'BUNDLED' &&
                      this.state.processing.role === 'assistant'
                    ) &&
                    <div>
                      <hr />
                      {!this.state.isBundlerProcChainHolder &&
                        <button className="btn letter-btn btn-success" onClick={() => { this.proceed('DONE'); }}><Fa.FaCheckSquareO />
                          {this.state.processing.role === 'signer' ?
                            'Tanda tangani' : this.state.processing.role === 'assistant' ?
                              'Teruskan ke atasan' : 'Setujui'
                          }
                        </button>
                      }
                      {!this.state.isBundlerProcChainHolder &&
                        <button
                          className="btn letter-btn btn-warning"
                          onClick={() => { this.proceed('REJECT'); }}
                        >
                          <Fa.FaChevronLeft /> Kembalikan
                        </button>
                      }
                      {!this.state.isBundlerProcChainHolder && this.state.isEditable &&
                        <button
                          className="btn letter-btn btn-danger"
                          onClick={() => { this.proceed('CANCELED'); }}
                        >
                          <Fa.FaStop /> Batal
                        </button>
                      }
                      {((currentUser && currentUser.id === originator) ||
                        (this.state.isEditable && (this.state.isInProcessChain || this.state.isBundlerProcChainHolder))) &&
                        letterState !== 'FINAL' &&
                        letterState !== 'SENT' &&
                        <span>
                          <button className="btn letter-btn btn-default" onClick={this.compose}><Fa.FaEdit /> Sunting Surat</button>
                          {!this.state.isBundlerProcChainHolder && !(this.state.opinion && this.state.opinion.id) &&
                            <AddressBookPersonal
                              style={{ float: 'right' }}
                              label="Minta Pendapat"
                              single={true}
                              name={'askOpinionRecipient'}
                              onChange={this.askOpinion}
                            />}
                        </span>
                      }
                    </div>
                  }
                </div>
              }

              {showEditButton === true &&
                <div>
                  <button className="btn letter-btn btn-default" onClick={this.compose}><Fa.FaEdit /> Sunting Surat</button>
                </div>
              }
              {(
                (currentUser.id === originator ||
                  currentUser.organization.id === originator ||
                  currentUser.delegatedId === originator ||
                  currentUser.pltId === originator) ||
                this.state.isInProcessChain) &&
                !this.state.isProcessChainHolder &&
                this.state.canBePulled &&
                letterState !== 'FINAL' &&
                letterState !== 'DRAFT' &&
                letterState !== 'RETURNED' &&
                letterState !== 'CANCELED' &&
                letterState !== 'SENT' &&
                bundleState !== 'WAIT' &&
                bundleState !== 'BUNDLE' &&
                <div>
                  <button className="btn letter-btn btn-warning" onClick={this.pullBack}><Fa.FaChevronLeft /> Tarik surat</button>
                </div>
              }
            </div>
            {this.state.manualLetterHistoryLoading && <Spinner />}
            {letterType !== 'TNDE_MEMO' &&
              ((this.state.combinedHistories && this.state.combinedHistories.length > 0) || this.state.query.action === 'processStatus') &&
              ((this.state.currentLetter.processings && this.state.currentLetter.processings.length > 0) ||
                letterState === 'RETURNED') &&
              !isFinal &&
              (!(this.state.isRecipient && letterState === 'SENT') && this.state.query.action !== 'outbox' ||
                (this.state.isRecipient && letterState === 'SENT') && this.state.query.action === 'outbox') &&
              (this.state.isSender ||
                AuthService.getRoles().role === 'FINALIZER' ||
                this.state.isProcessChainHolder ||
                this.state.isBundlerProcChainHolder ||
                this.state.isInProcessChain
              ) &&
              <div className="box box-body" style={{ marginTop: 15 }}>
                <table className="table table-bordered table-read-detail">
                  <thead>
                    <tr className="active">
                      <th colSpan="2">Riwayat Konsep Surat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        {this.state.combinedHistories && this.state.combinedHistories.map((item, index) => {
                          return <div style={{ marginLeft: 10 }} key={index}><div className="alert alert-default" style={{ padding: 0, paddingLeft: 4, marginBottom: 2, }}>
                            {date.format(item.timestamp, 'ddd, DD MMMM YYYY - HH:mm:ss', { locale: dateLocaleId })} -&nbsp;
                              {item.originatorName.split('|')[0]}
                            {item.originatorName.split('|')[1] && <span>, {item.originatorName.split('|')[1]}</span>}
                            &nbsp;:&nbsp;
                          {item.state && item.state === 'CREATE' && 'Membuat surat'}
                            {item.state && item.state === 'MODIFIED' && 'Menyunting'}
                            {item.state && item.state === 'PULLBACK' && <span>Menarik surat <Fa.FaChevronLeft color={'orange'} /></span>}
                            {item.state && item.state === 'REJECT' && <span>Mengembalikan <Fa.FaChevronLeft color={'red'} /></span>}
                            {item.state && item.state === 'DONE' && <span>Menyetujui <Fa.FaCheck color={'green'} /></span>}
                            {item.state && item.state === 'CANCELED' && <span>Membatalkan <Fa.FaClose color={'red'} /></span>}
                            {item.state && item.state.indexOf('FINALIZE') > -1 && <span>Finalisasi dan dikirim <Fa.FaCheck color={'green'} /></span>}
                            {item.notes && <span>Mengomentari  </span>}
                            {item.notes && <div className="alert alert-processing-comment">{item.notes}</div>}
                          </div></div>;
                        })}
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr className="active">
                      <th colSpan="2">Proses Konsep Surat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        <div style={{ marginLeft: 10 }}>
                          <span className="alert alert-default" style={{ padding: 4 }}>
                            {this.state.currentLetter.originatorTitle} (KONSEPTOR)
                            &nbsp;:&nbsp;
                              Membuat surat&nbsp;<Fa.FaCheck style={{ color: 'green' }} />
                          </span>
                        </div>
                        {this.state.currentLetter && this.state.currentLetter.processings && this.state.currentLetter.processings.map((item, index) => {
                          return <div style={{ marginLeft: 10, marginTop: 3 }} key={index}><span className="alert alert-default" style={{ padding: 4 }}>
                            {item.holderName.split('|')[0]}
                            {item.holderName.split('|')[1] && <span>, {item.holderName.split('|')[1]}</span>}
                            {item.role !== 'bundle' && <span>
                              &nbsp;(
                          {item.role && item.role === 'approver' && 'PEMERIKSA'}
                              {item.role && item.role === 'via' && 'MELALUI'}
                              {item.role && item.role === 'signer' && 'PENANDA TANGAN'}
                              {item.role && item.role === 'assistant' && 'ASISTEN'}
                              )&nbsp;:&nbsp;
													</span>}
                            {item.state && item.state === 'DONE' && <span>Telah menyetujui &nbsp; <Fa.FaCheck color={'green'} /></span>}
                            {item.state && item.state === 'RETURNED' && <span>Telah mengembalikan &nbsp; <Fa.FaChevronLeft color={'red'} /></span>}
                            {item.state && item.state === 'CANCELED' && <span>Telah membatalkan &nbsp; <Fa.FaClose color={'red'} /></span>}
                            {item.state && item.state === 'PROCESS' && item.role !== 'bundle' && <span>Menunggu diperiksa &nbsp; <Fa.FaEdit /></span>}
                            {item.state && item.state === 'PROCESS' && item.role === 'bundle' && <span>Menunggu dipaketkan &nbsp; <Fa.FaEdit /></span>}
                            {item.state && item.state === 'WAIT' && <span>Menunggu giliran &nbsp; <Fa.FaClockO /></span>}
                          </span></div>;
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
            {isSent &&
              <LetterReadDisposition
                currentUser={this.state.currentUser}
                currentLetter={this.state.currentLetter}
                dispositions={this.state.dispositions}
                isSender={this.state.isSender}
                back={this.backToRead}
                archiveView={this.state.archiveView}
                year={this.state.year}
                setDispositionRecipientOrigin={this.setDispositionRecipientOrigin}
                setDispositionState={this.setDispositionState}
              />
            }
            {(this.state.query.action === 'process' || this.state.query.action === 'opinion') && this.state.opinion && this.state.opinion.id &&
              <div className="box box-primary box-solid" style={{ marginTop: 15 }}>
                <div className="box-header with-border">
                  <h4 className="box-title">
                    Minta Pendapat dari {this.state.opinion.recipientName}
                  </h4>
                  {this.state.opinion && this.state.opinion.originator === this.state.currentUser.id && !this.state.opinion.isClosed && <div className="box-tools pull-right pointer">
                    <button className="btn btn-danger" onClick={this.cancelAskOpinion}>Batalkan</button>
                  </div>}
                </div>
                <div className={'body-body '}>
                  <div style={{ padding: 15 }}>
                    {this.state.opinion &&
                      this.state.opinion.comments &&
                      this.state.opinion.comments.data &&
                      this.state.opinion.comments.data.map((comment, commentIndex) => {
                        return <div key={commentIndex} style={{ border: '1px solid lightgrey', fontSize: 14, marginBottom: 15 }}>
                          <div
                            style={{
                              display: 'inline-block',
                              width: window.innerWidth > 800 ? '200px' : "100%",
                              background: '#f9fafc',
                              padding: '5px 10px 5px 10px',
                              borderRight: '1px solid lightgrey',
                              borderBottom: '1px solid lightgrey'
                            }}
                          >
                            <img
                              src={Config.apiHost + "/api/v1/blob/unrestricted/" + comment.originatorPicture}
                              onError={(e) => { e.target.src = "/images/default-avatar.png"; }}
                              alt=""
                              style={{
                                background: 'grey',
                                width: this.props.sidebarCollapse ? 30 : 40,
                                height: this.props.sidebarCollapse ? 30 : 40,
                                borderRadius: 50, marginBottom: 15
                              }}
                            />
                            <br />
                            {comment.originatorName.split('|')[0]}<br />
                            {comment.originatorName.split('|')[1] && <span> {comment.originatorName.split('|')[1]}</span>}<br />
                            <span style={{ fontSize: 12 }}>{date.format(comment.timestamp, 'dddd, DD MMMM YYYY', { locale: dateLocaleId })}</span><br />
                            <span style={{ fontSize: 12 }}>{date.format(comment.timestamp, 'HH:mm', { locale: dateLocaleId })}</span>
                          </div>
                          <div style={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            padding: '5px 10px 5px 10px',
                            width: '78%',
                            wordWrap: "break-word",
                            whiteSpace: 'pre-line'
                          }}>{comment.notes}</div>
                        </div>;
                      })}
                  </div>
                  <div className="col-md-12" style={{ marginBottom: 15, textAlign: 'center' }}>
                    {this.state.opinion.isClosed ?
                      <div>
                        Surat telah diteruskan
                      </div>
                      :
                      <div>
                        <textarea
                          className="form-control"
                          value={this.state['opinion_comment']}
                          name={'opinion_comment'}
                          onChange={(e) => {
                            let obj = {};
                            obj['opinion_comment'] = e.target.value; this.setState(obj);
                          }}
                          style={{ width: '100%' }}
                        />
                        {this.state['opinion_spinner'] && <Spinner />}
                        {!this.state['opinion_spinner'] &&
                          <button className="btn btn-primary" onClick={() => { this.comment(this.state.opinion); }}>
                            Komentari
                          </button>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>

            }
            <div className="row">
              <div className="col-md-12">
                {this.state.currentUser &&
                  AuthService.getRoles().role === 'FINALIZER' &&
                  letterState === 'FINAL' &&
                  <div>
                    <hr />
                    <div className="row">
                      <div className="col-md-4">
                        Nomor surat :
                        <input
                          width="300"
                          className="form-control"
                          onChange={(e) => {
                            this.setState({ manualLetterReferenceNumber: e.target.value });
                          }}
                          value={this.state.manualLetterReferenceNumber}
                        />
                        <span style={{ color: 'red', fontSize: 10 }}>Kosongkan untuk mendapatkan nomor surat otomatis</span>
                      </div>
                      <div className="col-md-4">
                        Tanggal surat :
                  	    <DateTimePicker
                          style={{ marginBottom: 0 }}
                          name="letterDate"
                          value={this.state.manualLetterDate}
                          onChange={(e) => {
                            this.setState({ manualLetterDate: e.target.value });
                          }}
                        />
                        <span style={{ color: 'red', fontSize: 10 }}>Kosongkan untuk mendapatkan tanggal surat otomatis (hari ini)</span>
                      </div>
                    </div>
                  </div>
                }
                {this.state.currentPreview != null &&
                  this.state.attachmentPreviews[this.state.currentPreview] &&
                  letterState === "SENT" &&
                  <div>
                    <hr />
                    <div style={{ textAlign: 'center' }}>
                      <button
                        className="btn btn-default"
                        onClick={this.attachmentPreviewPrev} disabled={this.state.currentPreview === 0}
                      >
                        <Fa.FaChevronLeft />Berkas Sebelumnya
                      </button>
                      <span
                        style={{ marginLeft: 15, marginRight: 15 }}
                      >
                        Lampiran pratayang : {this.state.attachmentPreviews[this.state.currentPreview].filename}
                      </span>
                      <button
                        className="btn btn-default"
                        onClick={this.attachmentPreviewNext}
                        disabled={this.state.currentPreview === (this.state.attachmentPreviews.length - 1)}
                      >
                        Berkas Berikutnya <Fa.FaChevronRight />
                      </button>
                    </div>
                    <hr />
                    <AttachmentViewer
                      url={this.state.attachmentPreviews[this.state.currentPreview].url}
                      type={this.state.attachmentPreviews[this.state.currentPreview].type}
                    />
                  </div>
                }

                {/* THE LETTER

                  CSS is in /src/print.css
*/}
                {/*
                  The margin in style bellow (and in its sub div with "letter-body" id) doesn't affect print style
                  See print() if you want to customize the print style
                */}
                {(letterType === 'TNDE_NOTA_DINAS' ||
                  letterType === 'TNDE_UNDANGAN' ||
                  letterType === 'TNDE_SURAT' ||
                  letterType === 'TNDE_MEMO' ||
                  letterType === 'TNDE_SURAT_TUGAS') && hasPDF === false &&
                  hasContents ?

                  <div
                    style={{
                      background: 'white',
                      width: '210mm',
                      margin: '0 auto',
                      marginTop: '15px',

                      padding: '15mm 20mm 20mm 20mm',
                    }}
                  >
                    <div
                      id="letter-body"
                      style={{
                        background: 'white',
                        width: '165mm',
                        margin: '0 auto',
                      }}
                    >
                      <div
                        style={{
                          fontFamily: 'Arial',
                        }}
                      >
                        <div>
                          <div>
                            {this.state.currentLetter.letterHeaderLogo && this.state.currentLetter.letterHeaderLogo.length > 0 &&
                              <div
                                style={{
                                  display: 'inline-block',
                                  textAlign: 'center',
                                  width: '30mm',
                                  height: '30mm',
                                  float: 'left',
                                  position: 'absolute'
                                }}
                              >
                                {useLetterHeader &&
                                  <img
                                    src={Config.apiHost + "/api/v1/blob/unrestricted/" + letterHeaderLogo}
                                    onError={(e) => { console.log(e); e.target.src = Config.variant.images.letterLogo; }}
                                    alt=""
                                    style={{ width: '31mm' }}
                                  />
                                }
                              </div>
                            }
                            {letterType === 'TNDE_MEMO' ?

                              <div style={{ textAlign: 'center', margin: 15, paddingTop: '10mm', minHeight: '30mm' }}>
                                <div style={{ fontWeight: 'bold', textDecoration: 'underline', fontSize: '1.3em' }}>
                                  MEMO
                            </div>
                                <span style={{ fontWeight: 'bold' }}>
                                  Nomor {this.renderReferenceNumber(letterState, number, this.state.manualLetterReferenceNumber)}
                                </span>
                              </div>
                              :
                              <div style={{
                                display: 'inline-block',
                                textAlign: 'center',
                                width: (letterHeaderLogo && letterHeaderLogo.length > 0) ? '170mm' : '100%',
                                minHeight: '35mm',
                                lineHeight: '0.8em',
                                paddingLeft: (letterHeaderLogo && letterHeaderLogo.length > 0) ? '30mm' : 0
                              }}>
                                {useLetterHeader && <div dangerouslySetInnerHTML={{ __html: this.state.currentLetter.letterHeaderBody }}></div>}
                              </div>
                            }
                          </div>
                          <hr style={{ height: 1, border: 'none', color: letterHeaderHRColor, background: 'transparent', borderTop: 'solid 1px ' + letterHeaderHRColor, marginTop: 0, marginBottom: 0 }} />
                          <hr style={{ height: 1, border: 'none', color: letterHeaderHRColor, background: 'transparent', borderTop: 'solid 2px ' + letterHeaderHRColor, marginTop: 0 }} />

                          {/* Each letter type has different sub header */}
                          {letterType === 'TNDE_NOTA_DINAS' &&
                            <div
                              style={{
                                lineHeight: '1.1em',
                              }}
                            >
                              <div style={{ textAlign: 'center', margin: 15 }}>
                                <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                  NOTA DINAS
                            </div>
                                <span>NOMOR {this.renderReferenceNumber(letterState, number, this.state.manualLetterReferenceNumber)}
                                </span>
                              </div>
                              <div>
                                <table className='tnde-nota-dinas-letter'>
                                  <tbody>
                                    <tr>
                                      <td width="60" style={{ verticalAlign: 'top' }}>Yth.</td>
                                      <td width="1em" style={{ verticalAlign: 'top' }}>:</td>
                                      <td style={{ verticalAlign: 'top' }}>
                                        {this.state.defaultRecipients && this.state.defaultRecipients.length > 5 ?
                                          'Para Penerima (Terlampir)'
                                          :
                                          (this.state.defaultRecipients && this.state.defaultRecipients.map((item, index) => {
                                            return <div className="letter-body-reset" key={index}>
                                              {!(index === 0 && this.state.defaultRecipients.length === 1) && <span>{parseInt(index, 10) + 1}.</span>} {item.recipientName}
                                              {item.recipientType === 'RECIPIENT' && this.state.forAttention.recipientType === 'RECIPIENT_FOR_ATTENTION' &&
                                                <p>{this.state.defaultRecipients.length > 1 && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}u.p. {this.state.forAttention.recipientName}</p>
                                              }
                                              {item.recipientType === 'EXTERN_RECIPIENT' && this.state.forAttention.recipientType === 'EXTERN_RECIPIENT_FOR_ATTENTION' &&
                                                <p>{this.state.defaultRecipients.length > 1 && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}u.p. {this.state.forAttention.recipientName}</p>
                                              }
                                            </div>;
                                          }))
                                        }

                                      </td>
                                    </tr>
                                    {externVia && externVia.length > 0 &&
                                      <tr>
                                        <td width="60" style={{ verticalAlign: 'top' }}>Melalui</td>
                                        <td width="1em" style={{ verticalAlign: 'top' }}>:</td>
                                        <td style={{ verticalAlign: 'top' }}>
                                          {externVia && externVia.map((item, index) => {
                                            return <div className="letter-body-reset" key={index}>
                                              {!(index === 0 && externVia.length === 1) && <span>{parseInt(index, 10) + 1}.</span>} {item}
                                            </div>;
                                          })}

                                        </td>
                                      </tr>
                                    }
                                    {via && via.length > 0 && (!externVia || (externVia && externVia.length === 0)) &&
                                      <tr>
                                        <td width="60" style={{ verticalAlign: 'top' }}>Melalui</td>
                                        <td width="1em" style={{ verticalAlign: 'top' }}>:</td>
                                        <td style={{ verticalAlign: 'top' }}>
                                          {via && via.map((item, index) => {
                                            return <div className="letter-body-reset" key={index}>
                                              {!(index === 0 && via.length === 1) && <span>{parseInt(index, 10) + 1}.</span>} {item.split('|')[1]}
                                            </div>;
                                          })}

                                        </td>
                                      </tr>
                                    }
                                    <tr>
                                      <td>Dari</td>
                                      <td>:</td>
                                      <td>
                                        {manualLetterSender ? manualLetterSender : onBehalfOfTitle ? onBehalfOfTitle : signerOrg ? signerOrg : signerName}
                                      </td>
                                    </tr>
                                    {Config.variant.name !== 'p3h' &&
                                      <tr>
                                        <td>Sifat</td>
                                        <td>:</td>
                                        <td>
                                          {importance && importance === 'NORMAL' && 'Biasa'}
                                          {importance && importance === 'LIMITED' && 'Terbatas'}
                                          {importance && importance === 'SECRET' && 'Rahasia'}
                                          {importance && importance === 'TOP_SECRET' && 'Sangat Rahasia'}
                                          {importance && importance === 'IMPORTANT' && 'Segera'}
                                          {importance && importance === 'VERY_IMPORTANT' && 'Sangat Segera'}
                                        </td>
                                      </tr>
                                    }
                                    {attachments && (autoAttachmentLength + attachments.length) > 0 &&
                                      <tr>
                                        <td>Lampiran</td>
                                        <td>:</td>
                                        <td>
                                          {customAttachment && customAttachment.length > 0 ?
                                            customAttachment :
                                            ((autoAttachmentLength + attachments.length) === 0 ? "" : (autoAttachmentLength + attachments.length) + ' berkas')}

                                        </td>
                                      </tr>
                                    }
                                    <tr>
                                      <td style={{ verticalAlign: 'top' }}>Hal</td>
                                      <td style={{ verticalAlign: 'top' }}>:</td>
                                      <td style={{ verticalAlign: 'top' }}><div className="letter-body-reset" dangerouslySetInnerHTML={{ __html: subject }}></div></td>
                                    </tr>
                                    <tr>
                                      <td>Tanggal</td>
                                      <td>:</td>
                                      <td>
                                        {this.renderLetterDate(letterState, letterDate, this.state.manualLetterDate, timestamp)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <hr style={{ height: 1, border: 'none', color: '#333', background: 'transparent', borderTop: 'solid 1px #333', marginBottom: 0 }} />
                            </div>
                          }
                          {letterType === 'TNDE_SURAT_TUGAS' &&
                            <div
                              style={{
                                lineHeight: '1.1em',
                              }}
                            >
                              <div style={{ textAlign: 'center', margin: 15 }}>
                                <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                  SURAT TUGAS
                            </div>
                                <span>
                                  Nomor {this.renderReferenceNumber(letterState, number, this.state.manualLetterReferenceNumber)}
                                </span>
                              </div>
                            </div>
                          }
                          {letterType === 'TNDE_UNDANGAN' &&
                            <div
                              style={{
                                lineHeight: '1.1em',
                              }}
                            >
                              <div>
                                <span style={{ float: 'right', paddingTop: '1mm' }}>
                                  {this.renderLetterDate(letterState, letterDate, this.state.manualLetterDate, timestamp)}
                                </span>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td width="60">Nomor</td>
                                      <td width="1em">:</td>
                                      <td>
                                        {this.renderReferenceNumber(letterState, number, this.state.manualLetterReferenceNumber)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Sifat</td>
                                      <td>:</td>
                                      <td>
                                        {importance && importance === 'NORMAL' && 'Biasa'}
                                        {importance && importance === 'LIMITED' && 'Terbatas'}
                                        {importance && importance === 'SECRET' && 'Rahasia'}
                                        {importance && importance === 'TOP_SECRET' && 'Sangat Rahasia'}
                                        {importance && importance === 'IMPORTANT' && 'Segera'}
                                        {importance && importance === 'VERY_IMPORTANT' && 'Sangat Segera'}
                                      </td>
                                    </tr>
                                    {attachments && attachments.length > 0 &&
                                      <tr>
                                        <td>Lampiran</td>
                                        <td>:</td>
                                        <td>
                                          {customAttachment && customAttachment.length > 0 ?
                                            customAttachment :
                                            ((autoAttachmentLength + attachments.length) === 0 ? "" : (autoAttachmentLength + attachments.length) + ' berkas')}

                                        </td>
                                      </tr>
                                    }
                                    {autoAttachmentLength > 0 && !attachments &&
                                      <tr>
                                        <td>Lampiran</td>
                                        <td>:</td>
                                        <td>
                                          {autoAttachmentLength + ' berkas'}
                                        </td>
                                      </tr>
                                    }
                                    <tr>
                                      <td style={{ verticalAlign: 'top' }}>Hal</td>
                                      <td style={{ verticalAlign: 'top' }}>:</td>
                                      <td style={{ verticalAlign: 'top' }}><div className="letter-body-reset" dangerouslySetInnerHTML={{ __html: subject }}></div></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div style={{ marginTop: '10mm' }}>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td width="30" style={{ verticalAlign: 'top' }}>Yth. </td>
                                      <td style={{ verticalAlign: 'top' }}>

                                        {this.state.defaultRecipients && this.state.defaultRecipients.length > 5 ?
                                          'Para Undangan (Penerima Terlampir)'
                                          :
                                          (this.state.defaultRecipients && this.state.defaultRecipients.map((item, index) => {
                                            return <div className="letter-body-reset" key={index}>
                                              {!(index === 0 && this.state.defaultRecipients.length === 1) && <span>{parseInt(index, 10) + 1}.</span>} {item.recipientName}
                                            </div>;
                                          }))
                                        }

                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>
                                        <div className="letter-body-reset" dangerouslySetInnerHTML={{ __html: this.state.currentLetter.destination }} style={{ padding: 0 }}></div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          }
                          {letterType === 'TNDE_SURAT' &&
                            <div
                              style={{
                                lineHeight: '1.1em',
                              }}
                            >
                              <div>
                                <span style={{ float: 'right', paddingTop: '1mm' }}>
                                  {this.renderLetterDate(letterState, letterDate, this.state.manualLetterDate, timestamp)}
                                </span>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td width="60">Nomor</td>
                                      <td width="1em">:</td>
                                      <td>
                                        {this.renderReferenceNumber(letterState, number, this.state.manualLetterReferenceNumber)}
                                      </td>
                                    </tr>
                                    {attachments && attachments.length > 0 &&
                                      <tr>
                                        <td>Lampiran</td>
                                        <td>:</td>
                                        <td>
                                          {customAttachment && customAttachment.length > 0 ?
                                            customAttachment :
                                            ((autoAttachmentLength + attachments.length) === 0 ? "" : (autoAttachmentLength + attachments.length) + ' berkas')}

                                        </td>
                                      </tr>
                                    }
                                    <tr>
                                      <td>Sifat</td>
                                      <td>:</td>
                                      <td>
                                        {importance && importance === 'NORMAL' && 'Biasa'}
                                        {importance && importance === 'LIMITED' && 'Terbatas'}
                                        {importance && importance === 'SECRET' && 'Rahasia'}
                                        {importance && importance === 'TOP_SECRET' && 'Sangat Rahasia'}
                                        {importance && importance === 'IMPORTANT' && 'Segera'}
                                        {importance && importance === 'VERY_IMPORTANT' && 'Sangat Segera'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ verticalAlign: 'top' }}>Hal</td>
                                      <td style={{ verticalAlign: 'top' }}>:</td>
                                      <td style={{ verticalAlign: 'top' }}><div className="letter-body-reset" dangerouslySetInnerHTML={{ __html: subject }}></div></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div style={{ marginTop: '10mm' }}>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {this.state.defaultRecipients && this.state.defaultRecipients.length > 5 ?
                                          'Yth. Para Penerima (Terlampir)'
                                          :
                                          (this.state.defaultRecipients && this.state.defaultRecipients.map((item, index) => {
                                            return <div style={{ marginLeft: index > 0 && 35 }} key={index}>
                                              <div>
                                                {index === 0 && 'Yth. '}
                                                {!(index === 0 && this.state.defaultRecipients.length === 1) && <span>{parseInt(index, 10) + 1}.</span>}
                                                {item.recipientName}
                                              </div>
                                              {this.state.forAttention &&
                                                ((this.state.forAttention.recipientType === 'RECIPIENT_FOR_ATTENTION' && item.recipientType === 'RECIPIENT') ||
                                                  (this.state.forAttention.recipientType === 'EXTERN_RECIPIENT_FOR_ATTENTION' && item.recipientType === 'EXTERN_RECIPIENT')) &&
                                                <div>
                                                  <div style={{ textAlign: 'center' }}>u.p</div>
                                                  <div>{this.state.forAttention.recipientName}</div>
                                                </div>
                                              }
                                            </div>;
                                          }))
                                        }

                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ paddingLeft: Object.keys(this.state.forAttention).length < 1 && 35 }}>
                                      <div className="letter-body-reset" dangerouslySetInnerHTML={{ __html: this.state.currentLetter.destination }} style={{ padding: 0 }}></div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          }
                          {letterType === 'TNDE_MEMO' &&
                            <div>
                              <table>
                                <tbody>
                                  <tr>
                                    <td width="60" style={{ verticalAlign: 'top' }}>Kepada</td>
                                    <td width="1em" style={{ verticalAlign: 'top' }}>:</td>
                                    <td style={{ verticalAlign: 'top' }}>
                                      {this.state.defaultRecipients && this.state.defaultRecipients.length > 5 ?
                                        'Para Penerima (Terlampir)'
                                        :
                                        (this.state.defaultRecipients && this.state.defaultRecipients.map((item, index) => {
                                          return <div className="letter-body-reset" key={index}>
                                            {!(index === 0 && this.state.defaultRecipients.length === 1) && <span>{parseInt(index, 10) + 1}.</span>} {item.recipientName}
                                          </div>;
                                        }))
                                      }

                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Dari</td>
                                    <td>:</td>
                                    <td>
                                      {manualLetterSender ? manualLetterSender : onBehalfOfTitle ? onBehalfOfTitle : signerOrg ? signerOrg : signerName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ verticalAlign: 'top' }}>Hal</td>
                                    <td style={{ verticalAlign: 'top' }}>:</td>
                                    <td style={{ verticalAlign: 'top' }}><div className="letter-body-reset" style={{ marginTop: 4 }} dangerouslySetInnerHTML={{ __html: subject }}></div></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          }
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: contents
                          }}
                          style={{
                            lineHeight: '1.1em',
                            marginTop: '5mm',
                            marginBottom: '5mm',
                          }}
                        >
                        </div>
                        <div style={{ width: '100%', height: '200px', marginTop: '10mm' }}>
                          {letterState === 'SENT' ?
                            <div style={{
                              width: 150,
                              height: 199,
                              float: 'left',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundImage: "url('" + qrImage + "')"
                            }}></div>
                            :
                            <span id="qr-code"></span>
                          }
                          <div style={{ float: 'right', width: '70mm' }}>
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{ verticalAlign: 'top' }} width="20">
                                  </td>
                                  <td>
                                    {(letterType === 'TNDE_MEMO' || letterType === 'TNDE_SURAT_TUGAS') &&
                                      <div>
                                        {city && city !== '' ?
                                          city + ', '
                                          :
                                          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,</span>
                                        }
                                        {this.renderLetterDate(letterState, letterDate, this.state.manualLetterDate, timestamp)}
                                      </div>}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ verticalAlign: 'top' }}>
                                    {onBehalfOf && onBehalfOfTitle && onBehalfOfTitle.length > 0 && 'a.n.'}
                                    {pelaksana && pelaksana === 'HARIAN' && 'Plh.'}
                                    {pelaksana && pelaksana === 'TUGAS' && 'Plt.'}
                                    &nbsp;
                                  </td>
                                  <td>
                                    {onBehalfOf && onBehalfOfTitle && onBehalfOfTitle.length > 0 ?
                                      <div>
                                        {onBehalfOfTitle}
                                        <br />
                                        {signerOrg}
                                      </div>
                                      :
                                      (this.state.currentLetter.letterType === 'TNDE_NOTA_DINAS' && (!pelaksana || (pelaksana && pelaksana.length < 1)) ?
                                        (Config.isSignerOrgVisible === 'true' ? <span>{signerOrg}</span> : <span></span>)
                                        :
                                        (signerOrgSignerName ? signerOrgSignerName : signerOrg))
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td style={{ padding: '7px 1px' }}>
                                    <div style={{ height: '25mm' }}>
                                      {scannedSignature &&
                                        ((isSent || (this.state.query.action === 'finalization' && AuthService.getRoles().role === 'FINALIZER')) ||
                                          (isFinal && this.state.currentUserIsSigner)) &&
                                        (this.state.currentLetter.signatureType !== 'HANDWRITTEN' || letterType === 'TNDE_MEMO') &&
                                        <img
                                          src={Config.apiHost + "/api/v1/blob/unrestricted/" + scannedSignature}
                                          alt=""
                                          onError={(e) => { e.target.src = '/images/blank.png'; }}
                                          style={{ height: '25mm' }}
                                        />
                                      }
                                      <br />
                                    </div>
                                    {signerName}
                                    <br />
                                    {this.state.settings.useNIPOnLetter === 'TRUE' && <span>NIP. {signerNIP}</span>}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                  </td>
                                  <td>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {this.state.ccRecipients.length > 0 &&
                          <div>
                            {onBehalfOfTitle && <div style={{ marginBottom: (letterType === 'TNDE_SURAT_TUGAS') ? 45 : 35 }}></div>}
                            Tembusan: <br />
                            {this.state.ccRecipients && this.state.ccRecipients.map((item, index) => {
                              return <div key={index}>
                                {!(index === 0 && this.state.ccRecipients.length === 1) && <span>{parseInt(index, 10) + 1}.</span>} {item.recipientName}
                              </div>;
                            })}
                          </div>}
                        <div style={{ fontSize: '11pt', marginTop: 30 }}>
                          <span className='kp'></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  letterType === 'TNDE_SURAT_KETERANGAN' && !hasPDF && hasContents ?
                    <LetterReadSuratKeterangan
                      parentState={this.state}
                      renderReferenceNumber={this.renderReferenceNumber}
                      renderLetterDate={this.renderLetterDate}
                    />
                    :
                    letterType === 'TNDE_LEMBAR_RALAT' && !hasPDF && hasContents ?
                      <LetterReadLembarRalat
                        parentState={this.state}
                        renderReferenceNumber={this.renderReferenceNumber}
                        renderLetterDate={this.renderLetterDate}
                      />
                      :
                      letterType === 'TNDE_LAPORAN' && !hasPDF && hasContents ?
                        <LetterReadLaporan
                          parentState={this.state}
                          renderReferenceNumber={this.renderReferenceNumber}
                          renderLetterDate={this.renderLetterDate}
                        />
                        :
                        letterType === 'TNDE_PENGUMUMAN' && !hasPDF && hasContents ?
                          <LetterReadPengumuman
                            parentState={this.state}
                            renderReferenceNumber={this.renderReferenceNumber}
                            renderLetterDate={this.renderLetterDate}
                          />
                          :
                          letterType === 'TNDE_TELAAHAN_STAF' && !hasPDF && hasContents ?
                            <LetterReadTelaahanStaf
                              parentState={this.state}
                              renderReferenceNumber={this.renderReferenceNumber}
                              renderLetterDate={this.renderLetterDate}
                            />
                            :
                            <div style={{ textAlign: 'center' }}>
                              {!isSent &&
                                <div>
                                  <hr />
                                  <span>Belum ada konten surat</span>
                                </div>
                              }
                            </div>
                }
                {!Config.noRapat &&
                  <span>
                    {
                      this.state.currentLetter.invitationRapatRoom === 'booked' && (
                        <div className="col-md-12">
                          <hr />
                          <div className="kop-references">
                            Ruang rapat daring akan dibuat setelah surat dikirim.

                        </div>
                        </div>
                      )
                    }
                    {
                      (!this.state.query || (this.state.query && !this.state.query.action)) &&
                      this.state.currentLetter.invitationRapatRoom !== 'booked' &&
                      this.state.currentLetter.invitationRapatRoom !== '' &&
                      this.state.currentLetter.invitationRapatRoom !== null && (
                        <div className="col-md-12">
                          <hr />
                          <div className="kop-references">
                            <button onClick={() => {
                              window.browserHistory.push('/tools/rapat/' + this.state.currentLetter.invitationRapatRoom);
                            }} className="btn btn-primary">
                              Masuk ruang rapat daring.</button>
                          </div>
                        </div>
                      )
                    }
                  </span>
                }
                <div className="col-md-12">
                  <hr />
                  <div className="letter-references">
                    Referensi Surat :
                    <br />
                    {(!this.state.currentLetter.references || (this.state.currentLetter.references && this.state.currentLetter.references.length === 0)) && <span>-</span>}
                    <ul>
                      {this.state.currentLetter.references && this.state.currentLetter.references.map((item, index) => {
                        let splitted = item.split('|')
                        if (splitted.length === 3) {
                          return <li key={index}>
                            {splitted.length > 1 && <span style={{ cursor: 'pointer', textDecoration: 'none', color: '#72afd2' }}
                              onClick={() => { this.downloadAttachment(Config.apiHost + "/api/v1/blob/" + splitted[2] + "?download=true", splitted[1]); }}
                            >{splitted[1]}</span>
                            }
                          </li>;
                        } else {
                          return <li key={index}>
                            {splitted.length > 1 && <a target="_blank" href={'/read/' + splitted[0]}>{splitted[1]}</a>}
                          </li>;
                        }
                      })}
                    </ul>
                  </div>
                  <hr />
                  <div className="letter-attachments">
                    Lampiran Surat:
                    <div className="row" style={{ margin: 15 }}>
                      {attachments && attachments.map((item, index) => {
                        return <div key={index} className="letter-read-attachment-info col-md-3">
                          {item.split(',')[3]}
                          <button
                            className="btn btn-default"
                            style={{ float: 'right' }}
                            onClick={() => { this.downloadAttachment(Config.apiHost + "/api/v1/blob/" + item.split(',')[0] + "?download=true", item.split(',')[3]); }}
                          >
                            <Fa.FaDownload />
                          </button>
                        </div>;
                      })}
                      <InvitationListPDF
                        name="invitationListPDF"
                        value={this.state}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="letter-attachments">
                    Lampiran Pendukung:
                    <div className="row" style={{ margin: 15 }}>
                      {(this.state.isSender ||
                        (this.state.opinion && this.state.opinion.recipientUuid) === this.state.currentUser.id ||
                        this.state.isInProcessChain) && internal_attachments && internal_attachments.map((item, index) => {
                          return <div key={index} className="letter-read-attachment-info col-md-3">
                            {item.split(',')[3]}
                            <button
                              className="btn btn-default"
                              style={{ float: 'right' }}
                              onClick={() => { this.downloadAttachment(Config.apiHost + "/api/v1/blob/" + item.split(',')[0] + "?download=true", item.split(',')[3]); }}
                            >
                              <Fa.FaDownload />
                            </button>
                          </div>;
                        })}
                    </div>
                  </div>
                  <hr />
                  {this.state.query.action === 'opinion' ? '' :
                    <div className="letter-btn-container">
                      {this.state.isAssistant &&
                        this.state.currentLetter.assistedRecipientName &&
                        <button className="btn letter-btn btn-primary" onClick={this.forwardToAssisted}>
                          Teruskan ke atasan :
                          {this.state.currentLetter.assistedRecipientName}
                        </button>
                      }
                      {this.state.isAssistant &&
                        !this.state.currentLetter.assistedRecipientName &&
                        <button className="btn letter-btn btn-default" disabled>Telah diteruskan ke atasan </button>
                      }
                      {this.state.isReviewer && !this.state.isReviewed &&
                        <button className="btn letter-btn btn-primary" onClick={this.forwardReview}>
                          Setujui dan teruskan surat
                        </button>
                      }
                      {this.state.isReviewer && this.state.isReviewed &&
                        <button className="btn letter-btn btn-default" disabled>Telah diteruskan ke penerima </button>
                      }
                      {!this.state.isAssistant && <div style={{ display: 'inline-block' }}>
                        {this.state.currentUser && AuthService.getRoles().role === 'FINALIZER' &&
                          isFinal &&
                          localStorage.getItem('impersonator_token_id') === null &&
                          <button className="btn letter-btn btn-primary" onClick={this.finalizePreparation}><Fa.FaCheckCircleO /> Finalisasi</button>
                        }
                        {((this.state.isRecipient || this.state.isDispositionRecipient) && isSent && !this.state.query.action) &&
                          <div className="dropdown">
                            <div className="dropdown-content">
                              <span onClick={() => { this.reply('TNDE_NOTA_DINAS'); }}>Nota Dinas</span>
                              <span onClick={() => { this.reply('TNDE_SURAT'); }}>Surat</span>
                              <span onClick={() => { this.reply('TNDE_SURAT_TUGAS'); }}>Surat Tugas</span>
                              <span onClick={() => { this.reply('TNDE_UNDANGAN'); }}>Surat Undangan</span>
                              <span onClick={() => { this.reply('TNDE_MEMO'); }}>Memo</span>
                            </div>
                            <button className="dropbtn btn btn-primary">Balas Surat</button>
                          </div>
                        }
                        <span style={{ textAlign: 'left', margin: 5 }}>
                          {this.state.isRecipient && isSent && !this.state.query.action &&
                            !this.state.isDispositionRecipient &&
                            <AddressBook
                              button={true}
                              name={'forwardRecipient'}
                              orgName={true}
                              onChange={this.forwardLetter}
                              label={'Teruskan Surat'}
                              primary={true}
                            />
                          }
                        </span>
                        {(this.state.dispositions && this.state.dispositions.length > 0 && this.state.dispositions[0].statusIsClosed) ?
                          (this.state.dispositions[0].closable ?
                            <button className="btn letter-btn btn-warning" onClick={() => { this.reopenDispositionState(); }}><Fa.FaShareAlt /> Buka Kembali Disposisi</button>
                            :
                            ''
                          )
                          :
                          <span>
                            {((this.state.isRecipient || this.state.isDispositionRecipient) && isSent && !this.state.query.action) &&
                              // By default, not everyone can create disposition
                              (Config.disposition.initiationByStaff || (
                                !Config.disposition.initiationByStaff &&
                                this.state.currentUser.organization &&
                                AuthService.getRoles().orgRole &&
                                AuthService.getRoles().orgRole === 'PUA'
                              )) &&
                              <button className="btn letter-btn btn-primary" onClick={this.createDisposition}><Fa.FaShareAlt /> Buat Disposisi</button>
                            }
                          </span>
                        }
                      </div>}
                      {isSent &&
                        <div className="dropdown">
                          <div className="dropdown-content">
                            <span onClick={() => { this.readDispositionHistory(); }}>Disposisi</span>
                            <span onClick={() => { this.readReferenceHistory(); }}>Balasan</span>
                            <span onClick={() => { this.readForwardHistory(); }}>Teruskan</span>
                          </div>
                          <button className="dropbtn btn btn-primary"><Fa.FaList /> Riwayat</button>
                        </div>
                      }
                      {this.state.dispositions.length > 0 && this.isDispositionOriginator() && <button className="btn letter-btn btn-primary" onClick={this.readDispositionMonitoring}><Fa.FaInfo /> Monitoring Disposisi</button>}
                      <button className="btn letter-btn btn-primary" onClick={this.readDetail}><Fa.FaInfo /> Agenda Surat</button>
                      {(hasPDF || hasContents ||
                        (this.state.attachmentPreviews && this.state.attachmentPreviews.length > 0)) &&
                        <button className="btn letter-btn btn-default" onClick={this.print}><Fa.FaPrint /> Cetak</button>
                      }
                      {this.state.currentUser &&
                        AuthService.getRoles().role === 'FINALIZER' &&
                        isFinal &&
                        this.state.currentLetter.signatureType === 'HANDWRITTEN' &&
                        <FileUploader
                          buttonLabel={'Unggah Lampiran Tanda Tangan basah'}
                          files={this.state.files}
                          onChange={(files, deletedFile) => {
                            let letter = this.state.currentLetter;
                            let action = "ADD";
                            let attachmentId;
                            let update = false;
                            if (files[0] && files[0].state === "done") {
                              attachmentId = files[0].uuid;
                              update = true;
                            }
                            if (deletedFile) {
                              attachmentId = deletedFile.uuid;
                              update = true;
                              action = "DELETE";
                            }
                            if (update) {
                              let payload = {
                                letterId: letter.id,
                                action,
                                attachmentId
                              };
                              LetterService.updateLetterAttachments(payload);
                            }
                          }}
                          blobType="SIGNED_ATTACHMENT"
                          single={true}
                          minimal={true}
                          style={{ display: 'inline-block', marginTop: 0 }}
                        />
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
            {this.state.proceed &&
              <ProcessActionModal
                currentLetter={this.state.currentLetter}
                processing={this.state.processing}
                uuid={this.props.uuid}
                action={this.state.action}
                onProcessed={this.onProcessed}
                onCanceled={this.onCanceled}
              />
            }
            <SwAlert
              show={this.state.action === 'DONE' && !this.state.proceed}
              title=""
              onConfirm={() => {
                this.setState({ action: '' });
                window.browserHistory.push('/processing/need-approval');
              }}
              text={'Surat telah disetujui.'}
            />
            <SwAlert
              show={this.state.action === 'REJECT' && !this.state.proceed}
              title=""
              onConfirm={() => {
                this.setState({ action: '' });
                window.browserHistory.push('/processing/need-approval');
              }}
              text={'Surat telah ditolak/kembalikan.'}
            />
            <SwAlert
              show={this.state.action === 'CANCELED' && !this.state.proceed}
              title=""
              onConfirm={() => {
                this.setState({ action: '' });
                window.browserHistory.push('/processing/need-approval');
              }}
              text={'Surat telah dibatalkan.'}
            />
          </div>
          : this.state.mode === 'readDetail' ?
            <LetterReadDetail
              currentLetter={this.state.currentLetter}
              currentUser={this.state.currentUser}
              isProcessChainHolder={this.state.isProcessChainHolder}
              isSender={this.state.isSender}
              isRecipient={this.state.isRecipient}
              isBccSecret={this.state.isBccSecret}
              back={this.backToRead}
              combinedHistories={this.state.combinedHistories}
            />
            : this.state.mode === 'createDisposition' ?
              <LetterReadCreateDisposition
                currentLetter={this.state.currentLetter}
                currentUser={this.state.currentUser}
                isSender={this.state.isSender}
                back={this.backToListFromDisposition}
                dispositionRecipientOrigin={this.state.dispositionRecipientOrigin}
                asPelaksanaRecipient={asPelaksanaRecipient}
              />
              : this.state.mode === 'readDispositionHistory' ?
                <LetterReadDispositionHistory
                  currentLetter={this.state.currentLetter}
                  currentUser={this.state.currentUser}
                  back={this.backToRead}
                />
                : this.state.mode === 'readReferenceHistory' ?
                  <LetterReadReferenceHistory
                    currentLetter={this.state.currentLetter}
                    currentUser={this.state.currentUser}
                    back={this.backToRead}
                  />
                  : this.state.mode === 'readForwardHistory' ?
                    <LetterReadForwardHistory
                      currentLetter={this.state.currentLetter}
                      currentUser={this.state.currentUser}
                      back={this.backToListFromDisposition}
                      recipients={this.state.currentLetter.recipients}
                    />
                    : this.state.mode === 'readDispositionMonitoring' ?
                      <LetterReadDispositionMonitoring
                        currentUser={this.state.currentUser}
                        currentLetter={this.state.currentLetter}
                        dispositions={this.state.dispositions}
                        isSender={this.state.isSender}
                        back={this.backToRead}
                      />
                      :
                      <LetterReadDisposition
                        currentUser={this.state.currentUser}
                        currentLetter={this.state.currentLetter}
                        isSender={this.state.isSender}
                        back={this.backToRead}
                      />
        )
        :
        <span className="alert alert-warning"><Fa.FaInfo />&nbsp;Surat tidak ditemukan / tidak dapat diakses.</span>
      )
    );
  }
}

let invitationHTMLSource = null;

const LetterRead = ({ match, testerMode }) => (
  <LetterReadComponent isTesterMode={testerMode} match={match} uuid={match.params.uuid} action={match.params.action} />
);

export default connect(mapper)(LetterRead);