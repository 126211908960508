import axios from "./Axios";
import debounce from 'debounce';
import Config from "../config";
import Dummy from './Dummy';
import Auth from './Auth';
import Error from './Error';
import Misc from "./Misc";
import * as date from "date-fns";
import dateLocaleId from 'date-fns/locale/id';

const DummyService = new Dummy();
const AuthService = new Auth();
const ErrorService = new Error();
const MiscService = new Misc();

const filterFields = ['order',
  'letterDateStart',
  'letterDateEnd',
  'referenceNumber',
  'subject',
  'signerName',
  'classification',
  'importance',
  'recipientName',
  'ccRecipientName',
]

const getStats = debounce(() => {
  if (!AuthService.getToken()) {
    return;
  }
  axios.get(Config.apiHost + "/api/v1/letter-stats?throughAssistant=" + Config.dispositionThroughAssistant, {
    headers: MiscService.generateHeaders()
  })
    .then((result) => {
      if (result && result.data) {
        // Wrap the data
        let wrapped = {
          "INBOX": 0,
          "ALL_UNREADS": 0,
          "TNDE_NOTA_DINAS": 0,
          "TNDE_SURAT_TUGAS": 0,
          "TNDE_UNDANGAN": 0,
          "TNDE_SURAT": 0,
          "TNDE_MEMO": 0,
          "OA2OA": 0,
          "TNDE_LAPORAN": 0,
          "TNDE_SURAT_KETERANGAN": 0,
          "TNDE_LEMBAR_RALAT": 0,
          "TNDE_PENGUMUMAN": 0,
          "TNDE_TELAAHAN_STAF": 0,
          "DISPOSITION": 0,
          "COMMENT": 0,

          "NASKAH_DINAS_KORESPONDENSI": 0,
          "NASKAH_DINAS_KHUSUS": 0,
          "NASKAH_DINAS_ARAHAN": 0,

          "CANCELED": 0,

          "PROCESSING_AS_FINALIZER": 0,
          "PROCESSING": 0,
          "FINAL": 0,
          "NEED_APPROVAL": 0,
          "NEED_OPINION": 0,
          "RETURNED": 0,
          "DRAFT": 0,

          // REKAP
          "INBOX_TOTAL": 0,
          "DISPOSITION_TOTAL": 0,
          "DELEGATED_TOTAL": 0,
        }
        let data = result.data.data
        for (let i in data) {
          if (data[i].box === 'MANUAL_SURAT_DINAS') data[i].box = 'TNDE_SURAT';
          wrapped[data[i].box] += data[i].total || 0;
          // INBOX
          if (data[i].contentType && data[i].contentType === 'LETTER' && data[i].box && data[i].box !== 'NEED_APPROVAL' && data[i].box !== 'RETURNED' && data[i].box !== 'NEED_OPINION' && data[i].box !== 'FINAL' && data[i].box !== 'DRAFT') {
            wrapped["ALL_UNREADS"] += data[i].total || 0;
            wrapped["INBOX"] += data[i].total || 0;
          }
          if (data[i].contentType && (data[i].contentType === 'DISPOSITION' || data[i].contentType === 'COMMENT')) {
            wrapped["ALL_UNREADS"] += data[i].total || 0;
          }
          if (data[i].contentType && data[i].contentType === 'OUTBOX_CANCELED') {
            wrapped["CANCELED"]++;
          }
          // PROCESSING
          if (data[i].contentType && data[i].contentType === 'LETTER' && data[i].box && (data[i].box === 'NEED_APPROVAL' || data[i].box === 'RETURNED' || data[i].box === 'NEED_OPINION' || data[i].box === 'FINAL' || data[i].box === 'DRAFT')) {
            if (data[i].box === 'FINAL' && AuthService.getRoles().role === 'FINALIZER') {
              wrapped["PROCESSING"] += data[i].total || 0;
            } else if (data[i].box !== 'FINAL') {
              wrapped["PROCESSING"] += data[i].total || 0;
            }
          }
          // TOTAL
          if (data[i].contentType && data[i].contentType === 'DELEGATED_TOTAL' && data[i].box && data[i].box === 'DELEGATED_TOTAL') {
            wrapped["DELEGATED_TOTAL"] += data[i].total || 0;
          }
        }
        wrapped["NASKAH_DINAS_KORESPONDENSI"] = wrapped['TNDE_NOTA_DINAS'] + wrapped['TNDE_UNDANGAN'] + wrapped['TNDE_SURAT']
        wrapped["NASKAH_DINAS_ARAHAN"] = wrapped['TNDE_SURAT_TUGAS']
        wrapped["NASKAH_DINAS_KHUSUS"] = wrapped['TNDE_SURAT_KETERANGAN'] + wrapped['TNDE_LAPORAN'] + wrapped['TNDE_PENGUMUMAN'] + wrapped['TNDE_TELAAHAN_STAF'] + wrapped['TNDE_LEMBAR_RALAT']

        window.store.dispatch(window.actions.stats(wrapped));
      }
    })
    .catch((err) => {
      // do nothing
    })
}, 1000)


class Letter {

  getLetterReferences = (opt) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(DummyService.getLetterReferences(opt));
      }, 500);
    });
  }

  getList = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      if (!opt.box) return reject(new Error('box type should be defined.'));
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/letters?box=${opt.box}&page=${opt.page}&limit=${opt.limit}`;
      if (opt.letterType && opt.letterType.length > 0 && opt.letterType.toUpperCase() !== 'ALL') {
        url += `&letter_type=${opt.letterType.toUpperCase()}`;
      }
      if (opt.unread) {
        url += `&unread=true`;
      }
      if (opt.invitationDisposition) {
        url += `&invitationDisposition=true`;
      }
      if (opt.search && opt.search.length > 0) {
        url += `&search=${opt.search}`;
        url += `&signerName=${opt.search}`;
      }
      if (opt.invitationStartDate && opt.invitationEndDate) {
        url += `&invitationStartDate=${opt.invitationStartDate}&invitationEndDate=${opt.invitationEndDate}`
      }
      for (let i in filterFields) {
        if (opt[filterFields[i]]) {
          url += `&${filterFields[i]}=${opt[filterFields[i]]}`
        }
      }
      if (opt.year) {
        url += `&year=${opt.year}`;
      }
      if (opt.markAllAsRead) {
        url += `&markAllAsRead=true`;
      }
      if (opt.bundleSigners && opt.bundleSigners.length > 0) {
        url += `&bundleSigners=${opt.bundleSigners}`;
      }
      if (opt.startDate && opt.endDate) {
        url += `&start_date=${opt.startDate}&end_date=${opt.endDate}`;
      }
      // Advanced filter
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          getStats()
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  createLetter = (letter) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letters",
        letter,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  updateLetter = (letter) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter/" + letter.id,
        letter,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  updateLetterAttachments = (payload) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter/attachments/" + payload.letterId,
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  deleteLetter = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/letter/" + letterId, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getPDFLetter = (documentID) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/pdf/view/" + documentID,
        {
          responseType: 'blob',
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          resolve(url);
        })
        .catch((err) => {
          console.log(err.response)
          if (err.response && err.response.status !== 404) {
            ErrorService.handle(err);

          }
          reject(err);
        })
    });
  }

  createPDFLetter = (html, documentID) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/pdf/create",
        { html: html, "document-id": documentID },
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          if (err.response &&
            err.response.data &&
            err.response.data.message) {
            if (err.response.data.message === "duplicate") {
              // Silently ignore error on duplicates
              resolve();
            } else {
              ErrorService.handle(err);
              reject(err);
            }
          }
        })
    });
  }

  getCSSForPDF = (id) => {
    return new Promise((resolve, reject) => {
      var url = window.location.href;
      var arr = url.split("/");
      var result = arr[0] + "//" + arr[2]
      axios.get(result + "/print.css", {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          return resolve(response)
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }


  readLetter = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/letter/" + id, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          if (response.data.letterState &&
            (
              response.data.letterState === 'SENT' ||
              response.data.letterState === 'CANCELED'
            )
          ) {
            this.setReadStatus(id, 'LETTER');
          }
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  processLetter = (uuid, payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter/process/" + uuid,
        payload, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          console.log(err)
          if (err.response && err.response.status && err.response.status !== 403) {
            ErrorService.handle(err);
          }
          reject(err);
        })
    });
  }

  pullBackLetter = (letter) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter/" + letter.id + '?pullBack=true',
        letter,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }


  getHistories = (id) => {
    return new Promise((resolve, reject) => {
      if (!id) return reject(new Error('id should not be null'));
      let url = `${Config.apiHost}/api/v1/letter-histories?letterId=${id}&limit=999`; // Fetch them all
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getComments = (id) => {
    return new Promise((resolve, reject) => {
      if (!id) return reject(new Error('id should not be null'));
      let url = `${Config.apiHost}/api/v1/letter-comments?itemId=${id}&limit=999`; // Fetch them all
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  createDisposition = (disposition) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter-dispositions",
        disposition, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  updateDisposition = (disposition) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter-disposition/" + disposition.id,
        disposition,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getDispositions = (opt) => {
    return new Promise((resolve, reject) => {
      let url = Config.apiHost + "/api/v1/letter-dispositions";
      let query = '';
      opt = opt || {}
      opt.throughAssistant = Config.dispositionThroughAssistant
      for (let i in Object.keys(opt)) {
        if (opt[Object.keys(opt)[i]] && opt[Object.keys(opt)[i]] !== undefined) {
          if (query.length > 0) {
            query += '&';
          }
          query += Object.keys(opt)[i] + '=' + opt[Object.keys(opt)[i]];
        }
      }
      if (query.length > 0) {
        url += '?' + query;
      }
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setDispositionState = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/disposition-state/" + payload.statusId,
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  forward = (letterId, payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter/forward/" + letterId,
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setReadStatus = (contentId, contentType) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/read-status",
        {
          id: contentId,
          contentType: contentType
        },
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          getStats()
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  askOpinion = (letterId, payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter/ask-opinion/" + letterId,
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  cancelAskOpinion = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/letter/ask-opinion/" + letterId,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getOpinion = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/letter/ask-opinion/" + letterId,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  answerOpinion = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter/ask-opinion/" + letterId,
        null,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setLetterHeaderForPUA = (puaID, letterHeaderID) => {
    const payload = {
      puaID,
      letterHeaderID
    }
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter/letter-header-for-pua",
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getLetterHeaderForPUA = (puaID) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/letter/letter-header-for-pua/" + puaID,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getStats = () => {
    getStats();
  }

  getDashboardAdminStats = () => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/dashboard-management-stats",
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((result) => {
          let groupedLetterStats = () => ({
            daily: 0,
            weekly: {
              "Senin": 0,
              "Selasa": 0,
              "Rabu": 0,
              "Kamis": 0,
              "Jumat": 0,
            },
            monthly: {
              "Minggu ke-1": 0,
              "Minggu ke-2": 0,
              "Minggu ke-3": 0,
              "Minggu ke-4": 0,
              "Minggu ke-5": 0,
            },
            yearly: {
              "Januari": 0,
              "Februari": 0,
              "Maret": 0,
              "April": 0,
              "Mei": 0,
              "Juni": 0,
              "Juli": 0,
              "Agustus": 0,
              "September": 0,
              "Oktober": 0,
              "November": 0,
              "Desember": 0,
            },
          });
          let wrapped = {
            "DISPOSITION_OUT": 0,
            "DISPOSITION_IN_PROCESS": 0,
            "DISPOSITION_CLOSED": 0,
            "LETTER_WILL_BE_APPROVED": 0,

            letterInProcess: groupedLetterStats(),
            letterReturned: groupedLetterStats(),
            letterInFinalization: groupedLetterStats(),
            letterSent: groupedLetterStats(),

            tndeNotaDinas: groupedLetterStats(),
            tndeSuratTugas: groupedLetterStats(),
            tndeUndangan: groupedLetterStats(),
            tndeSurat: groupedLetterStats(),
            tndeMemo: groupedLetterStats(),
            tndeSuratKeterangan: groupedLetterStats(),            
            tndeLaporan: groupedLetterStats(),
            tndeLembarRalat: groupedLetterStats(),
            tndePengumuman: groupedLetterStats(),
            tndeTelaahanStaf: groupedLetterStats(),            

            groupedLetterStatsKeys:{
              "LETTER_IN_PROCESS":"letterInProcess",
              "LETTER_RETURNED":"letterReturned",
              "LETTER_IN_FINAL":"letterInFinalization",
              "LETTER_SENT":"letterSent",
              "TNDE_NOTA_DINAS":"tndeNotaDinas",
              "TNDE_SURAT_TUGAS":"tndeSuratTugas",
              "TNDE_UNDANGAN":"tndeUndangan",
              "TNDE_SURAT":"tndeSurat",
              "TNDE_MEMO":"tndeMemo",
              "TNDE_SURAT_KETERANGAN":"tndeSuratKeterangan",
              "TNDE_LAPORAN":"tndeLaporan",
              "TNDE_LEMBAR_RALAT":"tndeLembarRalat",
              "TNDE_PENGUMUMAN":"tndePengumuman",
              "TNDE_TELAAHAN_STAF":"tndeTelaahanStaf",
            },
          };
          let data = result.data.data;

          for (let i in data) {
            if (data[i].contentType && data[i].box && data[i].contentType === data[i].box && wrapped.groupedLetterStatsKeys[data[i].contentType]) {
              let key = wrapped.groupedLetterStatsKeys[data[i].contentType];
              wrapped[key].daily += data[i].freqDaily || 0;
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId });
                if (val && val.length > 0) {
                  wrapped[key].weekly[val] += data[i].freqWeekly[j].total;
                }
              }
              for (let j in data[i].freqMonthly) {
                let weekKey = `Minggu ke-${data[i].freqMonthly[j].weekOfMonth}`;
                wrapped[key].monthly[weekKey] += data[i].freqMonthly[j].total;
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId });
                if (val && val.length > 0) {
                  wrapped[key].yearly[val] += data[i].freqYearly[j].total;
                }
              }
            } else if (data[i].contentType && data[i].box && data[i].contentType === data[i].box) {
              wrapped[data[i].contentType] += data[i].total || 0;
            }
          }
          resolve(wrapped);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  forwardDisposition = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/forward-disposition/" + letterId,
        null,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getDispositionMonitoring = (uuid, originatorUUID, recipientUUIDs) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + `/api/v1/disposition-monitoring?uuid=${uuid}&originatorUUID=${originatorUUID}&recipientUUIDs=${recipientUUIDs}`,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

}

export default Letter;
