import React, { Component } from 'react';

/*

Usage : 

<Component label="Perihal" 
  name="particularFieldName" value={this.state.particularFieldName} 
  onChange={this.inputEventHandler} 
  required={true} 
  maxChar={500} 
  labelWidth=2
  fieldWidth=8
  disabled=false
  additionalLabel={'Hindari menyalin perihal dari Ms. Word atau situs web.'}
/>

*/

class component extends Component {
  state = {
    value : '',
  }

  componentDidMount = () => {
    this.setState({value : this.props.value || ''});
  }

  onChange = (event) => {
    if (this.props.maxChar && this.props.maxChar > 0 && event.target.value.length > this.props.maxChar) {
      return;
    }
    this.setState({value : event.target.value});
    this.props.onChange(event);
  }

  render() {
    const { 
      label,
      name,
      required,
      maxChar,
      labelWidth,
      fieldWidth,
      additionalLabel,
      disabled,
      readonly,
    } = this.props;
    return (
      <div className="form-group row" style={{marginTop:10}}>
        <label className={`col-md-${labelWidth ? labelWidth.toString() : '1'} col-sm-${labelWidth ? labelWidth.toString() : '2'} control-label`}>
          {label}
          &nbsp;<span className="red">{required && '*'}</span>
        </label>
        <div className={`col-md-${fieldWidth ? fieldWidth.toString() : '10'} col-sm-${fieldWidth ? fieldWidth.toString() : '10'}`}>
         {readonly ?
         <span>{this.state.value}</span>
         :
          <input
            name={name}
            value={this.state.value}
            onChange={this.onChange}
            required={required}
            className="form-control"
            disabled={disabled}
            autoComplete="off"
          />
         }
          { additionalLabel ? <span style={{float:'left', color:'red'}}>{additionalLabel}</span> : '' }
          { maxChar && maxChar > 0 ? <span style={{float:'right'}}>{this.state.value.length}/{maxChar}</span> : '' }
        </div>
      </div>
    );
  }
}

export default component;
