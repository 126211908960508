import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import './styles.css';
import dateLocaleId from 'date-fns/locale/id';
import Letter from '../../../services/Letter';
import stripTags from '../../../utils'
import date from 'date-fns';
import Spinner from '../../../components/Spinner';
import Constants from '../../../consts';
import Error from '../../../services/Error';

const LetterService = new Letter();
const ErrorService = new Error();

export default class LetterReadDispositionMonitoring extends Component {
  state = {
    loading: false,
    dispositionMonitorings: [],
    monitoringStatus: Constants.monitoringStatus,
  }
  componentDidMount = () => {
    let currentUser = JSON.parse(localStorage.getItem('current_user'));
    this.setState({ loading: true }, () => {
      this.props.dispositions.forEach( disposition => {
        let recipientCSV = ""
        disposition.recipients.forEach(recipient => {
          let recipientUUID = recipient.split("|")[0]
          if (recipientUUID !== currentUser.organization.id) 
            recipientCSV += recipientUUID + ","
        });
        if(recipientCSV.length > 0){
          LetterService.getDispositionMonitoring(disposition.id, disposition.originator, recipientCSV)
            .then(res => {
              let newState = this.state.dispositionMonitorings
              newState.push(...res)
              this.setState({dispositionMonitorings:newState, loading:false})
            })
            .catch((err) => {
              console.log(err);
              ErrorService.handle(err)
              this.setState({ loading: false, dispositionMonitorings: [] });
            });
        }
      });
    });
  }
  render() {
    return (
      <div>
        <div>
          <div style={{ display: 'inline-block', verticalAlign: 'top', margin: 10, marginLeft: 0 }}>
            <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={this.props.back} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'inline-block' }}>
            <h4>Monitoring Disposisi</h4>
            <span> Nomor surat : {this.props.currentLetter.number} <br /> {stripTags(this.props.currentLetter.subject)}</span>
          </div>
        </div>
        {this.state.loading && <Spinner />}
        {!this.state.loading && (!this.state.dispositionMonitorings || (this.state.dispositionMonitorings && this.state.dispositionMonitorings.length === 0)) &&
          <div className="alert alert-warning" style={{ marginTop: 15 }}><Fa.FaInfo />&nbsp;Belum ada disposisi.</div>
        }
        {!this.state.loading && this.state.dispositionMonitorings && this.state.dispositionMonitorings.length > 0 &&
          <div className="row" style={{ marginTop: 15 }}>
            <div className="col-md-12">
              <div className="box">
                {this.state.dispositionMonitorings && this.state.dispositionMonitorings.map((item, index) => {
                  return <div className="box-body" key={index}>
                    <table className="table table-bordered table-read-detail">
                      <thead>
                        <tr className="active">
                          <th colSpan="2">
                            {date.format(item.time, 'DD MMMM YYYY, HH:mm', { locale: dateLocaleId })}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="20%">Dari</td>
                          <td width="80%">
                            {item.from}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Untuk</td>
                          <td width="80%">
                            {item.for}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Status</td>
                          <td width="80%">
                            {this.state.monitoringStatus[item.status]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                })}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
