import axios from "./Axios";
import Config from "../config";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc()

class Comment {

  create = (comment) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter-comments",
        comment,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getList = (opt) => {
    return new Promise((resolve, reject) => {
      let url = Config.apiHost + "/api/v1/letter-comments";
      let query = '';
      opt = opt || {}
      for (let i in Object.keys(opt)) {
        if (opt[Object.keys(opt)[i]]) {
          if (query.length > 0) {
            query += '&';
          }
          query += Object.keys(opt)[i] + '=' + opt[Object.keys(opt)[i]];
        }
      }
      if (query.length > 0) {
        url += '?' + query;
      }
      axios.get(url,{
        headers: MiscService.generateHeaders()
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

}

export default Comment;
