import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import DateTimePicker from '../../../components/DateTimePicker';
import ClassificationModal from '../../../components/ClassificationModal';

export default class ArchiveFilter extends Component {
  state = {
    mainCollapsed : true,
    filterCollapsed : true,
    otherFilterCollapsed : true,
    dateFilterCollapsed : true,
    // filter
    order : 'asc',
    letterDateStart : '',
    letterDateEnd : '',
    referenceNumber : '',
    subject : '',
    signerName : '',
    letterType : '',
    classification : '',
    classificationName : '',
    importance : '',
    recipientName : '',
    ccRecipientName : '',
  }

  inputEventHandler = (e) => {
    let obj = {};
    if (e.target.name === 'classification') {
      obj['classificationName'] = e.target.value;
      obj['classification'] = e.target.raw;
    } else {
      obj[e.target.name] = e.target.value;
    }
    this.setState(obj);
  }

  updateFilter = () => {
    let startDate = null;
    let endDate = null;
    if (this.state.letterDateStart) {
      startDate = this.state.letterDateStart.toISOString();
    }
    if (this.state.letterDateEnd) {
      endDate = this.state.letterDateEnd.toISOString();
    }
    this.props.updateFilter({
      order : this.state.order,
      letterDateStart : startDate,
      letterDateEnd : endDate,
      referenceNumber : this.state.referenceNumber,
      subject : this.state.subject,
      signerName : this.state.signerName,
      letterType : this.state.letterType,
      classification : this.state.classification,
      importance : this.state.importance,
      recipientName : this.state.recipientName,
      ccRecipientName : this.state.ccRecipientName,
    });
  }

  resetFilter = () => {
    var self = this;
    var keys = Object.keys(this.state);
    for (var i in keys) {
      if (keys[i].indexOf('Collapsed') > -1) continue;
      let obj = {}
      obj[keys[i]] = '';
      this.setState(obj);
    }
    setTimeout(function(){
      self.updateFilter();
    }, 100);
  }

  toggle = (filter) => {
    this.setState((prevState) => {
      let obj = {}
      obj[filter] = !prevState[filter];
      return obj;
    });
  }

  render() {
    return (
	  <div className="box box-success">
	    <div className="box-body" style={{padding:0}}>
          <div 
            className="row"
            style={{padding:15, cursor:'pointer'}}
            onClick={() => { this.toggle('mainCollapsed')}}
          >
            <div className="col-sm-12 col-md-12 col-lg-12">
            <span style={{float:'right', cursor : 'pointer', fontSize:18}}>
              {this.state.mainCollapsed ? <Fa.FaChevronDown/> : <Fa.FaChevronUp/>}
            </span>
            <h4>Filter : </h4>
            </div>
          </div>
        </div>
		<div
          className="box-body"
          style={{padding:0, display:this.state.mainCollapsed ? 'none' : 'block'}}
        >
          <div className="row" style={{padding:15}}>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group row" style={{marginRight:5}}>
                <label className="control-label col-sm-2">Urutkan berdasarkan tanggal</label>
                <select 
                  name="order"
                  value={this.state.order}
                  onChange={this.inputEventHandler}
                  className="form-control col-sm-10"
                >
                  <option value="asc">Urut Naik A-Z</option>
                  <option value="desc">Urut Turun Z-A</option>
                </select>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="card card-default">
                <div className="card-header" 
                  style={{cursor : 'pointer'}} 
                  onClick={() => {this.toggle('dateFilterCollapsed')}}
                >
                  <span style={{float:'right', cursor : 'pointer'}}>
                    {this.state.dateFilterCollapsed ? <Fa.FaChevronDown/> : <Fa.FaChevronUp/>}
                  </span>
                  Filter Tanggal
                </div>
                <div className="card-body" style={{display : this.state.dateFilterCollapsed ? 'none' : 'block'}}>
                  <DateTimePicker
                    label="Dari Tanggal"
                    name="letterDateStart"
                    value={this.state.letterDateStart}
                    onChange={this.inputEventHandler}
                    labelWidth={3}
                    fieldWidth={9}
                  />
                  <DateTimePicker
                    label="Sampai Tanggal"
                    name="letterDateEnd"
                    value={this.state.letterDateEnd}
                    onChange={this.inputEventHandler}
                    labelWidth={3}
                    fieldWidth={9}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{padding:15}}>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="card card-default">
                <div className="card-header" 
                  style={{cursor : 'pointer'}} 
                  onClick={() => {this.toggle('filterCollapsed')}}
                >
                  <span style={{float:'right', cursor : 'pointer'}}>
                    {this.state.filterCollapsed ? <Fa.FaChevronDown/> : <Fa.FaChevronUp/>}
                  </span>
                  Filter Nomor, Perihal dan Pengirim Surat  
                </div>
                <div className="card-body" style={{display: this.state.filterCollapsed ? 'none' : 'block'}}>
                  <div className="form-group row" style={{marginRight:5}}>
                    <label className="control-label col-sm-3">No. Surat</label>
                    <div className="col-sm-9">
                      <input
                        name="referenceNumber"
                        value={this.state.referenceNumber}
                        onChange={(e) => {this.inputEventHandler(e)}}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row" style={{marginRight:5}}>
                    <label className="control-label col-sm-3">Perihal</label>
                    <div className="col-sm-9">
                      <input
                        name="subject"
                        value={this.state.subject}
                        onChange={this.inputEventHandler}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row" style={{marginRight:5}}>
                    <label className="control-label col-sm-3">Dari</label>
                    <div className="col-sm-9">
                      <input name="signerName"
                        value={this.state.signerName}
                        onChange={this.inputEventHandler}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="card card-default">
                <div className="card-header" 
                  style={{cursor : 'pointer'}} 
                  onClick={() => {this.toggle('otherFilterCollapsed')}}
                >
                  <span style={{float:'right', cursor : 'pointer'}}>
                    {this.state.otherFilterCollapsed ? <Fa.FaChevronDown/> : <Fa.FaChevronUp/>}
                  </span>
                  Filter Lainnya
                </div>
                <div className="card-body" style={{display: this.state.otherFilterCollapsed ? 'none' : 'block'}}>
                  <div className="form-group row">
                    <label className="control-label col-sm-3">Jenis Surat</label>
                    <div className="col-md-9 col-sm-9">
                      <select
                        name="letterType"
                        value={this.state.letterType}
                        onChange={this.inputEventHandler}
                        className="form-control"
                      >
                        <option value="">- Pilih -</option>
                        <option value="TNDE_NOTA_DINAS">Nota Dinas</option>
                        <option value="TNDE_SURAT_TUGAS">Surat Tugas</option>
                        <option value="TNDE_UNDANGAN">Surat Undangan</option>
                        <option value="TNDE_SURAT">Surat</option>
                        <option value="TNDE_MEMO">Memo</option>
                      </select>
                    </div>
                  </div>
                  <ClassificationModal 
                    name="classification" 
                    label="Klasifikasi Masalah" 
                    placeholder="Klik di sini untuk membuka Daftar Klasifikasi"
                    value={this.state.classificationName}
                    onChange={this.inputEventHandler} 
                    labelWidth={3}
                    fieldWidth={9}
                  />
                  <div className="form-group row">
                    <label className="control-label col-sm-3">Sifat</label>
                    <div className="col-md-9 col-sm-9">
                      <select
                        name="importance"
                        value={this.state.importance}
                        onChange={this.inputEventHandler}
                        className="form-control"
                      >
                        <option value="">- Pilih -</option>
                        <option value="NORMAL">Biasa</option>
                        <option value="SECRET">Rahasia</option>
                        <option value="IMPORTANT">Segera</option>
                        <option value="VERY_IMPORTANT">Sangat Segera</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="control-label col-sm-3">Penerima</label>
                    <div className="col-md-9 col-sm-9">
                      <input name="recipientName"
                        value={this.state.recipientName}
                        onChange={this.inputEventHandler}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="control-label col-sm-3">Tembusan</label>
                    <div className="col-md-9 col-sm-9">
                      <input
                        name="ccRecipientName"
                        value={this.state.ccRecipientName}
                        onChange={this.inputEventHandler}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{margin:15}}>
            <button className="btn btn-primary" onClick={this.updateFilter}>Filter</button>&nbsp;&nbsp;
            <button className="btn btn-default" onClick={this.resetFilter}>Reset filter</button>
          </div>
        </div>
      </div>
    );
  }
}
