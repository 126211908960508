import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Letter from '../../../../../services/Letter';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import stripTags from '../../../../../utils'
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import '../styles.css';

const LetterService = new Letter();

export default class OutboxManual extends Component {
  state = {
    currentUser: {},
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    searchString: '',
    loading: false,
  }

  componentDidMount = () => {
    var currentUser = JSON.parse(localStorage.getItem('current_user'));
    this.setState({ currentUser: currentUser });
    window.store.dispatch(window.actions.showPageTitle(true));
    this.setState({ loading: true }, () => {
      LetterService.getList({ page: 1, limit: 10, search: this.state.searchString, outbox: true, box: 'EXTERNAL_ARCHIVE' })
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  page = (page) => {
    this.setState({ loading: true, searchString: '' });
    let opt = { page: page, limit: this.state.limit, search: this.state.searchString, outbox: true, box: 'EXTERNAL_ARCHIVE' }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = { page: this.state.page + 1, limit: this.state.limit, search: this.state.searchString, outbox: true, box: 'EXTERNAL_ARCHIVE' }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = { page: this.state.page - 1, limit: this.state.limit, search: this.state.searchString, outbox: true, box: 'EXTERNAL_ARCHIVE'  }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  reload = () => {
    this.setState({ loading: true });
    let opt = { page: this.state.page, limit: this.state.limit, search: this.state.searchString, outbox: true, box: 'EXTERNAL_ARCHIVE'  }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  read = (id) => {
    window.browserHistory.push('/read/' + id); 
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Surat Manual</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <div className="input-group input-group-sm">
                    <span className="input-group-btn">
                      <span className="btn btn-success">
                        <Fa.FaRefresh />
                      </span>
                    </span>
                    <input className="form-control" type="text" onChange={this.handleSearchString} value={this.state.searchString} />
                    {this.state.searchString && this.state.searchString.length > 0 &&
                      <span className="input-group-btn">
                        <span className="btn btn-default" onClick={this.dismissSearch}>
                          <Fa.FaClose />
                        </span>
                      </span>
                    }
                    <span className="input-group-btn">
                      <span className="btn btn-default" onClick={this.search}>
                        <Fa.FaSearch />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div style={{ float: 'right' }}>
                    <Pagination
                      disabled={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prev} next={this.next}
                      toPage={this.page} />
                  </div>
                </div>
                <hr />
              </div>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>No. Surat</th>
                      <th>Dari</th>
                      <th>Kepada</th>
                      <th>Subjek</th>
                      <th>Tanggal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && Object.keys(this.state.data).map((item, index) => {
                      return <tr
                        key={index}
                        onClick={() => {this.read(this.state.data[item].id)}}
                        style={{cursor:'pointer'}}
                      >
                        <td>{this.state.data[item].number}</td>
                        <td>
                          {this.state.data[item].manualLetterSender}
                        </td>
                        <td>
                        {this.state.data[item].recipients[0].recipientName} {this.state.data[item].recipients.length > 1 && <span>...</span>}
												</td>
                        <td>{stripTags(this.state.data[item].subject)}</td>
                        <td>
                          {date.format(this.state.data[item].letterDate, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <span style={{ fontSize: 32 }}>
                    <Fa.FaInbox />
                  </span>
                  <br />
                  <h4>Tidak ada surat manual</h4>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
