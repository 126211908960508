import React, { Component } from 'react';
import Disposition from './Disposition.js';

export default class InboxDisposisi extends Component {
  render() {
    return (
      <Disposition/>
    );
  }
}
