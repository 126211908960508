import React, { Component } from 'react';
import swal from 'sweetalert';
import Spinner from '../../../components/Spinner';

export default class PasswordCondfirmModal extends Component {
  state = {
    showModal: false,
    loading: false,
    password: '',
  }
  componentDidMount = () => {
    this.setState({ loading: false, notes: null, pin: null });
    this.showModal();
  }
  componentWillMount = () => {
    this.setState({ loading: false, notes: null, pin: null });
  }
  showModal = () => {
    this.setState({ showModal: true });
    clearTimeout(this.modalTimeOut)
    this.modalTimeOut = setTimeout(() => {
      this.passwordInputElement.focus();
    }, 500);
  }
  hideModal = () => {
    this.setState({ showModal: false });
    if (this.props.onCanceled) this.props.onCanceled()
  }

  ok = () => {
    if (!this.state.password || (this.state.password && this.state.password.length < 1)) {
      swal({
        title: '',
        type: 'warning',
        text: 'Silakan masukkan Password terlebih dahulu.',
      });
      return;
    }
    this.props.onConfirm(this.state.password)
    this.hideModal()
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.ok();
    }
  }

  render() {
    return (
      <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  aria-describedby="passwordHelp"
                  placeholder="Password"
                  value={this.state.password? this.state.password: ''}
                  onChange={(e) => { this.setState({ password: e.target.value }) }}
                  disabled={this.loading}
                  onKeyPress={this.onKeyPress}
                  ref={passwordInputElement => this.passwordInputElement = passwordInputElement}
                  autoFocus
                />
              </div>
            </div>
            <div className="modal-footer">
              {this.state.loading ?
                <Spinner />
                :
                <div>
                  <span className='btn btn-primary' style={{ marginRight: 10 }} onClick={this.ok} disabled={(!this.state.password || (this.state.password && this.state.password.length < 1))}>
                    Konfirmasi
                  </span>
                  <span className="btn btn-default" onClick={this.hideModal}>Cancel</span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
