import axios from "./Axios";
import Config from "../config";
import Error from './Error';
import Misc from "./Misc";
import swal from 'sweetalert';

const ErrorService = new Error();
const MiscService = new Misc()

class User {
  getMonitorLog = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/users/monitor?page=${opt.page}&limit=${opt.limit}`;
      if (opt.search && opt.search.length > 0) {
        url += `&search=${opt.search}`
      }
      axios.get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getMonitorPresence = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/users/monitor-presence?page=${opt.page}&limit=${opt.limit}`;

      if (opt.currentUser) {
        url += `&uuid=${opt.currentUser}`;
      }

      if (opt.unitId) {
        url += `&unitId=${opt.unitId}`;
      }

      if (opt.startDate && opt.endDate) {
        url += `&startDate=${opt.startDate}&endDate=${opt.endDate}`;
      }

      axios.get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getMonitorPresenceExport = (opt) => {
    return new Promise((resolve, reject) => {
      if (!opt.exportAs) reject('Required export to be');

      let url = `${Config.apiHost}/api/v1/users/monitor-presence?&exportRequest=true&exportAs=${opt.exportAs}`;
      if (opt.startDate && opt.endDate) {
        url += `&startDate=${opt.startDate}&endDate=${opt.endDate}`;
      }

      if (opt.unitId) {
        url += `&unitId=${opt.unitId}`;
      }

      axios.get(url,
        {
          responseType: 'blob',
          headers: MiscService.generateHeaders()
        }
      ).then((response) => {
        resolve(response.data);
      }).catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  getAll = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/users?page=${opt.page}&limit=${opt.limit}`;
      if (opt.search && opt.search.length > 0) {
        url += `&search=${opt.search}`
      }
      axios.get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getInvalidEmailUsers = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/users?invalid-emails=true&page=${opt.page}&limit=${opt.limit}`;
      axios.get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  get = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/user/" + id, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  create = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/users",
        payload,
        { headers: MiscService.generateHeaders() }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  createMultiple = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/users/multiple",
        payload,
        { headers: MiscService.generateHeaders() }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/user/" + id,
        payload,
        { headers: MiscService.generateHeaders() }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  changeEmail = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/change-email",
        payload,
        { headers: MiscService.generateHeaders() }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  delete = (id) => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/user/" + id, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          //ErrorService.handle(err);
          reject(err);
        })
    });
  }

  impersonate = (id) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/impersonate",
        id,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve({ body: response.data, impersonateToken: response.headers.authorization });
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  logoutImpersonate = () => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/impersonate/" + localStorage.getItem('impersonator_token_id'),
        {
          headers: MiscService.generateHeaders()
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

}

export default User;
