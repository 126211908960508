import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import LetterFormSelectInput from '../../Compose/components/LetterFormSelectInput';
import Letter from '../../../../../services/Letter';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import ArchiveFilter from './ArchiveFilter';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import Auth from '../../../../../services/Auth';
import stripTags from '../../../../../utils';

const AuthService = new Auth();
const LetterService = new Letter();

export default class ArchiveDisposition extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    filterValue: '',
    loading: false,
    filter: {
    },
    year: '' + ((new Date()).getFullYear() - 1),
    archiveYears: [],
  }

  componentDidMount = () => {
    if (AuthService.getRoles().role !== 'ADMIN' && AuthService.getRoles().orgRole !== 'PUKS') {
      window.browserHistory.push('/');
      return;
    }
    this.setState({ loading: true }, () => {
      LetterService.getDispositions({
        page: 1,
        limit: 10,
        archive: true,
        search: this.state.searchString,
        year: this.state.year,
        order: 'asc'
      })
        .then((result) => {
          const lastYear = (new Date()).getFullYear() - 1;
          const oldestYear = (new Date(result.startingDate)).getFullYear() === 1 ?
            lastYear : (new Date(result.startingDate)).getFullYear() - 1;
          let archiveYears = [];
          if (lastYear === oldestYear) {
            archiveYears.push({ str: '' + oldestYear, value: '' + oldestYear });
          } else if (lastYear > oldestYear) {
            for (let i = lastYear; i >= oldestYear; i--) {
              archiveYears.push({ str: '' + i, value: '' + i });
            }
          }

          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data,
            loading: false,
            archiveYears: archiveYears
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  updateFilter = (filterData) => {
    this.setState({ filter: filterData }, () => {
      let obj = {
        page: 1,
        limit: 10,
        archive: true,
        search: this.state.searchString,
        year: this.state.year,
        order: 'asc'
      }
      let keys = Object.keys(this.state.filter)
      for (let i in keys) {
        if (this.state.filter[keys[i]]) {
          obj[keys[i]] = this.state.filter[keys[i]];
        }
      }
      LetterService.getDispositions(obj)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data,
            loading: false,
          });
        })
        .catch((err) => {
          // handle err
          this.setState({
            currentLetter: {},
            data: [],
            loading: false,
          });
        });
    });
  }

  page = (page) => {
    this.setState({ loading: true, filterValue: '' });
    let obj = {
      page: page, limit: 10,
      archive: true,
      search: this.state.searchString,
      year: this.state.year,
      order: 'asc'
    }
    let keys = Object.keys(this.state.filter)
    for (let i in keys) {
      if (this.state.filter[keys[i]]) {
        obj[keys[i]] = this.state.filter[keys[i]];
      }
    }
    LetterService.getDispositions(obj)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        // handle err
        this.setState({
          currentLetter: {},
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, filterValue: '' });
    let obj = {
      page: this.state.page + 1,
      limit: 10,
      archive: true,
      search: this.state.searchString,
      year: this.state.year,
      order: 'asc'
    }
    let keys = Object.keys(this.state.filter)
    for (let i in keys) {
      if (this.state.filter[keys[i]]) {
        obj[keys[i]] = this.state.filter[keys[i]];
      }
    }
    LetterService.getDispositions(obj)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        // handle err
        this.setState({
          currentLetter: {},
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, filterValue: '' });
    let obj = {
      page: this.state.page - 1,
      limit: 10,
      archive: true,
      search: this.state.searchString,
      year: this.state.year,
      order: 'asc'
    }
    let keys = Object.keys(this.state.filter)
    for (let i in keys) {
      if (this.state.filter[keys[i]]) {
        obj[keys[i]] = this.state.filter[keys[i]];
      }
    }
    LetterService.getDispositions(obj)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        // handle err
        this.setState({
          currentLetter: {},
          data: [],
          loading: false,
        });
      });
  }

  read = (id) => {
    window.browserHistory.push('/read/' + id + '?archiveView=true&year=' + this.state.year);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4> Arsip Disposisi</h4>
          <ArchiveFilter updateFilter={this.updateFilter} filter={this.state.filter} />
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <LetterFormSelectInput
                    name="archiveYear"
                    labelWidth="0"
                    fieldWidth="3"
                    onChange={(e) => {
                      if (e && e.target && e.target.value) {
                        this.setState({ year: e.target.value }, () => { this.page(1); });
                      }
                    }}
                    options={this.state.archiveYears}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div style={{ float: 'right' }}>
                    <Pagination
                      disabled={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prev} next={this.next}
                      toPage={this.page} />
                  </div>
                </div>
                <hr />
              </div>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Dari</th>
                      <th>Catatan</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && Object.keys(this.state.data).map((item, index) => {
                      return <tr key={index} onClick={() => { this.read(this.state.data[item].letterId) }} style={{ cursor: 'pointer' }}>
                        <td>{this.state.data[item].originatorName}</td>
                        <td>{stripTags(this.state.data[item].notes)}</td>
                        <td>
                          {date.format(this.state.data[item].time, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <span style={{ fontSize: 32 }}>
                    <Fa.FaInbox />
                  </span>
                  <br />
                  <h4>Tidak ada surat</h4>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
