import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import swal from 'sweetalert';
import LetterTemplate from '../../../services/LetterTemplate';
import Blob from '../../../services/Blob';
import Config from "../../../config";
import Spinner from '../../../components/Spinner';
import Pagination from '../../../components/Pagination';
import FileUploader from './Compose/components/FileUploader/';
import '../styles.css';
import downloadjs from 'downloadjs';

const LetterTemplateService = new LetterTemplate();
const BlobService = new Blob();

export default class LetterTemplateComponent extends Component {
  state = {
    files: [],
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    searchString: '',
    loading: false,
    saveLoading: false,
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    this.setState({ loading: true }, () => {
      LetterTemplateService.getList({ page: 1, limit: 10, search: this.state.searchString })
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  downloadAttachment = (url, filename) => {
    BlobService.downloadToDataURI(url)
      .then((res) => {
        downloadjs(res.uri, filename, res.type);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  attachmentHandler = (files) => {
    this.setState({ files: files });
  }

  reset = () => {
    this.setState({ description: '', files: [] });
  }

  delete = (id) => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menghapus templat ini?',
    }, (ok) => {
      if (ok) {
        this.setState({ loading: true }, () => {
          LetterTemplateService.delete(id)
            .then(() => {
              this.setState({ loading: false, description: '', files: [] });
              this.reload();
            })
            .catch(() => {
              this.setState({ loading: false, description: '', files: [] });
            });
        });
      }
    });
  }

  save = () => {
    if (!this.state.name || (this.state.name && this.state.name.length < 1)
      || this.state.files.length < 1) {
      swal({
        title: '',
        type: 'warning',
        text: 'Mohon melengkapi isian yang ada.',
      });
      return;
    }
    this.setState({ saveLoading: true }, () => {
      let payload = {
        filename: this.state.files[0].file.name,
        name: this.state.name,
        blobId: this.state.files[0].uuid,
      }
      LetterTemplateService.create(payload)
        .then(() => {
          this.setState({ saveLoading: false, description: '', files: [] });
          this.reload();
        })
        .catch(() => {
          this.setState({ saveLoading: false, description: '', files: [] });
        });
    });
  }

  page = (page) => {
    this.setState({ loading: true, searchString: '' });
    let opt = { page: page, limit: this.state.limit, search: this.state.searchString }
    LetterTemplateService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = { page: this.state.page + 1, limit: this.state.limit, search: this.state.searchString }
    LetterTemplateService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, searchString: '' });
    let opt = { page: this.state.page - 1, limit: this.state.limit, search: this.state.searchString }
    LetterTemplateService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  reload = () => {
    this.setState({ loading: true });
    let opt = { page: this.state.page, limit: this.state.limit, search: this.state.searchString }
    LetterTemplateService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div>
          <h3>Templat Naskah Dinas</h3>
        </div>
        <div className="">
          <div className="">
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="box box-success">
                      <div className="box-body">
                        {this.state.saveLoading ?
                          <Spinner /> :
                          <div>
                            <div className="form-group">
                              <label htmlFor="name">Nama templat</label>
                              <input
                                className="form-control"
                                type="text"
                                name="name"
                                aria-describedby="nameHelp"
                                placeholder="Nama templat"
                                value={this.state.name}
                                onChange={(e) => { this.setState({ name: e.target.value }) }}
                                disabled={this.saveLoading}
                              />
                              <small id="nameHelp" className="form-text text-muted">Harap diisi</small>
                            </div>
                            <FileUploader single={true} files={this.state.files} onChange={this.attachmentHandler} />
                            <hr />
                            {this.state.saveLoading && <Spinner inline={true} />}
                            {!this.state.saveLoading &&
                              <div style={{ float: 'right' }}>
                                <button className="btn btn-primary" onClick={this.save} disabled={!(this.state.files && this.state.files.length > 0 && this.state.files[0].state === 'done' && this.state.name && this.state.name.length > 0)}>Simpan templat naskah</button>&nbsp;
                          <button className="btn btn-default" onClick={this.reset}>Batal dan bersihkan</button>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <div className="box box-success">
                      <div className="box-body" style={{ padding: 0 }}>
                        <div className="row" style={{ padding: 15 }}>
                          <div className="col-sm-12 col-md-6 col-lg-8">
                            <div className="input-group input-group-sm">
                              <span className="input-group-btn">
                                <span className="btn btn-success">
                                  <Fa.FaRefresh />
                                </span>
                              </span>
                              <input className="form-control" type="text" onChange={this.handleSearchString} value={this.state.searchString} />
                              {this.state.searchString && this.state.searchString.length > 0 &&
                                <span className="input-group-btn">
                                  <span className="btn btn-default" onClick={this.dismissSearch}>
                                    <Fa.FaClose />
                                  </span>
                                </span>
                              }
                              <span className="input-group-btn">
                                <span className="btn btn-default" onClick={this.search}>
                                  <Fa.FaSearch />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <div style={{ float: 'right' }}>
                              <Pagination
                                disabled={this.state.loading}
                                page={this.state.page}
                                limit={this.state.limit}
                                total={this.state.total}
                                prev={this.prev} next={this.next}
                                toPage={this.page} />
                            </div>
                          </div>
                          <hr />
                        </div>
                        {this.state.loading ? <Spinner /> :
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Nama templat</th>
                                <th>Nama berkas</th>
                                <th>Waktu</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data && Object.keys(this.state.data).map((item, index) => {
                                return <tr key={index}>
                                  <td>{this.state.data[item].name}</td>
                                  <td>{this.state.data[item].filename}</td>
                                  <td>{this.state.data[item].timestamp}</td>
                                  <td>
                                    <button
                                      onClick={() => { this.downloadAttachment(Config.apiHost + "/api/v1/blob/" + this.state.data[item].blobId + "?download=true", this.state.data[item].filename); }}
                                      className="btn btn-default" target='_blank' download={this.state.data[item].filename}>
                                      <Fa.FaDownload />
                                    </button>
                                    <span
                                      onClick={() => { this.delete(this.state.data[item].id) }}
                                      className="btn btn-default">
                                      <Fa.FaTrashO />
                                    </span>
                                  </td>
                                </tr>
                              })}
                            </tbody>
                          </table>
                        }
                        {!(this.state.total > 0) && !this.state.loading &&
                          <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                            <span style={{ fontSize: 32 }}>
                              <Fa.FaInbox />
                            </span>
                            <br />
                            <h4>Belum ada templat naskah yang terunggah</h4>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
