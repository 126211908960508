import React, { Component } from 'react';
import Manual from './Manual.js';

export default class InboxManual extends Component {
  render() {
    return (
      <Manual/>
    );
  }
}
