import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import validateUuid from 'uuid-validate';
import uuidGenerator from 'uuid/v4';
import './styles.css';
import DateTimePicker from '../components/DateTimePicker';
import Letter from '../../../services/Letter';
import Spinner from '../../../components/Spinner';
import AddressBookModal from './AddressBookModal';
import AddressBookPersonal from './AddressBookPersonalModal';
import Checkbox from 'rc-checkbox';
import async from 'async';
import SwAlert from 'sweetalert-react';
import swal from 'sweetalert';
import '../sweetalert.css';
import stripTags from '../../../utils'
import config from '../../../config';
import Cache from '../../../services/Cache'
const LetterService = new Letter();


const CacheService = new Cache();

export default class LetterReadCreateDisposition extends Component {
  state = {
    loading: false,
    actionEnums: [],
    recipients: {
      str: '',
      raw: []
    },
    PIC: null,
    PICName: null,
    data: [],
    deadline: null
  }

  componentDidMount = () => {
    this.setState({ loading: true}, () => {
      CacheService.getCacheDataFromStorage('disposition-action')
      .then((result) => {
        for ( let i in result.data) {
          this.state.actionEnums.push(result.data[i].codeName)
        }
        this.setState({ data: result.data, loading: false })
      })
    })
  }

  inputEventHandler = (event) => {
    let val = (event.target.getContent && event.target.getContent()) || event.target.value;
    let obj = {};
    if (event.target.raw) {
      obj[event.target.name] = {}
      obj[event.target.name].str = val;
      obj[event.target.name].raw = event.target.raw;
    } else {
      obj[event.target.name] = val;
    }
    this.setState(obj, () => {
      if (event &&
        event.target &&
        event.target.name &&
        event.target.name === 'recipients') {
        this.preparePICCandidates();
      }
    });
  }
  checkboxHandler = (e) => {
    console.log(e);
    let obj = {};
    obj[e.target.name] = e.target.checked;
    this.setState(obj);
    console.log(this.state)
  }
  sendConfirmation = () => {
    var actions = [];
    for (let i in this.state.actionEnums) {
      if (this.state[this.state.actionEnums[i]]) {
        actions.push(this.state.actionEnums[i]);
      }
    }
    if (actions.length < 1) {
      this.setState({ action: '', loading: false });
      swal({
        title: '',
        type: 'warning',
        text: 'Nota tindakan belum ditentukan.',
      });
      return;
    }
    if (this.state.recipients.raw.length < 1) {
      swal({
        title: '',
        type: 'warning',
        text: 'Mohon pilih minimal satu penerima disposisi',
      });
      return;
    }
    if (!(this.state.PIC && validateUuid(this.state.PIC))) {
      swal({
        title: '',
        type: 'warning',
        text: 'Mohon pilih salah satu penerima disposisi sebagai PIC',
      });
      return;
    }
    this.setState({ sendConfirmationPopup: true });
  }
  preparePICCandidates = () => {
    let PICCandidates = [];
    for (let i in this.state.recipients.raw) {
      PICCandidates.push({
        name: this.state.recipients.str.split('\n')[i],
        id: this.state.recipients.raw[i].recipientUuid,
        organization: {},
        level: 0,
        visibility: true,
        collapse: false,
      })
    }
    this.setState({ PICCandidates: PICCandidates, PIC: null, PICName: null });
  }
  handleSelectedPIC = (e) => {
    if (e && e.target && e.target.value && e.target.raw && e.target.raw.PIC) {
      this.setState({ PIC: e.target.raw.PIC, PICName: e.target.value });
    }
  }
  handleDeadlineInput = e => {
    const {target} = e
    this.setState({deadline: target.value})
  }
  send = () => {
    this.setState({ sendConfirmationPopup: false });
    // Generate group ID
    const groupId = uuidGenerator();
    this.setState({ action: 'Mengirim...', loading: true }, () => {
      var actions = [];
      for (let i in this.state.actionEnums) {
        if (this.state[this.state.actionEnums[i]]) {
          actions.push(this.state.actionEnums[i]);
        }
      }

      // Make sure the PIC is the first in order
      let recipients = this.state.recipients.raw.slice(0);
      let PICRecipient = {}
      for (let i in recipients) {
        if (recipients[i].recipientUuid === this.state.PIC) {
          PICRecipient = recipients[i];
          recipients.splice(i, 1);
          break;
        }
      }
      recipients.unshift(PICRecipient);
      async.eachSeries(recipients, (recipient, cb) => {
        var payload = {
          letterId: this.props.currentLetter.id,
          recipients: [recipient.recipientUuid],
          actions: actions,
          time: (new Date()).toISOString(),
          notes: this.state.notes,
          asPelaksana: this.props.asPelaksanaRecipient,
          parent: this.props.dispositionRecipientOrigin,
          groupId: groupId,
          PIC: this.state.PIC,
          throughAssistant: config.dispositionThroughAssistant,
          deadline: this.state.deadline
        }
        console.log(payload);
        LetterService.createDisposition(payload)
          .then(() => {
            cb();
          })
          .catch((err) => {
            cb(err);
          });
      }, (err) => {
        if (err) {
          console.log(err);
          this.setState({ action: null, loading: false, sendConfirmationPopup: false });
        }
        swal({
          title: '',
          text: 'Disposisi berhasil terkirim.',
        });
        this.props.back();
      });
    });
  }
  render() { 
    const {
      subject,
      number,
    } = this.props.currentLetter;
    return (this.state.loading ? <Spinner /> :
      <div>
        <SwAlert
          show={this.state.sendConfirmationPopup}
          title="Kirim"
          text={'Apakah Anda yakin ingin mengirim disposisi ini?'}
          showCancelButton
          onConfirm={this.send}
          onCancel={() => { this.setState({ sendConfirmationPopup: false }) }}
        />
        <div>
          <div style={{ display: 'inline-block', verticalAlign: 'top', margin: 10, marginLeft: 0 }}>
            <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={this.props.back} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'inline-block' }}>
            <h4>Buat Disposisi</h4>
            <span>{stripTags(subject)}</span>
            {number && <span><br />{number}</span>}
          </div>
          {this.props.asPelaksanaRecipient &&
            <div style={{ display: 'inline-block', float: 'right' }}>
              <Fa.FaUser size={24} color={'#3c8dbc'} /> Sebagai Pelaksana
              {this.props.currentLetter.currentUserPelaksanaType && this.props.currentLetter.currentUserPelaksanaType === 'HARIAN' && ' Harian '}
              {this.props.currentLetter.currentUserPelaksanaType && this.props.currentLetter.currentUserPelaksanaType === 'TUGAS' && ' Tugas '}
            </div>
          }
        </div>
        <div className="row" style={{ marginTop: 15 }}>
          <div className="col-md-12">
            <div className="box">
              <div className="box-body">
                <form className="form-horizontal" style={{ paddingTop: 10 }}>
                  <AddressBookModal
                    name="recipients"
                    value={this.state.recipients.str}
                    raw={this.state.recipients.raw}
                    label="Penerima disposisi"
                    placeholder="Klik di sini untuk membuka Buku Alamat"
                    onChange={this.inputEventHandler} 
                    row={4}
                    required={true}
                    single={false}
                    plt={(this.props.currentLetter.isCurrentUserAPelaksana && this.props.currentUser && this.props.currentUser.pltId) && this.props.currentUser.pltId}
                    delegated={(this.props.currentLetter.isCurrentUserAPelaksana && this.props.currentUser && this.props.currentUser.delegatedId) && this.props.currentUser.delegatedId}
                  />
                  <div className="form-group row" style={{ marginTop: 10 }}>
                    <label
                      className={`col-md-1 col-sm-2 control-label disposition`}
                    >
                      PIC
                      &nbsp;<span className="red">*</span>
                    </label>
                    
                    <div
                      className={`col-md-'10' col-sm-'10'`}
                      style={{ paddingLeft: 15}}
                    >
                      <AddressBookPersonal
                        style={{ float: 'right' }}
                        single={true}
                        candidates={this.state.PICCandidates}
                        name={'PIC'}
                        onChange={this.handleSelectedPIC}
                        selected={this.state.PIC}
                      />
                      <span>&nbsp;&nbsp;{this.state.PICName}</span>
                    </div>
                  </div>
                  <div className="form-group row" style={{ marginTop: 10 }}>
                    <label
                      className={`col-md-1 col-sm-2 control-label disposition`}
                    >
                      Tenggat waktu / prioritas
                      &nbsp;<span className="red">*</span>
                    </label>
                    
                    <div
                      className={`col-md-'10' col-sm-'10'`}
                      style={{ padding: 15 }}
                    >
                      <DateTimePicker
                        name="deadline"
                        value={this.state.deadline}
                        onChange={this.handleDeadlineInput}
                        required={false}
                        labelWidth="0"
                        fieldWidth="12"
                      />
                    </div>
                  </div>
                  
                  <div className="form-group row" style={{ marginTop: 10 }}>
                    <label
                      className={`col-md-1 col-sm-2 control-label disposition `}
                    >
                      Nota tindakan
                      &nbsp;<span className="red">*</span>
                    </label>
                    <div
                      className={`col-md-'10' col-sm-'10'`}
                      style={{ padding: 15 }}
                    >
                      { this.state.data.map((item, index) => {
                        return <div key={index} >
                          <label>
                            <Checkbox onChange={this.checkboxHandler} name={item.codeName} />
                            <span style={{ position: 'absolute' }} >&nbsp; {item.name}</span>
                          </label>
                          <br/>
                        </div>
                      })
                      }
                    </div>
                  </div>
                  <div className="form-group row" style={{ marginTop: 10 }}>
                    <label
                      className={`col-md-1 col-sm-2 control-label disposition`}
                    >
                      Catatan
                    </label>
                    <div
                      className={`col-md-10 col-sm-10`}
                      style={{ padding: 15 }}
                    >
                      <textarea className="form-control" name="notes" style={{ width: '100%' }} onChange={this.inputEventHandler} />
                    </div>
                  </div>
                  <div className="form-group row" style={{ marginTop: 10 }}>
                    <div
                      className={`col-md-1 col-sm-2 control-label`}
                    >
                    </div>
                    <div
                      className={`col-md-10 col-sm-10`}
                      style={{ padding: 15 }}
                    >
                      <button className="btn btn-primary btn-sm"
                        type="button"
                        onClick={this.sendConfirmation}
                        disabled={!(this.state.recipients && this.state.recipients.raw && this.state.recipients.raw.length > 0 && this.state.deadline !== null)}
                      >
                        <Fa.FaPaperPlaneO /> &nbsp;
                        Kirim disposisi
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
