import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../../components/Spinner';
import User from '../../../../services/User';
import Pagination from '../../../../components/Pagination';
import {
  getTimeFromTimeStamp,
  getDateFromTimeStamp,
  renderReports,
  renderStatus
} from './exts';
import Filter from "../Filter";
import './style.css';
const UserService = new User();

class Presence extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    loading: false,
    letterDateStart: null,
    letterDateEnd: null,
    presenceRecap: {
      today: {},
      current: {}
    }
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    const currentUser = JSON.parse(localStorage.getItem('current_user'));
    this.setState({ loading: true, currentUser }, () => {
      this.reload({
        page: this.state.page,
        limit: this.state.limit,
        currentUser: currentUser.id
      });
    });
  }

  reload = (opt) => {
    if (this.state.letterDateStart && this.state.letterDateEnd) {
      opt.startDate = this.state.letterDateStart.toISOString();
      opt.endDate = this.state.letterDateEnd.toISOString();
    }

    UserService.getMonitorPresence(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.presences || [],
          loading: false
        }, () => { this.getPresenceStatus(this.state.data); });
      })
      .catch(() => {
        this.setState({ data: [], loading: false, });
      });
  }

  getPresenceStatus = (presences) => {
    this.setState({
      presenceRecap: {
        today: presences[0],
        current: presences[1]
      }
    })
  }

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true }, () => {
      this.reload({
        page: page,
        limit: this.state.limit,
        currentUser: this.state.currentUser.id
      });
    });
  }

  next = () => {
    this.setState({ loading: true }, () => {
      this.reload({
        page: this.state.page + 1,
        limit: this.state.limit,
        currentUser: this.state.currentUser.id
      });
    });
  }

  prev = () => {
    this.setState({ loading: true }, () => {
      this.reload({
        page: this.state.page - 1,
        limit: this.state.limit,
        currentUser: this.state.currentUser.id
      });
    });
  }

  handleStatus = (now) => {
    const { page, letterDateStart, letterDateEnd } = this.state;
    if (page !== 1 || (letterDateStart && letterDateEnd)) {
      return renderStatus(now, '-', '-');
    }

    return renderStatus(
      now,
      this.state.presenceRecap.today,
      this.state.presenceRecap.current
    );
  };

  filterHandler = (filter) => {
    this.setState({ ...filter, loading: true }, () => {
      this.reload({
        page: this.state.page,
        limit: this.state.limit,
        total: this.state.total,
        currentUser: this.state.currentUser.id
      });
    });
  }

  render() {

    return (
      <div className="row">
        <div className="col-md-12"><h4>Monitor Presensi</h4></div>
        <div className="col-md-12"
          style={{
            display: 'flex', padding: 0
          }}>
          <div className="col-md-6">
            <div className="box box-resence">
              <div className="box-header">Status Hari Ini</div>
              <div className="box-body">
                {this.handleStatus(true)}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="box box-resence">
              <div className="box-header">Status Kemarin</div>
              <div className="box-body">
                {this.handleStatus(false)}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <h4>Riwayat</h4>
          <Filter
            startDate={this.state.letterDateStart}
            endDate={this.state.letterDateEnd}
            filterHandler={this.filterHandler}
            exportVisible={false}
          />
          <div className="box box-secondary">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="table-responsive">
                {this.state.loading ? <Spinner /> :
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Tanggal</th>
                        <th>Jam Masuk</th>
                        <th>Jam Pulang</th>
                        <th>Laporan/Memo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(this.state.data).map((item, index) => {
                        return <tr key={index}>
                          <td>{getDateFromTimeStamp(this.state.data[item].loginAt)}</td>
                          <td>{getTimeFromTimeStamp(this.state.data[item].loginAt)}</td>
                          <td>{getTimeFromTimeStamp(this.state.data[item].logoutAt, true)}</td>
                          <td>
                            <ul>
                              {renderReports(this.state.data[item].laporan)}
                            </ul>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                }
              </div>
            </div>
            <div className="box-footer">
              <Pagination
                disabled={this.state.loading}
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prev} next={this.next}
                toPage={this.page} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapper = (state) => {
  return {
    currentUser: state.rootReducers.currentUser
  }
}

export default connect(mapper)(Presence)