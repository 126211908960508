import config from './config';

export default {
  actionMap: {
    REJECT: 'Tolak',
    CHECK_GIVE_OPINION: 'Teliti dan berikan pendapat',
    FINISH_IT: 'Selesaikan',
    EXECUTE: (config.variant.name === 'rskariadi')? 'Laksanakan / selesaikan':'Kerjakan seusai disposisi Direktur',
    FOR_ATTENTION: (config.variant.name === 'rskariadi')? 'Untuk perhatian / arsip': 'Untuk dijadikan perhatian',
    TALK_WITH_ME: 'Bicarakan dengan saya',
    SHARE_IT: 'Edarkan',
    ATTEND_REPORT: (config.variant.name === 'rskariadi')? 'Hadiri & laporan': 'Hadiri dan laporkan',
    PLEASE_DIRECT: 'Mohon arahan',
    FORWARDED: 'Diteruskan',
    STUDY_IT: 'Buat kajian',
    ETC: 'Lain-lain: ...',
  },
  monitoringStatus: {
    UNREAD:'Belum Dibaca',
    READ:'Sudah Dibaca',
    REDISPOSITIONED:'Didisposisikan kembali',
    REPLIED:'Disposisi dibuat surat keluar',
  }
}
