import React, { Component } from 'react';
import swal from 'sweetalert'
import Spinner from '../../../components/Spinner';
import PasswordCondfirmModal from './PasswordConfirmModal';

export default class UserProfileChangeEmail extends Component {
  state = {
    username: this.props.currentUser.username,
    password: '',
    emailAddress: '',
  }
  
  apply = () => {
    if(!this.validateEmail(this.state.emailAddress)){
      swal({
        title: '',
        type: 'warning',
        text: 'Alamat email tidak valid.',
      });
      this.setState({ loading:false })
      return;
    }
    this.setState({ confirmPassword: true });
  }
  onConfirmPassword = (password) => {
    this.setState({ loading: true }, () => {
      let payload = {
        emailAddress: this.state.emailAddress,
        username: this.state.username,
        password,
      }
      this.props.apply(payload, () => {
        this.setState({ loading: false });
      });
    });
  }
  onCanceled = () => { this.setState({ confirmPassword:false }) }
  validateEmail= (emailAddress) => {
    const emailAddressRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailAddressRe.test(emailAddress)
  }

  cancel = () => {
    this.props.back();
  }

  render() {
    return (
      <div className="box box-success">
        <div className="box-body">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="newEmail">Email baru</label>
                <input
                  className="form-control"
                  style={{ width: 300 }}
                  type="email"
                  name="newEmail"
                  aria-describedby="emailHelp"
                  placeholder="Email Baru"
                  value={this.state.emailAddress}
                  onChange={(e) => { this.setState({ emailAddress: e.target.value }) }}
                  disabled={this.loading}
                  autoComplete="off"
                />
                <small id="emailHelp" className="form-text text-muted">Harap diisi</small>
              </div>
              
              {this.state.loading && <Spinner inline={true} />}
              {!this.state.loading &&
                <div>
                  <button className="btn btn-primary" onClick={this.apply}>Ubah</button>&nbsp;
                <button className="btn btn-default" onClick={this.cancel}>Batal</button>
                </div>
              }
            </div>
          </div>
        </div>
        {
          this.state.confirmPassword && 
            <PasswordCondfirmModal
              onConfirm={this.onConfirmPassword}
              onCanceled={this.onCanceled}
            />
        }
      </div>
    );
  }
}
