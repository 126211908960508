import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';

/*

Give it a map and handler : 

let map = {
  tab1 : {
    name : 'Tab One',
    default : true,
  },
  tab2 : {
    name : 'Tab Two',
  },
  tab3 : {
    name : 'Tab Three',
    disabled : true,
  }
}

const handler = (tabName) => {
  console.log(tabName); // Will print 'tab1' when Tab One got clicked;
}

<Tab nav={map} handler={handler}/>

*/

const Icon = (props) => {
  return !props.completed ? <Fa.FaCircleO /> :
    <Fa.FaCheckCircleO color={'green'} />;
}

export default class component extends Component {
  state = {
    activeTab: '',
    className: '',
  }

  componentWillMount = () => {
    // Search for default tab
    let first = true;
    if (this.props.activeTab && this.props.activeTab.length > 0) {
      this.setState({ defaultTab: this.props.activeTab });
    } else {
      for (var key in this.props.nav) {
        if (first) {
          this.setState({ defaultTab: key });
          first = false;
        }
        if (this.props.nav[key].default) {
          this.setState({ defaultTab: key });
          break;
        }
      }
    }
    this.setState({ className: `tab-component-${(new Date()).valueOf()}` });
  }

  componentDidMount = () => {
    this.setState({ activeTab: this.state.defaultTab });
  }

  switchTab = (tabName, event) => {
    this.setState({ activeTab: this.state.defaultTab });
    this.props.handler(tabName);
    let elements = document.getElementsByClassName(this.state.className);
    for (var i in elements) {
      if (elements[i].localName === 'a') {
        elements[i].classList.remove('active')
      }
    }
    event.target.classList.toggle('active', true)
  }

  render() {
    return (
      <ul className="nav nav-tabs">
        {Object.keys(this.props.nav).map((item, index) => (
          <li key={index} className="uib-tab nav-item">
            <a
              className={`nav-link a-nohref ${this.state.className} ${this.state.activeTab === item && 'active'}`}
              onClick={(event) => { this.switchTab(item, event); }}
            >
              {this.props.nav[item].icon &&
                <Icon
                  completed={this.props.nav[item].completed}
                  color={this.props.nav[item].iconColor}
                />
              }
              {this.props.nav[item].name}
            </a>
          </li>
        ))}
      </ul>
    );
  }
}
