import swal from 'sweetalert';

class Error {

  handle(err) {
    console.log("error------------------------------", err)
    let msg = `<span style="text-align:center;margin-bottom: 5px;font-family: sans-serif;">Silahkan menghubungi administrator atau tim <a href="${window.location.origin}/helpdesk">OA-Helpdesk</a></span>`
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 401:
          if (window.location.toString().indexOf('login') > -1 || window.location.pathname === '/help' || window.store.getState().router.location.pathname.includes('/helpdesk/panel')) {
            return;
          }
          swal({
            title: '',
            type: 'warning',
            text: 'Sesi Anda telah habis. Silakan masuk kembali dengan nama pengguna dan sandi',
          }, () => {
            window.location = '/login?redirect=' + window.location.pathname.toString();
          });
          break;
        case 403:
          swal({ title: '', text: 'Anda tidak memiliki hak akses terhadap data / operasi ini.', confirmButtonText: 'OK' });
          break;
        case 400:
          swal({ title: '', text: 'Data yang terkirim belum lengkap/benar. Silakan diperiksa kembali.', confirmButtonText: 'OK' });
          break;
        case 404:
          swal({ title: '', text: 'Data tidak ditemukan.', confirmButtonText: 'OK' });
          break;
        case 500:
          swal({ title: '', text: `Terdapat galat pada peladen/server. ${msg}`, confirmButtonText: 'OK', html: true });
          break;
        case 502:
          swal({ title: '', text: `Peladen/server bermasalah. ${msg}`, confirmButtonText: 'OK', html: true });
          break;
        case 503:
          swal({ title: '', text: `Peladen/server bermasalah. ${msg}`, confirmButtonText: 'OK', html: true });
          break;
        case 504:
          swal({ title: '', text: `Peladen/server terlalu lama merespon. ${msg}`, confirmButtonText: 'OK', html: true });
          break;
        default:
          // Do nothing. This should be handled by the requester itself.
      }
    } else {
      swal({ title: '', text: `Peladen/server tidak dapat dihubungi. Cek koneksi Anda atau ${msg}`, confirmButtonText: 'OK', html: true });
    }
  }
}

export default Error;
