import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import Unit from '../../../services/Unit';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';

const UnitService = new Unit();

export default class Units extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    loading: false,
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    if (window.store.getState().rootReducers.currentUser.role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.setState({ loading: true }, () => {
      let opt = { page: this.state.page, limit: this.state.limit, search: this.state.searchString }
      UnitService.getAll(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          })
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: page, limit: this.state.limit, search: this.state.searchString }
    UnitService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: this.state.page + 1, limit: this.state.limit, search: this.state.searchString }
    UnitService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: this.state.page - 1, limit: this.state.limit, search: this.state.searchString }
    UnitService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  newUnit = () => {
    window.browserHistory.push('/settings/unit/new');
  }

  read = (id) => {
    console.log(id);
    window.browserHistory.push('/settings/unit/' + id);
  }

  reload = () => {
    this.page()
  }

  search = () => {
    this.reload()
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div style={{ float: 'right' }}>
            <span className="btn btn-default" onClick={this.newUnit}>
              <Fa.FaPlus /> Tambah unit
            </span>
          </div>
          <h4>Unit</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <div className="row" style={{ padding: 15 }}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div style={{ float: 'right' }}>
                    <Pagination
                      disabled={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prev} next={this.next}
                      toPage={this.page} />
                  </div>
                </div>
                <hr />
              </div>
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Nama unit</th>
                      <th>Tanggal ditambahkan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(this.state.data).map((item, index) => {
                      return <tr key={index} onClick={() => { this.read(this.state.data[item].id) }} style={{ cursor: 'pointer' }}>
                        <td>{this.state.data[item].name}</td>
                        <td>
                          {date.format(this.state.data[item].createdAt, 'DD MMMM YYYY', { locale: dateLocaleId })}
												</td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <br />
                  <h4>Tidak ada data</h4>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
