import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import downloadjs from 'downloadjs';
import './styles.css';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import Auth from '../../../services/Auth';
import Blob from '../../../services/Blob';
import Config from "../../../config";
import stripTags from '../../../utils'

const AuthService = new Auth();
const BlobService = new Blob();

export default class LetterReadDetail extends Component {
  state = {
    letterHistoryLoading: false,
    letterHistories: {},
    letterComments: {},
    combinedHistories: [],
    recipients: [],
    forAttention: {},
  }
  componentDidMount = () => {
    // Sort it so we can put parent above their childs.
    let recipients = [];
    let forAttention = {};
    for (let i in this.props.currentLetter.recipients) {
      if (this.props.currentLetter.recipients[i].recipientType && this.props.currentLetter.recipients[i].recipientType.indexOf('_FOR_ATTENTION') > -1) {
        forAttention = this.props.currentLetter.recipients[i]
        continue;
      }
      if (this.props.currentLetter.recipients[i].recipientUuid &&
        (!this.props.currentLetter.recipients[i].parent ||
          (this.props.currentLetter.recipients[i].parent &&
            this.props.currentLetter.recipients[i].parent.length === 0)
        )) {
        recipients.push(this.props.currentLetter.recipients[i])
        if (this.props.currentLetter.recipients[i].isGroup &&
          this.props.currentLetter.recipients[i].recipientUuid) {
          for (let j in this.props.currentLetter.recipients) {
            if (this.props.currentLetter.recipients[j].parent === this.props.currentLetter.recipients[i].recipientUuid &&
              this.props.currentLetter.recipients[j].recipientUuid) {
              recipients.push(this.props.currentLetter.recipients[j])
            }
          }
        }
      }
    }
    this.setState({ recipients: recipients, forAttention: forAttention })
  }
  downloadAttachment = (url, filename) => {
    BlobService.downloadToDataURI(url)
      .then((res) => {
        downloadjs(res.uri, filename, res.type);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const {
      subject,
      deadline,
      manualLetterSender,
      number,
      importance,
      letterType,
      classification,
      signers,
      via,
      approvers,
      contentAbstract,
      references,
      notes,
      letterDate,
      receivedDate,
      customAttachment,
      attachments,
      incomingNumber,
      pelaksana
    } = this.props.currentLetter;

    const isFinalOrSent = this.props.currentLetter.letterState === 'SENT' || this.props.currentLetter.letterState === 'FINAL'
    return (
      <div>
        <div>
          <div style={{ display: 'inline-block', verticalAlign: 'top', margin: 10, marginLeft: 0 }}>
            <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={this.props.back} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'inline-block' }}>
            <h4>Detail Agenda Surat {this.props.isRecipient ? 'Masuk' : 'Keluar'}</h4>
            <span>{stripTags(subject)}</span>
          </div>
        </div>
        <div className="row" style={{ marginTop: 15 }}>
          <div className="col-md-12">
            <div className="box">
              <div className="box-body">
                <table className="table table-bordered table-read-detail">
                  <thead>
                    <tr className="active">
                      <th colSpan="2">Informasi Surat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomingNumber && (
                      <tr>
                        <td width="20%">Nomor Agenda</td>
                        <td width="80%">
                          <span>{incomingNumber}</span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td width="20%">Nomor Surat</td>
                      <td width="80%">
                        <span>{number}</span>
                      </td>
                    </tr>
                    {letterDate && letterDate.length > 0 && letterDate !== '0001-01-01T00:00:00Z' &&
                      <tr>
                        <td width="20%">Tanggal Surat</td>
                        <td width="80%">{letterDate && (new Date(letterDate)).getFullYear() !== 1 && date.format(letterDate, 'dddd, DD MMMM YYYY', { locale: dateLocaleId })}</td>
                      </tr>
                    }
                    {receivedDate && receivedDate.length > 0 && receivedDate !== '0001-01-01T00:00:00Z' &&
                      <tr>
                        <td width="20%">Tanggal Terima</td>
                        <td width="80%">{receivedDate && (new Date(receivedDate)).getFullYear() !== 1 && date.format(receivedDate, 'dddd, DD MMMM YYYY', { locale: dateLocaleId })}</td>
                      </tr>
                    }
                    {deadline && deadline.length > 0 && deadline !== '0001-01-01T00:00:00Z' &&
                      <tr>
                        <td width="20%">Tenggat Waktu</td>
                        <td width="80%">{deadline && (new Date(deadline)).getFullYear() !== 1 && date.format(deadline, 'dddd, DD MMMM YYYY', { locale: dateLocaleId })}</td>
                      </tr>
                    }
                    <tr>
                      <td width="20%">Jenis Surat</td>
                      <td width="80%">
                        <span>{letterType}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%">Pola Klasifikasi</td>
                      <td width="80%">{classification && classification.split('|').length > 1 && classification.split('|')[1]}</td>
                    </tr>
                    <tr>
                      <td width="20%">Sifat Surat</td>
                      <td width="80%">
                        {importance && importance === 'NORMAL' && 'Biasa'}
                        {importance && importance === 'LIMITED' && 'Terbatas'}
                        {importance && importance === 'SECRET' && 'Rahasia'}
                        {importance && importance === 'TOP_SECRET' && 'Sangat Rahasia'}
                        {importance && importance === 'IMPORTANT' && 'Segera'}
                        {importance && importance === 'VERY_IMPORTANT' && 'Sangat Segera'}
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr className="active">
                      <th colSpan="2">Alamat Surat</th>
                    </tr>
                  </thead>
                  <tbody>{manualLetterSender &&
                    <tr>
                      <td width="20%">Pengirim surat manual</td>
                      <td width="80%">
                        {manualLetterSender}
                      </td>
                    </tr>
                  }{!manualLetterSender && (this.props.isSender || AuthService.getRoles().role === 'FINALIZER' || this.props.isProcessChainHolder) &&
                    <tr>
                      <td width="20%">Pemeriksa</td>
                      <td width="80%">
                        {via && via.map((item, index) => {
                          return <span key={index}>
                            {item.split('|').length > 1 && item.split('|')[1]},&nbsp;
                      </span>
                        })}
                        {approvers && approvers.map((item, index) => {
                          return <span key={index}>
                            {item.split('|').length > 1 && item.split('|')[1]},&nbsp;
                      </span>
                        })}
                      </td>
                    </tr>
                    }
                    {manualLetterSender &&
                      <tr>
                        <td width="20%">Penanda tangan</td>
                        <td width="80%">
                          {manualLetterSender}
                        </td>
                      </tr>
                    }
                    {(!manualLetterSender || (manualLetterSender && manualLetterSender.length < 1)) &&
                      <tr>
                        <td width="20%">Penanda tangan</td>
                        <td width="80%">
                          {signers && signers.map((item, index) => {
                            return <span key={index}>
                              {pelaksana && pelaksana === 'HARIAN' && 'Plh.'}
                              {pelaksana && pelaksana === 'TUGAS' && 'Plt.'}
                              {item.split('|').length > 1 && item.split('|')[1]},&nbsp;
                      </span>
                          })}
                        </td>
                      </tr>
                    }
                    <tr>
                      <td width="20%">Penerima</td>
                      <td width="80%">
                        {this.state.recipients && this.state.recipients.map((item, index) => {
                          if (item.recipientType.indexOf('RECIPIENT') > -1 || item.recipientType.indexOf('OA') > -1) {
                            return <div key={item.id}>
                              {item.parent && item.parent.length > 0 && <span style={{ marginLeft: 5 }}> - </span>}
                              {item.recipientName}
                              {item.recipientType === 'RECIPIENT' && this.state.forAttention.recipientType === 'RECIPIENT_FOR_ATTENTION' && <span><br/>u.p. {this.state.forAttention.recipientName}</span>}
                              {item.recipientType === 'EXTERN_RECIPIENT' && this.state.forAttention.recipientType === 'EXTERN_RECIPIENT_FOR_ATTENTION' && <span><br/>u.p. {this.state.forAttention.recipientName}</span>}
                              {item.isGroup && <span> : </span>}
                            </div>
                          } else {
                            return '';
                          }
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td width="20%">Melalui</td>
                      <td width="80%">
                        {via && via.map((item, index) => {
                          return <div key={index}>{item.split('|')[1]},&nbsp;</div>
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td width="20%">Tembusan</td>
                      <td width="80%">
                        {this.state.recipients && this.state.recipients.map((item, index) => {
                          if (item.recipientType.indexOf('CC') > -1 && item.recipientType !== "BCC_SECRET") {
                            return <div key={item.id}>
                              {item.parent && item.parent.length > 0 && <span style={{ marginLeft: 5 }}> - </span>}
                              {item.recipientName}
                              {item.isGroup && <span> : </span>}
                            </div>
                          } else {
                            return '';
                          }
                        })}
                      </td>
                    </tr>
                    {(this.props.isSender || this.props.isBccSecret) && 
                    <tr>
                      <td width="20%">Tembusan Rahasia</td>
                      <td width="80%">
                        {this.state.recipients && this.state.recipients.map((item, index) => {
                          if (item.recipientType === "BCC_SECRET") {
                            return <div key={item.id}>
                              {item.parent && item.parent.length > 0 && <span style={{ marginLeft: 5 }}> - </span>}
                              {item.recipientName}
                              {item.isGroup && <span> : </span>}
                            </div>
                          } else {
                            return '';
                          }
                        })}
                      </td>
                    </tr>}
                  </tbody>
                  <thead>
                    <tr className="active">
                      <th colSpan="2">Perihal Surat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="20%">Perihal</td>
                      <td width="80%">{stripTags(subject)}</td>
                    </tr>
                    <tr>
                      <td width="20%">Penjelasan Isi Surat</td>
                      <td width="80%"><div className="kop-contents" dangerouslySetInnerHTML={{ __html: contentAbstract }}></div></td>
                    </tr>
                    <tr>
                      <td width="20%">Lampiran</td>
                      <td width="80%">
                        {(customAttachment && customAttachment.length > 0) ? customAttachment :
                          (attachments == null ? "-" : (attachments && attachments.length === 0) ? "" : attachments.length + ' berkas')}
                      </td>
                    </tr>
                    <tr>
                      <td width="20%">Dokumen</td>
                      <td width="80%"><button className="btn btn-primary" onClick={this.props.back}><Fa.FaFile /> Lihat surat</button></td>
                    </tr>
                    <tr>
                      <td width="20%">Referensi</td>
                      <td width="80%">
                        {references && references.map((item, index) => {
                          if (item.split('|').length === 3) {
                            return <span key={index}>
                              {item.split('|').length > 1 && <span style={{ cursor: 'pointer', textDecoration: 'none', color: '#72afd2' }}
                                onClick={() => { this.downloadAttachment(Config.apiHost + "/api/v1/blob/" + item.split('|')[2] + "?download=true", item.split('|')[1]); }}
                              >{item.split('|')[1]}</span>
                              },&nbsp;
                        	</span>
                          } else {
                            return <span key={index}>
                              {item.split('|').length > 1 && <a target="_blank" href={'/read/' + item.split('|')[0]}>{item.split('|')[1]}</a>},&nbsp;
                        	</span>
                          }
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td width="20%">Catatan</td>
                      <td width="80%">{notes}</td>
                    </tr>
                    {!this.props.isRecipient && isFinalOrSent &&
                      <tr>
                        <td width="20%">Riwayat Surat</td>
                        <td width="80%">
                          {this.props.combinedHistories && this.props.combinedHistories.map((item, index) => {
                            return <div style={{ marginLeft: 10 }} key={index}><div className="alert alert-default" style={{ padding: 4, marginBottom: 5 }}>
                              {date.format(item.timestamp, 'ddd, DD MMMM YYYY - HH:mm:ss', { locale: dateLocaleId })} -&nbsp;
                              {item.originatorName.split('|')[0]}
                              {item.originatorName.split('|')[1] && <span>, {item.originatorName.split('|')[1]}</span>}
                              &nbsp;:&nbsp;
                          {item.state && item.state === 'CREATE' && 'Membuat surat'}
                              {item.state && item.state === 'MODIFIED' && 'Menyunting'}
                              {item.state && item.state === 'REJECT' && <span>Mengembalikan <Fa.FaChevronLeft color={'red'} /></span>}
                              {item.state && item.state === 'DONE' && <span>Menyetujui <Fa.FaCheck color={'green'} /></span>}
                              {item.state && item.state === 'CANCELED' && <span>Membatalkan <Fa.FaClose color={'red'} /></span>}
                              {item.state && item.state.indexOf('FINALIZE') > -1 && <span>Finalisasi dan dikirim <Fa.FaCheck color={'green'} /></span>}
                              {item.state && item.state === 'PULLBACK' && <span>Menarik surat <Fa.FaChevronLeft color={'orange'} /></span>}
                              {item.notes && <span>Mengomentari  </span>}
                              {item.notes && item.notes.includes("\n") ?<br></br>: <span>&nbsp;</span>}
                              {item.notes && <span className="alert alert-comment" style={{whiteSpace:'pre-line'}}>{item.notes}</span>}
                            </div></div>
                          })}
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
