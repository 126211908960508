import React, { Component } from 'react';
import Checkbox from 'rc-checkbox';
import * as Fa from 'react-icons/lib/fa';

export default class OriginatorModal extends Component {
  state = {
    delegation: false,
    definitive: false
  }
  
  checkboxHandler = (e) => {
    let obj = {};
    obj[e.target.name] = e.target.checked;
    this.setState(obj);
  }

  ok = () => {
    let e = {
      delegation: this.state.delegation,
      modalMode: this.props.modalMode
    }
    this.props.modalConfirm(e);
    this.props.toogleModal();
  }

  render() {
    return (
      <div className="modal modal-open" style={{ display: this.props.showModal ? 'block' : 'none'}}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                Buat Surat
              </h4>
              <span onClick={() => {this.props.toogleModal()}} className="close"><Fa.FaClose /></span>
              <hr/>
            </div>
            <div className="modal-body">
              <div style={{ textAlign: 'left', marginLeft: 15 }}>
                <div className="form-group row">
                  <Checkbox 
                    onChange={this.checkboxHandler} 
                    name="definitive" 
                    style={{ marginTop: 4 }}
                    disabled={this.state.delegation} 
                  />
                  <label style={{ fontSize: 14, fontWeight: 'normal' }}>&nbsp;Buat surat sebagai pejabat definitif</label>
                </div>
                <div className="form-group row">
                  <Checkbox 
                    disabled={this.state.definitive} 
                    onChange={this.checkboxHandler} 
                    name="delegation" 
                    style={{ marginTop: 4 }} 
                  />
                  <label style={{ fontSize: 14, fontWeight: 'normal' }}>&nbsp;Buat surat sebagai plt/plh</label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <span className="btn btn-primary" style={{ marginRight: 10 }} onClick={this.ok}>OK</span>
              <span className="btn btn-default" onClick={() => {this.props.toogleModal()}}>Cancel</span>
            </div>
          </div>
        </div>                
      </div>
    )
  }
}
