import axios from "./Axios";
import Config from "../config";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Unit {
  getMonitorLog = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/units/monitor?page=${opt.page}&limit=${opt.limit}`;
      if (opt.search && opt.search.length > 0) {
        url += `&search=${opt.search}`
      }
      axios.get(url,
        {
          headers: MiscService.generateHeaders()
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }
  
  getAll = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/units?page=${opt.page}&limit=${opt.limit}`;
      if (opt.search && opt.search.length > 0) {
        url += `&search=${opt.search}`
      }
      axios.get(url,
        {
          headers: MiscService.generateHeaders()
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  get = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/unit/" + id,
      {
        headers: MiscService.generateHeaders()
      }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  findUnit = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/find-unit-by-entity/" + id,
      {
        headers: MiscService.generateHeaders()
      }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }


  create = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/units",
        payload,
        {headers: MiscService.generateHeaders()}
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload.phoneNumber) payload.phoneNumber = ' ';
      if (!payload.emailAddress) payload.emailAddress = ' ';
      axios.put(Config.apiHost + "/api/v1/unit/" + id,
      payload,
      {headers: MiscService.generateHeaders()})
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  delete = (id) => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/unit/" + id, {headers: MiscService.generateHeaders()})
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        //ErrorService.handle(err);
        reject(err);
      })
    });
  }
}

export default Unit;
