import React, { Component } from 'react';

export default class Pagination extends Component {
  state = {
    pagination: [],
  }

  componentWillReceiveProps = (props) => {
    let lastPage = 1;
    let c = 0;
    if (props.total % props.limit === 0) c = 1;
    if ((props.total / props.limit) - ((props.total - ((props.total+c)% props.limit)) / props.limit) > 0) {
      lastPage = (props.total - (props.total % props.limit)) / props.limit + 1 - c;
    }
    let pagination = [];
    for (var i = 1; i <= lastPage; i++) {
      if (i === 1 || i === lastPage) {
        pagination.push(i);
      } else {
        if ((props.page < 4 && i < 4) || (props.page > lastPage - 4 && i > lastPage - 4)) {
          pagination.push(i);
        } else {
          if (i === props.page || i === props.page - 1 || i === props.page + 1) {
            pagination.push(i);
          } else if (pagination[pagination.length - 1] !== 'jumpNext' && pagination[pagination.length - 1] !== 'jumpPrev') {
            if (i > props.page) {
              pagination.push('jumpNext');
            } else {
              pagination.push('jumpPrev');
            }
          }
        }
      }
    }
    this.setState({ pagination: pagination });
  }

  render() {
    return (
      <nav aria-label="Page navigation" style={{ margin: 'auto', display: 'table' }}>
        <ul className="pagination">
          <li className={this.props.page > 1 && !this.props.disabled ? 'page-item' : 'page-item disabled'}>
            <a
              className={this.props.page > 1 && !this.props.disabled ? 'page-link' : 'page-link disabled'}
              onClick={this.props.prev}
            >
              Previous
            </a>
          </li>
          {this.state.pagination.map((page, index) => {
            return <li
              className={this.props.page === page ? 'page-item active' :
                !this.props.disabled ? 'page-item' : 'page-item disabled'}
              key={index}
            >
              <a
                className={!this.props.disabled ?
                  'page-link' : 'page-link disabled'
                }
                onClick={() => {
                  if (page === 'jumpPrev') {
                    this.props.toPage(this.props.page - 3);
                  } else if (page === 'jumpNext') {
                    this.props.toPage(this.props.page + 3);
                  } else {
                    this.props.toPage(page);
                  }
                }}
              >
                {page === 'jumpNext' || page === 'jumpPrev' ? '...' : page}
              </a>
            </li>
          })}
          <li
            className={((this.props.page * this.props.limit) < this.props.total) && !this.props.disabled ?
              'page-item' : 'page-item disabled'
            }
          >
            <a
              className={((this.props.page * this.props.limit) < this.props.total) && !this.props.disabled ?
                'page-link' : 'page-link disabled'
              }
              onClick={this.props.next}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
