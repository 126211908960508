import React, { PureComponent } from 'react';
import * as Fa from 'react-icons/lib/fa';
import DateTimePicker from '../components/DateTimePicker';
import Spinner from '../../../components/Spinner';

export default class Filter extends PureComponent {
  state = {
    collapsed: true,
    startDate: null,
    endDate: null,
    showExportOptions: false,
    exportType: '',
  }

  inputEventHandler = (e) => {
    let obj = {};
    obj[e.target.name] = e.target.value;
    this.setState(obj);
  }

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  }

  filter = () => {
    this.props.filterHandler({
      letterDateStart: this.state.startDate,
      letterDateEnd: this.state.endDate
    });
  }

  resetFilter = () => {
    this.setState({
      startDate: null,
      endDate: null,
      exportType: '',
      exportVisible: false
    }, () => {
      this.props.filterHandler({
        letterDateStart: null,
        letterDateEnd: null,
        exportLoading: false,
      });
    })
  }

  showExportOptions = () => {
    this.setState({ showExportOptions: !this.state.showExportOptions })
  }

  exportEvent = (e) => {
    this.setState({ exportType: e.target.name });
  }

  exportPDF = () => {
    this.props.exportProcess(
      'pdf',
      this.state.exportType,
      this.state.startDate,
      this.state.endDate
    );
  }

  exportCSV = () => {
    this.props.exportProcess(
      'csv',
      this.state.exportType,
      this.state.startDate,
      this.state.endDate
    );
  }

  exportExcel = () => {
    this.props.exportProcess(
      'xlsx',
      this.state.exportType,
      this.state.startDate,
      this.state.endDate
    );
  }

  render() {
    const {
      startDate,
      endDate,
      exportVisible,
      exportLoading
    } = this.props;

    return (
      <div className="box box-success">
        <div className="card card-default">
          <div className="card-header"
            style={{ cursor: 'pointer' }}
            onClick={this.toggle} >
            <span style={{ float: 'right', cursor: 'pointer' }}>
              {this.state.collapsed ? <Fa.FaChevronDown /> : <Fa.FaChevronUp />}
            </span>
            Filter Tanggal
          </div>
          <div className="card-body" style={{ display: this.state.collapsed ? 'none' : 'block' }}>
            <div style={{ float: "left", width: '50%' }}>
              <DateTimePicker
                label="Dari Tanggal"
                name="startDate"
                value={startDate || this.state.startDate}
                onChange={this.inputEventHandler}
                labelWidth={3}
                fieldWidth={9}
              />
            </div>
            <div style={{ float: "right", width: '50%' }}>
              <DateTimePicker
                label="Sampai Tanggal"
                name="endDate"
                value={endDate || this.state.endDate}
                onChange={this.inputEventHandler}
                labelWidth={3}
                fieldWidth={9}
              />
            </div>
            <button className="btn btn-small btn-success" onClick={this.filter}>Filter</button> &nbsp;
            <button className="btn btn-small btn-default" onClick={this.resetFilter}>Reset Filter</button> &nbsp;
            {exportVisible && <button className="btn btn-small btn-default" onClick={this.showExportOptions}>Ekspor</button>} &nbsp;
            {exportLoading && <Spinner inline={true} />}
            <br />
            {this.state.showExportOptions &&
              <div style={{ marginTop: 10 }}>
                <input
                  disabled={!this.state.startDate && !this.state.endDate}
                  type="checkbox"
                  name="exportRange"
                  onClick={this.exportEvent}
                  checked={this.state.exportType === "exportRange" ? 'checked' : ''} /> Ekspor Dengan Rentang Waktu
                <br />
                <input
                  disabled={this.state.startDate && this.state.endDate}
                  type="checkbox"
                  name="exportAll"
                  onClick={this.exportEvent}
                  checked={this.state.exportType === "exportAll" ? 'checked' : ''} /> Ekspor Semua
                <br /><br />

                <button
                  disabled={!this.state.exportType || exportLoading}
                  className="btn btn-small btn-default"
                  onClick={this.exportPDF}>Ekspor PDF</button> &nbsp;

                <button
                  disabled={!this.state.exportType || exportLoading}
                  className="btn btn-small btn-default"
                  onClick={this.exportCSV}>Ekspor CSV</button> &nbsp;

                <button
                  disabled={!this.state.exportType || exportLoading}
                  className="btn btn-small btn-default"
                  onClick={this.exportExcel}>Ekspor Excel</button> &nbsp;
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
