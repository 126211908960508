import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';
import config from './config';

class GoogleAnalytics extends Component {
  componentDidMount () {
    this.logPageChange(this.props.location.pathname, this.props.location.search);
  }

  componentDidUpdate ({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props;
    if (pathname !== prevLocation.pathname || search !== prevLocation.search) {
      this.logPageChange(pathname, search)
    }
  }

  logPageChange (pathname, search = '') {
    const page = pathname + search;
    ReactGA.set({
      page, location: 
      `${window.location.origin}${page}`,
      ...this.props.options
    });
    ReactGA.pageview(page);
  }

  render () {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string, 
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (options = {}) => {
  const enableGA = config.production;
  if (enableGA) ReactGA.initialize(config.trackingCode, {
    // Uncoment code below for debug only
    // debug: true,
    gaOptions: {
      userId: options? options.userId: null,
    }
  });
  return enableGA;
};

export default { GoogleAnalytics, RouteTracker, init };