import React, { Component } from 'react';
import {Bar,HorizontalBar} from 'react-chartjs-2';
import * as Fa from 'react-icons/lib/fa';
import Letter from '../../../services/Letter';

const LetterService = new Letter();

export default class DasboardLeader extends Component {
    state = {
        sortLetter: "Daily",
        sortLetterType: "Daily",
        userFilter: "A",

        dispositionOut: 0,
        dispositionInProcess: 0,
        dispositionClosed: 0,
        letterWillBeApproved: 0,

        barDataLetterDay: {},
        barDataLetterWeek: {},
        barDataLetterMonth: {},
        barDataLetterYear: {},
        barDataLetterTypeDay: {},
        barDataLetterTypeWeek: {},    
        barDataLetterTypeMonth: {},          
        barDataLetterTypeYear: {},

        horizontalBarData: { 
            datasets:[], 
            labels:[] 
        },

        horizontalBarOptions: {
          responsive: true,
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
              }
            }]
          }
        },

        barOptions: {
            responsive: true,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    }
                }]
            }
        }
    };

    componentDidMount = () => {
      LetterService.getDashboardAdminStats()
      .then((result) => {
        let getGroupedDataSetsObj = items => {
          let groupedDataSetsObj = {
            barDayDatasets:[],
            barWeekDatasets:[],
            barMonthDatasets:[],
            barYearDatasets:[],
          };
          let daysColors = {
            Senin:"#FFC900",
            Selasa:"#00ACE4",
            Rabu:"#E80041",
            Kamis:"#00CE7E",
            Jumat:"#152D43",
          };
          let monthsColors = {
            Januari:"#FFC900",
            Februari:"#00ACE4",
            Maret:"#E80041",
            April:"#00CE7E",
            Mei:"#152D43",
            Juni:"#FF00E5",
            Juli:"#B000FF",
            Agustus:"#1A00FF",
            September:"#FF8100",
            Oktober:"#005A14",
            November:"#403F3A",
            Desember:"#2B5597",
          };
          let weekColors = [
            "#FFC900",
            "#00ACE4",
            "#E80041",
            "#00CE7E",
            "#152D43",
          ];
          
          for (let item in items) {
            groupedDataSetsObj.barDayDatasets.push({
              label: items[item].label,
              backgroundColor: items[item].color,
              data: [items[item].data.daily]
            });
          }
          for (let day in daysColors) {
            let weeklyData = [];
            for (let item in items) {
              weeklyData.push(items[item].data.weekly[day])
            };
            groupedDataSetsObj.barWeekDatasets.push({
              label: day,
              backgroundColor: daysColors[day],
              data: weeklyData,
            });
          };

          for (let index = 0; index < weekColors.length; index++) {
            let week = "Minggu ke-"+(index + 1);
            let monthlyData = [];
            for (let item in items) {
              monthlyData.push(items[item].data.monthly[week]);
            }
            groupedDataSetsObj.barMonthDatasets.push({
              label: week,
              backgroundColor: weekColors[index],
              data: monthlyData,
            });
          }

          for (let month in monthsColors) {
            let yearlyData = [];
            for (let item in items) {
              yearlyData.push(items[item].data.yearly[month]);
            }
            groupedDataSetsObj.barYearDatasets.push({
              label: month,
              backgroundColor: monthsColors[month],
              data: yearlyData,
            });
          }
          return groupedDataSetsObj;
        }
        let labels = ["Dalam Proses", "Dalam Finalisasi", "Dikembalikan", "Terkirim"];
        let letterTypeLabels = [
          "Nota Dinas", 
          "Surat Tugas", 
          "Surat Undangan", 
          "Surat Dinas", 
          "Memo", 
          "Surat Keterangan",
          "Laporan",
          "Lembar Ralat",
          "Pengumuman",
          "Telaahan Staf"
        ];
        let items = {
          letterInProcess: {
            label: labels[0],
            color: "#FFC900",
            data: result.letterInProcess
          },
          letterInFinalization: {
            label: labels[1],
            color: "#00ACE4",
            data: result.letterInFinalization,
          },
          letterReturned: {
            label: labels[2],
            color: "#E80041",
            data: result.letterReturned,
          },
          letterSent: {
            label: labels[3],
            color: "#00CE7E",
            data: result.letterSent,
          },
        };
        let letterTypeItems = {
          tndeNotaDinas:{
            label:letterTypeLabels[0],
            color:"#FFC900",
            data:result.tndeNotaDinas
          },
          tndeSuratTugas:{
            label:letterTypeLabels[1],
            color:"#00ACE4",
            data:result.tndeSuratTugas
          },
          tndeUndangan:{
            label:letterTypeLabels[2],
            color:"#E80041",
            data:result.tndeUndangan
          },
          tndeSurat:{
            label:letterTypeLabels[3],
            color:"#00CE7E",
            data:result.tndeSurat
          },
          tndeMemo:{
            label:letterTypeLabels[4],
            color:"#152D43",
            data:result.tndeMemo
          },
          tndeSuratKeterangan:{
            label: letterTypeLabels[5],
            color: "#1A00FF",
            data: result.tndeSuratKeterangan,
          },
          tndeLaporan:{
            label: letterTypeLabels[6],
            color: "#FF8100",
            data: result.tndeLaporan,
          },
          tndeLembarRalat:{
            label: letterTypeLabels[7],
            color: "#005A14",
            data: result.tndeLembarRalat,
          },
          tndePengumuman:{
            label: letterTypeLabels[8],
            color: "#403F3A",
            data: result.tndePengumuman,
          },
          tndeTelaahanStaf:{
            label: letterTypeLabels[9],
            color: "#2B5597",
            data: result.tndeTelaahanStaf,
          },
        };
        let letterGroupedDataset = getGroupedDataSetsObj(items);
        let letterTypeGroupedDataset = getGroupedDataSetsObj(letterTypeItems);
        
        let obj = {
          dispositionOut: result['DISPOSITION_OUT'],
          dispositionInProcess: result['DISPOSITION_IN_PROCESS'],
          dispositionClosed: result['DISPOSITION_CLOSED'],
          letterWillBeApproved: result['LETTER_WILL_BE_APPROVED'],
          barDataLetterDay: {
            labels: ["Hari Ini"],
            datasets: letterGroupedDataset.barDayDatasets,
          },
          barDataLetterWeek: {
            labels,
            datasets: letterGroupedDataset.barWeekDatasets,
          },
          barDataLetterMonth: {
            labels,
            datasets: letterGroupedDataset.barMonthDatasets,
          },
          barDataLetterYear: {
              labels,
              datasets: letterGroupedDataset.barYearDatasets,
          },
          barDataLetterTypeDay: {
              labels: ["Hari Ini"],
              datasets: letterTypeGroupedDataset.barDayDatasets,
          },
          barDataLetterTypeWeek: {
              labels: letterTypeLabels,
              datasets: letterTypeGroupedDataset.barWeekDatasets,
          },
          barDataLetterTypeMonth: {
              labels: letterTypeLabels,
              datasets: letterTypeGroupedDataset.barMonthDatasets,
          },
          barDataLetterTypeYear: {
              labels: letterTypeLabels,
              datasets: letterTypeGroupedDataset.barYearDatasets
          },
        };
        this.setState(obj);
      })
      .catch(console.log)
    }
    
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-4">
                        <div onClick={() => {window.browserHistory.push('/outbox/disposition')}} className="small-box bg-red pointer">
                            <div className="inner" style={{ textAlign: 'center' }}>
                                <h5>Disposisi Keluar</h5>
                                <h3>{this.state.dispositionOut}</h3>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => {window.browserHistory.push('/disposition/IN_PROCESS')}} className="col-md-4">
                        <div className="small-box bg-yellow pointer">
                            <div className="inner" style={{textAlign: 'center', color: 'black' }}>
                                <h5>Disposisi Dalam Proses</h5>
                                <h3>{this.state.dispositionInProcess}</h3>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => {window.browserHistory.push('/disposition/CLOSED')}} className="col-md-4">
                        <div className="small-box bg-green pointer">
                            <div className="inner" style={{ textAlign: 'center'   }}>
                                <h5>Disposisi Selesai</h5>
                                <h3>{this.state.dispositionClosed}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div onClick={() => {window.browserHistory.push('/processing/need-approval?tab=WILL_NEED_APPROVAL')}} className="small-box bg-aqua pointer">
                            <div className="inner" style={{ textAlign: 'center' }}>
                                <h5>Surat Akan Disetujui</h5>
                                <h3>{this.state.letterWillBeApproved}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="box box-success">
                            <div className="box-header box-header-dashboard">
                                <div className="row">
                                    <div className="col-md-9">
                                        <Fa.FaBarChart /> Status Surat Keluar 
                                    </div>
                                    <div className="col-md-3">
                                        <select 
                                        name="sort" 
                                        className="form-control"
                                        placeholder="Sort"
                                        value={this.state.sortLetter}
                                        onChange={e => this.setState({sortLetter: e.target.value})}
                                        >
                                        <option value="Daily">Hari ini</option>
                                        <option value="Weekly">Minggu ini</option>
                                        <option value="Monthly">Bulan ini</option>
                                        <option value="Yearly">Tahun Ini</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                {this.state.sortLetter === "Daily" ? 
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterDay} />
                                : this.state.sortLetter === "Weekly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterWeek} />
                                : this.state.sortLetter === "Monthly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterMonth} />
                                : this.state.sortLetter === "Yearly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterYear} />
                                : <p>No Data</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="box box-success">
                            <div className="box-header box-header-dashboard">
                                <div className="row">
                                    <div className="col-md-9">
                                        <Fa.FaBarChart /> Status Surat Keluar Per Jenis Surat
                                    </div>
                                    <div className="col-md-3">
                                        <select
                                        name="otherSort"
                                        className="form-control"
                                        placeholder="Sort"
                                        value={this.state.sortLetterType}
                                        onChange={e => this.setState({sortLetterType: e.target.value})}
                                        >
                                        <option value="Daily">Hari ini</option>
                                        <option value="Weekly">Minggu ini</option>
                                        <option value="Monthly">Bulan ini</option>
                                        <option value="Yearly">Tahun Ini</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                {this.state.sortLetterType === "Daily" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterTypeDay} />
                                : this.state.sortLetterType === "Weekly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterTypeWeek} />
                                : this.state.sortLetterType === "Monthly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterTypeMonth} />
                                : this.state.sortLetterType === "Yearly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterTypeYear} />
                                : <p>No Data</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {false && //Hide this feature
                  <div className="row">
                      <div className="col-md-6">
                          <div className="box box-success">
                              <div className="box-header box-header-dashboard">
                                  <div className="row">
                                      <div className="col-md-9">
                                          <Fa.FaBarChart /> Surat Keluar Tiap Pengguna
                                      </div>
                                      <div className="col-md-3">
                                          <select 
                                          name="userFilter"
                                          className="form-control" 
                                          placeholder="Filter"
                                          value={this.state.userFilter}
                                          onChange={e => this.setState({userFilter: e.target.value})}
                                          >
                                            <option value="A">Top 10</option>
                                            <option value="B">Top 20</option>
                                            <option value="C">Hari Ini</option>
                                            <option value="D">Minggu Ini</option>
                                            <option value="E">Bulan Ini</option>
                                            <option value="F">Tahun Ini</option>
                                          </select>
                                      </div>
                                  </div>
                              </div>
                              <div className="box-body">
                                  <HorizontalBar type='horizontalBar' data={this.state.horizontalBarData} options={this.state.horizontalBarOptions} />
                              </div>
                          </div>
                      </div>
                  </div>
                }
            </div>
        )
    }
}